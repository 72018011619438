import { collection, query, where, getDocs } from 'firebase/firestore';
import { firestore } from '@redux/firebase';
import store from '@redux/store';
import { setPodcast, setPodcastLoading, setPodcastError } from '@redux/reducers/podcastReducer';

/**
 * Recupera il podcast audio associato a un documento specifico
 * @param {string} documentId - ID del documento per cui recuperare il podcast
 * @param {string} userId - ID dell'utente corrente
 * @returns {Promise<Object|null>} - Dati del podcast audio o null se non trovato
 */
export const getPodcastAudioByDocumentId = async (documentId, userId) => {
  if (!documentId || !userId) {
    console.warn('getPodcastAudioByDocumentId: documentId o userId mancante', { documentId, userId });
    return null;
  }

  try {
    console.log(`Inizio caricamento podcast per documento ${documentId} e utente ${userId}`);
    
    // Prima controlla se abbiamo già questo podcast in Redux
    const state = store.getState();
    if (state.podcast && state.podcast.podcasts && state.podcast.podcasts[documentId]) {
      console.log(`Podcast trovato nella cache Redux per il documento ${documentId}:`, state.podcast.podcasts[documentId]);
      return state.podcast.podcasts[documentId];
    }

    // Imposta lo stato di caricamento
    store.dispatch(setPodcastLoading(true));

    // Semplifichiamo la query per evitare problemi di indici
    // Rimuoviamo l'ordinamento per created_at e il limite
    console.log(`Creazione query Firestore semplificata per documento ${documentId} e utente ${userId}`);
    const podcastQuery = query(
      collection(firestore, "podcastAudio"),
      where("document_id", "==", documentId),
      where("user_id", "==", userId)
    );

    // Esegui la query
    console.log('Esecuzione query Firestore...');
    const querySnapshot = await getDocs(podcastQuery);
    
    if (querySnapshot.empty) {
      console.log(`Nessun podcast trovato in Firestore per il documento ${documentId}`);
      store.dispatch(setPodcastLoading(false));
      return null;
    }

    // Poiché non abbiamo più l'ordinamento nella query, dobbiamo ordinare manualmente i risultati
    // per ottenere il podcast più recente
    let mostRecentPodcast = null;
    let mostRecentTimestamp = null;

    querySnapshot.forEach((doc) => {
      const podcastData = doc.data();
      const timestamp = podcastData.created_at ? podcastData.created_at.toMillis() : 0;
      
      if (!mostRecentTimestamp || timestamp > mostRecentTimestamp) {
        mostRecentPodcast = { ...podcastData, firestore_id: doc.id };
        mostRecentTimestamp = timestamp;
      }
    });
    
    if (!mostRecentPodcast) {
      console.log(`Nessun podcast valido trovato in Firestore per il documento ${documentId}`);
      store.dispatch(setPodcastLoading(false));
      return null;
    }
    
    console.log(`Podcast trovato in Firestore per il documento ${documentId}:`, mostRecentPodcast);
    
    // Crea l'oggetto podcast completo
    const podcast = {
      ...mostRecentPodcast,
      success: true,
      _loadedFromFirestore: true
    };

    // Salva in Redux
    console.log(`Salvataggio podcast in Redux per il documento ${documentId}`);
    store.dispatch(setPodcast({ documentId, podcastData: podcast }));
    store.dispatch(setPodcastLoading(false));
    
    // Restituisci i dati del podcast con l'ID del documento Firestore
    return podcast;
  } catch (error) {
    console.error(`Errore nel recupero del podcast audio per il documento ${documentId}:`, error);
    store.dispatch(setPodcastError(error.message));
    store.dispatch(setPodcastLoading(false));
    return null;
  }
};

/**
 * Salva o aggiorna il podcast nello store Redux
 * @param {Object} podcastData - Dati del podcast
 * @param {string} documentId - ID del documento associato
 */
export const savePodcastToRedux = (podcastData, documentId) => {
  if (!podcastData || !documentId) {
    console.warn('savePodcastToRedux: podcastData o documentId mancante');
    return;
  }

  store.dispatch(setPodcast({ documentId, podcastData }));
};