import React, { useEffect, useState, useCallback } from "react";
import "./index.scss";
import useGetDocuments from "@hooks/useGetDocuments";
import useGetFolders from "@hooks/useGetFolders";
import useSelectDocument from "./hook/useSelectDocument";
import { TbBook, TbCards, TbBrain, TbCheckbox } from "react-icons/tb";
import { Search, FolderPlus, X } from "lucide-react";
import { useSelector } from 'react-redux';

import Heading from "@ui/heading/heading";
import Spinner from "@ui/spinner/spinner";
import MaterialsDocuments from "./materialsDocuments/materialsDocuments";
import Flashcards from "./materialsFlashcards/materialFlashcards";
import MaterialsSummary from "./materialsSummary/materialsSummary";
import MaterialsQuestions from "./materialsQuestions/materialsQuestions";
import { Dot } from "lucide-react";
import MaterialsMultipleChoice from './materialsMultipleChoice/materialsMultipleChoice';
import Input from "@ui/input/input";

// Onboarding
import { Steps } from 'intro.js-react';
import useGetOnboardingState from "../uploadNotes/uploadNotesCard/uploadNotesForm/hooks/useGetOnboardingState";
import { getDocumentById } from "../../lib/firestoreQueries";

const MENU_ITEMS = [
  { id: 'questions', icon: TbBrain, label: 'Domande ripetizione', subtitle: 'Domande esame orale', color: 'rgb(234 88 12)' },
  { id: 'flashcards', icon: TbCards, label: 'Flashcard', subtitle: 'Memorizzazione rapida', color: 'rgb(5 150 105)' },
  { id: 'quiz', icon: TbCheckbox, label: 'Domande risposta multipla', subtitle: 'Per esami scritti', color: 'rgb(147 51 234)' },
  { id: 'summary', icon: TbBook, label: 'Riassunto', subtitle: 'Lettura e comprensione', color: 'rgb(37 99 235)' }
];

const Materials = () => {
  const { isLoading: isLoadingDocuments, documents } = useGetDocuments();
  const { isLoading: isLoadingFolders } = useGetFolders();
  const { selectedDocument, changeDocumentId } = useSelectDocument();
  
  // Combinazione degli stati di caricamento
  const isLoading = isLoadingDocuments || isLoadingFolders;
  
  const [currentLink, setCurrentLink] = useState('questions');
  const [searchMode, setSearchMode] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [createFolderFn, setCreateFolderFn] = useState(null);

  //Onboarding states
  const currentStep = useSelector(state => state.onboarding.currentStep);
  const [stepsEnabled, setStepsEnabled] = useState(false);
  const { getCurrentStep, updateCurrentStep } = useGetOnboardingState();
  const [onboardingDocument, setOnboardingDocument] = useState(null);
  
  // Aggiungere un nuovo stato per tracciare quando l'utente è in una cartella
  const [isInsideFolder, setIsInsideFolder] = useState(false);

  // Recupera tutti i documenti e cartelle dallo stato
  const allDocuments = useSelector(state => state.document.documents);
  const allFolders = useSelector(state => state.document.folders);
  
  // Log di debug per mostrare quali documenti sono in cartelle
  useEffect(() => {
    if (!isLoadingDocuments && !isLoadingFolders) {
      // Conta i documenti nelle cartelle
      const documentIdsInFolders = new Set();
      
      allFolders.forEach(folder => {
        if (folder.documentIds && Array.isArray(folder.documentIds)) {
          folder.documentIds.forEach(docId => documentIdsInFolders.add(docId));
        }
      });
      
      console.log(`Documenti in cartelle: ${documentIdsInFolders.size} su ${allDocuments.length}`);
      
      // Verifica se ci sono documenti recenti non visualizzati
      if (allDocuments.length > 0) {
        // Ordina i documenti per data di caricamento (dal più recente)
        const sortedDocuments = [...allDocuments].sort((a, b) => {
          const dateA = a.uploadDate?.seconds || 0;
          const dateB = b.uploadDate?.seconds || 0;
          return dateB - dateA;
        });
        
        // Mostra i 5 documenti più recenti
        console.log("Documenti più recenti:", sortedDocuments.slice(0, 5).map(doc => ({
          id: doc.id,
          title: doc.title,
          date: new Date(doc.uploadDate?.seconds * 1000).toISOString()
        })));
      }
    }
  }, [allDocuments, allFolders, isLoadingDocuments, isLoadingFolders]);
  
  // Funzione per recuperare il documento e il riassunto
  const fetchDocument = async () => {
    try {
      const data = await getDocumentById('yZI5jQzrvGbTsid0kvk6'); // Usa l'ID del documento selezionato
      setOnboardingDocument(data); // Imposta il riassunto recuperato
    } catch (error) {
      console.error('Error fetching document:', error);
    } 
  };

  useEffect(() => {
    if(currentStep < 22) { 
      fetchDocument();
    }
  },[currentStep]);
  
  // Determina se il documento usa il vecchio o il nuovo formato
  const isLegacyFormat = selectedDocument?.summary && typeof selectedDocument.summary === 'string';
  
  // Filtra i menu items in base al formato del documento
  const availableMenuItems = isLegacyFormat 
    ? MENU_ITEMS.filter(item => item.id === 'summary' || item.id === 'flashcards')
    : MENU_ITEMS;

  const getMenuItemStyles = (itemId) => {
    const isActive = currentLink === itemId;
    
    return {
      link: `Materials__menu__${itemId}-link${isActive ? `__${itemId}-active` : ''}`,
      iconBg: `Materials__menu__${isActive ? `${itemId}-icon-bg` : 'icon-bg'}`,
      dotColor: `Materials__menu__${itemId}-circle-icon`
    };
  };

  const renderMenuItem = (item) => {
    const styles = getMenuItemStyles(item.id);
    const Icon = item.icon;
    
    return (
      <div 
        key={item.id}
        className={styles.link}
        onClick={() => setCurrentLink(item.id)}
      >
        <div className={styles.iconBg}>
          <Icon size={18} style={{ minWidth: '18px' }} />
        </div>
        
        <div>
          <h4 className="Materials__menu__title">
            {item.label}
          </h4>
          <span className="Materials__menu__subtitle">
            {item.subtitle}
          </span>
        </div>
        
        {currentLink === item.id && (
          <Dot className={styles.dotColor} size={50} />
        )}
      </div>
    );
  };

  const renderContent = () => {
    switch(currentLink) {
      case 'flashcards':
        return <Flashcards idSelectedDocument={selectedDocument?.id} onboardingDocId={selectedDocument === 'yZI5jQzrvGbTsid0kvk6' ? 'yZI5jQzrvGbTsid0kvk6' : null} />;
      case 'summary':
        return <MaterialsSummary document={selectedDocument} onboardingDocument={onboardingDocument?.summary} />;
      case 'questions':
        return <MaterialsQuestions idSelectedDocument={selectedDocument?.id} onboardingDocId={selectedDocument === 'yZI5jQzrvGbTsid0kvk6' ? 'yZI5jQzrvGbTsid0kvk6' : null} />;
      case 'quiz':
        return <MaterialsMultipleChoice idSelectedDocument={selectedDocument?.id} onboardingDocId={selectedDocument === 'yZI5jQzrvGbTsid0kvk6' ? 'yZI5jQzrvGbTsid0kvk6' : null} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    getCurrentStep();

    if (!isLoading && currentStep <= 20) {
      setStepsEnabled(true);
    };
  }, [isLoading, currentStep, getCurrentStep]);

  const steps = [
    currentStep === 15 && {
      element: '.Document__card',
      intro: "Questo è il documento che hai caricato",
    },
    currentStep === 16 && {
      intro: window.innerWidth > 450 
        ? "Per ogni documento caricato, potrai accedere:" 
        : "Per ogni documento caricato, potrai accedere alle domande e al riassunto",
    },
    currentStep === 17 && window.innerWidth > 450 && {
      element: '.Materials__menu__questions-link__questions-active',
      intro: "Alle domande d'esame generate dal documento",
    },
    currentStep === 18 && window.innerWidth > 450 && {
      element: '.Materials__menu__flashcards-link',
      intro: "Alle flashcards generate dal documento",
    },
    currentStep === 19 && window.innerWidth > 450 && {
      element: '.Materials__menu__quiz-link',
      intro: "Al quiz generato dal documento",
    },
    currentStep === 20 && window.innerWidth > 450 && {
      element: '.Materials__menu__summary-link',
      intro: "Al riassunto estratto dal documento",
    },
  ].filter(Boolean);
  
  const onExit = async () => {
    const isMobile = window.innerWidth <= 450;

    if(isMobile) {
      if(currentStep === 15) {
        updateCurrentStep(currentStep+1); 
      } else if(currentStep > 15) {
        updateCurrentStep(21);
      }
    } else if(currentStep >= 14 && currentStep < 21 && !isMobile) {
      updateCurrentStep(currentStep+1);
    }
    setStepsEnabled(false);
  };

  useEffect(() => {
    const isDesktop = window.innerWidth >= 768;
    const menuElement = document.querySelector('.Materials__menu');
   
    if(currentStep === 19 && isDesktop && menuElement) {
      menuElement.scrollTo({
        left: menuElement.scrollWidth,
        behavior: 'smooth'
      });
    }
    else if(currentStep >= 19 && !isDesktop && menuElement) {
      menuElement.scrollTo({
        left: menuElement.scrollWidth * 0.3 * (currentStep - 16),
        behavior: 'smooth'
      });
    }
  }, [currentStep]);

  // Modifica il callback setCreateFolderFunction per passare anche lo stato di navigazione dentro una cartella
  const setCreateFolderFunction = (fn) => {
    if (typeof fn === 'function') {
      setCreateFolderFn(() => fn);
    }
  };

  // Funzione per tracciare quando l'utente entra o esce da una cartella
  const handleFolderNavigation = useCallback((isInFolder) => {
    setIsInsideFolder(isInFolder);
  }, []);

  // Gestisce il clic sul pulsante di ricerca
  const toggleSearchMode = () => {
    setSearchMode(prev => !prev);
    if (searchMode) {
      setSearchQuery('');
    }
  };

  // Gestisce il clic sul pulsante di creazione cartella
  const handleCreateFolder = () => {
    if (createFolderFn && typeof createFolderFn === 'function') {
      createFolderFn();
    }
  };

  return (
    <div className="Materials">
      {stepsEnabled && (  
        <Steps
          enabled={stepsEnabled}
          steps={steps}
          initialStep={0}
          onExit={onExit}
        />
      )}
      <div className="container">
        <div className="row gy-xs-1 gy-lg-3">
          <div className="col-xs-12">
            <div className={isLoading ? 'Materials__loading-docs' : ""}>
              <div className="Materials__header">
                <Heading className={`Materials__title Materials__title--bold ${isInsideFolder ? 'Materials__title--full' : ''}`} heading="h4" title="Cartelle e documenti" />
                <div className="Materials__actions">
                  {!isInsideFolder && (
                    <button 
                      className={`Materials__action-button ${searchMode ? 'Materials__action-button--active' : ''}`} 
                      title={searchMode ? "Chiudi ricerca" : "Cerca documenti"} 
                      onClick={toggleSearchMode}
                    >
                      {searchMode ? <X size={20} /> : <Search size={20} />}
                    </button>
                  )}
                  {!isInsideFolder && (
                    <button 
                      className="Materials__action-button" 
                      title="Crea cartella" 
                      onClick={handleCreateFolder}
                    >
                      <FolderPlus size={20} />
                    </button>
                  )}
                </div>
              </div>
              
              {!isInsideFolder && searchMode && (
                <div className="Materials__search-container">
                  <Input
                    className="Materials__search-input"
                    type="text"
                    placeholder="Cerca per titolo o tag..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    autoFocus
                  />
                </div>
              )}
              
              <section className="Materials__loader-section">
                {isLoading && 
                  <div className="Materials__loader-section__inner-container">
                    <Spinner size="sm" />
                    <p className="Materials__loader-section__text">Caricamento Materiali...</p>
                  </div>
                }
              </section>
              {!isLoading && (
                <MaterialsDocuments
                  selectedDocument={selectedDocument}
                  onChange={changeDocumentId}
                  onCreateFolder={setCreateFolderFunction}
                  onFolderNavigation={handleFolderNavigation}
                  showSearchResults={searchMode && searchQuery.trim() !== ''}
                  searchQuery={searchQuery}
                />
              )}
            </div>
          </div>

          {!isLoading && (
            <div className="col-xs-12">
              <Heading className="Materials__title" heading="h4" title="Ripasso" />
              <div className="Materials__menu">
                {availableMenuItems.map(renderMenuItem)}
              </div>

              <Heading className="Materials__title" heading="h4" title={MENU_ITEMS.find(i => i.id === currentLink)?.label} />
              {renderContent()}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Materials;
