import React from "react";
import "./index.scss";

import UploadNotesProvider from "@context/uploadNotes/uploadNotesProvider";
import UploadNotesCard from "./uploadNotesCard/uploadNotesCard";
import DocumentsQA from "./documentsQA/documentsQA";
import CookieSolution from "../landing/CookieSolution/CookieSolution";
import TutorialPopup from "./../../components/TutorialPopup/TutorialPopup";
import { useSelector } from "react-redux";

const UploadNotes = () => {
  const currentStep = useSelector(state => state.onboarding.currentStep);

  return (
    <div className="UploadNotes">
      <div className="container">
        <div className="UploadNotes__wrapper">
          {currentStep === 0  && <TutorialPopup />}
          <UploadNotesProvider>
            <div className="col-xs-12 col-sm-10 col-md-8 col-lg-6">
              <UploadNotesCard />
            </div>
            <div className="flex justify-center col-xs-12 col-sm-10 col-md-8 col-lg-6">
              <DocumentsQA />
            </div>
          </UploadNotesProvider>
        </div>
      </div>
      <CookieSolution/>
    </div>
  );
};

export default UploadNotes;
