import React from 'react';
import PropTypes from 'prop-types';
import { Plus } from 'lucide-react';
import './CreateFolderTile.scss';

const CreateFolderTile = ({ onClick }) => {
  return (
    <li className="CreateFolderTile" onClick={onClick}>
      <div className='CreateFolderTile__card'>
        <div className='CreateFolderTile__icon-container'>
          <Plus size={28} className='CreateFolderTile__icon' strokeWidth={2} />
        </div>
        <p className='CreateFolderTile__text'>Crea cartella</p>
      </div>
    </li>
  );
};

CreateFolderTile.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CreateFolderTile;
