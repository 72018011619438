import React from 'react';
import PropTypes from 'prop-types';
import { HiLockClosed } from 'react-icons/hi';
import './ContentIndex.scss';

const ContentIndex = ({ 
  effectiveSummary, 
  processedContent, 
  isPremium, 
  activeSection, 
  handleSectionClick 
}) => {
  return (
    <div className="ContentIndex">
      <div className="ContentIndex__container">
        <div className="ContentIndex__content">
          <h3 className="ContentIndex__title">Indice dei contenuti</h3>
          <div className="ContentIndex__list">
            {/* Abstract */}
            {effectiveSummary?.abstract && (
              <div
                className={`ContentIndex__item ${
                  activeSection === 'abstract' ? 'ContentIndex__item--active' : ''
                } ${!isPremium && processedContent.abstractTruncated ? 'ContentIndex__item--partial' : ''}`}
                onClick={() => handleSectionClick('abstract')}
              >
                Introduzione
                {!isPremium && processedContent.abstractTruncated && (
                  <HiLockClosed className="ContentIndex__item-lock" />
                )}
              </div>
            )}

            {/* Sections */}
            {processedContent.sections.map((section, index) => {
              // Mostra tutte le sezioni, anche quelle bloccate
              return (
                <div
                  key={index}
                  className={`ContentIndex__item ${
                    activeSection === `section-${index}` ? 'ContentIndex__item--active' : ''
                  } ${
                    section.status === 'partial' ? 'ContentIndex__item--partial' : 
                    section.status === 'locked' ? 'ContentIndex__item--locked' : ''
                  }`}
                  onClick={() => section.status !== 'locked' ? handleSectionClick(`section-${index}`) : null}
                >
                  {section.title}
                  {(section.status === 'partial' || section.status === 'locked') && !isPremium && (
                    <HiLockClosed className="ContentIndex__item-lock" />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

ContentIndex.propTypes = {
  effectiveSummary: PropTypes.object,
  processedContent: PropTypes.object.isRequired,
  isPremium: PropTypes.bool.isRequired,
  activeSection: PropTypes.string,
  handleSectionClick: PropTypes.func.isRequired
};

export default ContentIndex; 