import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import autoLogin from "./autoLogin";
import { setUser } from "@redux/actions/authActions";
import { toast } from "react-toastify";

const useAuthentication = () => {
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // check if user signed in before
  const checkUserSign = useCallback(() => {
    return onAuthStateChanged(getAuth(), async user => {
      setLoader(true);
      
      if (!user) {
        setLoader(false);
        return;
      }

      // Se l'utente non ha verificato l'email
      if (!user.emailVerified) {
        setLoader(false);
        return navigate("/verifica-email", { replace: true });
      }

      // Se l'utente sta facendo il login con Google, non fare nulla
      // getRedirectResult si occuperà di gestire il flusso
      const isGoogleAuth = user.providerData[0]?.providerId === "google.com";
      const isAuthPage = window.location.pathname === "/signIn" || window.location.pathname === "/signUp";

      if (isGoogleAuth && isAuthPage) {
        setLoader(false);
        return;
      }

      // Per tutti gli altri casi, procedi con l'autoLogin
      try {
        const userInfo = await autoLogin(user);
        dispatch(setUser(userInfo));
        setLoader(false);
        navigate("/home", { replace: true });
      } catch (error) {
        console.error("Auto-login failed:", error);
        setLoader(false);
        toast.error(error.message);
      }
    });
  }, [dispatch, navigate]);

  useEffect(() => {
    const unsubscribe = checkUserSign();
    return () => {
      unsubscribe();
    };
  }, [checkUserSign]);

  return { loader };
};

export default useAuthentication;
