import {
  SET_DOCUMENT,
  SET_EXTRACTED_QA,
  SET_SUBMITTING_DOC,
  SET_PROCESSING_STATE,
  SET_GENERATE,
  SET_IS_LOADING,
  SET_DOCUMENTS_QA,
  SET_DOCUMENTS_TAGS,
  SET_SUMMARY,
  SET_PROCESSED_NOTES,
  DELETE__NOTE_FILE,
  RESET_PROCESSED_NOTES,
  SET_FLASHCARDS_QA,
  SET_QUIZ_QA,
  DELETE_DOCUMENT,
  SET_FEEDBACK,
  RESET_FEEDBACK,
  // Azioni per le cartelle
  CREATE_FOLDER,
  UPDATE_FOLDER,
  DELETE_FOLDER,
  ADD_DOCUMENT_TO_FOLDER,
  REMOVE_DOCUMENT_FROM_FOLDER,
  SELECT_DOCUMENT,
  SYNC_FOLDERS,
  REFRESH_FOLDERS,
  // Nuove costanti aggiunte
  FREE_DOCUMENTS_FROM_FOLDER,
  DOCUMENT_ADDED_TO_FOLDER_SUCCESS
 } from '../actions/types';
 
 
 import { matchFileName } from '../../context/uploadNotes/matchFileName';
 
 
 const initialState = {
  documents: [],
  extractedQA: [],
  flashcardsQA: [],
  quizQA: [],
  isSubmitting: false,
  isProcessing: false,
  isGenerate: false,
  isLoading: false,
  documentsQA: [],
  tags: [],
  summary: '',
  processedNotes: [],
  feedbacks: { // Nuovo stato per i feedback unificati
    questions: new Map(),
    flashcards: new Map()
  },
  // Nuovo campo per le cartelle
  folders: [],
  // Documento selezionato
  selectedDocument: null,
  // Contatore per forzare aggiornamenti UI
  folderRefreshCounter: 0,
 };
 
 
 /**
  * Fusione intelligente delle cartelle locali e quelle caricate da Firebase
  * Ottimizzata per gestire grandi set di dati
  */
 const mergeFolders = (localFolders, firebaseFolders) => {
   // Se non ci sono cartelle Firebase, restituisci solo quelle locali
   if (!firebaseFolders || !Array.isArray(firebaseFolders) || firebaseFolders.length === 0) {
     return [...localFolders];
   }
   
   // Se non ci sono cartelle locali, restituisci solo quelle Firebase
   if (!localFolders || !Array.isArray(localFolders) || localFolders.length === 0) {
     return [...firebaseFolders];
   }
   
   // Crea un Map delle cartelle Firebase per lookup efficiente
   // La chiave è una combinazione di nome e parentId per gestire cartelle con lo stesso nome in posizioni diverse
   const firebaseFolderMap = new Map();
   
   firebaseFolders.forEach(folder => {
     const key = `${(folder.name || '').toLowerCase()}_${folder.parentId || 'root'}`;
     firebaseFolderMap.set(key, true);
   });
   
   // Filtra le cartelle locali che non esistono su Firebase
   const uniqueLocalFolders = localFolders.filter(localFolder => {
     // Considera solo cartelle locali (che hanno un ID che inizia con 'local_')
     if (!localFolder.id || !localFolder.id.startsWith('local_')) {
       return false;
     }
     
     // Crea la stessa chiave di ricerca
     const key = `${(localFolder.name || '').toLowerCase()}_${localFolder.parentId || 'root'}`;
     
     // Se la cartella esiste già su Firebase, non aggiungerla
     return !firebaseFolderMap.has(key);
   });
   
   // Combina le cartelle Firebase con le cartelle locali uniche
   return [...firebaseFolders, ...uniqueLocalFolders];
 };
 
 
 /**
  * Funzione di utilità per ordinare le cartelle per data di creazione
  */
 const sortFoldersByDate = (folders) => {
   return [...folders].sort((a, b) => {
     // Gestisci il caso in cui createdAt potrebbe mancare
     if (!a.createdAt) return 1;
     if (!b.createdAt) return -1;
     
     // Converti le stringhe in oggetti Date se necessario
     const dateA = typeof a.createdAt === 'string' ? new Date(a.createdAt) : a.createdAt;
     const dateB = typeof b.createdAt === 'string' ? new Date(b.createdAt) : b.createdAt;
     
     // Ordina in ordine decrescente (più recenti prima)
     return dateB - dateA;
   });
 };
 
 
 // Funzione di validazione per verificare che un payload sia presente e valido
 const validatePayload = (payload, actionType) => {
  if (!payload) {
    console.error(`Payload mancante per l'azione ${actionType}`);
    return false;
  }
  
  if (typeof payload !== 'object') {
    console.error(`Payload non valido per l'azione ${actionType}. Atteso un oggetto, ricevuto: ${typeof payload}`);
    return false;
  }
  
  return true;
 };
 
 // Funzione di validazione per verificare che un ID sia presente e valido
 const validateId = (id, entityName) => {
  if (!id) {
    console.error(`ID ${entityName} non valido o mancante`);
    return false;
  }
  
  if (typeof id !== 'string') {
    console.error(`ID ${entityName} deve essere una stringa, ricevuto: ${typeof id}`);
    return false;
  }
  
  return true;
 };
 
 // Funzioni di utilità per gestire ID cartelle e sottocartelle

 /**
  * Determina se un ID appartiene a una sottocartella
  * @param {string} folderId - ID della cartella da controllare
  * @returns {boolean} - true se è una sottocartella, false altrimenti
  */
 const isSubfolder = (folderId) => {
   if (!folderId) return false;
   return folderId.startsWith('subfolder_');
 };

 /**
  * Estrae l'ID del genitore da un ID sottocartella
  * @param {string} subfolderId - ID della sottocartella
  * @returns {string|null} - ID del genitore o null se non è una sottocartella valida
  */
 const getParentIdFromSubfolder = (subfolderId) => {
   if (!isSubfolder(subfolderId)) return null;
   
   const parts = subfolderId.split('_parent_');
   if (parts.length < 2) {
     console.error("Formato ID sottocartella non valido:", subfolderId);
     return null;
   }
   
   const parentId = parts[1];
   // Rimuovi il prefisso 'firebase_' se presente
   return parentId.startsWith('firebase_') ? parentId.replace('firebase_', '') : parentId;
 };

 /**
  * Trova una cartella nello store, gestendo correttamente prefissi Firebase
  * @param {Array} folders - Array di cartelle dal Redux store
  * @param {string} folderId - ID della cartella da trovare
  * @returns {Object|null} - Oggetto cartella o null se non trovata
  */
 const findFolderById = (folders, folderId) => {
   if (!folderId || !folders || !Array.isArray(folders)) return null;
   
   // Normalizza l'ID rimuovendo eventuali prefissi
   const normalizedId = folderId.startsWith('firebase_') ? folderId.replace('firebase_', '') : folderId;
   
   // Cerca la cartella con qualsiasi formato dell'ID
   return folders.find(folder => 
     folder.id === folderId || 
     folder.id === normalizedId || 
     folder.id === `firebase_${normalizedId}`
   );
 };
 
 const documentReducer = (state = initialState, action) => {
  // Validazione di base dell'azione
  if (!action || !action.type) {
    console.error('Azione non valida passata al reducer documentReducer');
    return state;
  }
  
  switch (action.type) {
    case SET_DOCUMENT:
      return {
        ...state,
        documents: [...action.payload],
      };
    case SET_SUMMARY:
      return {
        ...state,
        summary: action.payload,
      };
    case SET_PROCESSED_NOTES:
      return {
        ...state,
        processedNotes: [...state.processedNotes, action.payload],
      };
    case RESET_PROCESSED_NOTES:
      return {
        ...state,
        processedNotes: [],
      };
    case DELETE__NOTE_FILE: {
      const copyArray = [...state.processedNotes];
 
 
      // search file by name and delete it
      const indexFile = copyArray.findIndex(note => matchFileName(note.name, action.payload));
 
 
      // if file not found, return state
      if (indexFile === -1) return state;
      copyArray.splice(indexFile, 1);
 
 
      return { ...state, processedNotes: [...copyArray] };
    }
    case SET_DOCUMENTS_TAGS:
      return {
        ...state,
        tags: action.payload,
      };
    case SET_EXTRACTED_QA:
      return {
        ...state,
        extractedQA: action.payload,
      };
    case SET_DOCUMENTS_QA:
      return {
        ...state,
        documentsQA: action.payload,
      };
    case SET_FLASHCARDS_QA:
      return {
        ...state,
        flashcardsQA: action.payload,
      };
    case SET_QUIZ_QA:
      return {
        ...state,
        quizQA: action.payload,
      };
    case SET_PROCESSING_STATE:
      return {
        ...state,
        isProcessing: action.payload,
      };
    case SET_GENERATE:
      return {
        ...state,
        isGenerate: action.payload,
      };
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_SUBMITTING_DOC:
      return {
        ...state,
        isSubmitting: action.payload,
      };
    case DELETE_DOCUMENT: {
      // ID del documento da eliminare
      const documentId = action.payload;
      
      if (!documentId) {
        console.error('DELETE_DOCUMENT: ID documento mancante');
        return state;
      }
      
      // Rimuovere il documento eliminato anche dalle cartelle
      const updatedFolders = state.folders.map(folder => {
        // Verifica che folder.documentIds sia un array prima di chiamare filter
        if (!folder || !Array.isArray(folder.documentIds)) {
          return folder;
        }
        
        return {
          ...folder,
          documentIds: folder.documentIds.filter(id => id !== documentId)
        };
      });
      
      return {
        ...state,
        documents: state.documents.filter(doc => doc.id !== documentId),
        folders: updatedFolders
      };
    }
    // Gestione cartelle
    case CREATE_FOLDER: {
      console.log('Creazione cartella nel reducer:', action.payload);
      
      // Validazione dell'input
      if (!action.payload || typeof action.payload !== 'object') {
        console.error('Payload non valido per CREATE_FOLDER:', action.payload);
        return state;
      }
      
      // Verifica che i campi obbligatori siano presenti
      const { id, name, parentId } = action.payload;
      if (!id || !name) {
        console.error('Payload incompleto per CREATE_FOLDER:', action.payload);
        return state;
      }
      
      // Verifica che non ci siano cartelle con lo stesso ID
      const existingFolder = state.folders.find(folder => folder.id === id);
      if (existingFolder) {
        console.error(`Cartella con ID ${id} già esistente`);
        return state;
      }
      
      // Prepara il payload con valori di default per campi opzionali
      const folderPayload = {
        ...action.payload,
        documentIds: Array.isArray(action.payload.documentIds) ? action.payload.documentIds : [],
        createdAt: action.payload.createdAt || new Date().toISOString(),
        color: action.payload.color || 'blue'
      };
      
      // Se è una sottocartella, aggiorna la relazione con il genitore
      if (parentId) {
        // Cerca la cartella genitore
        const parentFolder = findFolderById(state.folders, parentId);
        
        if (!parentFolder) {
          console.error(`Cartella genitore con ID ${parentId} non trovata per la creazione della sottocartella`);
          // Creiamo comunque la cartella, ma senza relazione genitore-figlio
          return {
            ...state,
            folders: sortFoldersByDate([...state.folders, folderPayload])
          };
        }
        
        // Log per debug
        console.log(`Creazione cartella "${name}" dentro cartella genitore "${parentFolder.name}" (ID: ${parentId})`);
        
        return {
          ...state,
          folders: sortFoldersByDate([
            // Aggiungiamo la nuova cartella all'array principale
            ...state.folders, 
            folderPayload,
            // Aggiorniamo la cartella genitore
          ].map(folder => {
            if (folder.id === parentId) {
              // Aggiungiamo la relazione in subfolders
              const subfolders = Array.isArray(folder.subfolders) ? folder.subfolders : [];
              
              console.log(`Aggiornando sottocartelle per "${folder.name}":`, 
                [...subfolders.map(sf => sf.name), name].join(', '));
              
              return {
                ...folder,
                subfolders: [...subfolders, {
                  id: id,
                  name: name,
                  documentIds: folderPayload.documentIds,
                  createdAt: folderPayload.createdAt,
                  color: folderPayload.color,
                  parentId: parentId
                }]
              };
            }
            return folder;
          }))
        };
      }
      
      // Cartella di primo livello
      console.log(`Creazione cartella di primo livello: "${name}"`);
      return {
        ...state,
        folders: sortFoldersByDate([...state.folders, folderPayload])
      };
    }
    case UPDATE_FOLDER: {
      const { folderId, updates } = action.payload;
      console.log(`Aggiornamento cartella ${folderId} nel reducer:`, updates);
      
      // Utilizza la funzione isSubfolder per verificare se è una sottocartella
      if (isSubfolder(folderId)) {
        const parentId = getParentIdFromSubfolder(folderId);
        if (!parentId) {
          console.error("ID cartella genitore non trovato per la sottocartella:", folderId);
          return state;
        }
        
        // Usa findFolderById per trovare la cartella genitore
        const parentFolder = findFolderById(state.folders, parentId);
        if (!parentFolder) {
          console.error(`Cartella genitore con ID ${parentId} non trovata`);
          return state;
        }
        
        return {
          ...state,
          folders: state.folders.map(folder => {
            // Verifica se questa è la cartella genitore
            if (folder.id === parentFolder.id) {
              const subfolders = folder.subfolders || [];
              
              // Aggiorna la sottocartella
              const updatedSubfolders = subfolders.map(subfolder => {
                if (subfolder.id === folderId) {
                  // Se ci sono documenti da rimuovere, li filtriamo
                  if (updates.removeDocumentIds && Array.isArray(updates.removeDocumentIds) && updates.removeDocumentIds.length > 0) {
                    const updatedDocumentIds = Array.isArray(subfolder.documentIds) ?
                      subfolder.documentIds.filter(id => !updates.removeDocumentIds.includes(id)) :
                      [];
                    
                    return {
                      ...subfolder,
                      ...updates,
                      documentIds: updatedDocumentIds
                    };
                  }
                  // Se ci sono documentIds espliciti, li utilizziamo direttamente
                  else if (updates.documentIds) {
                    return {
                      ...subfolder,
                      ...updates
                    };
                  }
                  // Altrimenti, aggiorniamo normalmente la sottocartella
                  return { ...subfolder, ...updates };
                }
                return subfolder;
              });
              
              return {
                ...folder,
                subfolders: updatedSubfolders
              };
            }
            return folder;
          })
        };
      }
      
      // Cartella di primo livello
      return {
        ...state,
        folders: state.folders.map(folder => {
          if (folder.id === folderId) {
            console.log(`Aggiornamento cartella di primo livello: ${folder.name}`, updates);
            
            // Se ci sono documenti da rimuovere, li filtriamo
            if (updates.removeDocumentIds && Array.isArray(updates.removeDocumentIds) && updates.removeDocumentIds.length > 0) {
              const updatedDocumentIds = Array.isArray(folder.documentIds) ?
                folder.documentIds.filter(id => !updates.removeDocumentIds.includes(id)) :
                [];
              
              return {
                ...folder,
                ...updates,
                documentIds: updatedDocumentIds
              };
            }
            // Altrimenti, aggiorniamo normalmente la cartella
            return { ...folder, ...updates };
          }
          return folder;
        })
      };
    }
    case DELETE_FOLDER: {
      // Utilizza le funzioni di utilità per trovare la cartella
      const folderToDelete = findFolderById(state.folders, action.payload);
      
      // Se la cartella non esiste, rimuovi solo dall'array di cartelle
      if (!folderToDelete) {
        console.log(`Cartella con ID ${action.payload} non trovata, rimuoviamo solo dall'array`);
        return {
          ...state,
          folders: state.folders.filter(folder => 
            folder.id !== action.payload && 
            folder.id !== `firebase_${action.payload}` &&
            `firebase_${folder.id}` !== action.payload
          )
        };
      }
      
      // Nota: i documenti contenuti nella cartella devono essere già stati liberati
      // dall'azione FREE_DOCUMENTS_FROM_FOLDER che dovrebbe essere chiamata prima di DELETE_FOLDER
      
      // Gestione diversa per sottocartelle
      if (isSubfolder(folderToDelete.id)) {
        const parentId = getParentIdFromSubfolder(folderToDelete.id);
        if (parentId) {
          console.log(`Eliminando sottocartella ${folderToDelete.id} con genitore ${parentId}`);
          
          return {
            ...state,
            // Rimuovi la cartella dall'array
            folders: state.folders.filter(folder => folder.id !== folderToDelete.id)
              .map(folder => {
                // Aggiorna la struttura della cartella genitore se esiste
                const isParent = folder.id === parentId || 
                                folder.id === `firebase_${parentId}` ||
                                `firebase_${folder.id}` === parentId;
                
                if (isParent && folder.subfolders) {
                  // Rimuovi la sottocartella dall'array subfolders del genitore
                  return {
                    ...folder,
                    subfolders: folder.subfolders.filter(sf => sf.id !== folderToDelete.id)
                  };
                }
                return folder;
              })
          };
        }
      }
      
      // Per cartelle principali, semplicemente rimuoviamo dall'array di cartelle
      console.log(`Eliminando cartella principale ${folderToDelete.id}`);
      return {
        ...state,
        folders: state.folders.filter(folder => folder.id !== folderToDelete.id)
      };
    }
    case ADD_DOCUMENT_TO_FOLDER: {
      const { folderId, documentId } = action.payload;
      
      // Verifica che il documento esista
      if (!state.documents.some(doc => doc.id === documentId)) {
        console.error(`Documento con ID ${documentId} non trovato nello stato Redux`);
        return state;
      }
      
      // Crea un nuovo array di cartelle con il documento aggiunto alla cartella specificata
      const updatedFolders = state.folders.map(folder => {
        // Se è questa la cartella alla quale dobbiamo aggiungere il documento
        if (folder.id === folderId) {
          // Se la cartella ha già un array documentIds, aggiungi il nuovo documento
          // altrimenti crea un nuovo array con il documento
          const existingDocIds = folder.documentIds || [];
          
          // Se il documento è già presente, non fare nulla
          if (existingDocIds.includes(documentId)) {
            console.log(`Documento ${documentId} già presente nella cartella ${folderId}`);
            return folder;
          }
          
          const updatedFolder = {
            ...folder,
            documentIds: [...existingDocIds, documentId]
          };
          
          console.log(`Documento ${documentId} aggiunto alla cartella ${folderId} in Redux`);
          console.log(`Nuovi documentIds: ${updatedFolder.documentIds.length} documenti`);
          
          return updatedFolder;
        }
        
        return folder;
      });
      
      // Crea un nuovo stato con l'array di cartelle aggiornato
      return {
        ...state,
        folders: updatedFolders,
        // Incrementiamo il counter per forzare un aggiornamento dell'UI
        folderRefreshCounter: state.folderRefreshCounter + 1
      };
    }
    case REMOVE_DOCUMENT_FROM_FOLDER: {
      const { folderId, documentId } = action.payload;
      
      // Utilizziamo le funzioni di utilità per identificare il tipo di cartella
      if (isSubfolder(folderId)) {
        const parentId = getParentIdFromSubfolder(folderId);
        if (!parentId) {
          console.error("ID cartella genitore non trovato per la sottocartella:", folderId);
          return state;
        }
        
        // Cerca la cartella genitore usando la funzione di utilità
        const parentFolder = findFolderById(state.folders, parentId);
        if (!parentFolder) {
          console.error(`Cartella genitore con ID ${parentId} non trovata`);
          return state;
        }
        
        return {
          ...state,
          folders: state.folders.map(folder => {
            // Verifica se questa è la cartella genitore
            if (folder.id === parentFolder.id) {
              const subfolders = folder.subfolders || [];
              
              // Aggiorna la sottocartella
              const updatedSubfolders = subfolders.map(subfolder => {
                if (subfolder.id === folderId) {
                  const documentIds = subfolder.documentIds || [];
                  return {
                    ...subfolder,
                    documentIds: documentIds.filter(id => id !== documentId)
                  };
                }
                return subfolder;
              });
              
              return {
                ...folder,
                subfolders: updatedSubfolders
              };
            }
            return folder;
          })
        };
      }
      
      // Cartella di primo livello (comportamento esistente)
      // ma verifichiamo che documentIds sia un array
      return {
        ...state,
        folders: state.folders.map(folder => {
          if (folder.id === folderId) {
            // Aggiungiamo un controllo per assicurarci che documentIds sia un array
            const documentIds = Array.isArray(folder.documentIds) ? 
              folder.documentIds : [];
              
            return {
              ...folder,
              documentIds: documentIds.filter(id => id !== documentId)
            };
          }
          return folder;
        })
      };
    }
    case SET_FEEDBACK: {
      const { feedbackType, index, feedback } = action.payload;
      const newFeedbacks = {
        ...state.feedbacks,
        [feedbackType]: new Map(state.feedbacks[feedbackType]).set(index, feedback)
      };
      return {
        ...state,
        feedbacks: newFeedbacks
      };
    }
    case RESET_FEEDBACK: {
      return {
        ...state,
        feedbacks: {
          questions: new Map(),
          flashcards: new Map()
        }
      };
    }
    case SELECT_DOCUMENT:
      return {
        ...state,
        selectedDocument: action.payload,
      };
    case SYNC_FOLDERS:
      // Verifica che action.payload sia un array valido
      if (!action.payload || !Array.isArray(action.payload)) {
        console.error('SYNC_FOLDERS: payload non valido', action.payload);
        return state;
      }
      
      console.log('Sincronizzazione cartelle da Firebase', action.payload.length);
      return {
        ...state,
        folders: sortFoldersByDate(mergeFolders(state.folders, action.payload))
      };
    case REFRESH_FOLDERS:
      console.log('Forzando refresh delle cartelle nello store Redux');
      // Forza un nuovo array per triggerare aggiornamenti React
      // Creiamo un deep clone delle cartelle per garantire nuovi riferimenti
      // e applichiamo l'ordinamento per data
      return {
        ...state,
        folders: sortFoldersByDate(state.folders.map(folder => {
          // Deep clone della cartella
          const clonedFolder = {...folder};
          
          // Deep clone delle sottocartelle se presenti
          if (Array.isArray(folder.subfolders) && folder.subfolders.length > 0) {
            console.log(`Aggiornamento sottocartelle per ${folder.name} (ID: ${folder.id})`);
            clonedFolder.subfolders = folder.subfolders.map(subfolder => ({...subfolder}));
            console.log(`Sottocartelle aggiornate (${clonedFolder.subfolders.length}): `, 
              clonedFolder.subfolders.map(sf => sf.name || sf.id).join(', '));
          } else if (folder.subfolders && !Array.isArray(folder.subfolders)) {
            // Correggi se subfolders non è un array
            console.warn(`Correzione formato non-array di subfolders per la cartella ${folder.id}`);
            clonedFolder.subfolders = [];
          } else {
            console.log(`Nessuna sottocartella trovata per ${folder.name || folder.id}`);
          }
          
          return clonedFolder;
        }))
      };
    case FREE_DOCUMENTS_FROM_FOLDER: {
      const { folderId, documentIds, includeSubfolders, forceCleanup, finalCleanup } = action.payload;
      
      // Validazione input: verifica che documentIds sia un array non vuoto
      if (!documentIds || !Array.isArray(documentIds) || documentIds.length === 0) {
        console.error('FREE_DOCUMENTS_FROM_FOLDER: documentIds non valido', action.payload);
        return state;
      }
      
      // Il finalCleanup è un'operazione speciale che non richiede un folderId valido
      if (!finalCleanup && !folderId) {
        console.error('FREE_DOCUMENTS_FROM_FOLDER: folderId non valido', action.payload);
        return state;
      }
      
      console.log(`Liberando ${documentIds.length} documenti dalla cartella ${folderId}${includeSubfolders ? ' (incluse sottocartelle)' : ''}${forceCleanup ? ' (pulizia forzata)' : ''}${finalCleanup ? ' (pulizia finale)' : ''}`);
      
      // Se è finalCleanup, elaboriamo direttamente i documenti senza cercare cartelle
      let folderIdsToProcess = finalCleanup ? [] : [folderId];
      let allDocumentIds = [...documentIds];
      
      // Se non è finalCleanup, cerchiamo le cartelle correlate
      if (!finalCleanup) {
        // Verifica che la cartella esista effettivamente nello store
        const folder = state.folders.find(f => 
          f.id === folderId || 
          `firebase_${f.id.replace('firebase_', '')}` === folderId ||
          f.id === `firebase_${folderId.replace('firebase_', '')}`
        );
        
        // Se includeSubfolders è true e la cartella ha sottocartelle, aggiungi anche gli ID delle sottocartelle
        if (includeSubfolders && folder && Array.isArray(folder.subfolders) && folder.subfolders.length > 0) {
          // Aggiungi gli ID delle sottocartelle all'elenco delle cartelle da processare
          folderIdsToProcess = [
            ...folderIdsToProcess,
            ...folder.subfolders.map(sf => sf.id).filter(Boolean)
          ];
          
          // Raccogliamo tutti i documentIds da tutte le sottocartelle
          folder.subfolders.forEach(subfolder => {
            if (subfolder && Array.isArray(subfolder.documentIds)) {
              allDocumentIds = [...allDocumentIds, ...subfolder.documentIds];
            }
          });
          
          // Rimuovi duplicati
          allDocumentIds = [...new Set(allDocumentIds)];
          
          console.log(`Elaborazione estesa a ${folderIdsToProcess.length} cartelle con totale ${allDocumentIds.length} documenti`);
        }
        
        // Approccio più aggressivo: trova tutti i documenti che sono in qualsiasi cartella che corrisponde al pattern
        const allFolderIds = new Set(folderIdsToProcess);
        
        // Per il cleanup finale o forzato, estendi la ricerca
        if (forceCleanup || finalCleanup) {
          // Esplora tutte le cartelle per trovare riferimenti a cartelle in eliminazione
          state.folders.forEach(f => {
            if (allFolderIds.has(f.id)) return; // Ignora le cartelle che stiamo già elaborando
            
            // Cerca nelle sottocartelle per vedere se ci sono coincidenze
            if (Array.isArray(f.subfolders)) {
              f.subfolders.forEach(sf => {
                if (allFolderIds.has(sf.id)) {
                  // Aggiungi documenti della sottocartella correlata all'elenco
                  if (Array.isArray(sf.documentIds)) {
                    allDocumentIds = [...allDocumentIds, ...sf.documentIds];
                  }
                }
              });
            }
          });
        }
      }
      
      // Rimuovi duplicati un'ultima volta
      allDocumentIds = [...new Set(allDocumentIds)];
      
      // Aggiorna i documenti: segna i documenti come non più appartenenti a quella cartella
      const updatedDocuments = state.documents.map(doc => {
        if (allDocumentIds.includes(doc.id)) {
          // Creiamo una copia del documento per evitare mutazioni dirette
          const docCopy = { ...doc };
          
          // Se è cleanup finale o forzato, rimuovi completamente tutti i riferimenti
          if (finalCleanup || forceCleanup) {
            delete docCopy.folderId;
            delete docCopy.folderIds;
            console.log(`Documento ${doc.id} completamente liberato da tutte le associazioni`);
            return docCopy;
          }
          
          // Se il documento ha un folderId che corrisponde a una delle cartelle da processare, lo rimuoviamo
          if (folderIdsToProcess.some(fid => 
            docCopy.folderId === fid || 
            `firebase_${docCopy.folderId}` === fid ||
            docCopy.folderId === `firebase_${fid}`
          )) {
            delete docCopy.folderId;
            console.log(`Documento ${doc.id} liberato (folderId rimosso)`);
          }
          
          // Se il documento ha un folderIds array che include la cartella eliminata, lo rimuoviamo
          if (Array.isArray(docCopy.folderIds) && docCopy.folderIds.length > 0) {
            const originalLength = docCopy.folderIds.length;
            docCopy.folderIds = docCopy.folderIds.filter(id => 
              !folderIdsToProcess.some(fid => 
                id === fid || 
                `firebase_${id}` === fid || 
                id === `firebase_${fid}`
              )
            );
            
            if (docCopy.folderIds.length !== originalLength) {
              console.log(`Documento ${doc.id} liberato (rimosso da folderIds array)`);
            }
          }
          
          return docCopy;
        }
        return doc;
      });
      
      // Se è cleanup finale, elimina tutti i riferimenti alle cartelle specificate
      let updatedFolders = state.folders;
      
      if (finalCleanup) {
        // Pulisci completamente tutti i riferimenti
        updatedFolders = updatedFolders.map(folder => {
          let hasChanges = false;
          
          // Pulisci documentIds nei documenti specificati
          if (Array.isArray(folder.documentIds)) {
            const newDocIds = folder.documentIds.filter(id => !allDocumentIds.includes(id));
            if (newDocIds.length !== folder.documentIds.length) {
              hasChanges = true;
              folder = { ...folder, documentIds: newDocIds };
            }
          }
          
          // Pulisci sottocartelle
          if (Array.isArray(folder.subfolders) && folder.subfolders.length > 0) {
            // Pulisci documentIds in ogni sottocartella
            const newSubfolders = folder.subfolders.map(sf => {
              if (Array.isArray(sf.documentIds)) {
                const newSubfolderDocIds = sf.documentIds.filter(id => !allDocumentIds.includes(id));
                if (newSubfolderDocIds.length !== sf.documentIds.length) {
                  return { ...sf, documentIds: newSubfolderDocIds };
                }
              }
              return sf;
            });
            
            if (JSON.stringify(newSubfolders) !== JSON.stringify(folder.subfolders)) {
              hasChanges = true;
              folder = { ...folder, subfolders: newSubfolders };
            }
          }
          
          return hasChanges ? folder : folder;
        });
      } else {
        // NUOVO: Rimuovi esplicitamente tutti i riferimenti ai documenti in tutte le cartelle, incluso nelle sottocartelle
        const cleanFolders = folders => {
          return folders.map(folder => {
            let updated = false;
            
            // Pulisci documentIds nella cartella principale
            if (Array.isArray(folder.documentIds) && folder.documentIds.some(id => allDocumentIds.includes(id))) {
              updated = true;
              folder = {
                ...folder,
                documentIds: folder.documentIds.filter(id => !allDocumentIds.includes(id))
              };
            }
            
            // Pulisci documentIds nelle sottocartelle
            if (Array.isArray(folder.subfolders) && folder.subfolders.length > 0) {
              const updatedSubfolders = folder.subfolders.map(subfolder => {
                if (Array.isArray(subfolder.documentIds) && subfolder.documentIds.some(id => allDocumentIds.includes(id))) {
                  return {
                    ...subfolder,
                    documentIds: subfolder.documentIds.filter(id => !allDocumentIds.includes(id))
                  };
                }
                return subfolder;
              });
              
              // Se almeno una sottocartella è cambiata, aggiorna le sottocartelle
              if (updatedSubfolders.some((sf, i) => sf !== folder.subfolders[i])) {
                updated = true;
                folder = {
                  ...folder,
                  subfolders: updatedSubfolders
                };
              }
            }
            
            return updated ? folder : folder;
          });
        };
        
        // Aggiorna anche tutte le altre cartelle per rimuovere riferimenti ai documenti
        
        // Rimuovi prima le cartelle che stiamo eliminando (quelle in folderIdsToProcess)
        if (forceCleanup) {
          updatedFolders = updatedFolders.filter(folder => 
            !folderIdsToProcess.some(id => 
              folder.id === id || 
              `firebase_${folder.id}` === id || 
              folder.id === `firebase_${id}`
            )
          );
        }
        
        // Ora pulisci tutte le rimanenti
        updatedFolders = cleanFolders(updatedFolders);
      }
      
      // Crea un nuovo stato con gli array aggiornati e forza un aggiornamento componenti
      const newState = {
        ...state,
        documents: updatedDocuments,
        folders: updatedFolders,
        // Incrementiamo il counter per forzare un aggiornamento dell'UI
        folderRefreshCounter: state.folderRefreshCounter + 2 // +2 per garantire un aggiornamento forzato
      };
      
      console.log(`Completata liberazione dei documenti: ${allDocumentIds.length} documenti liberati`);
      return newState;
    }
    default:
      return state;
  }
 };
 
 
 export default documentReducer;