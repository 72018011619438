import React, { useState } from 'react';
import { X, Sparkles, ArrowRight } from 'lucide-react';
import styles from './TutorialPopup.module.scss';
// Onboarding imports
import { setCurrentStep } from '../../redux/actions/oboardingActions';
import useGetOnboardingState from '../../pages/uploadNotes/uploadNotesCard/uploadNotesForm/hooks/useGetOnboardingState';
import { useSelector } from 'react-redux';

const TutorialPopup = ({ onStart, onClose }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isClosing, setIsClosing] = useState(false);

  //onboarding
  const currentStep = useSelector(state => state.onboarding.currentStep);
  const { updateCurrentStep } = useGetOnboardingState({ setCurrentStep, currentStep });

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsVisible(false);
      onClose?.();
    }, 300);
    updateCurrentStep(22);

    // Add Google Analytics event
    try {    
      if ( typeof window.gtag === 'function') {
        window.gtag('event', 'onboardingSkipped', {
          'event_category': 'Onboarding',
          'event_label': 'Tutorial Popup',
        });
      }
    } catch (trackingError) {
      console.error("Errore nel tracciamento:", trackingError);
    }
  };

  const handleStart = () => {
    setIsClosing(true);
    setTimeout(() => {
      onStart?.();
    }, 300);
    updateCurrentStep(1);

    // Trigger the event
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'userStartTutorial', {
        'event_category': 'User',
        'event_label': 'user Start Tutorial',
      });        
    } else {
      console.error("gtag is not defined");
    }
  };

  if (!isVisible) return null;
  
  return (
    <div className={styles.overlay}>
      <div className={`${styles.tutorialPopup} ${isClosing ? styles.closing : ''}`}>
        <div className={styles.card}>
          {/* Header */}
          <div className={styles.header}>
            <button
              onClick={handleClose}
              className={styles.closeButton}
              aria-label="Close tutorial popup"
            >
              <X className={styles.closeIcon} />
            </button>
            <div className={styles.headerContent}>
              <div className={styles.iconWrapper}>
                <Sparkles className={styles.svgIcon} />
              </div>
              <h3 className={styles.title}>Vuoi iniziare il tutorial?</h3>
            </div>
          </div>
          
          {/* Content */}
          <div className={styles.content}>
            <div className={styles.contentBox}>
              <p className={styles.description}>
                Scopri tutte le funzionalità di StudierAI con una guida interattiva che ti segue passo dopo passo.
              </p>
              <div className={styles.featuresList}>
                <div className={styles.featureItem}>
                  <div className={styles.featureIconBadge}>1</div>
                  <p>Carica i tuoi appunti e documenti</p>
                </div>
                <div className={styles.featureItem}>
                  <div className={styles.featureIconBadge}>2</div>
                  <p>Genera domande pertinenti ai tuoi documenti</p>
                </div>
                <div className={styles.featureItem}>
                  <div className={styles.featureIconBadge}>3</div>
                  <p>Ripeti e simula l'interrogazione orale</p>
                </div>
                <div className={styles.featureItem}>
                  <div className={styles.featureIconBadge}>4</div>
                  <p>Ripassa i tuoi materiali</p>
                </div>
              </div>
            </div>
            
            <div className={styles.buttonGroup}>
              <button
                onClick={handleStart}
                className={styles.primaryButton}
              >
                Inizia il tutorial <ArrowRight size={18} className={styles.buttonIcon} />
              </button>
              <button
                onClick={handleClose}
                className={styles.secondaryButton}
              >
                Non ora
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TutorialPopup;