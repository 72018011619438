import {
  SET_DOCUMENT,
  SET_EXTRACTED_QA,
  SET_DOCUMENT_TITLE,
  SET_SUBMITTING_DOC,
  SET_DOCUMENTS_TAGS,
  SET_SUMMARY,
  SET_PROCESSED_NOTES,
  SET_FLASHCARDS_QA,
  SET_QUIZ_QA,
  DELETE_DOCUMENT,
  SET_FEEDBACK,
  RESET_FEEDBACK,
  CREATE_FOLDER,
  UPDATE_FOLDER,
  DELETE_FOLDER,
  ADD_DOCUMENT_TO_FOLDER,
  REMOVE_DOCUMENT_FROM_FOLDER,
  SELECT_DOCUMENT,
  SYNC_FOLDERS,
  REFRESH_FOLDERS,
  FREE_DOCUMENTS_FROM_FOLDER,
  DOCUMENT_ADDED_TO_FOLDER_SUCCESS
 } from './types';
 import {
  collection,
  addDoc,
  serverTimestamp,
  doc,
  setDoc,
  writeBatch,
  updateDoc,
 } from 'firebase/firestore';
 import { firestore, auth } from '../firebase';
 import { toast } from 'react-toastify';
 import {
  saveFolder,
  updateFolder as updateFirestoreFolder,
  deleteFolder as deleteFirestoreFolder,
  addDocumentToFolder as addDocToFirestoreFolder,
  removeDocumentFromFolder as removeDocFromFirestoreFolder
 } from '../../lib/firestoreQueries';
 import { store } from '../store';
 
 
 // Azioni sincrone
 export const setExtractedQA = (documentId, extractedQA) => ({
  type: SET_EXTRACTED_QA,
  payload: { documentId, extractedQA },
 });
 
 
 export const setFlashcardsQA = (flashcardsQA) => ({
  type: SET_FLASHCARDS_QA,
  payload: flashcardsQA,
 });
 
 
 export const setDocumentTitle = (documentId, newTitle) => ({
  type: SET_DOCUMENT_TITLE,
  payload: { documentId, newTitle },
 });
 
 
 export const setDocumentTags = (documentId, tags) => ({
  type: SET_DOCUMENTS_TAGS,
  payload: { documentId, tags },
 });
 
 
 export const setProcessedNotes = (file) => ({
  type: SET_PROCESSED_NOTES,
  payload: file,
 });
 
 
 export const setDocumentSummary = (summary) => ({
  type: SET_SUMMARY,
  payload: summary,
 });
 
 
 export const setSubmitDocState = (submitState) => ({
  type: SET_SUBMITTING_DOC,
  payload: submitState,
 });
 
 
 export const setQuizQA = (quizQA) => ({
  type: SET_QUIZ_QA,
  payload: quizQA,
 });
 
 
 export const setFeedback = (feedbackType, index, feedback) => ({
  type: SET_FEEDBACK,
  payload: { feedbackType, index, feedback }
 });
 
 
 export const resetFeedback = () => ({ type: RESET_FEEDBACK });
 
 
 // Costante per il limite di Firestore (lasciamo un margine di sicurezza)
 const FIRESTORE_DOC_SIZE_LIMIT = 1000000; // ~1MB
 
 
 // Funzione di utilità per troncare il testo se necessario
 const truncateText = (document) => {
  // Creiamo una copia dell'oggetto per non modificare l'originale
  const docCopy = { ...document };
   // Se non c'è testo, ritorniamo l'oggetto così com'è
  if (!docCopy.text) return docCopy;
 
 
  // Stimiamo la dimensione del documento senza il testo
  const docWithoutText = { ...docCopy, text: '' };
  const baseSize = new TextEncoder().encode(JSON.stringify(docWithoutText)).length;
   // Calcoliamo quanto spazio abbiamo per il testo
  const availableSpace = FIRESTORE_DOC_SIZE_LIMIT - baseSize - 100; // 100 bytes di margine extra
   // Se il testo è troppo lungo, lo tronchiamo
  const textSize = new TextEncoder().encode(docCopy.text).length;
  if (textSize > availableSpace) {
    // Calcoliamo il rapporto tra bytes e caratteri per questo testo specifico
    const bytesPerChar = textSize / docCopy.text.length;
    // Calcoliamo quanti caratteri possiamo mantenere
    const maxChars = Math.floor(availableSpace / bytesPerChar);
    // Tronchiamo il testo
    docCopy.text = docCopy.text.substring(0, maxChars) + ' [Testo troncato per limiti di dimensione]';
    console.log(`⚠️ Testo troncato da ${textSize} a ${new TextEncoder().encode(docCopy.text).length} bytes`);
  }
   return docCopy;
 };
 
 
 // Salva domande e feedback in batch
 const saveQABatch = async (
  examQuestions,
  documentId,
  userEmail,
  flashcards = [],
  quizQuestions = [],
  feedbacks,
  feedback = [],
  documentType
 ) => {
  try {
    const batch = writeBatch(firestore);
    let totalFeedbackCount = 0;
 
 
    // Verifica e pulisci i dati in ingresso
    const validExamQuestions = examQuestions.filter(q => q && q.question && q.answer);
    const validFlashcards = flashcards.filter(f => f && f.question && f.answer);
    const validQuizQuestions = quizQuestions.filter(q => q && q.query_text && q.answer_choices && q.correct_choice);
 
 
    console.log('🔍 Validazione dati:', {
      examQuestions: validExamQuestions.length,
      flashcards: validFlashcards.length,
      quizQuestions: validQuizQuestions.length
    });
 
 
    // Creazione timestamp unico per tutti gli elementi
    const currentTimestamp = serverTimestamp();
 
 
    // Creazione struttura unificata
    const questionDocRef = doc(collection(firestore, 'questions'), documentId);
    const questionData = {
      documentID: documentId,
      uploadDate: currentTimestamp,
      qaMap: {},
      metadata: {
        examCount: validExamQuestions.length,
        flashcardCount: validFlashcards.length,
        quizCount: validQuizQuestions.length
      },
    };
 
 
    // Popolazione mappa QA
    const addToMap = (items, type) => {
      items.forEach((item, index) => {
        const qaId = `${type}_${documentId}_${index}`;
        const feedbackKey = type === 'exam' ? 'questions' :
                          type === 'flashcard' ? 'flashcards' :
                          null;
        const feedbackArray = feedbacks[feedbackKey] || [];
        let feedbackQA = '';
 
 
        if(feedbackArray.size > 0) {
          feedbackQA = feedbackArray.has(index) ? feedbackArray.get(index) : null;
        };
 
 
        questionData.qaMap[qaId] = {
          ...item,
          id: qaId,
          type,
          isHelpful: feedbackQA,
          documentId,
          documentReference: {
            id: documentId,
            type: documentType || '',
          },
          metadata: {
            ...item.metadata,
          }
        };
      });
    };
 
 
    addToMap(validExamQuestions, 'exam');
    addToMap(validFlashcards, 'flashcard');
    addToMap(validQuizQuestions, 'quiz');
 
 
    // Salvataggio batch
    batch.set(questionDocRef, questionData, { merge: true });
 
 
    await batch.commit();
   
    return {
      success: true,
      totalSaved: Object.keys(questionData.qaMap).length,
      examQuestions: validExamQuestions.length,
      flashcards: validFlashcards.length,
      feedback: totalFeedbackCount
    };
  } catch (error) {
    console.error('❌ Errore nel salvataggio:', error);
    throw error;
  }
 };
 
 
 // Azione principale per salvare i documenti
 export const setDocument = (documents, feedbacks, feedbackStates = []) => {
  return async (dispatch) => {
    try {
      const userId = auth.currentUser?.uid;
      const userEmail = auth.currentUser?.email;
      const documentsArray = [];
 
 
      for (const document of documents) {
        // Tronchiamo il testo se necessario
        const processedDocument = truncateText(document);
       
        // Estrai le domande d'esame
        const examQuestions = processedDocument.examExtractedQA?.[0]?.qa_pairs || [];
 
 
        // Estrai i tags dal documento
        const documentTags = processedDocument.tags || [];
 
 
        // Estrai le flashcard dal formato corretto
        let flashcards = [];
        if (processedDocument.qa_response?.flashcards) {
          flashcards = processedDocument.qa_response.flashcards.map(card => ({
            question: card.card_question,
            answer: card.card_answer,
            type: 'flashcard',
            metadata: {
              isFlashcard: true,
              tags: documentTags
            }
          }));
        } else if (processedDocument.rawFlashcards?.flashcards) {
          flashcards = processedDocument.rawFlashcards.flashcards.map(card => ({
            question: card.card_question,
            answer: card.card_answer,
            type: 'flashcard',
            metadata: {
              isFlashcard: true,
              tags: documentTags
            }
          }));
        }
 
 
        const quizQuestions = processedDocument.quiz_questions || [];
 
 
        if (!examQuestions.length && !flashcards.length && !quizQuestions.length) {
          throw new Error('Non ci sono domande, flashcard o quiz da salvare');
        }
 
 
        // Filtra solo i feedback validi
        const validFeedbacks = feedbackStates.filter(f => f?.isHelpful !== null);
        const validFeedbackCount = validFeedbacks.length;
 
 
        console.log('📊 Feedback validi:', {
          totale: feedbackStates.length,
          validi: validFeedbackCount,
          feedbackStates: validFeedbacks.map(f => ({
            isValid: true,
            value: f
          }))
        });
 
 
        // Define main document data
        const documentData = {
          title: processedDocument.title,
          type: processedDocument.type,
          text: processedDocument.text,
          uploadDate: serverTimestamp(),
          lastModified: serverTimestamp(),
          uploadedBy: userId,
          userEmail: userEmail,
          isRemoved: false,
          tags: processedDocument.tags || [],
          summary: {
            abstract: processedDocument.summary?.abstract || '',
            sections: processedDocument.summary?.sections || [],
            hasAbstract: !!processedDocument.summary?.abstract,
            sectionsCount: processedDocument.summary?.sections?.length || 0
          },
          metadata: {
            questionsCount: examQuestions.length,
            flashcardsCount: flashcards.length,
            quizCount: quizQuestions.length,
            feedbackCount: validFeedbackCount,
            sectionsCount: processedDocument.summary?.sections?.length || 0,
            hasAbstract: !!processedDocument.summary?.abstract,
            isTextTruncated: processedDocument.text !== document.text
          }
        };
 
 
        // Save the document
        const docRef = await addDoc(collection(firestore, 'documents'), documentData);
        const documentId = docRef.id;
 
 
        console.log('💾 Salvataggio domande:', {
          examQuestions: examQuestions.length,
          flashcards: flashcards.length,
          total: examQuestions.length + flashcards.length,
          feedback: validFeedbackCount
        });
 
 
        // Save QA pairs with correct separation
        const saveResult = await saveQABatch(
          examQuestions,
          documentId,
          userEmail,
          flashcards,
          quizQuestions,
          feedbacks,
          processedDocument.type
        );
 
 
        // Update document with actual feedback count from batch save
        if (saveResult.feedback > 0) {
          await updateDoc(docRef, {
            'metadata.feedbackCount': saveResult.feedback
          });
          console.log('📝 Aggiornamento feedback count:', {
            salvati: saveResult.feedback,
            validi: validFeedbackCount
          });
        }
 
 
        documentsArray.push({ ...documentData, id: documentId });
      }
 
 
      dispatch({ type: SET_DOCUMENT, payload: documentsArray });
      return documentsArray;
    } catch (error) {
      console.error('❌ Errore nel salvataggio:', error);
      throw error;
    }
  };
 };
 
 
 // Action to change document title
 export const changeDocumentTitle = (documentId, newTitle) => {
  return async (dispatch) => {
    try {
      const documentRef = doc(firestore, 'documents', documentId);
      await setDoc(
        documentRef,
        {
          title: newTitle,
          lastModified: serverTimestamp(),
        },
        { merge: true }
      );
      dispatch(setDocumentTitle(documentId, newTitle));
    } catch (error) {
      console.error('❌ Errore modifica titolo:', error);
      throw error;
    }
  };
 };
 
 
 // Action to change tags for a specific document
 export const changeDocumentTags = (documentId, newTags) => {
  return async (dispatch) => {
    try {
      const documentRef = doc(firestore, 'documents', documentId);
      await setDoc(
        documentRef,
        {
          tags: newTags,
          lastModified: serverTimestamp(),
        },
        { merge: true }
      );
      dispatch(setDocumentTags(documentId, newTags));
    } catch (error) {
      console.error('❌ Error updating document tags:', error);
      throw error;
    }
  };
 };
 
 
 // Action to add summary for a specific document
 export const addDocumentSummary = (documentId, summary) => {
  return async (dispatch) => {
    try {
      const documentRef = doc(firestore, 'documents', documentId);
      await setDoc(
        documentRef,
        {
          summary: summary,
          lastModified: serverTimestamp(),
        },
        { merge: true }
      );
      dispatch(setDocumentSummary(summary));
    } catch (error) {
      console.error("Error updating document summary:", error.message);
      throw error;
    }
  };
 };
 
 
 // Action to delete a document
 export const deleteDocument = (documentId) => {
  return async (dispatch) => {
    try {
      const documentRef = doc(firestore, 'documents', documentId);
     
      // Update document with isRemoved flag instead of deleting
      await updateDoc(documentRef, {
        isRemoved: true,
        lastModified: serverTimestamp()
      });
     
      // Remove from Redux state
      dispatch({ type: DELETE_DOCUMENT, payload: documentId });
     
      toast.success('Documento eliminato con successo');
    } catch (error) {
      console.error('❌ Errore eliminazione documento:', error);
      toast.error('Errore durante l\'eliminazione del documento');
      throw error;
    }
  };
 };
 
 
 // Genera un ID unico per la cartella
 const generateFolderId = () => {
  return `local_${new Date().getTime()}_${Math.random().toString(36).substr(2, 9)}`;
 };
 
 
 // Variabile per tenere traccia dell'ultima richiesta di sincronizzazione
 let lastRefreshTime = 0;
 let pendingRefreshPromise = null;

 // Aggiungi questa azione tra le azioni sincrone all'inizio del file
 export const refreshFolders = (options = {}) => {
  return async (dispatch, getState) => {
    try {
      // Prima emette l'azione di refresh locale per aggiornare subito l'UI
      dispatch({ type: REFRESH_FOLDERS });
      
      // Controllo di throttling: non fare più di un'operazione di sincronizzazione ogni 5 secondi
      // A meno che non sia richiesto un aggiornamento immediato
      const now = Date.now();
      const { immediate = false } = options;
      
      if (!immediate && now - lastRefreshTime < 5000) {
        console.log("Sincronizzazione cartelle ignorata (troppo ravvicinata alla precedente)");
        // Se c'è già una sincronizzazione in corso, ritorna quella
        if (pendingRefreshPromise) {
          return pendingRefreshPromise;
        }
        // Altrimenti ritorna un successo immediato
        return Promise.resolve({ throttled: true });
      }
      
      // Aggiorna il timestamp
      lastRefreshTime = now;
      
      // Poi cerca di ottenere le cartelle da Firebase in background
      // Questa parte è asincrona e potrebbe richiedere più tempo
      const userId = auth.currentUser?.uid;
      if (!userId) {
        console.log("Utente non autenticato, refresh cartelle solo locale");
        return Promise.resolve({ localOnly: true });
      }
      
      // Ottieni le cartelle attuali dallo state
      const currentFolders = getState().document.folders || [];
      
      // Salva la promise corrente per potenziali chiamate ravvicinate
      pendingRefreshPromise = (async () => {
        try {
          // Usiamo le funzioni già importate all'inizio del file invece di un import dinamico
          // Importa il modulo delle query
          const firestoreQueries = await import('../../lib/firestoreQueries');
          
          // Recupera la lista delle cartelle da Firebase
          if (typeof firestoreQueries.getUserFolders === 'function') {
            const foldersFromFirebase = await firestoreQueries.getUserFolders();
            if (Array.isArray(foldersFromFirebase)) {
              // Controlla se ci sono effettivamente nuove cartelle o modifiche
              const firebaseFolderIds = new Set(foldersFromFirebase.map(f => f.id));
              const localFolderIds = new Set(currentFolders.map(f => f.id.replace('firebase_', '')));
              
              // Calcola quante cartelle nuove ci sono
              const newFolders = foldersFromFirebase.filter(f => !localFolderIds.has(f.id));
              
              if (newFolders.length > 0) {
                console.log(`Trovate ${newFolders.length} nuove cartelle da Firebase`);
                // Se ci sono novità, sincronizza
                dispatch({
                  type: SYNC_FOLDERS,
                  payload: foldersFromFirebase
                });
                return { success: true, newFolders: newFolders.length };
              } else {
                console.log('Nessuna nuova cartella da sincronizzare');
                return { success: true, unchanged: true };
              }
            }
            return { success: true, emptyResult: true };
          } else {
            console.log("La funzione getUserFolders non è disponibile, uso fallback");
            // Utilizzo fallback alternativo per retrocompatibilità
            if (typeof firestoreQueries.getFolders === 'function') {
              const folders = await firestoreQueries.getFolders();
              if (Array.isArray(folders) && folders.length > 0) {
                dispatch({
                  type: SYNC_FOLDERS,
                  payload: folders
                });
                return { success: true, usedFallback: true };
              }
            }
            return { success: false, reason: 'no_fallback_available' };
          }
        } catch (error) {
          console.error('Errore nel recupero delle cartelle da Firebase:', error);
          return { success: false, error };
        } finally {
          // Pulisci la promise pendente
          pendingRefreshPromise = null;
        }
      })();
      
      return pendingRefreshPromise;
    } catch (error) {
      console.error('Errore nel refresh delle cartelle:', error);
      pendingRefreshPromise = null;
      return Promise.reject(error);
    }
  };
 };
 
 
 // Funzione di supporto per calcolare il livello di nidificazione
 const calculateFolderNestingLevel = (folderId, folders) => {
  if (!folderId) return 1; // Root level
  
  // Funzione ricorsiva per calcolare il livello
  const calculateLevel = (currentId, visitedIds = new Set(), level = 1) => {
    if (!currentId) return level;
    
    // Prevenzione cicli infiniti
    if (visitedIds.has(currentId)) return level;
    visitedIds.add(currentId);
    
    // Trova la cartella corrente
    let currentFolder = folders.find(f => 
      f.id === currentId || 
      `firebase_${f.id}` === currentId || 
      f.id === `firebase_${currentId}` ||
      currentId.replace('firebase_', '') === f.id
    );
    
    if (!currentFolder || !currentFolder.parentId) return level;
    
    // Chiamata ricorsiva con il parentId
    return calculateLevel(currentFolder.parentId, visitedIds, level + 1);
  };
  
  // Inizia il calcolo ricorsivo
  return calculateLevel(folderId);
 };
 
 
 // Crea una nuova cartella sia in Redux che in Firebase
 export const createFolder = (name, documentIds = [], color = 'blue', parentId = null) => {
  return async (dispatch) => {
    try {
      // Verifica il livello di nidificazione se esiste parentId
      if (parentId) {
        const folders = store.getState().document.folders;
        const parentFolder = folders.find(f => f.id === parentId);
        
        // Verifica se la cartella genitore ha già un parentId (è già una cartella figlia)
        if (parentFolder && parentFolder.parentId) {
          console.error(`Impossibile creare cartella: le cartelle possono essere create solo a livello principale o come dirette figlie di una cartella principale`);
          return Promise.reject('Le cartelle possono essere create solo a livello principale o come dirette figlie di una cartella principale');
        }
        
        console.log(`Creazione cartella figlio nella cartella principale ${parentId}`);
      }
      
      // Prepara la nuova cartella con i suoi documenti
      const folderPayload = {
        id: generateFolderId(),
        name,
        documentIds: [...documentIds], // Copiamo l'array per evitare riferimenti incrociati
        createdAt: new Date().toISOString(),
        color,
        parentId
      };
      
      // 1. AGGIORNAMENTO REDUX IMMEDIATO (per UI reattiva)
      // Dispaccia subito l'azione per creare la cartella in Redux
      dispatch({
        type: CREATE_FOLDER,
        payload: folderPayload
      });
      
      // 2. Se stiamo spostando documenti da una cartella genitore, aggiorna subito anche il genitore in Redux
      if (documentIds.length > 0 && parentId) {
        dispatch({
          type: UPDATE_FOLDER,
          payload: {
            folderId: parentId,
            updates: {
              removeDocumentIds: documentIds
            }
          }
        });
      }
      
      // 3. FORZARE UN AGGIORNAMENTO IMMEDIATO dello stato Redux
      dispatch(refreshFolders());
      
      console.log("Creazione cartella in Firebase:", folderPayload);
      
      // 4. AGGIORNAMENTO FIREBASE (in background)
      // Esegue le operazioni su Firebase in parallelo quando possibile
      try {
        // Salva la cartella su Firebase
        const savedFolder = await saveFolder(folderPayload);
        console.log("Cartella salvata in Firebase:", savedFolder);
        
        // Aggiorna l'ID se necessario
        if (savedFolder.id !== folderPayload.id) {
          dispatch({
            type: UPDATE_FOLDER,
            payload: {
              folderId: folderPayload.id,
              updates: {
                id: savedFolder.id
              }
            }
          });
        }
        
        // Se ci sono documenti da spostare, aggiorna Firebase
        if (documentIds.length > 0) {
          // Crea un array di promesse per tutte le operazioni Firebase
          const operations = [];
          
          // Aggiungi documenti alla nuova cartella
          documentIds.forEach(docId => {
            operations.push(addDocToFirestoreFolder(savedFolder.id, docId));
          });
          
          // Rimuovi documenti dalla cartella genitore se necessario
          if (parentId) {
            documentIds.forEach(docId => {
              operations.push(removeDocFromFirestoreFolder(parentId, docId));
            });
          }
          
          // Esegui tutte le operazioni in parallelo
          await Promise.all(operations);
          console.log(`Operazioni Firebase completate per documenti`);
        }
        
        // Aggiornamento finale dopo tutte le operazioni Firebase
        dispatch(refreshFolders());
        
        return {
          folder: savedFolder,
          documentIds,
          parentId
        };
      } catch (error) {
        console.error('Errore nel salvataggio della cartella in Firebase:', error);
        
        if (error.message.includes('Cartella genitore non trovata')) {
          toast.error('Impossibile creare la cartella: cartella genitore non trovata');
        } else {
          toast.error('Errore nella creazione della cartella: ' + error.message);
        }
        
        // Rimuovi la cartella dal Redux state se il salvataggio su Firebase è fallito
        dispatch({
          type: DELETE_FOLDER,
          payload: folderPayload.id
        });
        
        throw error;
      }
    } catch (error) {
      console.error('Errore nella creazione della cartella:', error);
      throw error;
    }
  };
 };
 
 
 // Aggiorna una cartella sia in Redux che in Firebase
 export const updateFolder = (folderId, updates) => {
  return async (dispatch) => {
    try {
      // Aggiorna Redux
      dispatch({
        type: UPDATE_FOLDER,
        payload: {
          folderId,
          updates
        }
      });

      // Aggiorna la cartella su Firebase
      await updateFirestoreFolder(folderId, updates);
      
      return true;
    } catch (error) {
      console.error('Errore nell\'aggiornamento della cartella:', error);
      toast.error('Errore nell\'aggiornamento della cartella');
      throw error;
    }
  };
 };
 
 
 // Funzione di utilità per normalizzare gli ID delle cartelle
 const normalizeFirebaseId = (folderId) => {
  if (!folderId) return null;
  
  // Se l'ID ha già il prefisso firebase_, lo rimuoviamo
  if (folderId.startsWith('firebase_')) {
    return folderId.replace('firebase_', '');
  }
  return folderId;
 };
 
 
 // Elimina una cartella sia da Redux che da Firebase
 export const deleteFolder = (folderId, options = {}) => {
  return async (dispatch, getState) => {
    // Estratto il parametro existingToast dalle opzioni
    const { existingToast } = options;
    
    try {
      // Trova il nome della cartella e i suoi documenti per eventuali messaggi di log
      const state = getState();
      
      // Normalizziamo l'ID per gestire sia gli ID con prefisso che senza
      const normalizedId = normalizeFirebaseId(folderId);
      const firestoreId = normalizedId;
      
      // Cerca la cartella sia per ID originale che per ID normalizzato per gestire entrambi i casi
      const folderToDelete = state.document.folders.find(f => 
        f.id === folderId || 
        f.id === normalizedId || 
        f.id === `firebase_${normalizedId}`
      );
      
      if (!folderToDelete) {
        console.error(`Cartella con ID ${folderId} non trovata nello stato Redux`);
        return { success: false, error: 'Cartella non trovata' };
      }
      
      const folderName = folderToDelete.name || 'sconosciuta';
      const documentIds = folderToDelete.documentIds || [];
      
      // NUOVO: Raccogli tutti i documenti dalle sottocartelle se presenti
      let allDocumentIds = [...documentIds];
      let subfoldersToDelete = [];
      
      // Se è una cartella principale con sottocartelle, raccogli tutti i documenti dalle sottocartelle
      if (Array.isArray(folderToDelete.subfolders) && folderToDelete.subfolders.length > 0) {
        console.log(`La cartella "${folderName}" contiene ${folderToDelete.subfolders.length} sottocartelle`);
        
        folderToDelete.subfolders.forEach(subfolder => {
          if (subfolder && subfolder.id) {
            subfoldersToDelete.push(subfolder.id);
            
            // Aggiungi i documenti di questa sottocartella all'elenco completo
            if (Array.isArray(subfolder.documentIds)) {
              allDocumentIds = [...allDocumentIds, ...subfolder.documentIds];
              console.log(`Aggiunti ${subfolder.documentIds.length} documenti dalla sottocartella ${subfolder.name || subfolder.id}`);
            }
          }
        });
        
        // Rimuovi duplicati
        allDocumentIds = [...new Set(allDocumentIds)];
      }
      
      console.log(`Iniziata eliminazione cartella "${folderName}" (ID: ${folderId}) da Firebase e Redux`);
      console.log(`La cartella e sottocartelle contengono ${allDocumentIds.length} documenti che verranno liberati`);
      
      try {
        // NUOVA STRATEGIA: Liberare prima i documenti e rimuovere le sottocartelle da Firebase
        // prima di rimuovere la cartella principale
        
        // 1. FASE DI LIBERAZIONE DOCUMENTI
        if (allDocumentIds.length > 0) {
          // Fase 1.1: Liberare documenti dalla cartella principale
          dispatch({
            type: FREE_DOCUMENTS_FROM_FOLDER,
            payload: {
              folderId: folderToDelete.id,
              documentIds: documentIds,
              forceCleanup: true
            }
          });
          
          // Fase 1.2: Liberare documenti da ciascuna sottocartella individualmente
          for (const subfolder of subfoldersToDelete) {
            try {
              const subfoldersData = folderToDelete.subfolders || [];
              const subfoldersMatch = subfoldersData.find(sf => sf.id === subfolder);
              
              if (subfoldersMatch && Array.isArray(subfoldersMatch.documentIds) && subfoldersMatch.documentIds.length > 0) {
                console.log(`Liberazione esplicita di ${subfoldersMatch.documentIds.length} documenti dalla sottocartella ${subfolder}`);
                
                dispatch({
                  type: FREE_DOCUMENTS_FROM_FOLDER,
                  payload: {
                    folderId: subfolder,
                    documentIds: subfoldersMatch.documentIds,
                    forceCleanup: true
                  }
                });
              }
            } catch (subfErr) {
              console.warn(`Errore durante la liberazione dalla sottocartella ${subfolder}`, subfErr);
            }
          }
          
          // Forza un aggiornamento immediato dell'UI
          await dispatch(refreshFolders({ immediate: true }));
        }
        
        // 2. FASE DI ELIMINAZIONE SOTTOCARTELLE DA FIREBASE
        if (subfoldersToDelete.length > 0) {
          console.log(`Eliminazione di ${subfoldersToDelete.length} sottocartelle da Firebase`);
          
          // Elimina ogni sottocartella singolarmente da Firebase
          for (const subfolderIdToDelete of subfoldersToDelete) {
            try {
              // Estrai l'ID Firebase pulito dalla sottocartella
              const subfolderFirebaseId = getParentIdFromSubfolderID(subfolderIdToDelete);
              if (subfolderFirebaseId) {
                console.log(`Eliminazione diretta della sottocartella ${subfolderIdToDelete} da Firebase`);
                // Rimuovi la sottocartella da Firebase direttamente
                // Nota: questa è una nuova funzione che dobbiamo creare
                await removeSubfolderFromFirestoreFolder(firestoreId, subfolderIdToDelete);
              }
            } catch (subFolderErr) {
              console.warn(`Errore nell'eliminazione della sottocartella ${subfolderIdToDelete}:`, subFolderErr);
            }
          }
          
          // Aggiornamento post-eliminazione delle sottocartelle
          await dispatch(refreshFolders({ immediate: true }));
        }
        
        // 3. FASE DI RIMOZIONE CARTELLA PRINCIPALE DA REDUX
        // Rimuovi la cartella principale da Redux
        dispatch({
          type: DELETE_FOLDER,
          payload: folderToDelete.id
        });
        
        // 4. FASE DI RIMOZIONE CARTELLA PRINCIPALE DA FIREBASE
        // Elimina la cartella principale da Firebase
        const result = await deleteFirestoreFolder(firestoreId);
        console.log(`Cartella "${folderName}" (ID: ${folderId}) eliminata con successo da Firebase`);
        
        // 5. AGGIORNAMENTO FINALE
        dispatch(refreshFolders({ immediate: true }));
        
        // 6. NUOVO: LIBERA DOCUMENTI UNA SECONDA VOLTA
        // Questa è una misura di sicurezza per assicurarsi che i documenti siano completamente liberati
        if (allDocumentIds.length > 0) {
          console.log(`Liberazione finale di sicurezza per ${allDocumentIds.length} documenti`);
          dispatch({
            type: FREE_DOCUMENTS_FROM_FOLDER,
            payload: {
              folderId: 'CLEANUP_ALL_DOCUMENTS',  // ID fittizio per indicare un'operazione speciale
              documentIds: allDocumentIds,
              forceCleanup: true,
              finalCleanup: true
            }
          });
          
          // Forza un ultimo refresh
          setTimeout(() => {
            dispatch(refreshFolders({ immediate: true }));
          }, 300);
        }
        
        return {
          success: true,
          message: `Cartella "${folderName}" eliminata con successo`,
          documentIds: allDocumentIds,
          subfoldersCount: subfoldersToDelete.length
        };
      } catch (error) {
        console.error(`Errore nell'eliminazione della cartella "${folderName}" da Firebase:`, error);
        
        // In caso di errore, cerchiamo comunque di liberare i documenti e aggiornare Redux
        if (allDocumentIds.length > 0) {
          console.log(`Tentativo finale di liberazione di emergenza per ${allDocumentIds.length} documenti`);
          dispatch({
            type: FREE_DOCUMENTS_FROM_FOLDER,
            payload: {
              folderId: 'EMERGENCY_CLEANUP',
              documentIds: allDocumentIds,
              forceCleanup: true,
              finalCleanup: true
            }
          });
          
          dispatch(refreshFolders({ immediate: true }));
        }
        
        return {
          success: false,
          error: error.message || 'Errore nella sincronizzazione con il server',
          documentIds: allDocumentIds
        };
      }
    } catch (error) {
      console.error('Errore generale nell\'eliminazione della cartella:', error);
      throw error;
    }
  };
 };
 
 
 // Funzione di utilità per verificare la risposta di Firebase
 const validateFirebaseResponse = (response, operation) => {
  if (!response) {
    console.error(`Risposta Firebase non valida per l'operazione ${operation}`);
    return false;
  }
  
  // Controlla errori specifici di Firebase
  if (response.error || response.code) {
    console.error(`Errore Firebase per l'operazione ${operation}:`, response);
    return false;
  }
  
  return true;
 };
 
 
 // Aggiunge un documento a una cartella sia in Redux che in Firebase
 export const addDocumentToFolder = (folderId, documentId) => async (dispatch, getState) => {
  // Variabile per tenere traccia delle operazioni di pulizia in corso
  let cleanupPromise = null;
  
  try {
    // Normalizza l'ID della cartella
    const normalizedFolderId = normalizeFirebaseId(folderId);
    const firestoreId = normalizedFolderId;
    
    console.log(`Aggiungendo documento ${documentId} alla cartella ${folderId} (Firebase ID: ${firestoreId})`);
    
    // Gestisce prima il Redux
    dispatch({
      type: ADD_DOCUMENT_TO_FOLDER,
      payload: { folderId, documentId }
    });

    // Forza un aggiornamento immediato per l'interfaccia utente
    dispatch(refreshFolders());
    
    // Trova la cartella aggiornata nel Redux state
    const state = getState();
    const folders = state.document.folders;
    
    // Trova la cartella, provando sia con l'ID originale che con quello normalizzato
    const updatedFolder = folders.find(f => 
      f.id === folderId || 
      f.id === normalizedFolderId || 
      f.id === `firebase_${normalizedFolderId}`
    );
    
    if (!updatedFolder) {
      console.error(`Cartella con ID ${folderId} non trovata nello stato Redux dopo l'aggiornamento`);
      return { success: false, error: 'Cartella non trovata' };
    }
    
    // Ottimizziamo la gestione della cartella genitore
    // Creiamo un'unica Promise che gestisce tutte le operazioni di pulizia
    if (updatedFolder.parentId) {
      const parentId = normalizeFirebaseId(updatedFolder.parentId);
      const parentFolder = folders.find(f => 
        f.id === updatedFolder.parentId || 
        f.id === parentId || 
        f.id === `firebase_${parentId}`
      );
      
      if (parentFolder && parentFolder.documentIds && parentFolder.documentIds.includes(documentId)) {
        console.log(`Preparazione rimozione del documento ${documentId} dalla cartella genitore ${parentId}`);
        
        // Rimuovi immediatamente da Redux
        dispatch({
          type: REMOVE_DOCUMENT_FROM_FOLDER,
          payload: { 
            folderId: parentFolder.id, 
            documentId 
          }
        });
        
        // Prepara l'operazione di pulizia ma non blocca il flusso principale
        cleanupPromise = removeDocFromFirestoreFolder(parentId, documentId)
          .then(() => {
            console.log(`Documento ${documentId} rimosso con successo dalla cartella genitore`);
            dispatch(refreshFolders());
            return true;
          })
          .catch(error => {
            console.error('Errore nella rimozione dalla cartella genitore:', error);
            return false;
          });
      }
    }
    
    // Aggiungi il documento a Firebase
    return addDocToFirestoreFolder(firestoreId, documentId)
      .then((response) => {
        // Verifica la risposta di Firebase
        if (!validateFirebaseResponse(response, `addDocToFirestoreFolder(${firestoreId}, ${documentId})`)) {
          throw new Error('Errore nella comunicazione con Firebase');
        }
        
        console.log(`Documento ${documentId} aggiunto con successo alla cartella: ${firestoreId} (Totale documenti: ${updatedFolder?.documentIds?.length || 'N/A'})`);
        
        // Notifica l'aggiornamento avvenuto con successo
        dispatch({
          type: DOCUMENT_ADDED_TO_FOLDER_SUCCESS,
          payload: { folderId, documentId, updatedFolder }
        });
        
        // Forza un secondo aggiornamento dopo la sincronizzazione con Firebase
        dispatch(refreshFolders());
        
        // Se c'era un'operazione di pulizia, attendiamo che venga completata
        // ma non blocchiamo il ritorno della funzione
        if (cleanupPromise) {
          cleanupPromise
            .then((cleanupResponse) => {
              // Verifica la risposta di Firebase per l'operazione di pulizia
              if (!validateFirebaseResponse(cleanupResponse, 'removeDocFromFirestoreFolder')) {
                console.warn('Operazione di pulizia completata con warning');
              } else {
                console.log('Operazioni di pulizia completate con successo');
              }
            })
            .catch(err => console.error('Errore nelle operazioni di pulizia:', err));
        }
        
        return { success: true, folder: updatedFolder, response };
      })
      .catch(error => {
        console.error('Errore nell\'aggiunta del documento alla cartella su Firebase:', error);
        
        // In caso di errore, potremmo anche decidere di mantenere l'aggiornamento locale
        // per evitare confusione all'utente - dipende dai requisiti
        toast.error('Errore nella sincronizzazione con il server, ma il documento rimane assegnato localmente');
        
        // Per maggiore coerenza, potremmo ripristinare lo stato precedente
        // dispatch({
        //   type: REMOVE_DOCUMENT_FROM_FOLDER,
        //   payload: { folderId, documentId }
        // });
        
        return { 
          success: false, 
          error: error.message || 'Errore sconosciuto',
          localSuccess: true  // Indica che l'operazione è riuscita localmente
        };
      });
  } catch (error) {
    console.error('Errore generale nell\'aggiunta del documento alla cartella:', error);
    
    // Ripristina lo stato precedente in caso di errore
    dispatch({
      type: REMOVE_DOCUMENT_FROM_FOLDER,
      payload: { folderId, documentId }
    });
    
    toast.error('Impossibile aggiungere il documento alla cartella');
    throw error;
  }
};
 
 
 // Rimuove uno o più documenti da una cartella sia in Redux che in Firebase
 export const removeDocumentFromFolder = (folderId, documentId) => {
  return async (dispatch) => {
    try {
      // Verifica se documentId è un array o un singolo ID
      const documentIds = Array.isArray(documentId) ? documentId : [documentId];
      
      if (documentIds.length === 0) {
        console.log("Nessun documento da rimuovere");
        return { success: true, folderId, documentIds: [] };
      }
      
      console.log(`Rimozione di ${documentIds.length} documenti dalla cartella ${folderId}`);
      
      // Aggiorna Redux per tutti i documenti
      documentIds.forEach(docId => {
        dispatch({
          type: REMOVE_DOCUMENT_FROM_FOLDER,
          payload: {
            folderId,
            documentId: docId
          }
        });
      });

      // Aggiorna Firebase per tutti i documenti
      const promises = documentIds.map(docId => removeDocFromFirestoreFolder(folderId, docId));
      await Promise.all(promises);
      
      // Restituisci informazioni sulla cartella aggiornata
      return {
        success: true,
        folderId,
        documentIds
      };
    } catch (error) {
      console.error('Errore nella rimozione del documento dalla cartella:', error);
      toast.error('Errore nella rimozione del documento dalla cartella');
      throw error;
    }
  };
 };
 
 
 // Azione per selezionare un documento
 export const selectDocument = (document) => ({
  type: SELECT_DOCUMENT,
  payload: document,
 });
 

// Sincronizza le cartelle da Firebase con Redux
export const syncFolders = (folders) => (dispatch) => {
  console.log(`Sincronizzando ${folders.length} cartelle con Redux...`);
  
  dispatch({
    type: SYNC_FOLDERS,
    payload: folders
  });
  
  // Non facciamo più refresh multipli per evitare loop infiniti
  // Lasciamo che la UI si aggiorni naturalmente
};

// Funzione per estrarre l'ID del genitore da un ID di sottocartella
const getParentIdFromSubfolderID = (subfolderId) => {
  if (!subfolderId || typeof subfolderId !== 'string') {
    return null;
  }
  
  // Pattern: subfolder_TIMESTAMP_RANDOM_parent_PARENTID
  const parentIdMatch = subfolderId.match(/_parent_([^_]+)$/);
  if (parentIdMatch && parentIdMatch[1]) {
    return parentIdMatch[1];
  }
  
  return null;
};

// Funzione per rimuovere una sottocartella da una cartella in Firebase
const removeSubfolderFromFirestoreFolder = async (parentFolderId, subfolderId) => {
  try {
    if (!parentFolderId || !subfolderId) {
      console.error("ID mancanti per l'operazione di rimozione sottocartella");
      return false;
    }
    
    // Importa dinamicamente la funzione di aggiornamento da firestoreQueries
    const firestoreQueries = await import('../../lib/firestoreQueries');
    
    // Se esiste una funzione specifica per rimuovere sottocartelle, usala
    if (typeof firestoreQueries.removeSubfolderFromFolder === 'function') {
      return firestoreQueries.removeSubfolderFromFolder(parentFolderId, subfolderId);
    }
    
    // Altrimenti, usa updateFolder come fallback
    if (typeof firestoreQueries.updateFirestoreFolder === 'function') {
      // Ottieni prima la cartella esistente
      const existingFolder = await firestoreQueries.getFolderById(parentFolderId);
      
      if (!existingFolder || !existingFolder.subfolders) {
        console.warn(`Cartella ${parentFolderId} non trovata o senza sottocartelle`);
        return false;
      }
      
      // Filtra la sottocartella da rimuovere
      const updatedSubfolders = existingFolder.subfolders.filter(sf => sf.id !== subfolderId);
      
      // Applica l'aggiornamento
      return firestoreQueries.updateFirestoreFolder(parentFolderId, { 
        subfolders: updatedSubfolders 
      });
    }
    
    console.warn("Nessuna funzione disponibile per rimuovere sottocartelle");
    return false;
  } catch (error) {
    console.error(`Errore nella rimozione della sottocartella ${subfolderId}:`, error);
    return false;
  }
};

// Variabile per tenere traccia dell'ultima richiesta di sincronizzazione
