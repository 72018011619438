import { useCallback } from "react";
import { useUploadNotesAction } from "../../hook/useUploadNotes";
import { getExtractedQA } from "@lib/file";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

export const useGetDocuments = () => {
  const dispatch = useUploadNotesAction();
  const { documentsQA, isLoading } = useSelector(state => state.document);
  const notes = useSelector(state => state.document.processedNotes);
  const navigate = useNavigate();
  const dispatchDoc = useDispatch()
  const { user } = useSelector(state => state.auth);
  const isPremium = user?.plan === 'Premium';

  // Funzione per unire tutto l'extractedText da processedNotes
  const getText = useCallback(async () => {
    notes.reduce((prev, doc) => ({
      ...prev,
      extractedText: (prev.extractedText || '') + doc.extractedText,
    }), {});

    // Fetch QA basato su processed notes
    console.log('User premium status:', isPremium);
    dispatchDoc({ type: "SET_DOCUMENTS_QA", payload: [...(await getExtractedQA(notes, 'interrogation', isPremium))] });
  }, [notes, dispatchDoc, isPremium]);

  // Funzione per fetchare i documenti e aggiornare lo stato
  const getDocuments = useCallback(async () => {
    try {
      console.log("processedNotes: ", notes);
      console.log("User premium status:", isPremium, "User plan:", user?.plan);

      // Imposta lo stato di caricamento
      dispatchDoc({ type: "SET_IS_LOADING", payload: true });
      dispatch({ type: "HANDLE__DISABLED_FORM" });

      // Calcola la dimensione totale del testo
      const totalLength = notes.reduce((total, note) => total + (note.extractedText?.length || 0), 0);
      console.log(`Total document length: ${totalLength} characters`);

      let processedNotes = notes;

      // Check for free users first
      if (totalLength > 50000 && !isPremium) {
        toast.warning('Il documento è molto grande. Per ottenere risultati migliori, considera di passare al piano Premium.');
        processedNotes = notes.map(note => ({
          ...note,
          extractedText: note.extractedText?.slice(0, Math.floor((50000 * note.extractedText.length) / totalLength)) || ''
        }));
      }
      // Truncate documents if they're too large
      else if (totalLength > 500000) {
        toast.warning('Il documento è molto grande, sarà tagliato a 500.000 parole');
        processedNotes = notes.map(note => ({
          ...note,
          extractedText: note.extractedText?.slice(0, Math.floor((500000 * note.extractedText.length) / totalLength)) || ''
        }));
      }

      // Per utenti premium, fai una singola chiamata con tutti i servizi
      if (isPremium) {
        dispatchDoc({ type: "SET_DOCUMENTS_QA", payload: [...(await getExtractedQA(processedNotes, 'all', isPremium))] });
      } else {
        // Per utenti free, mantieni la logica separata
        if (processedNotes?.length > 1) {
          await getText();
        } else {
          dispatchDoc({ type: "SET_DOCUMENTS_QA", payload: [...(await getExtractedQA(processedNotes, 'interrogation', isPremium))] });
        }
      }

      // Reimposta lo stato del form e rimuove il caricamento
      dispatch({ type: "HANDLE__DISABLED_FORM" });
      dispatchDoc({ type: "SET_IS_LOADING", payload: false });
    } catch (error) {
      // Gestione errori e notifica utente
      toast.error(error.message);
      dispatch({ type: "HANDLE__DISABLED_FORM" });
      dispatchDoc({ type: "SET_IS_LOADING", payload: false });
    }
  }, [notes, dispatchDoc, dispatch, getText, isPremium, user?.email, user?.plan]);

  // Funzione per resettare documentsQA
  const resetDocsQA = useCallback(() => {
    dispatchDoc({ type: "SET_DOCUMENTS_QA", payload: [] });
    dispatch({ type: "RESET" });
    navigate("/home/ripeti");
    dispatch({ type: "HANDLE_IS_SAVED" });
  }, [dispatchDoc, dispatch, navigate]);

  return {
    isLoading,
    notes,
    documentsQA,
    resetDocsQA,
    getDocuments,
  };
};

export default useGetDocuments;
