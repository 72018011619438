import { useMemo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import React from 'react';

/**
 * Custom hook that provides selectors for fetching folder-related data
 */
export const useFolderSelectors = () => {
  // Get all state data we need
  const allFolders = useSelector(state => state.document.folders);
  const allDocuments = useSelector(state => state.document.documents);
  // Add state for forcing re-renders
  const [refreshCounter, setRefreshCounter] = useState(0);
  
  // Creiamo il ref al livello superiore, non all'interno della callback di useEffect
  const foldersRef = React.useRef([]);
  
  // Add effect to refresh component when folders change
  useEffect(() => {
    // Preveniamo aggiornamenti infiniti controllando prima di aggiornare lo stato
    // Confrontiamo se c'è stato un cambiamento reale nel numero di cartelle
    // o negli ID delle cartelle prima di forzare un aggiornamento
    const hasFoldersChanged = allFolders.length !== foldersRef.current.length || 
      allFolders.some((folder, index) => 
        index >= foldersRef.current.length || folder.id !== foldersRef.current[index].id);
    
    if (hasFoldersChanged) {
      console.log("Folder state changed, refreshing components:", allFolders.length);
      foldersRef.current = allFolders;
      setRefreshCounter(prev => prev + 1);
    }
  }, [allFolders]);
  
  // Create memoized selector for root folders with sorting
  const selectRootFolders = useMemo(() => createSelector(
    [(state) => state.document.folders],
    (folders) => {
      // Filter root folders (no parentId)
      const rootFolders = folders.filter(folder => !folder.parentId);
      
      // Sort by createdAt date (newest first)
      return rootFolders.sort((a, b) => {
        // Handle case where createdAt might be missing
        if (!a.createdAt) return 1;
        if (!b.createdAt) return -1;
        
        // Convert string dates to Date objects if needed
        const dateA = typeof a.createdAt === 'string' ? new Date(a.createdAt) : a.createdAt;
        const dateB = typeof b.createdAt === 'string' ? new Date(b.createdAt) : b.createdAt;
        
        // Sort descending (newest first)
        return dateB - dateA;
      });
    }
  ), [refreshCounter]); // Add refreshCounter as dependency to ensure recomputation
  
  // Use the memoized selector to get root folders
  const rootFolders = useSelector(selectRootFolders);
  
  // Create memoized selector for subfolders
  const createSubfoldersSelector = useMemo(() => createSelector(
    [(state) => state.document.folders, (_, folderId) => folderId],
    (folders, folderId) => {
      // Check for subfolders in nested structure
      const parentFolder = folders.find(f => f.id === folderId);
      if (parentFolder && parentFolder.subfolders && parentFolder.subfolders.length > 0) {
        return parentFolder.subfolders;
      }
      
      // Or check by parentId
      const subfolders = folders.filter(f => f.parentId === folderId);
      
      // Sort by createdAt date (newest first)
      return subfolders.sort((a, b) => {
        if (!a.createdAt) return 1;
        if (!b.createdAt) return -1;
        
        const dateA = typeof a.createdAt === 'string' ? new Date(a.createdAt) : a.createdAt;
        const dateB = typeof b.createdAt === 'string' ? new Date(b.createdAt) : b.createdAt;
        
        return dateB - dateA;
      });
    }
  ), [refreshCounter]); // Add refreshCounter as dependency
  
  // Create memoized selector for folder documents
  const createFolderDocumentsSelector = useMemo(() => createSelector(
    [
      (state) => state.document.documents,
      (_, documentIds) => documentIds || []
    ],
    (documents, documentIds) => documents.filter(doc => documentIds.includes(doc.id))
  ), [refreshCounter]);
  
  // Find a folder by ID
  const getFolderById = useMemo(() => {
    return (folderId) => {
      return allFolders.find(folder => folder.id === folderId);
    };
  }, [allFolders, refreshCounter]);
  
  // Find parent folder of a given folder
  const getParentFolder = useMemo(() => {
    return (folder) => {
      if (!folder || !folder.parentId) return null;
      
      return allFolders.find(f => 
        f.id === folder.parentId || 
        `firebase_${f.id}` === folder.parentId || 
        f.id === `firebase_${folder.parentId}` ||
        folder.parentId.replace('firebase_', '') === f.id
      );
    };
  }, [allFolders, refreshCounter]);
  
  // Calculate nesting level of a folder
  const getFolderNestingLevel = useMemo(() => {
    return (folder) => {
      if (!folder || !folder.parentId) return 1; // Root folder (level 1)
      
      // Funzione ricorsiva per calcolare il livello esatto
      const calculateLevel = (currentFolderId, visitedIds = new Set(), level = 1) => {
        if (!currentFolderId) return level;
        
        // Prevenzione cicli infiniti in caso di riferimenti circolari
        if (visitedIds.has(currentFolderId)) return level;
        visitedIds.add(currentFolderId);
        
        // Trova la cartella corrente
        const currentFolder = allFolders.find(f => 
          f.id === currentFolderId || 
          `firebase_${f.id}` === currentFolderId || 
          f.id === `firebase_${currentFolderId}` ||
          currentFolderId.replace('firebase_', '') === f.id
        );
        
        if (!currentFolder || !currentFolder.parentId) return level;
        
        // Chiamata ricorsiva con il parentId
        return calculateLevel(currentFolder.parentId, visitedIds, level + 1);
      };
      
      // Inizia il calcolo ricorsivo dal parentId della cartella corrente
      return calculateLevel(folder.parentId);
    };
  }, [allFolders, refreshCounter]);
  
  // Debug log when folders change
  useEffect(() => {
    console.log(`Root folders (${rootFolders.length}):`, rootFolders.map(f => f.name));
  }, [rootFolders]);
  
  return {
    allFolders,
    rootFolders,
    allDocuments,
    getFolderById,
    getParentFolder,
    getFolderNestingLevel,
    createSubfoldersSelector,
    createFolderDocumentsSelector,
    refreshCounter
  };
};

export default useFolderSelectors;