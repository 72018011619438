import { useState } from "react";
import { firestore } from "@redux/firebase";
import { doc, serverTimestamp, setDoc, collection, updateDoc, increment, deleteDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { saveQAExam } from "@lib/firestoreQueries";
import { useNavigate, useLocation } from "react-router-dom";
import { deleteExamFromFirestore } from "@lib/firestoreQueries";
import { useSelector } from "react-redux";

const useSaveExam = (documentID, messages) => {
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const savedExamID = useSelector(state => state.exam.currentExamID);
  const interrogationQA = useSelector(state => state.exam.interrogationQA);

  const saveExam = async (status, questionsAnswered) => {
    try {
      const userID = JSON.parse(localStorage.getItem("userId"));
      if (!userID) {
        throw new Error("User ID non trovato.");
      }

      if (!documentID) {
        throw new Error("Document ID non fornito.");
      }

      if (!messages || messages.length === 0) {
        throw new Error("Nessun messaggio da salvare.");
      }

      setIsSaving(true);

      // Salva l'esame su Firestore
      const examCollectionRef = collection(firestore, "exam");
      const examDocRef = doc(examCollectionRef);
      const examID = examDocRef.id;
      const examObject = {
        documentID: state?.documentID || documentID,
        examDifficulty: "",
        examEnd: serverTimestamp(),
        EstimatedVote: questionsAnswered ? questionsAnswered : null,
        status: status,
        reviewMode: state.reviewMode,
      };
      await setDoc(examDocRef, examObject);

      const messagesToSave = state.reviewMode === false 
        ? interrogationQA.map(qa => ({            
            index: qa.index,
            question: qa.question,
            answer: qa.answer,
            correctAnswer: qa.correct_answer,
        })) : state?.messages || messages;

      // Salva la sessione su Firestore
      await saveQAExam(examID, messagesToSave, state?.reviewMode);

      // Aggiorna il contatore degli esami tentati solo se l'esame è stato completato
      if (status === 'completed') {
        const documentRef = doc(firestore, "students", userID);
        await updateDoc(documentRef, {
          examsAttempted: increment(1),
        });
      }
      
      if(savedExamID) {
        // Elimina l'esame in corso da firestore perchè completato
        await deleteExamFromFirestore(savedExamID);
      }

      setIsSaving(false);
      if (status === 'completed') {
        navigate('/home/ripeti');
      } else {
        navigate(-1);
      }

      return true;
    } catch (error) {
      console.error("Errore durante il salvataggio dell'esame:", error);
      let errorMessage = "Errore durante il salvataggio dell'esame. ";
      if (error.message) {
        errorMessage += error.message;
      } else {
        errorMessage += "Contatta l'assistenza in caso di ulteriori problemi.";
      }
      toast.error(errorMessage);
      setIsSaving(false);
      throw error;
    }
  };

  const deleteExam = async (documentID) => {
    try {
      const userID = JSON.parse(localStorage.getItem("userId"));
      if (!userID) {
        throw new Error("User ID non trovato.");
      }

      if (!documentID) {
        throw new Error("Document ID non fornito.");
      }

      setIsSaving(true);

      // Elimina l'esame da Firestore
      const examRef = doc(firestore, "exam", documentID);
      
      await deleteDoc(examRef);

      // Aggiorna il contatore degli esami tentati (decrementa di 1)
      const documentRef = doc(firestore, "students", userID);
      await updateDoc(documentRef, {
        examsAttempted: increment(-1),
      });

      setIsSaving(false);
      /* navigate('/home/ripeti'); */
      return true;
    } catch (error) {
      console.error("Errore durante l'eliminazione dell'esame:", error);
      let errorMessage = "Errore durante l'eliminazione dell'esame. ";
      if (error.message) {
        errorMessage += error.message;
      } else {
        errorMessage += "Contatta l'assistenza in caso di ulteriori problemi.";
      }
      toast.error(errorMessage);
      setIsSaving(false);
      throw error;
    }
  };

  return { isSaving, saveExam, deleteExam };
};

export default useSaveExam;