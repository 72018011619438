import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { getFirestore, enableIndexedDbPersistence, initializeFirestore } from 'firebase/firestore';
import { getStorage, ref } from 'firebase/storage';

// Verifica che le variabili d'ambiente siano definite
const checkEnvVariables = () => {
  const requiredVars = [
    'REACT_APP_FIREBASE_API_KEY',
    'REACT_APP_FIREBASE_AUTH_DOMAIN',
    'REACT_APP_FIREBASE_PROJECT_ID',
    'REACT_APP_FIREBASE_STORAGE_BUCKET',
    'REACT_APP_FIREBASE_MESSAGING_SENDER_ID',
    'REACT_APP_FIREBASE_APP_ID',
    'REACT_APP_FIREBASE_MEASUREMENT_ID'
  ];
  
  const missingVars = requiredVars.filter(varName => !process.env[varName]);
  
  if (missingVars.length > 0) {
    console.error('Variabili d\'ambiente mancanti:', missingVars);
    return false;
  }
  
  return true;
};

// Funzione per rilevare Safari
const isSafari = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.includes('safari') && !userAgent.includes('chrome');
};

let firebaseConfig;

if (process.env.REACT_APP_ENVIRONMENT === "production" || process.env.REACT_APP_ENVIRONMENT === "development") {
  firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
  };
} else {
  // Use the preprod firebase config if not in production
  firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY_PREPROD,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_PREPROD,
    databaseURL: process.env.REACT_APP_DATABASE_URL_PREPROD,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_PREPROD,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_PREPROD,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID_PREPROD,
    appId: process.env.REACT_APP_FIREBASE_APP_ID_PREPROD,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID_PREPROD
  };
}

let app;
let firestore;
let analytics;
let auth;
let storage;
let storageRef;
let documentsRef;

try {
  // Verifica che le variabili d'ambiente siano definite
  if (!checkEnvVariables()) {
    throw new Error('Variabili d\'ambiente mancanti per la configurazione di Firebase');
  }
  
  app = initializeApp(firebaseConfig);
  
  // Configurazione specifica per Safari
  const firestoreConfig = isSafari() ? {
    // Configurazione ottimizzata per Safari
    experimentalForceLongPolling: true,
    localCache: {
      cacheSizeBytes: 50 * 1024 * 1024, // 20MB per Safari
      cacheStrategy: 'persistent'
    }
  } : {
    // Configurazione standard per altri browser
    localCache: {
      cacheStrategy: 'persistent'
    }
  };

  // Inizializza Firestore con la configurazione appropriata
  firestore = initializeFirestore(app, firestoreConfig);

  // Abilita la persistenza con gestione ottimizzata per Safari
  enableIndexedDbPersistence(firestore)
    .then(() => {
      console.log('Persistenza Firestore abilitata con successo');
    })
    .catch((err) => {
      if (err.code === 'failed-precondition') {
        // Se la persistenza fallisce, l'app continuerà a funzionare senza cache offline
        console.warn('Persistenza Firestore non disponibile, l\'app funzionerà in modalità online');
      } else if (err.code === 'unimplemented') {
        console.warn('Il browser non supporta la persistenza Firestore');
      }
    });

  analytics = getAnalytics(app);
  auth = getAuth(app);
  storage = getStorage(app);
  storageRef = ref(storage);
  documentsRef = ref(storage, 'documents');
  console.log("Firebase è stato inizializzato correttamente.");
} catch (error) {
  console.error("Errore durante l'inizializzazione di Firebase:", error);
}

export {
  app,
  analytics,
  auth,
  sendPasswordResetEmail,
  firestore,
  storage,
  storageRef,
  documentsRef,
  firebaseConfig
};
