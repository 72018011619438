import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserFolders } from '@lib/firestoreQueries';
import { toast } from 'react-toastify';
import { syncFolders, refreshFolders } from '../redux/actions/documentActions';

/**
 * Hook per caricare le cartelle dell'utente da Firebase e sincronizzarle con Redux
 */
const useGetFolders = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const allFolders = useSelector(state => state.document.folders);
  const documents = useSelector(state => state.document.documents);

  // Funzione per caricare le cartelle, esportata per poterla chiamare anche esternamente
  const fetchFolders = useCallback(async (forceRefresh = false) => {
    try {
      if (forceRefresh) {
        setIsLoading(true);
      }
      
      console.log("Caricamento cartelle...");
      
      // Recupera le cartelle dell'utente da Firebase
      const folders = await getUserFolders();
      console.log(`${folders.length} cartelle trovate in Firebase`);
      
      // Sincronizza le cartelle con Redux
      dispatch(syncFolders(folders));
      
      // Forza un secondo refresh dopo un breve ritardo per assicurarsi
      // che React abbia completato tutti gli aggiornamenti
      setTimeout(() => {
        console.log("Forzando refresh dopo il caricamento");
        dispatch(refreshFolders());
      }, 500);
    } catch (error) {
      console.error('Errore nel caricamento delle cartelle:', error);
      toast.error('Errore nel recupero delle cartelle');
    } finally {
      setIsLoading(false);
    }
  }, [dispatch]);

  // Log di debug per mostrare lo stato attuale delle cartelle
  useEffect(() => {
    if (allFolders.length > 0) {
      console.log(`${allFolders.length} cartelle presenti nello stato Redux`);
      
      // Conta i documenti nelle cartelle
      const documentIdsInFolders = new Set();
      
      allFolders.forEach(folder => {
        if (folder.documentIds && Array.isArray(folder.documentIds)) {
          folder.documentIds.forEach(docId => documentIdsInFolders.add(docId));
        }
      });
      
      console.log(`Documenti in cartelle: ${documentIdsInFolders.size} su ${documents.length}`);
    }
  }, [allFolders, documents]);

  // Carica le cartelle all'avvio del componente
  useEffect(() => {
    fetchFolders();
    
    // Imposta un intervallo per aggiornare periodicamente le cartelle (ogni 60 secondi)
    const intervalId = setInterval(() => {
      fetchFolders(false); // Non mostrare il loading per non disturbare l'utente
    }, 60000);
    
    return () => clearInterval(intervalId);
  }, [fetchFolders]);

  return { 
    isLoading, 
    folders: allFolders,
    refreshFolders: () => fetchFolders(true) // Metodo per forzare un refresh
  };
};

export default useGetFolders;