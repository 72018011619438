import React from 'react'
import PropTypes from 'prop-types'

import Header from './header/header'
import HelpMenu from '../../components/helpMenu/HelpMenu'
import ReferralBanner from '../../components/referralBanner/ReferralBanner'

const Layout = ({ children }) => {
  return (
    <div className="Layout">
      <ReferralBanner />
      <Header />
      {children}
      <HelpMenu />
    </div>
  )
}

/* Layout prop types */
Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default Layout
