import { useState, useEffect, useRef, useCallback } from "react";
import { transcribeAudio } from "@services/openaiApi";
import { useDispatch } from "react-redux";

const useAudioRecorder = (onFeedback, onHideAudioRecorder, onAddMessage, setUserAnswer, currentQuestion, qa, reviewMode, saveExamInInterrogationMode,
  saveInterrogationQA,) => {
  const [isTranscribing, setIsTranscribing] = useState(false);
  const [recordingAmount, setRecordingAmount] = useState(0);
  const mediaRecorderRef = useRef(null);
  const audioChunks = useRef([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isTranscribing) return setRecordingAmount(0);

    const intervalId = setInterval(() => {
      setRecordingAmount((prevAmount) => {
        const maxTime = 360000;
        if (prevAmount === maxTime) {
          clearInterval(intervalId);
          return maxTime;
        }

        return prevAmount + 1;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [isTranscribing]);

  const getAudio = useCallback(async () => {
    try {
      setIsTranscribing(true);
      if (!audioChunks.current.length) return null;

      // If it isn't an interrogation exam, add a placeholder message
      if(reviewMode) {
        onAddMessage({ text: "...", sender: "user", type: "audio" });
      };

      const audioBlob = new Blob(audioChunks.current, { type: "audio/mp3" }); // Use audio/mp3
      const userResponse = await transcribeAudio(audioBlob);
      setUserAnswer(userResponse.text);
      audioChunks.current = [];
      setIsTranscribing(false);
      const index = currentQuestion;  
      const question = qa[index].question;
      const correct_answer = qa[index].answer;
      const answer = userResponse.text;  

      if(!reviewMode) {
        const data = { 
          index,
          question,
          answer,
          correct_answer 
        };
        const updatedQA = saveInterrogationQA(data);
        saveExamInInterrogationMode(currentQuestion, updatedQA);
      };

      // If it isn't an interrogation exam, send the answer to the feedback
      if(reviewMode) {
        onFeedback({ answer: userResponse.text });
      };

      onHideAudioRecorder();
    } catch (error) {
      setIsTranscribing(false);
    }
  }, [onFeedback, onHideAudioRecorder, onAddMessage, setUserAnswer, currentQuestion, dispatch, qa, reviewMode]);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        mediaRecorderRef.current = new MediaRecorder(stream);

        mediaRecorderRef.current.ondataavailable = (e) => {
          if (e.data.size === 0) return;
          audioChunks.current.push(e.data);
        };

        mediaRecorderRef.current.onstop = getAudio;

        // Start recording with 1-second timeslice
        mediaRecorderRef.current.start(1000);
      })
      .catch(() => {
      });
  }, [getAudio]);

  const stopRecording = () => mediaRecorderRef.current.stop();

  return { recordingAmount, isTranscribing, stopRecording };
};

export default useAudioRecorder;