import React, { useEffect, useRef, useState } from "react";
import Button from "@ui/button/button";
import { ModalRoot } from "@ui/modal/modal";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { doc, increment, updateDoc, serverTimestamp } from "firebase/firestore";
import { firestore } from "@redux/firebase";
import PropTypes from "prop-types";
import "./chatModalFreeSubscription.scss";
import { AlertCircle } from "lucide-react";
import useShowModal from "../../hooks/useShowModal";
import sendBrevoEmail from "../../../../utils/brevoEmailsUtils";

const ChatModalFreeSubscription = ({ onClose, examCount, uploadingDoc }) => {
  const navigate = useNavigate();
  const dialogRef = useRef(null);
  const { user } = useSelector(state => state.auth);
  const { popupViewed } = useSelector(state => state.leadScore);
  const { getTotalExamsAttempted } = useShowModal(false);
  const [uploadDocTotalExams, setUploadDocTotalExams] = useState(0);
  
  // Determina se l'utente è premium
  const isPremium = user?.plan === 'Premium';

  useEffect(() => {
    // Calcola il numero totale di esami tentati
    const examsAttempted = getTotalExamsAttempted();
    setUploadDocTotalExams(examsAttempted);

    // Invio email limite piano gratuito raggiunto
    if(uploadingDoc && uploadDocTotalExams === 2) {
      sendBrevoEmail(user.email, user.fullName, 12);
    }
    
    // trigger event for how many times popup was viewed
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'PopUpViewed', {
        'event_category': 'Account',
        'event_label': 'PopUp Viewed',
        'email': user.email,
        'popup_viewed': popupViewed+1,
      });        
    } else {
      console.error("gtag is not defined");
    };
    
    const updateUserLimits = async () => {
      try { 
        const userId = localStorage.getItem('userId');
        let userStats = doc(firestore, 'userStats', JSON.parse(userId));

        await updateDoc(userStats, {
          limitReached: increment(1),
          popupViewed: increment(1),
          lastPopupViewed: serverTimestamp(),
        });
        
        
      } catch(error) {
        console.error('Error fetching user stats', error);
      }
    };
    
    updateUserLimits();
  }, [popupViewed, user, getTotalExamsAttempted]);

  //Function GA4 event trigger 
  const handleTriggerEvent = () => {
    let event = uploadingDoc ? 'uploadDocToPremium' : 'ExamToPremium';  
    
      if (typeof window.gtag === 'function') {
        window.gtag('event', event, {
          'event_category': 'Account',
          'event_label': event,
          'email': user.email,
        });        
      } else {
        console.error("gtag is not defined");
      }

    navigate("/home/piani");
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  };

  const handleClose = async () => {
    onClose();    
    // trigger event for how many times popup was closed
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'popUpClosed', {
        'event_category': 'Account',
        'event_label': 'popUpClosed',
        'email': user.email,
      });        
    } else {
      console.error("gtag is not defined");
    };
  };

  return (
    <ModalRoot.Modal ref={dialogRef} className="ChatModalFreeSubscription">
      <ModalRoot.Header
        className="ChatModalFreeSubscription__header"
        title="Limite Piano Gratuito"
        onClose={handleClose}
      />
      <ModalRoot.Content className="ChatModalFreeSubscription__content">
      {uploadingDoc && <p>Puoi caricare massimo 2 documenti componibili per il tuo piano gratuito.</p>}
        <div className={uploadingDoc && "ChatModalFreeSubscription__content-wrapper"}>
          <div className={uploadingDoc && "ChatModalFreeSubscription__content-text-wrapper"}>
            <p className={uploadingDoc && "ChatModalFreeSubscription__content-text"}>
              { uploadingDoc 
                ? 'File da caricare'
                
                : `Hai completato 1 esame. Per continuare ad usare StudierAI, abbonati al piano premium o ripeti un'altro documento.`
              }
            </p>
            {uploadingDoc && <AlertCircle size={18} className="ChatModalFreeSubscription__alert-icon" />}
          </div>

         {uploadingDoc && 
            <div>
              <span className="ChatModalFreeSubscription__content-span">2/2</span>
            </div>
          }
        </div>
        {uploadingDoc &&  
          <div className={uploadingDoc && "ChatModalFreeSubscription__content-wrapper"}>
            <div className={uploadingDoc && "ChatModalFreeSubscription__content-text-wrapper"}>
              <p className={uploadingDoc && "ChatModalFreeSubscription__content-text"}>
                Simulazioni orali
              </p>
            </div>
          
            <div>
              <span className="ChatModalFreeSubscription__content-span">{uploadDocTotalExams}/2</span>
            </div>
          </div>
        }
        <Button
          className="ChatModalFreeSubscription__button"
          size="md"
          variant="primary"
          onClick={handleTriggerEvent}
        >
          {isPremium ? "Gestisci il tuo piano" : "Aggiorna il tuo piano"}
        </Button>
      </ModalRoot.Content>
    </ModalRoot.Modal>
  );
};

// chat modal free subscription props types
ChatModalFreeSubscription.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ChatModalFreeSubscription;