import PriceCard from "../../../containers/priceCard/priceCard";
import "./monthlyPlan.scss";

export const MonthlyPlan = ({ isAnnual, setIsAnnual, isPremium, handlePremiumSubscription, isLanding, onClick }) => {

  return (
    <div className={isPremium ? 'MonthlyPlan__premium' : null}>
      <PriceCard
      title="Premium"
      originalCost={"14.99"}
      cost={"12.99"}
      method={"mese"}
      isAnnual={isAnnual}
      setIsAnnual={setIsAnnual}
      options={[
        "Documenti illimitati con analisi approfondita",
        "Flashcards, riassunti, domande di ripetizione e a risposta multipla illimitati",
        "Simulazioni illimitate di ripetizioni e interrogazioni con feedback personalizzati e voto finale",
        "Podcast audio illimitati su i tuoi riassunti ",
        "Usufruisci a tutte le funzionalità future",
        "Assistenza e-mail e chat prioritaria"
      ]}
      buttonText={isPremium ? "Gestisci abbonamento" : "Acquista Ora"}
      onClick={isLanding ? onClick : () => handlePremiumSubscription("price_1R14HeHFdskyUb7jLD5uAlZm", 'Monthly')}
      advised={!isPremium}
      showCountdown={!isPremium}
      />
    </div>
  )
};