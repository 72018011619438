import './document.scss';
import PropTypes from 'prop-types';
import { getFileIcon } from '@lib/file';
import clsx from 'clsx';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { changeDocumentTitle, deleteDocument } from '../../../../redux/actions/documentActions';
import { IoMdCheckmark } from "react-icons/io";
import { TbEdit } from "react-icons/tb";
import { RiDeleteBinLine } from "react-icons/ri";
import { toast } from 'react-toastify';
import { CheckCircle2 } from 'lucide-react';

import Heading from '@ui/heading/heading';
import Input from '@ui/input/input';
import { getTagColor } from '../../../uploadNotes/documentsQA/hooks/useGetTagColor';
import { Image, Music, Video, FileText } from 'lucide-react';

// Onboarding
import useGetOnboardingState from '../../../uploadNotes/uploadNotesCard/uploadNotesForm/hooks/useGetOnboardingState';

export const Document = ({ id, type, title, tags, date, isActive, onChange, selectionMode = false, disableSelection = false, folderColor = null, className = '' }) => {
  // Assicuriamoci che title sia una stringa valida, in caso contrario usiamo una stringa vuota o un valore predefinito
  const safeTitle = typeof title === 'string' ? title : '';
  
  const [isChanging, setIsChanging] = useState(false);
  const [inputValue, setInputValue] = useState(safeTitle);
  const [currentTitle, setCurrentTitle] = useState(safeTitle);
  const dispatch = useDispatch();
  const currentStep = useSelector(state => state.onboarding.currentStep);
  const { updateCurrentStep } = useGetOnboardingState();

  const handleSelectDocument = () => {
    if (currentStep === 13) { 
      updateCurrentStep(14);
    };
    
    if (disableSelection && selectionMode) {
      return;
    }
    
    onChange(id);
  };

  const handleEditTitle = (e) => {
    e.stopPropagation();
    if (selectionMode) return; // Disabilita l'editing durante la modalità di selezione
    
    if (isChanging) {
      // Assicuriamoci che inputValue sia una stringa
      const newTitle = (inputValue || '').trim().replace(/\s+/g, ' ');
      if (!newTitle) {
        toast.error('Il titolo non può essere vuoto');
        setInputValue(currentTitle);
        setIsChanging(false);
        return;
      }
      if (newTitle !== currentTitle) {
        if (/^[\w\sàèéìòù'\-.,]{2,50}$/i.test(newTitle)) {
          dispatch(changeDocumentTitle(id, newTitle));
          setCurrentTitle(newTitle);
        } else {
          toast.error('Il titolo deve contenere tra i 2-30 caratteri');
          setInputValue(currentTitle);
        }
      }
      setIsChanging(false);
    } else {
      setIsChanging(true);
    }
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    if (selectionMode) return; // Disabilita l'eliminazione durante la modalità di selezione
    
    if (window.confirm('Sei sicuro di voler eliminare questo documento?')) {
      if(id === 'yZI5jQzrvGbTsid0kvk6') {
        updateCurrentStep(22)
        return; 
      } else {
        dispatch(deleteDocument(id)); 
      }
    }
  };

  const changingInputValue = (e) => {
    const value = e.target.value || '';
    setInputValue(value.charAt(0).toUpperCase() + value.slice(1));
  };

  const getUpdatedFileIcon = fileType => {
    switch (fileType) {
      case 'png':
      case 'jpeg':
      case 'jpg':
      case 'gif':
      case 'image/png':
      case 'image/jpeg':
      case 'image/jpg':
      case 'image/gif':
        return <Image className="FilteredDocument__left-icon" />;
      case 'mp3':
      case 'vaw':
        return <Music className="FilteredDocument__left-icon" />;
      case 'mp4':
        return <Video className="FilteredDocument__left-icon" />;
      case 'multiple':
        return <FileText className="FilteredDocument__left-icon" size={44} />;
      default:
        return null; // O un'icona predefinita se necessario
    }
  };

  // Genera stili personalizzati in base al colore della cartella
  const getCustomStyles = () => {
    if (!folderColor || (!selectionMode && !isActive)) return {};
    
    // Supporta sia il formato precedente (con color e rgb) che quello nuovo (usato in materialsDocuments.js)
    const colorValue = folderColor.color || folderColor.icon || '#3498db';
    const rgbValue = folderColor.rgb || '52, 152, 219';
    
    return {
      '--document-active-border-color': colorValue,
      '--document-active-bg-color': `rgba(${rgbValue}, 0.05)`,
      '--document-hover-border-color': `rgba(${rgbValue}, 0.3)`,
      '--document-hover-bg-color': `rgba(${rgbValue}, 0.02)`,
      '--document-selected-icon-color': colorValue,
      '--document-selected-icon-bg-color': `rgba(${rgbValue}, 0.1)`
    };
  };

  // Proteggiamo il rendering per evitare errori
  const renderSafeTitle = () => {
    // Assicuriamoci che inputValue sia una stringa
    const displayValue = inputValue || '';
    return displayValue.trim().length > 20 ? `${displayValue.slice(0,22)}...` : displayValue;
  };

  return (
    <div className={clsx(
      'Document', 
      isActive && 'Document--active', 
      selectionMode && 'Document--selectable',
      className
    )} onClick={handleSelectDocument} style={getCustomStyles()}>
      <div className='Document__card'>
        <div className='Document__row'>
          {getUpdatedFileIcon(type) || <img className="FilteredDocument__left-image" src={getFileIcon(type)} alt={`file ${safeTitle}`} />}
          <div className="Document__actions">
            {id === 'yZI5jQzrvGbTsid0kvk6' 
              ? null
              : isChanging 
                ? <IoMdCheckmark className='Document__saveIcon' onClick={handleEditTitle}/>
                : (!selectionMode && <TbEdit className='Document__editIcon' onClick={handleEditTitle}/>)
            }
            {!selectionMode && <RiDeleteBinLine className='Document__deleteIcon' onClick={handleDelete} />}
            {selectionMode && isActive && (
              <CheckCircle2 
                className='Document__selectedIcon' 
                size={24} 
                style={folderColor ? { color: folderColor.color || folderColor.icon || '#3498db' } : {}}
              />
            )}
          </div>
        </div>

        <div className='Document__box'>
          {isChanging ? (
            <Input 
              className='Document_titleInput'
              type='text'
              value={inputValue || ''}
              onChange={changingInputValue}
              maxLength={50}
              pattern="^[\w\sàèéìòù'\-.,]{5,50}$"
              required
            />
          ) : (
            <Heading heading='h6' title={renderSafeTitle()} />
          )}
          <p className='Document__box-text'>
            <time dateTime={date}>{date}</time>
          </p>
        </div>
        
        { tags !== null && (Array.isArray(tags) || (typeof tags === 'object' && tags.tags)) ? 
          <div className="Document__tags">
              {(Array.isArray(tags) ? tags : tags.tags).map((tag, idx) => {
                return (
                  <div key={`${tag}-${idx}`}>
                  <span
                    className="Document__tag"
                    style={{ 
                      backgroundColor: getTagColor(idx, tag).backgroundColor, 
                      color: getTagColor(idx, tag).color,
                      borderColor: getTagColor(idx, tag).borderColor,
                    }}
                    >
                    {tag}
                  </span>
                </div> 
              )})}
          </div>
          
          : null
        }
      </div>
    </div>
  );
};

Document.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  date: PropTypes.oneOfType([
    PropTypes.string, 
    PropTypes.number,
    PropTypes.instanceOf(Date)
  ]),
  tags: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.object,
  ]),
  isActive: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  selectionMode: PropTypes.bool,
  disableSelection: PropTypes.bool,
  folderColor: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.oneOf([null])
  ]),
  className: PropTypes.string
};