import { SET_INTERROGATION_QA, SET_CURRENT_EXAM_ID, RESET_INTERROGATION_QA } from '../actions/types';

const initialState = {
  interrogationQA: [],
  currentExamID: null,
  timeRemaining: 2400 // Valore iniziale
};

const examReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INTERROGATION_QA:
      return {
        ...state,
        interrogationQA: Array.isArray(action.payload) ? 
          action.payload : 
          [...state.interrogationQA, action.payload]
      };
    case SET_CURRENT_EXAM_ID:
      return { 
        ...state, 
        currentExamID: action.payload, 
      };
    case RESET_INTERROGATION_QA:
      return { 
        ...state, 
        interrogationQA: [],
      };
    case 'UPDATE_TIME_REMAINING':
      return { ...state, timeRemaining: action.payload };
    default:
      return state;
  }
};

export default examReducer;
