export const formatTimeStamp = (timeStamp) => {
  if (!timeStamp) return '';

  try {
    let date;
    
    // Gestione dei timestamp di Firestore
    if (timeStamp && typeof timeStamp === 'object' && timeStamp.seconds) {
      date = new Date(timeStamp.seconds * 1000 + (timeStamp.nanoseconds || 0) / 1000000);
    } 
    // Gestione delle stringhe ISO o oggetti Date
    else {
      date = new Date(timeStamp);
      if (isNaN(date.getTime())) {
        return 'Data non disponibile';
      }
    }
    
    // Formatta data nel formato italiano: "DD/MM/YYYY HH:MM"
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  } catch (error) {
    console.error('Errore nella formattazione del timestamp:', error, timeStamp);
    return 'Data non disponibile';
  }
};
