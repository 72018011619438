import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getQuestions } from "@lib/firestoreQueries";

const useGetMultipleChoice = (idSelectedDocument, isLegacyFormat = true) => {
  const [isLoading, setIsLoading] = useState(false);
  const [questions, setQuestions] = useState([]);

  const getMultipleChoiceQuestions = useCallback(async () => {
    try {
      if (!idSelectedDocument) return;

      setIsLoading(true);
      const questionsData = await getQuestions(idSelectedDocument);
      
      // Filter for multiple choice questions
      const filteredQuestions = questionsData.docs
        .map(doc => {
          const data = doc.data();
          return {
            ...data,
            id: doc.id
          };
        })
        .filter(qa => {
          if (!qa) return false;

          // Nuovo formato: verifica il tipo 'quiz' e la struttura
          const isNewFormatQuiz = qa.type === 'quiz' && 
                                qa.query_text && 
                                typeof qa.answer_choices === 'object' && 
                                qa.correct_choice;

          // Vecchio formato: verifica metadata e quiz_questions
          const isLegacyQuiz = qa?.metadata?.isQuizQuestion === true && 
                             qa.quiz_questions?.query_text && 
                             typeof qa.quiz_questions?.answer_choices === 'object' && 
                             qa.quiz_questions?.correct_choice;

          return isLegacyQuiz || isNewFormatQuiz;
        })
        .map(qa => ({
          id: qa.id,
          documentID: qa.documentID,
          feedback: qa.feedback,
          metadata: qa.metadata,
          // Unifica i campi da entrambi i formati
          ...(qa.type === 'quiz' && qa.answer_choices ? {
            query_text: qa.query_text,
            answer_choices: qa.answer_choices,
            correct_choice: qa.correct_choice,
            difficulty_level: qa.difficulty_level,
            justification: qa.justification
          } : {
            ...qa.quiz_questions,
            difficulty_level: qa.quiz_questions?.difficulty_level,
            justification: qa.quiz_questions?.justification
          }),
          // Valori di default
          type: qa.type || 'quiz',
          source: qa.source || 'quiz',
          uploadDate: qa.uploadDate || qa.metadata?.createdAt
        }));

      console.log('Filtered multiple choice questions:', filteredQuestions);
      
      const sortedQuestions = filteredQuestions.sort((a, b) => {
        // Ordine di difficoltà predefinito
        const difficultyOrder = { easy: 1, medium: 2, hard: 3 };
        
        // Estrazione valori con fallback
        const diffA = difficultyOrder[a.difficulty_level?.toLowerCase()] || 2;
        const diffB = difficultyOrder[b.difficulty_level?.toLowerCase()] || 2;
        
        // Confronto primario per difficoltà
        if (diffA !== diffB) return diffA - diffB;

        // Confronto secondario per testo della domanda
        // Gestione sicura per entrambi i formati (nuovo/legacy)
        const textA = a.query_text?.trim() || a.quiz_questions?.query_text?.trim() || '';
        const textB = b.query_text?.trim() || b.quiz_questions?.query_text?.trim() || '';
        
        return textA.localeCompare(textB);
      });

      const processedQuestions = sortedQuestions.map(question => ({
        ...question,
        difficulty_level: question.difficulty_level || 'medium',
        justification: question.justification || `La risposta corretta è: ${question.correct_choice}`,
        metadata: {
          ...question.metadata,
          isMultipleChoice: true,
          isQuizQuestion: true
        }
      }));

      setQuestions(processedQuestions);

      setIsLoading(false);
    } catch (error) {
      console.error('Error in getMultipleChoiceQuestions:', error);
      toast.error("Errore nel recupero delle domande a risposta multipla");
      setIsLoading(false);
    }
  }, [idSelectedDocument]);

  useEffect(() => {
    getMultipleChoiceQuestions();
  }, [getMultipleChoiceQuestions]);

  return { isLoading, questions };
};

export default useGetMultipleChoice; 