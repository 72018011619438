import React, { useState, useEffect } from "react";
import "./reviewDocuments.scss";
import PropTypes from "prop-types";
import useSearchByTitle from "@hooks/useSearchByTitle";

import Heading from "@ui/heading/heading";
import ReviewDocument from "../reviewDocument/reviewDocument";
import { useSelector } from "react-redux";

const ReviewDocuments = ({ query, onSaveDocument, documents, selectedId, setSelectedId, selectedStatus, setSelectedStatus, filteredExamsInProgress }) => {
  const filteredDocuments = useSearchByTitle(documents, query);
  const currentStep = useSelector(state => state.onboarding.currentStep);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 575);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 575);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="ReviewDocuments">
      <Heading 
        className="ReviewDocuments__title" 
        heading="h3" 
        title={isMobile ? "Prepara il tuo orale" : "Scegli il materiale per prepararti all'orale"} 
      />
      <ul className="ReviewDocuments__list">
        {(filteredDocuments.length === 0 && currentStep === 20) && <p>Nessun documento disponibile</p>}
        {filteredDocuments.length > 0 &&
          filteredDocuments.map(doc => (
            <ReviewDocument
              key={doc.id}
              id={doc.id}
              title={doc.title}
              type={doc.type}
              text={doc.text}
              tags={doc.tags || null}
              onChange={onSaveDocument}
              status="not-started"
              selectedId={selectedId}
              setSelectedId={setSelectedId}
              selectedStatus={selectedStatus}
              setSelectedStatus={setSelectedStatus}
              filteredExamsInProgress={filteredExamsInProgress}
            />
          ))}

        {/* onboarding review doc  */}
        {currentStep < 22 && 
          currentStep > 8 &&
            <ReviewDocument
              id={'yZI5jQzrvGbTsid0kvk6'}
              title={'Divina_Commedia'}
              type={'pdf'}
              text={'LILU 1 - PROF . FERRETTI Dante Alighieri: la Commedia (introduzion...'}
              tags={['introduzione', 'saggio', 'letteratura italiana']}
              status="not-started"
              selectedId={selectedId}
              setSelectedId={setSelectedId}
              selectedStatus={selectedStatus}
              setSelectedStatus={setSelectedStatus}
              filteredExamsInProgress={filteredExamsInProgress}
              onChange={onSaveDocument}
            />
        }
      </ul>
    </div>
  );
};

// ReviewDocuments prop types
ReviewDocuments.propTypes = {
  query: PropTypes.string,
  onSaveDocument: PropTypes.func.isRequired,
};

export default ReviewDocuments;