import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getQuestions } from "@lib/firestoreQueries";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { firestore } from "@redux/firebase";

// Funzione per estrarre le frasi delimitatrici da un testo
const extractDelimitingSentences = (text) => {
  if (!text) return { startSentence: null, endSentence: null };
  
  // Dividi il testo in frasi
  const sentences = text.split(/[.!?]+/).filter(s => s.trim().length > 0);
  
  if (sentences.length === 0) return { startSentence: null, endSentence: null };
  if (sentences.length === 1) return { startSentence: sentences[0].trim() + '.', endSentence: sentences[0].trim() + '.' };
  
  // Prendi la prima e l'ultima frase significativa
  return {
    startSentence: sentences[0].trim() + '.',
    endSentence: sentences[sentences.length - 1].trim() + '.'
  };
};

const useGetQuestions = (idSelectedDocument, isLegacyFormat = true) => {
  const [isLoading, setIsLoading] = useState(false);
  const [questions, setQuestions] = useState([]);

  const getQuizQuestions = useCallback(async () => {
    try {
      if (!idSelectedDocument) return;

      setIsLoading(true);
      const questionsData = await getQuestions(idSelectedDocument);

      // Prima filtriamo per tipo di domanda
      const filteredQuestions = questionsData.docs
        .map(doc => ({
          ...doc.data(),
          id: doc.id // Assicuriamoci di mantenere l'ID del documento
        }))
        .filter(qa => {
          // Se è un documento vecchio (legacy), mostra tutte le domande
          if (isLegacyFormat) {
            if (!qa?.metadata) return true;
            return qa.metadata.isExamQuestion === true;
          }

          // Per i nuovi documenti, mostra solo le domande di quiz
          return qa?.metadata?.isQuizQuestion === true;
        });

      // Poi rimuoviamo i duplicati usando un Set per tenere traccia delle domande già viste
      const seenQuestions = new Set();
      const uniqueQuestions = filteredQuestions.filter(qa => {
        // Verifichiamo che la domanda esista e sia una stringa
        if (!qa?.question || typeof qa.question !== 'string') return false;

        // Normalizziamo la domanda (rimuoviamo spazi extra e rendiamo lowercase)
        const normalizedQuestion = qa.question.trim().toLowerCase();

        // Se la domanda non è stata vista, la aggiungiamo al set e la manteniamo
        if (!seenQuestions.has(normalizedQuestion)) {
          seenQuestions.add(normalizedQuestion);
          return true;
        }
        return false;
      });

      // Aggiungiamo le frasi delimitatrici alle domande che non le hanno
      const questionsWithContextMarkers = uniqueQuestions.map(question => {
        // Se la domanda ha già i contextMarkers, la lasciamo invariata
        if (question.contextMarkers?.startSentence && question.contextMarkers?.endSentence) {
          return question;
        }
        
        // Altrimenti, estraiamo le frasi delimitatrici dalla risposta
        if (question.answer) {
          const contextMarkers = extractDelimitingSentences(question.answer);
          return {
            ...question,
            contextMarkers
          };
        }
        
        return question;
      });

      // Ordiniamo le domande alfabeticamente
      const sortedQuestions = questionsWithContextMarkers.sort((a, b) =>
        a.question.localeCompare(b.question)
      );

      setQuestions(sortedQuestions);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      toast.error("Errore nel recupero delle domande");
      setIsLoading(false);
    }
  }, [idSelectedDocument, isLegacyFormat]);

  const updateQuestionState = useCallback(async (questionId, newState) => {
    try {
      if (!idSelectedDocument || !questionId) return;

      // Per i documenti nel nuovo formato
      const docRef = doc(firestore, "questions", idSelectedDocument);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists() && docSnap.data().qaMap) {
        // Nuovo formato con mappa QA
        const qaMap = docSnap.data().qaMap;
        if (qaMap[questionId]) {
          await updateDoc(docRef, {
            [`qaMap.${questionId}.knowledgeState`]: newState
          });
        }
      } else {
        // Formato legacy
        const questionRef = doc(firestore, "questions", questionId);
        await updateDoc(questionRef, {
          knowledgeState: newState
        });
      }

      // Aggiorna lo stato locale
      setQuestions(prevQuestions =>
        prevQuestions.map(q =>
          q.id === questionId ? { ...q, knowledgeState: newState } : q
        )
      );

    } catch (error) {
      console.error("Errore nell'aggiornamento dello stato della domanda:", error);
      toast.error("Errore nell'aggiornamento dello stato della domanda");
      throw error;
    }
  }, [idSelectedDocument]);

  // Funzione per salvare i contextMarkers di una domanda
  const updateQuestionContextMarkers = useCallback(async (questionId, contextMarkers) => {
    try {
      if (!idSelectedDocument || !questionId) return;

      // Per i documenti nel nuovo formato
      const docRef = doc(firestore, "questions", idSelectedDocument);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists() && docSnap.data().qaMap) {
        // Nuovo formato con mappa QA
        const qaMap = docSnap.data().qaMap;
        if (qaMap[questionId]) {
          await updateDoc(docRef, {
            [`qaMap.${questionId}.contextMarkers`]: contextMarkers
          });
        }
      } else {
        // Formato legacy
        const questionRef = doc(firestore, "questions", questionId);
        await updateDoc(questionRef, {
          contextMarkers: contextMarkers
        });
      }

      // Aggiorna lo stato locale
      setQuestions(prevQuestions =>
        prevQuestions.map(q =>
          q.id === questionId ? { ...q, contextMarkers } : q
        )
      );

    } catch (error) {
      console.error("Errore nell'aggiornamento dei contextMarkers:", error);
      // Non mostriamo toast per non disturbare l'utente
      throw error;
    }
  }, [idSelectedDocument]);

  useEffect(() => {
    getQuizQuestions();
  }, [getQuizQuestions]);

  return { isLoading, questions, updateQuestionState, updateQuestionContextMarkers };
};

export default useGetQuestions;