import React, { useEffect, useState } from "react";
import "./chatCardForm.scss";
import PropTypes from "prop-types";
import useChatForm from "./hooks/useChatForm";
import useAudio from "./hooks/useAudio";

import Field from "@ui/field/field";
import AudioRecorder from "./audioRecorder/audioRecorder";

import AnswersControls from "../AnswersControls/AnswersControls";
import ExamChat from "../ExamChat/ExamChat";
import { MicOff, Mic, MessageCircle } from "lucide-react";
import { useCallback } from "react";
import { toast } from "react-toastify"; 
import { updateInterrogationQA, updateTimeRemaining } from "../../../../redux/actions/examActions";
import { useSelector, useDispatch } from "react-redux";

const ChatCardForm = ({ 
  isExamFinished,
  messageID, 
  onAddMessage, 
  onFeedbackError, 
  onRetryQuestion,
  onCreateFeedback,
  firstUserAnswer,
  setUserAnswer,

  showChat,
  examState,
  isExamStarted,
  setShowChat,
  isRecording,
  startExam,
  setIsRecording,
  setExamState,
  setAiIconState,

  chatMessages,  
  inputRef,
  Send,
  sendChatMessage,
  processAnswer,
  messages,
  reviewMode,
  nextQuestion,
  currentQuestion,
  qa,
  saveExam,
}) => {
  const correctAnswer = qa[currentQuestion].answer;
  const { reset, fetcher, register, handleSubmit, getFeedback } = useChatForm(
    messageID,
    onAddMessage,
    onFeedbackError,
    onCreateFeedback,
    onRetryQuestion,
    correctAnswer,
  );
  const { showAudio, showAudioRecorder, isPermissionGranted, hideAudioRecorder } = useAudio();

  const [localFeedback, setLocalFeedback] = useState(false);
  const { interrogationQA, timeRemaining } =  useSelector(state => state.exam);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("ChatCardForm rendered with props:", { isExamFinished, messageID });
    if(examState === 'processing') setAiIconState('processing');
  }, [isExamFinished, messageID, setAiIconState, examState]);

  useEffect(() => {
    if (fetcher.data) {
      setLocalFeedback(fetcher.data.feedback);
    }
  }, [fetcher.data, setAiIconState]);

  useEffect(() => {
    if (localFeedback) {
      processAnswer(localFeedback); 
      setLocalFeedback(null);
    }
  }, [localFeedback, processAnswer]);
  
  const saveInterrogationQA = (data) => {
    const newEntry = {
      index: currentQuestion,
      question: qa[currentQuestion].question,
      answer: data.answer,
      correct_answer: qa[currentQuestion].answer
    };
    
    dispatch(updateInterrogationQA(newEntry));
    
    return [...interrogationQA, newEntry];
  };

  const handleGetFeedback = (data) => {
    // Gestisci l'invio di risposte vuote
    if (!data.answer) return;

    // Salva l'interrogazione QA se non siamo in modalità revisione
    if (!reviewMode) { 
      const updatedQA = saveInterrogationQA(data);
      saveExamInInterrogationMode(currentQuestion, updatedQA);
      nextQuestion();
      reset(); // Resetta tutti i campi del form
    }

    console.log("Submitting feedback with data:", data);
    setUserAnswer(data.answer);
    sendChatMessage(data.answer);
    firstUserAnswer();

    // Se siamo in modalità revisione, gestisci il feedback
    if (reviewMode) {
        setExamState('processing');
        getFeedback(data);
    }
  };

  const saveExamInInterrogationMode = async (currentQuestion, updatedQA) => {
    try {
        // Save timeRemaining for mic answer and chat answer cases
        dispatch(updateTimeRemaining(timeRemaining));
        await saveExam('in-progress', currentQuestion, 'auto-save', updatedQA);
    } catch (error) {
        console.error("Errore durante il salvataggio dell'esame in modalità interrogazione:", error);
    }
  };

  const toggleRecording = useCallback(() => {
    if (examState === 'listening' || examState === 'waiting') {
      try {
        if (!isPermissionGranted) {
          toast.error("Permessi microfono disabilitati. Per favore abilita l'accesso al microfono nelle impostazioni del tuo browser.");
          return;
        }

        if (!isExamStarted) {
          setIsRecording(true);
          startExam();
          showAudioRecorder();
        } else if (!isRecording) {
          setIsRecording(true);
          setExamState('listening');
          showAudioRecorder();
        }
      } catch (error) {
        console.error("Error toggling recording:", error);
      }
    }
  }, [isExamStarted, isRecording, startExam, showAudioRecorder, setExamState, setIsRecording, showChat, examState, isPermissionGranted]);

  return (
    <fetcher.Form
      className={showChat && !isRecording ? "ChatCardForm" : "chat-inactive"}
      onSubmit={handleSubmit(handleGetFeedback)}
      noValidate
    >
      <Field className={isExamFinished ? "ChatCardForm__field" : ""}>
        <Field.Label className="ChatCardForm__label" htmlFor="answer" />
        <div className="ChatCardForm__group">
          {!showAudio ? (
            <>
              <AnswersControls
                showChat={showChat}
                examState={examState}
                isExamStarted={isExamStarted}
                setShowChat={setShowChat}
                isRecording={isRecording}
                MessageCircle={MessageCircle}
                MicOff={MicOff}
                Mic={Mic}
                toggleRecording={toggleRecording}
                startExam={startExam}
                reviewMode={reviewMode}
                currentHints={qa && qa[currentQuestion] && qa[currentQuestion].hints ? 
                  qa[currentQuestion].hints : 
                  ['Nessun suggerimento disponibile', 'Nessun suggerimento disponibile']}
                
              />

              <ExamChat
                showChat={showChat}
                chatMessages={chatMessages}
                inputRef={inputRef}
                Send={Send}
                sendChatMessage={sendChatMessage}
                processAnswer={processAnswer}
                messages={messages}
                handleGetFeedback={handleGetFeedback}
                isExamFinished={isExamFinished}
                messageID={messageID}
                onCreateFeedback={onCreateFeedback}
                onAddMessage={onAddMessage}
                onFeedbackError={onFeedbackError}
                
                isRecording={isRecording}
                toggleRecording={toggleRecording}
                examState={examState}
                getFeedback={getFeedback}
                register={register}
                handleSubmit={handleSubmit}
                firstUserAnswer={firstUserAnswer}
                nextQuestion={nextQuestion}
              />  
            </>
          ) : (
            <AudioRecorder 
              onHideAudioRecorder={hideAudioRecorder} 
              firstUserAnswer={firstUserAnswer}
              onFeedback={getFeedback} 
              onAddMessage={onAddMessage} 
              showChat={showChat}
              setShowChat={setShowChat}
              isRecording={isRecording}
              toggleRecording={toggleRecording}
              examState={examState}
              setExamState={setExamState}
              isExamStarted={isExamStarted}
              setIsRecording={setIsRecording}
              setUserAnswer={setUserAnswer}
              nextQuestion={nextQuestion}
              currentQuestion={currentQuestion}
              qa={qa}
              reviewMode={reviewMode}
              saveExamInInterrogationMode={saveExamInInterrogationMode}
              saveInterrogationQA={saveInterrogationQA}
              currentHints={qa && qa[currentQuestion] && qa[currentQuestion].hints ? 
                qa[currentQuestion].hints : 
                ['Nessun suggerimento disponibile', 'Nessun suggerimento disponibile']}
            />
          )}        
        </div>
      </Field>
    </fetcher.Form>
  );
};

ChatCardForm.propTypes = {
  isExamFinished: PropTypes.bool.isRequired,
  messageID: (props, propName, componentName) => {
    if (props.reviewMode === true && (props[propName] === undefined || typeof props[propName] !== 'string')) {
      return new Error(`Prop '${propName}' is required when reviewMode is true or undefined in ${componentName}`);
    }
  },
  onAddMessage: PropTypes.func.isRequired,
  onCreateFeedback: PropTypes.func.isRequired,
  onFeedbackError: PropTypes.func.isRequired,
  firstUserAnswer: PropTypes.func.isRequired, 
};

export default ChatCardForm;