import { useState, useCallback, useEffect, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { getQuestions, isExamInProgress, getQAExamSessions } from "@lib/firestoreQueries";
import { updateInterrogationQA } from "../../../redux/actions/examActions";
import { useDispatch } from "react-redux";

const useGetQA = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [qa, setQA] = useState([]);
  const [currentQA, setCurrentQA] = useState(null);
  const { documentID } = useParams();
  const { state: { status, examID } } = useLocation();
  const didInit = useRef(false);
  const dispatch = useDispatch();

  const fetchQuestions = useCallback(async () => {
    const questions = await getQuestions(documentID);
    const formattedQuestions = questions.docs.map(doc => {
      const data = doc.data();
      if (!data.id) {
        data.id = doc.id;
      }
      return { id: doc.id, ...data };
    });

    // Filtraggio ibrido per supportare entrambi i formati
    const filteredQuestions = formattedQuestions.filter(qa => {
      // Escludi esplicitamente le flashcard
      if(qa.type === 'flashcard') return false;

      // Verifica il nuovo formato basato sul tipo
      const isNewType = qa.type === 'exam' || qa.type === 'repetition';

      // Controlla se il documento è quello di onboarding
      const isOnboardingDocument = documentID === 'yZI5jQzrvGbTsid0kvk6';
      
      // Verifica il formato legacy nei metadata
      const isLegacyType = qa?.metadata?.isRepetitionQuestion === true || 
                         qa?.metadata?.isExamQuestion === true || 
                         (!isOnboardingDocument && (qa?.flaggedWrong === true || qa?.flaggedWrong === false));
                         
      return isNewType || isLegacyType;
    });

    // Rimuovi duplicati e valida le domande
    const seenQuestions = new Set();
    const uniqueQuestions = filteredQuestions.filter(qa => {
      const question = qa.question || qa.domanda || '';
      if (!question || typeof question !== 'string') return false;
      
      const normalizedQuestion = question.trim().toLowerCase();
      if (!seenQuestions.has(normalizedQuestion)) {
        seenQuestions.add(normalizedQuestion);
        return true;
      }
      return false;
    });

    // Gestione sicura del sorting
    const sortedQuestions = uniqueQuestions.sort((a, b) => {
      const questionA = a.question || a.domanda || '';
      const questionB = b.question || b.domanda || '';
      
      try {
        return questionA.localeCompare(questionB);
      } catch (error) {
        console.warn('Errore ordinamento domande:', error);
        return 0;
      }
    });

    console.log("Questions caricate:", sortedQuestions);
    return sortedQuestions;
  }, [documentID]);

  const fetchCurrentQA = useCallback(async (examInProgressID) => {
    const { messages, reviewMode } = await getQAExamSessions(examInProgressID, documentID);
    if (reviewMode === false) {
      messages.forEach(message => dispatch(updateInterrogationQA(message)))
      setCurrentQA([...messages]);
    } 

    setCurrentQA([...messages]);
    console.log("Fetched current QA:", messages, "Review Mode:", reviewMode);
  }, [documentID, dispatch]);

  const getQA = useCallback(async () => {
    try {
      setIsLoading(true);
      const questions = await fetchQuestions();

      if (status === "not-started") {
        setQA(questions);
        setIsLoading(false);
        return;
      }

      const examInProgressID = await isExamInProgress(examID);
      if (examInProgressID) await fetchCurrentQA(examInProgressID);

      const uniqueQuestions = questions.filter(qa => {
        const question = qa.question || qa.domanda || ''; // Supporto per vecchio campo 'domanda'
        return question.trim() !== '';
      });

      setQA(uniqueQuestions);
      setIsLoading(false);
    } catch (error) {
      toast.error("Errore nel caricamento delle domande e risposte");
      console.error("Errore nel caricamento delle domande e risposte:", error);
      setIsLoading(false);
    }
  }, [fetchQuestions, fetchCurrentQA, status, examID]);

  useEffect(() => {
    if (!didInit.current) {
      didInit.current = true;
      getQA();
    }
  }, [getQA]);

  return { isLoading, qa, currentQA, documentID };
};

export default useGetQA;
