import React, { useEffect, useState, } from "react";
import clsx from "clsx";
import useUploadFiles from "./hooks/useUploadFiles";
import useDropFiles from "./hooks/useDropFiles";
import useGetDocuments from "../../documentsQA/hooks/useGetDocuments";
import { useSelector, useDispatch } from "react-redux";
import { IoClose } from "react-icons/io5";
import { Plus, Upload, AlertCircle } from "lucide-react";
import Heading from "@ui/heading/heading";
import Field from "@ui/field/field";
import Input from "@ui/input/input";
import TextArea from "@ui/textarea/textarea";
import Button from "@ui/button/button";
import ChatModalFreeSubscription from "../../../chat/chatCard/chatModalFreeSubscription/chatModalFreeSubscription";
import { toast } from "react-toastify";
import { setSubmitDocState, setProcessedNotes } from "../../../../redux/actions/documentActions";

import { setDocUploadProgress } from "../../../../redux/actions/docUploadProgressAction";
import { setPopupViewed, setLimitReached } from "../../../../redux/actions/loadScoreActions";
import "./uploadNotesForm.scss";

//Onboarding imports
import { Steps } from "intro.js-react";
import useGetOnboardingState from "./hooks/useGetOnboardingState";
import "intro.js/introjs.css";

const UploadNotesForm = ({ addMoreFiles }) => {
  const { fetcher, register, disabledForm, onNotesChange, uploadNotes, showTextArea, saveText, closeTextArea } = useUploadFiles();
  const { isDragging, onDragOver, onDragLeave, dropFiles } = useDropFiles(uploadNotes);
  const { user } = useSelector(state => state.auth);
  const [filesUploaded, setFilesUploaded] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [isFreeAccount, setIsFreeAccount] = useState(false);
  const { isLoading, isGenerate } = useSelector(state => state.document);
  const { getDocuments } = useGetDocuments()
  const dispatch = useDispatch();
  const isSubmitting = fetcher.state === "submitting";

  const files = useSelector(state => state.document.processedNotes);
  const currFiles = files;

  // Onboarding status
  const currentStep = useSelector(state => state.onboarding.currentStep);
  const [stepsEnabled, setStepsEnabled] = useState(currentStep < 3);
  const [onboardingClosed, setOnboardingClosed] = useState(false);
  const { updateCurrentStep, getCurrentStep } = useGetOnboardingState();

  useEffect(() => {
    getCurrentStep();
    if(currentStep === 1 && currentStep !== 22 && !onboardingClosed) setStepsEnabled(true);
  }, [getCurrentStep, isGenerate, currentStep, onboardingClosed]);

  const steps = [
    currentStep === 1 && !isGenerate && {
      element: ".UploadNotesForm__field",
      intro: "Clicca per selezionare il file da caricare",          
    },
    currentStep === 2 && isGenerate && {
      element: ".UploadNotesForm__form-footer-btn",
      intro: "Clicca per generare il tuo esame",
    },  
  ].filter(Boolean);

  const onExit = async () => {
    if(currentStep === 1) {
      updateCurrentStep(22);
      setOnboardingClosed(true);
    }
    setStepsEnabled(false);
  };

  // Salvataggio state per assicurarsi di renderizzare il Loader al momento giusto
  useEffect(() => {
    if(isSubmitting) {
      dispatch(setSubmitDocState(isSubmitting));
    } else {
      dispatch(setSubmitDocState(false));
    }
  }, [isSubmitting, dispatch, files]);
  
  useEffect(() => {
    if(currFiles.length !== files) {
      dispatch({ type: "SET_IS_LOADING", payload: false });
    }
    
    const tooltip = document.querySelector('.introjs-tooltip');
    if (tooltip) {
      tooltip.style.marginTop = '48px';
    }
    
    const helperLayer = document.querySelector('.introjs-helperLayer');
    if (helperLayer) {
      helperLayer.style.marginTop = '48px';
    }
      
    //End upload if it's a pages file
    if(showTextArea && files.length === 0) {
      dispatch({ type: "SET_IS_LOADING", payload: false });  
    }
  }, [files, currFiles, dispatch, showTextArea]);

  useEffect(() => {
    if(isGenerate) {
      const tooltip = document.querySelector('.introjs-tooltip');
      if (tooltip) {
        tooltip.style.marginTop = '0px';
      }
      
      const helperLayer = document.querySelector('.introjs-helperLayer');
      if (helperLayer) {
        helperLayer.style.marginTop = '0px';
      }
    }
  }, [isGenerate]);

  const handleAddFile = async (event) => {
    event.preventDefault();    

    //Aggiungi mock file per l'onboarding
    if(currFiles.length === 0 && currentStep <= 2) {
      dispatch(setProcessedNotes({
        name: "Divina_Commedia",
        type: "pdf",
        extractedText: "LILU 1 - PROF . FERRETTI Dante Alighieri: la Commedia (introduzion...",
      }));

      dispatch({ type: "SET_GENERATE", payload: true });
      
      if (currentStep === 1) {
        updateCurrentStep(2);
      };
      
      return;
    };
    
    if(addMoreFiles && currentStep < 9) {
      updateCurrentStep(22);
    };
    
    // Gestione dei file selezionati
    const files = event.target.files;
    if (!files || files.length === 0) {
      toast.warning('Nessun file selezionato. Aggiungere un file.');
      return;
    };

    // Conta il numero di file selezionati
    const selectedFilesCount = files.length;
    const notesUploaded = user?.documentsUploaded || 0;
    const totalNotesAfterUpload = notesUploaded + currFiles.length + selectedFilesCount;
    
    // Verifica se l'utente ha superato il limite di file
    if ((totalNotesAfterUpload > 2) && user?.plan !== 'Premium') {
      // Calcola quanti file possiamo ancora caricare
      const remainingSlots = Math.max(0, 2 - (notesUploaded + currFiles.length));
      
      if (remainingSlots <= 0) {
        setShowModal(true);
        dispatch(setLimitReached(true));
        dispatch(setPopupViewed());
        setIsFreeAccount(true);
        dispatch({ type: "SET_IS_PROCESSING", payload: false });
        dispatch({ type: "SET_IS_LOADING", payload: false });

        // trigger event for limit documents uploaded reached
        if (typeof window.gtag === 'function') {
          window.gtag('event', 'limitDocumentsUploadedReached', {
            'event_category': 'Account',
            'event_label': 'limit documents uploaded reached',
            'email': user.email
          });        
        } else {
          console.error("gtag is not defined");
        }
        
        return;
      }
      
      // Avvisa l'utente che non tutti i file selezionati verranno caricati
      if (selectedFilesCount > remainingSlots) {
        toast.info(`Stai caricando ${remainingSlots} file su ${selectedFilesCount} selezionati. Per caricare più file, passa al piano Premium.`);
      }
    }

    // Verifica la dimensione dei file
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileDimension = parseInt(file.size / 1024 / 1024);
      const fileType = file.type;
      
      if(fileType.startsWith('audio/') || fileType.startsWith('video/')) {
        dispatch(setDocUploadProgress('audio'));
      };
      
      if(fileDimension > 80 && (!fileType.startsWith('audio/') || !fileType.startsWith('video/'))) {
        toast.info('Inserisci un file di dimensione minore di 80mb (se non è un file video/audio, prova a rimuovere le foto dal file e riprova)');
        return;
      };
      
      if(fileDimension > 24 && (fileType.startsWith('audio/') || fileType.startsWith('video/'))) {
        toast.info('Inserisci un file audio/video di dimensione minore di 25mb');
        return;
      };
    }
    
    dispatch({ type: "SET_IS_LOADING", payload: true });
    dispatch({ type: "SET_GENERATE", payload: true });
    onNotesChange(event);
  };

  const handleGenerateExam = async () => {
    if(currentStep < 3) {
      updateCurrentStep(3);
      setStepsEnabled(false);
      dispatch({ type: "SET_GENERATE", payload: false });
      return;
    }

    dispatch({ type: "SET_PROCESSING_STATE", payload: true });
    dispatch({ type: "SET_GENERATE", payload: false });
    dispatch({ type: "SET_IS_LOADING", payload: true });
    dispatch(setSubmitDocState(isSubmitting));
    getDocuments();
  };  
  
  useEffect(() => {
    if(user?.plan !== 'Premium' && (user?.documentsUploaded === 2 || (user?.documentsUploaded === 1 && files.length >= 1))) {
      setFilesUploaded('2 / 2');
    }  else if(user?.plan !== 'Premium' && user?.documentsUploaded === 1) {
      setFilesUploaded('1 / 2');
    } else {
      setFilesUploaded(`${files.length} / 2`);
    } 
  }, [user.documentsUploaded, files.length, user.plan]);
  
  return (
    <>  
      <Steps
        enabled={stepsEnabled}
        steps={steps}
        initialStep={0}
        onExit={onExit}
      />
      {(showModal && isFreeAccount) && <ChatModalFreeSubscription onClose={() => setShowModal(false)} uploadingDoc={true} />}
      <fetcher.Form
        className={clsx(
          `UploadNotesForm ${user?.plan !== 'Premium' && 'UploadNotesForm__free-plan'} ${addMoreFiles && 'second-form'}`,
          (isDragging && user?.plan !== 'Premium') ? "UploadNotesForm--active-free" : (isDragging && user?.plan === 'Premium') ? "UploadNotesForm--active" : null,
          (((isLoading || disabledForm || showTextArea) ) || (!addMoreFiles && files.length > 0)) && "UploadNotesForm--disabled",
        )}
        onDrop={dropFiles}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
        action="/home?index"
        method="post"
        encType="multipart/form-data"
        onClick={addMoreFiles ? () => {
          // Quando addMoreFiles è true, l'intero form è cliccabile
          const fileInput = document.getElementById('notes');
          if (fileInput) fileInput.click();
        } : undefined}
      >
        <div className="UploadNotesForm__inner">
          {
            addMoreFiles ? 
              <>
                {!isDragging && (
                  <Field className={`UploadNotesForm__field ${addMoreFiles && 'UploadNotesForm__field-second-form'}`}>
                    {<Field.Label className="UploadNotesForm__field-label" label="Aggiungi File" htmlFor="notes" />}
                    <Input
                      className={`UploadNotesForm__field-input ${addMoreFiles && 'UploadNotesForm__input-second-form'}`}
                      id="notes"
                      name="notes"
                      type="file"
                      accept=".pdf, .txt, .doc, .docx, .jpg, .png, .jpeg, .ppt, .pptx, .pages, .key, .mp3, .mp4"
                      multiple
                      {...register("files")}
                      onChange={(event) => handleAddFile(event)}
                    />
                  </Field>
                )}
                <div className="second-form__wrapper">
                  <Plus size={20} className="second-form__more-files-icon" />
                  <span className="second-form__text">Aggiungi un altro file</span>
                </div>
              </>
              
            : 
              <>
                <div className="UploadNotesForm__icon-wrapper">
                  <Upload size={40} className="UploadNotesForm__icon" /> 
                </div>
            
                <div /* className={isDragging ? `UploadNotesForm__dragging` : null} */>         
                  <Heading className="UploadNotesForm__title" title="Trascina o Seleziona i file da caricare" heading="h5" />  
                  <p className="UploadNotesForm__text">
                    Carica i tuoi appunti, dispense, immagini, audio o qualsiasi materiale di studio per creare il tuo esame personalizzato
                  </p>
                </div>
  
                {!isDragging && files.length === 0 ?  (

                  <Field className={`UploadNotesForm__field ${user?.plan === 'Premium' && 'UploadNotesForm__field'}`}>
                    <span className="UploadNotesForm__field-label-wrapper">
                      <Plus className="UploadNotesForm__field-label-icon" size={19} />
                      <Field.Label className="UploadNotesForm__field-label" label="Seleziona File" htmlFor="notes" />
                    </span>
                    <Input
                      className="UploadNotesForm__field-input"
                      id="notes"
                      name="notes"
                      type={currentStep === 1 ? "button" : "file"}
                      accept=".pdf, .txt, .doc, .docx, .jpg, .png, .jpeg, .ppt, .pptx, .pages, .key, .mp3, .mp4"
                      multiple
                      {...register("files")}     
                      /* onChange={(event) => handleAddFile(event)} */
                      
                      // Onboarding logic              
                      onChange={(currentStep > 2 || !currentStep) ? (event) => handleAddFile(event) : undefined}
                      onClick={currentStep <= 2 ? (event) => handleAddFile(event) : undefined}
                      />
                  </Field>)
                  
                  : null
                }
              </>
          }
        </div>
      </fetcher.Form>
      <>
      
      {(user?.plan !== 'Premium' && !addMoreFiles && files.length === 0) && (
        <div className="UploadNotesForm__info-container-first-form">
          <div className="UploadNotesForm__alert-wrapper">
            <AlertCircle size={18} className="UploadNotesForm__alert-icon" />
            <p className="UploadNotesForm__info">
              <strong>Documenti caricati:</strong> {filesUploaded}
            </p>
          </div>
        </div>
      )}
        
      {user?.plan !== 'Premium' && addMoreFiles
        ?
          <div className="UploadNotesForm__form-footer">
            {user?.documentsUploaded === undefined && (
             <>
              <AlertCircle size={18} className="UploadNotesForm__alert-icon" />
              <p className="UploadNotesForm__info">
                <strong>Documenti caricati:</strong> {filesUploaded}
              </p>
             </>
            )}
            {typeof user.documentsUploaded === "number" && (
              <div className="UploadNotesForm__alert-wrapper">
                <AlertCircle size={18} className="UploadNotesForm__alert-icon" />
                <p className="UploadNotesForm__info">
                  <strong>Documenti caricati:</strong> {filesUploaded}
                </p>
              </div>
            )}

            {
              (files.length && !isSubmitting && (!isLoading)) && isGenerate
               
              ? 
                <button className={`UploadNotesForm__form-footer-btn ${(isSubmitting || !files.length) && 'btn-disabled'}`} onClick={handleGenerateExam}>
                  Genera Esame
                </button>
                
              : null
            }
          </div> 
        
        : 
          (files.length && !isSubmitting && (!isLoading) && addMoreFiles) && isGenerate
            
          ? <div className="UploadNotesForm__generate-container">
              <button className={`UploadNotesForm__form-footer-btn ${(isSubmitting || !files.length) && 'btn-disabled'}`} onClick={handleGenerateExam}>
                Genera Esame
              </button>
            </div>
            
          : null 
      }
      </>
      {showTextArea && (
        <div className="UploadNotesForm__textarea-container">
          <p className="UploadNotesForm__error">
            <span className="UploadNotesForm__error-title">Problema nel caricamento</span><br /><br />
            Non possiamo leggere testo dai file Pages o Keynote,
            per favore copia e incolla il tuo testo qui
            o converti il file in PDF e caricalo sopra.
          </p>
          <button onClick={closeTextArea} className="UploadNotesForm__close-button">
            <IoClose className="UploadNotesForm__close-icon" />
          </button>
          <TextArea
            id="manualText"
            className="UploadNotesForm__textarea"
            placeholder="Inserisci il testo qui..."
            {...register("manualText")}
          />
          <Button onClick={saveText} className="UploadNotesForm__save-button">Salva</Button>
        </div>
      )}
    </>
  );
};

export default UploadNotesForm;
