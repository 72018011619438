import React from 'react';
import PropTypes from 'prop-types';
import Feedback from './feedback/feedback';
import clsx from 'clsx';
import { FaLock } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import './itemQA.scss';

const ContentOverview = ({ icon: Icon, count, type, color, additionalInfo }) => {
  const currentStep = useSelector(state => state.onboarding.currentStep);
  const { user } = useSelector(state => state.auth);
  const isPremium = user?.plan === 'Premium';

  const getLabel = () => {
    switch (type) {
      case 'flashcard':
        return `${count} flashcard disponibili`;
      case 'question':
        return `${count} domande disponibili`;
      case 'summary':
        return `~${currentStep <= 8 ? 5 : count} min di lettura`;
      default:
        return `${count} elementi`;
    }
  };

  return (
    <div className={clsx("ItemQA__overview", {
      "ItemQA__overview--locked": !isPremium && type === 'flashcard'
    })}>
      <div className="ItemQA__overview-icon" style={{ color }}>
        <Icon size={20} />
      </div>
      <div className="ItemQA__overview-content">
        <span className="ItemQA__overview-total" data-type={type}>
          {getLabel()}
          {type === 'summary' && additionalInfo && (
            <span className="ItemQA__overview-details">
              {' '}| {currentStep <= 8 ? 5.276 : additionalInfo.words.toLocaleString()} caratteri | {currentStep <= 8 ? 8 : additionalInfo.sections} sezioni
            </span>
          )}
        </span>
      </div>
    </div>
  );
};

const ItemQA = ({ questionNum, question, answer, feedback, onFeedback, type, isLocked }) => {
  const { user } = useSelector(state => state.auth);
  const isPremium = user?.plan === 'Premium';

  // Se l'utente è premium, non mostrare mai il lucchetto
  const shouldLock = isPremium ? false : isLocked;

  return (
    <li className={clsx("ItemQA", { "ItemQA--locked": shouldLock })}>
      <div className="ItemQA__content">
        <h3 className="ItemQA__question">
          <span className={clsx("ItemQA__question-number", `ItemQA__question-number--${type}`)}>
            {questionNum}.
          </span>
          {shouldLock ? question.substring(0, 50) + '...' : question}
        </h3>
        {!shouldLock ? (
          <p className="ItemQA__answer">{answer}</p>
        ) : (
          <div className="ItemQA__preview">
            <p className="ItemQA__preview-text">
              {answer.substring(0, 40)}...
            </p>
            <div className="ItemQA__lock-message">
              <FaLock className="ItemQA__lock-icon" />
              <span>Contenuto Premium</span>
            </div>
          </div>
        )}
      </div>

      {!shouldLock && (
        <Feedback
          feedback={feedback}
          onFeedback={onFeedback}
          question={question}
        />
      )}
    </li>
  );
};

ItemQA.propTypes = {
  questionNum: PropTypes.number.isRequired,
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  feedback: PropTypes.shape({
    isHelpful: PropTypes.bool,
    timestamp: PropTypes.number,
    userId: PropTypes.string
  }),
  onFeedback: PropTypes.func,
  type: PropTypes.oneOf(['question', 'flashcard']).isRequired,
  isLocked: PropTypes.bool
};

ContentOverview.propTypes = {
  icon: PropTypes.elementType.isRequired,
  count: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['question', 'flashcard', 'summary']).isRequired,
  color: PropTypes.string.isRequired,
  additionalInfo: PropTypes.shape({
    words: PropTypes.number,
    sections: PropTypes.number
  })
};

ItemQA.defaultProps = {
  isLocked: false
};

export { ContentOverview };
export default ItemQA;