import React from 'react';
import PropTypes from 'prop-types';
import { Info } from 'lucide-react';
import { TbCheckbox } from "react-icons/tb";
import { FaLock } from 'react-icons/fa';
import { HiLockClosed } from 'react-icons/hi';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import './MultipleChoice.scss';

const FREE_QUESTIONS_LIMIT = 3;

const QuestionStats = ({ questions }) => {
  const { user } = useSelector(state => state.auth);
  const isPremium = user?.plan === 'Premium';

  if (isPremium) return null;

  return (
    <div className="MultipleChoice__stats">
      <div className="MultipleChoice__stats-header">
        <div className="MultipleChoice__stats-icon">
          <HiLockClosed />
        </div>
        <div className="MultipleChoice__stats-info">
          <div className="MultipleChoice__stats-details">
            <div className="MultipleChoice__stats-item MultipleChoice__stats-item--premium">
              Versione gratuita
            </div>
            <div className="MultipleChoice__stats-item">
              <span className="premium">{FREE_QUESTIONS_LIMIT}</span> domande a risposta multipla gratuite su {questions.length}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const QuestionSummary = ({ questions }) => {
  const { user } = useSelector(state => state.auth);
  const isPremium = user?.plan === 'Premium';

  // Calcola il conteggio per difficoltà
  const difficultyCount = questions.reduce((acc, q) => {
    acc[q.difficulty_level] = (acc[q.difficulty_level] || 0) + 1;
    return acc;
  }, {});

  return (
    <div className={clsx("MultipleChoice__summary", {
      "MultipleChoice__summary--locked": !isPremium
    })}>
      <div className="MultipleChoice__summary-content">
        <div className="MultipleChoice__summary-icon">
          <TbCheckbox size={20} />
        </div>
        <div className="MultipleChoice__summary-info">
          <div className="MultipleChoice__summary-total">
            {questions.length} domande disponibili
          </div>
          <div className="MultipleChoice__summary-badges">
            {difficultyCount.easy > 0 && (
              <span className="MultipleChoice__summary-badge MultipleChoice__summary-badge--easy">
                {difficultyCount.easy} facili
              </span>
            )}
            {difficultyCount.medium > 0 && (
              <span className="MultipleChoice__summary-badge MultipleChoice__summary-badge--medium">
                {difficultyCount.medium} medie
              </span>
            )}
            {difficultyCount.hard > 0 && (
              <span className="MultipleChoice__summary-badge MultipleChoice__summary-badge--hard">
                {difficultyCount.hard} difficili
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const MultipleChoice = ({ questions }) => {
  const { user } = useSelector(state => state.auth);
  const isPremium = user?.plan === 'Premium';
  
  // Current onboarding user step 
  const currentStep = useSelector(state => state.onboarding.currentStep);

  //Condition for Onboarding
  const getChoice = (question, choice) => {
    if(currentStep <= 8) {
      return question.quiz_questions.answer_choices[choice];
    } else {
      return question.answer_choices[choice];
    }
  }
  
  //Condition for Onboarding
  const getCorrectChoice = (question) => {
    if(currentStep <= 8) {
      return question.quiz_questions.correct_choice;
    } else {
      return question.correct_choice;
    }
  }

  const getDifficultyLabel = (level) => {
    const labels = {
      easy: 'Facile',
      medium: 'Media',
      hard: 'Difficile',
      difficult: 'Difficile'
    };
    return labels[level] || level;
  };

  return (
    <div className="MultipleChoice">
      <QuestionSummary questions={questions} />
      {!isPremium && <QuestionStats questions={questions} />}

      {questions.map((question, index) => {
        const isLocked = !isPremium && index >= FREE_QUESTIONS_LIMIT;

        return (
          <div key={index} className={clsx("MultipleChoice__container", {
            "MultipleChoice__container--locked": isLocked
          })}>
            <div className="MultipleChoice__header">
              <div className="MultipleChoice__question">
                <h4 className="MultipleChoice__text">
                  <span className="MultipleChoice__number">{index + 1}.</span>
                  {isLocked ? question.query_text || question.quiz_questions.query_text + '...' : question.query_text || question.quiz_questions.query_text}
                </h4>
              </div>
              <span className={`MultipleChoice__difficulty MultipleChoice__difficulty--${question.difficulty_level || question.quiz_questions.difficulty_level}`}>
                {getDifficultyLabel(question.difficulty_level || question.quiz_questions.difficulty_level)}
              </span>
            </div>

            {!isLocked ? (
              <>
                <div className="MultipleChoice__options">
                  {/* Risposta numero 1 */}
                  <div 
                    className={`MultipleChoice__option ${
                      '1' === getCorrectChoice(question)
                        ? 'MultipleChoice__option--correct'
                        : ''
                    }`}
                  >
                    {getChoice(question, '1')}
                  </div>
                  
                  {/* Risposta numero 2 */}
                  <div 
                    className={`MultipleChoice__option ${
                      '2' === getCorrectChoice(question)
                        ? 'MultipleChoice__option--correct'
                        : ''
                    }`}
                  >
                    {getChoice(question, '2')}
                  </div>
                  
                  {/* Risposta numero 3 */}
                  <div 
                    className={`MultipleChoice__option ${
                      '3' === getCorrectChoice(question)
                        ? 'MultipleChoice__option--correct'
                        : ''
                    }`}
                  >
                    {getChoice(question, '3')}
                  </div>
                  
                  {/* Risposta numero 4 */}
                  <div 
                    className={`MultipleChoice__option ${
                      '4' === getCorrectChoice(question)
                        ? 'MultipleChoice__option--correct'
                        : ''
                    }`}
                  >
                    {getChoice(question, '4')}
                  </div>
                </div>

                {(question.justification || question.quiz_questions.justification) && (
                  <div className="MultipleChoice__explanation">
                    <div className="MultipleChoice__explanation-header">
                      <Info className="MultipleChoice__explanation-icon" />
                      <div className="MultipleChoice__explanation-content">
                        <div className="MultipleChoice__explanation-title">
                          Risposta corretta: {question.correct_choice || question.quiz_questions.correct_choice}
                        </div>
                        <p className="MultipleChoice__explanation-text">
                          {question.justification || question.quiz_questions.justification}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="MultipleChoice__preview">
                <div className="MultipleChoice__preview-text">
                  {getChoice(question, '1').substring(0, 40)}...
                </div>
                <div className="MultipleChoice__lock-message">
                  <FaLock className="MultipleChoice__lock-icon" />
                  <span>Contenuto Premium</span>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

MultipleChoice.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      query_text: PropTypes.string.isRequired,
      answer_choices: PropTypes.object.isRequired,
      justification: PropTypes.string,
      difficulty_level: PropTypes.oneOf(['easy', 'medium', 'hard','difficult']).isRequired,
      correct_choice: PropTypes.string.isRequired
    })
  ).isRequired
};

export default MultipleChoice;