import React, { useState, useRef, useEffect } from 'react';
import { IoHelpCircleOutline } from 'react-icons/io5';
import { Bell, MessageSquarePlus, MessageCircle } from 'lucide-react';
import clsx from 'clsx';
import './HelpMenu.scss';

const HelpMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMainMenuOpen, setIsMainMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    const handleMainMenuChange = () => {
      const hamburgerPortal = document.getElementById('hamburgerMenu');
      if (hamburgerPortal) {
        const isMenuOpen = hamburgerPortal.children.length > 0;
        setIsMainMenuOpen(isMenuOpen);
        if (isMenuOpen) {
          setIsOpen(false);
        }
      }
    };

    // Osserva il portale del menu principale
    const observer = new MutationObserver(handleMainMenuChange);
    const hamburgerPortal = document.getElementById('hamburgerMenu');
    
    if (hamburgerPortal) {
      observer.observe(hamburgerPortal, {
        childList: true,
        subtree: true
      });
      // Controlla lo stato iniziale
      handleMainMenuChange();
    }

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchend', handleClickOutside, { passive: true });

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchend', handleClickOutside);
      observer.disconnect();
    };
  }, []);

  const handleWhatsNew = () => {
    setIsOpen(false);
    window.open('https://studierai.canny.io/changelog', '_blank');
  };

  const handleFeedback = () => {
    setIsOpen(false);
    window.open('https://studierai.canny.io/feedback', '_blank');
  };

  const handleSupport = () => {
    setIsOpen(false);
    window.open('https://studierai.canny.io/errori-bugs', '_blank');
  };

  const handleTriggerClick = (e) => {
    if (e && e.type === 'click') {
      e.preventDefault();
      e.stopPropagation();
    }
    
    if (!isMainMenuOpen) {
      requestAnimationFrame(() => {
        setIsOpen(prev => !prev);
      });
    }
  };

  if (isMainMenuOpen) {
    return null;
  }

  return (
    <div className="HelpMenu" ref={menuRef}>
      <button
        className="HelpMenu__icon"
        onClick={handleTriggerClick}
        aria-expanded={isOpen}
        aria-haspopup="menu"
        aria-label="Menu aiuto"
      >
        <IoHelpCircleOutline className="help-circle-icon" />
      </button>

      <div 
        className={clsx(
          "HelpMenu__dropdown",
          isOpen && "HelpMenu__dropdown--visible"
        )}
        role="menu"
        aria-orientation="vertical"
      >
        <div className="HelpMenu__content">
          <button onClick={handleWhatsNew} role="menuitem">
            <Bell size={18} />
            Novità StudierAI
          </button>
          <button onClick={handleFeedback} role="menuitem">
            <MessageSquarePlus size={18} />
            Condividi tue idee
          </button>
          <button onClick={handleSupport} role="menuitem">
            <MessageCircle size={18} />
            Assistenza
          </button>
        </div>
      </div>
    </div>
  );
};

export default HelpMenu;