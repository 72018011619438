import React, { useState, useRef, useEffect, useCallback } from 'react';
import { ChevronDown } from 'lucide-react';
import { NAVIGATION_ITEMS } from '../constants';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import './Dropmenudown.scss';

const StudentiDropdown = ({ onClose }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const handleClick = useCallback((path) => {
    navigate(path);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setIsOpen(false);
    if (onClose) onClose();
  }, [navigate, onClose]);

  const handleTriggerClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(prev => !prev);
  }, []);

  return (
    <div 
      ref={dropdownRef}
      className="DropMenudown DropMenudown--per-chi"
    >
      <button
        className={clsx(
          "DropMenudown__trigger",
          isOpen && "DropMenudown__trigger--active"
        )}
        onClick={handleTriggerClick}
        aria-expanded={isOpen}
      >
        {NAVIGATION_ITEMS.perChi.label}
        <ChevronDown 
          size={18}
          className={clsx(
            "DropMenudown__chevron",
            isOpen && "DropMenudown__chevron--rotated"
          )}
        />
      </button>

      <div 
        className={clsx(
          "DropMenudown__content",
          isOpen && "DropMenudown__content--visible"
        )}
      >
        <div className="DropMenudown__items">
          <button
            onClick={() => handleClick('/faculty')}
            className="DropMenudown__item"
          >
            Università
          </button>
          <button
            onClick={() => handleClick('/faculty/scuole')}
            className="DropMenudown__item"
          >
            Scuole
          </button>
          <button
            onClick={() => handleClick('/faculty/partner')}
            className="DropMenudown__item"
          >
            Diventa Partner
          </button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(StudentiDropdown); 