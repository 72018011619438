export const QuestionStates = {
  NOT_EVALUATED: 0,
  TO_REVIEW: 1,
  KNOWN: 2,
  TOTAL_STATES: 3
};

export const QuestionStateLabels = {
  [QuestionStates.NOT_EVALUATED]: 'Non valutata',
  [QuestionStates.TO_REVIEW]: 'Da rivedere',
  [QuestionStates.KNOWN]: 'La so!'
};