import { useEffect, useState } from 'react';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { HiOutlineLightBulb } from "react-icons/hi";
import './hintButton.scss';

const HintButton = ({ currentHints = ['Nessun suggerimento disponibile', 'Nessun suggerimento disponibile'], examState, audioRecorder, showChat }) => {
  const [showHints, setShowHints] = useState(false);
  const [currentHintIndex, setCurrentHintIndex] = useState(0);

  useEffect(() => {
    if (showChat) {
      setShowHints(false);
    }
  }, [showChat]);

  const toggleHints = () => {
    if (examState === 'processing') return;
    setShowHints(!showHints);
  };

  const handlePreviousHint = () => setCurrentHintIndex(0);
  const handleNextHint = () => setCurrentHintIndex(1);

  return (
    <div className="hint-button-container">
      <button 
        className={`hint-button ${showHints ? 'active' : ''}`} 
        onClick={toggleHints}
        aria-label="Mostra suggerimenti"
        data-tooltip="Suggerimenti"
        disabled={examState === 'processing' || audioRecorder}
      >
        <HiOutlineLightBulb size={30} />
      </button>
      
      {showHints && (
        <div className="hint-popup">
          <div className="hint-content">
            <HiOutlineLightBulb className="hint-icon" />
            {currentHints[currentHintIndex] || 'Nessun suggerimento disponibile'}
          </div>
          <div className="hint-navigation">
            <button 
              className="hint-nav-button" 
              onClick={handlePreviousHint}
              disabled={currentHintIndex === 0}
              aria-label="Suggerimento precedente"
            >
              <BsChevronLeft size={16} className="hint-nav-icon" />
            </button>
            <div className="hint-indicator">
              <span className={currentHintIndex === 0 ? 'active' : ''}></span>
              <span className={currentHintIndex === 1 ? 'active' : ''}></span>
            </div>
            <button 
              className="hint-nav-button" 
              onClick={handleNextHint}
              disabled={currentHintIndex === 1}
              aria-label="Suggerimento successivo"
            >
              <BsChevronRight size={16} className="hint-nav-icon" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default HintButton; 