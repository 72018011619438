import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./documentsQA.scss";
import useGetDocuments from "./hooks/useGetDocuments";
import useChangeDocument from "./hooks/useChangeDocument";
import DocumentsQALoader from "./documentsQALoader/documentsQALoader";
import DocumentsQAInfo from "./documentsQAInfo/documentsQAInfo";
import DocumentQA from "./documentQA/documentQA";
import DocumentsQAActions from "./documentsQAActions/documentsQAActions";
import DocumentsQASaveButton from "./documentsQASaveButton/documentsQASaveButton";
import { setSubmitDocState } from "@redux/actions/documentActions";
import { useFeedback } from "./hooks/useFeedback";

import useGetOnboardingState from "../uploadNotesCard/uploadNotesForm/hooks/useGetOnboardingState";
import { setCurrentStep } from "../../../redux/actions/oboardingActions";

const DocumentsQA = () => {
 const dispatch = useDispatch();
 const { resetDocsQA } = useGetDocuments();
 const { currentDocument, previousDocument, nextDocument } = useChangeDocument();
 const [documentsWithTempTitles, setDocumentsWithTempTitles] = useState([]);
 const [currentState, setCurrentState] = useState(false);
 
 const currentStep = useSelector(state => state.onboarding.currentStep);
 const { getCurrentStep } = useGetOnboardingState({ setCurrentStep, currentStep });

 useEffect(() => {
  getCurrentStep();
 }, [getCurrentStep, currentStep]);
 
 const {
   documentsQA,
   isGenerate,
   isLoading,
   tags,
   summary,
   processedNotes,
   isSubmitting: submitState
 } = useSelector(state => state.document);

 // Inizializza i documenti con titoli temporanei
 useEffect(() => {
   if (documentsQA.length > 0) {
     setDocumentsWithTempTitles(documentsQA.map(doc => ({
       ...doc,
       originalTitle: doc.title // Mantiene il titolo originale
     })));
   }
 }, [documentsQA, dispatch]);

 // Gestione loading state
 useEffect(() => {
   if (!isLoading && currentState) {
     dispatch(setSubmitDocState(false));
   } else if (isLoading) {
     setCurrentState(isLoading);
   }
 }, [isLoading, currentState, dispatch]);

 const {
   feedbackStates,
   handleFeedback,
   resetFeedback,
   activeFeedbackCount
 } = useFeedback(
   documentsQA[currentDocument]?.examExtractedQA || [],
   (index, isHelpful) => {
     const totalQuestions = documentsQA[currentDocument]?.examExtractedQA?.length || 0;
     console.log(`Feedback per domanda ${index + 1}/${totalQuestions}: ${isHelpful ? '✅' : '❌'}`);
   }
 );

  const currentDocumentData = documentsQA[currentDocument]; 
  const documentTag = currentDocumentData ? currentDocumentData.tags : null; 

 // Handler per aggiornare il titolo temporaneo
 const handleUpdateTitle = useCallback((index, newTitle) => {
   console.log('📝 Aggiornamento titolo locale:', {
     index,
     newTitle,
     originalTitle: documentsWithTempTitles[index]?.originalTitle
   });
  
   setDocumentsWithTempTitles(prevDocs => {
     const newDocs = [...prevDocs];
     if (newDocs[index]) {
       newDocs[index] = {
         ...newDocs[index],
         title: newTitle,
         lastModified: Date.now()
       };
     }
     return newDocs;
   });
 }, [documentsWithTempTitles]);


 // Handler per il reset combinato
 const handleReset = useCallback(() => {
   console.log('🔄 Reset documenti e feedback');
   resetFeedback();
   resetDocsQA();
   setDocumentsWithTempTitles(documentsQA.map(doc => ({ ...doc })));
 }, [resetFeedback, resetDocsQA, documentsQA]);

 // Prepara i documenti con feedback per il salvataggio
 const documentsToSave = useMemo(() => {
   return documentsWithTempTitles.map((doc, docIndex) => {
     // Estrai i dati grezzi una sola volta
     const { rawExamData = {}, rawFlashcards = {}, summary: docSummary = {} } = doc;
     const qa_pairs = rawExamData.qa_pairs || [];
     const flashcard_pairs = rawFlashcards.qa_pairs || [];
     
     // Prepara il documento in modo efficiente
     const updatedDoc = {
       ...doc,
       tags: tags || [],
       summary: {
         abstract: docSummary.abstract || summary?.abstract || '',
         sections: docSummary.sections || summary?.sections || [],
         hasAbstract: !!(docSummary.abstract || summary?.abstract),
         sectionsCount: (docSummary.sections || summary?.sections || []).length
       },
       rawExamData: {
         qa_pairs,
         tags: tags || []
       },
       rawFlashcards: {
         qa_pairs: flashcard_pairs
       }
     };

     // Aggiungi feedback solo per il documento corrente
     if (docIndex === currentDocument && qa_pairs.length > 0) {
       updatedDoc.extractedQA = qa_pairs.map((qa, qaIndex) => ({
         ...qa,
         feedback: feedbackStates[qaIndex]
       }));
     } else {
       updatedDoc.extractedQA = qa_pairs;
     }

     // Rimuovi campi non necessari
     const { originalTitle, sections, sectionsCount, ...cleanedDoc } = updatedDoc;

     return cleanedDoc;
   });
 }, [documentsWithTempTitles, currentDocument, feedbackStates, tags]);


 if (isLoading || submitState) {
   return <DocumentsQALoader />;
 }

 if (!processedNotes.length || isGenerate || currentStep < 3) {
   return <DocumentsQAInfo />;
 }

 return (
   <div className="DocumentsQA">
     <div className="DocumentsQA__list">
       {documentsWithTempTitles.map((document, index) => {
         // Assicurati che examExtractedQA sia un array e che contenga almeno un elemento
         const examExtractedQA = Array.isArray(document.examExtractedQA) ? document.examExtractedQA : [];
         
         const validDocument = {
           ...document,
           extractedQA: examExtractedQA.map(qa => ({
             question: qa.question || "",  // Assicura che question sia sempre definito
             answer: qa.answer || "",      // Assicura che answer sia sempre definito
             answers: qa.answers || qa.answer || ""
           })) || [{ question: "", answer: "", answers: "" }]  // Fornisce un valore di default se l'array è vuoto
         };

         const isCurrentDoc = currentDocument === index;
        
         return (
           <DocumentQA
             key={document.id || index}
             index={index}
             id={document.id}
             isActive={isCurrentDoc}
             documentsQA={documentsWithTempTitles}
             documentTag={documentTag}             
             feedbackStates={isCurrentDoc ? feedbackStates : []}
             onUpdateTitle={(newTitle) => handleUpdateTitle(index, newTitle)}
             onFeedback={(questionIndex, isHelpful) => {
               if (isCurrentDoc) {
                 handleFeedback(questionIndex, isHelpful);
               }
             }}
             {...validDocument}
           />
         );
       })}
     </div>
      
     {/*Render DocumentQA if onboarding active */}
     {currentStep <= 8 ? <DocumentQA /> : null}

     <div className="DocumentsQA__footer">
       <div className="DocumentsQA__footer-content">
         <DocumentsQAActions
           currentDocument={currentDocument}
           onPrevious={previousDocument}
           onNext={nextDocument}
           totalDocuments={documentsWithTempTitles.length}
         />
         <DocumentsQASaveButton
           documentsQA={documentsToSave}
           feedbackStates={feedbackStates}
           activeFeedbackCount={activeFeedbackCount}
           onReset={handleReset}
         />
       </div>
     </div>
   </div>
 );
};


export default DocumentsQA;


