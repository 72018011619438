import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ChevronLeft, Trash2, Plus, FolderPlus, Search, Folder as FolderIcon, ArrowRight, X, ArrowLeft, Check } from 'lucide-react';
import './FolderContent.scss';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Document } from '../../../materials/materialsDocuments/document/document';
import Button from '@ui/button/button';
import { useFolderSelectors } from '../hooks/useFolderSelectors';
import { useFolderActions } from '../hooks/useFolderActions';
import Folder from './Folder';
import CreateSubfolderTile from './CreateSubfolderTile';
import { getFolderColor, folderColors } from '../utils/folderColors';
import { formatTimeStamp } from '../../../materials/materialsDocuments/formatTimeStamp';
import Input from '@ui/input/input';

const FolderContent = ({ folder, onBack, onChange }) => {
  const { 
    createSubfoldersSelector,
    createFolderDocumentsSelector,
    getFolderNestingLevel, 
    getParentFolder,
    allDocuments,
    allFolders,
    refreshCounter
  } = useFolderSelectors();
  
  const { 
    removeDocumentsFromFolder, 
    addDocumentsToFolder, 
    createNewFolder, 
    deleteFolderById,
    forceRefreshFolders
  } = useFolderActions();
  
  // Stato locale per forzare aggiornamenti quando i documenti cambiano
  const [localRefreshCounter, setLocalRefreshCounter] = useState(0);
  
  // Stato locale per mantenere i documentIds aggiornati
  const [localDocumentIds, setLocalDocumentIds] = useState(folder.documentIds || []);
  
  // Aggiorna lo stato locale quando la prop folder cambia
  useEffect(() => {
    setLocalDocumentIds(folder.documentIds || []);
  }, [folder.documentIds]);
  
  // Get folder documents using local document IDs to ensure UI stays updated
  const documents = useSelector(state => 
    createFolderDocumentsSelector(state, localDocumentIds)
  );
  
  // Get subfolder directly using useSelector
  const childFolders = useSelector(state => 
    createSubfoldersSelector(state, folder.id)
  );
  
  // State variables
  const [isRemovingMode, setIsRemovingMode] = useState(false);
  const [isAddingMode, setIsAddingMode] = useState(false);
  const [isCreatingFolder, setIsCreatingFolder] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [folderName, setFolderName] = useState('');
  const [selectedFolderColor, setSelectedFolderColor] = useState('blue');
  const [isNamingFolder, setIsNamingFolder] = useState(false);
  const [searchMode, setSearchMode] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [forceUpdate, setForceUpdate] = useState(false);
  
  // Refs per monitorare i cambiamenti delle cartelle figlie
  const prevChildFoldersLengthRef = React.useRef(0);
  const prevRefreshCounterRef = React.useRef(0);
  const prevDocumentIdsLengthRef = React.useRef(0);
  const isRefreshingRef = React.useRef(false);
  
  // Get folder color
  const folderColor = getFolderColor(folder.color || 'blue');
  
  // Check if this is a subfolder (has a parentId)
  const isSubfolder = !!folder.parentId;
  
  // Get parent folder if this is a subfolder
  const parentFolder = useMemo(() => {
    if (!isSubfolder) return null;
    return getParentFolder(folder);
  }, [isSubfolder, folder, getParentFolder]);
  
  // Monitora i cambiamenti nelle sottocartelle e nel refreshCounter
  // Combiniamo gli effetti per evitare aggiornamenti ciclici
  useEffect(() => {
    const hasChildFoldersChanged = childFolders?.length !== prevChildFoldersLengthRef.current;
    const hasRefreshCounterChanged = refreshCounter !== prevRefreshCounterRef.current;
    
    // Aggiorniamo solo se c'è stato un cambiamento effettivo
    if (hasChildFoldersChanged || hasRefreshCounterChanged) {
      console.log(`Cartelle figlie aggiornate (${childFolders?.length}):`, 
        childFolders?.map(f => f.name).join(', '));
      
      // Aggiorniamo i riferimenti
      prevChildFoldersLengthRef.current = childFolders?.length || 0;
      prevRefreshCounterRef.current = refreshCounter;
      
      // Forziamo un aggiornamento una sola volta
      setForceUpdate(prev => !prev);
    }
  }, [childFolders, refreshCounter]);
  
  // Aggiorniamo anche l'effetto che monitora i cambiamenti in folder
  useEffect(() => {
    const hasDocumentIdsChanged = folder.documentIds?.length !== prevDocumentIdsLengthRef.current;
    
    if (hasDocumentIdsChanged) {
      console.log("Cartella aggiornata, documentIds:", folder.documentIds?.length);
      prevDocumentIdsLengthRef.current = folder.documentIds?.length || 0;
      setLocalRefreshCounter(prev => prev + 1);
    }
  }, [folder, folder.documentIds]);
  
  // Determine nesting level of the folder
  const folderNestingLevel = getFolderNestingLevel(folder);
  
  // Flag to determine if we can create subfolders
  const canCreateChildFolder = folderNestingLevel < 4;
  
  // Current color object for new subfolder
  const currentColor = useMemo(() => {
    const folderColorObj = getFolderColor(selectedFolderColor);
    return {
      name: selectedFolderColor,
      label: selectedFolderColor.charAt(0).toUpperCase() + selectedFolderColor.slice(1),
      color: folderColorObj.icon,
      rgb: folderColorObj.rgb,
      buttonBg: folderColorObj.buttonBg,
      buttonHoverBg: folderColorObj.buttonHoverBg,
      buttonActiveBg: folderColorObj.buttonActiveBg
    };
  }, [selectedFolderColor]);
  
  // Available colors for folder selection
  const availableColors = useMemo(() => {
    return Object.values(folderColors);
  }, []);
  
  // Selezioniamo i documenti utilizzando useMemo per reagire al localRefreshCounter
  const filteredDocuments = useMemo(() => {
    if (!searchMode || !searchQuery.trim()) {
      // Questo ricalcolo sarà trigger quando localRefreshCounter cambia
      console.log("Ricalcolo dei documenti della cartella con ID", folder.id, "- Refresh counter:", localRefreshCounter);
      return documents;
    }
    
    const query = searchQuery.toLowerCase().trim();
    return documents.filter(doc => 
      doc.title.toLowerCase().includes(query) || 
      (doc.description && doc.description.toLowerCase().includes(query))
    );
  }, [documents, searchMode, searchQuery, localRefreshCounter]);

  // Get available documents that are not already in the folder
  const availableDocumentsToAdd = useMemo(() => {
    if (!isAddingMode) return [];
    
    // Raccogliamo gli ID di tutti i documenti che sono già in qualsiasi cartella
    // Questo ci permette di escluderli dai documenti disponibili
    const documentsInFolders = new Set();
    
    // Processo per raccogliere tutti i documenti già in cartelle
    allFolders.forEach(folder => {
      // Aggiungi i documenti nella cartella principale
      if (folder.documentIds && Array.isArray(folder.documentIds)) {
        folder.documentIds.forEach(docId => documentsInFolders.add(docId));
      }
      
      // Aggiungi i documenti nelle sottocartelle
      if (folder.subfolders && Array.isArray(folder.subfolders)) {
        folder.subfolders.forEach(subfolder => {
          if (subfolder.documentIds && Array.isArray(subfolder.documentIds)) {
            subfolder.documentIds.forEach(docId => documentsInFolders.add(docId));
          }
        });
      }
    });
    
    console.log(`Trovati ${documentsInFolders.size} documenti già presenti in cartelle`);
    
    // Restituisci solo i documenti che non sono in nessuna cartella
    return allDocuments.filter(doc => !documentsInFolders.has(doc.id));
  }, [allDocuments, allFolders, isAddingMode, refreshCounter, localRefreshCounter]);

  // Filter available documents by search when in adding mode
  const filteredAvailableDocuments = useMemo(() => {
    if (!isAddingMode || !searchMode || !searchQuery.trim()) {
      return availableDocumentsToAdd;
    }
    
    const query = searchQuery.toLowerCase().trim();
    return availableDocumentsToAdd.filter(doc => 
      doc.title.toLowerCase().includes(query) || 
      (doc.description && doc.description.toLowerCase().includes(query))
    );
  }, [availableDocumentsToAdd, isAddingMode, searchMode, searchQuery]);
  
  // Toggle removing mode
  const toggleRemovingMode = () => {
    // Utilizziamo la stessa UI della creazione cartella
    setIsRemovingMode(!isRemovingMode);
    setIsAddingMode(false);
    setIsCreatingFolder(false);
    setSelectedDocuments([]);
    
    // Se stiamo attivando la modalità rimozione, impostiamo anche l'UI come se fosse in modalità creazione cartella
    // ma con uno stato interno che indica che è in modalità rimozione
    if (!isRemovingMode) {
      setIsCreatingFolder(true);
      setIsNamingFolder(false);
    }
  };
  
  // Toggle adding mode
  const toggleAddingMode = () => {
    setIsAddingMode(!isAddingMode);
    setIsRemovingMode(false);
    setIsCreatingFolder(false);
    setSelectedDocuments([]);
  };
  
  // Toggle folder creation mode
  const toggleFolderMode = () => {
    // Verifica se siamo già in una cartella di secondo livello
    if (folder.parentId) {
      toast.error('Impossibile creare cartelle a questo livello. Le cartelle possono essere create solo a livello principale o come dirette figlie di una cartella principale.');
      return;
    }
    
    setIsCreatingFolder(!isCreatingFolder);
    setIsRemovingMode(false);
    setIsAddingMode(false);
    setSelectedDocuments([]);
  };
  
  // Select a document
  const toggleDocumentSelection = (documentId) => {
    setSelectedDocuments(prev => {
      if (prev.includes(documentId)) {
        return prev.filter(id => id !== documentId);
      } else {
        return [...prev, documentId];
      }
    });
  };
  
  // Proceed to naming folder
  const handleProceedToNaming = () => {
    if (selectedDocuments.length === 0) {
      return;
    }
    
    setIsNamingFolder(true);
  };
  
  // Remove selected documents from folder
  const removeSelectedDocuments = () => {
    if (selectedDocuments.length === 0) return;
    
    // Mostra un toast di caricamento
    const loadingToast = toast.loading(`Rimozione di ${selectedDocuments.length} ${selectedDocuments.length === 1 ? 'documento' : 'documenti'} in corso...`);
    
    // Passiamo il riferimento al toast alla funzione per evitare toast duplicati
    removeDocumentsFromFolder(folder.id, selectedDocuments, { skipLoadingToast: true, existingToast: loadingToast })
      .then((result) => {
        // Non è più necessario chiudere il toast qui perché viene gestito nella funzione removeDocumentsFromFolder
        // La funzione removeDocumentsFromFolder si occuperà di chiudere il toast
        
        // Aggiorniamo lo stato locale rimuovendo i documentIds
        setLocalDocumentIds(prevIds => 
          prevIds.filter(id => !selectedDocuments.includes(id))
        );
        
        // Aggiorniamo il componente forzando un refresh
        setLocalRefreshCounter(prev => prev + 1);
        
        // Reset dello stato locale
        setSelectedDocuments([]);
        setIsRemovingMode(false);
        setIsCreatingFolder(false); // Chiude il modale completamente
      })
      .catch(error => {
        // Non è più necessario chiudere il toast qui perché viene gestito nella funzione removeDocumentsFromFolder
        // La funzione removeDocumentsFromFolder si occuperà di chiudere il toast in caso di errore
        
        console.error('Errore nella rimozione dei documenti:', error);
      });
  };
  
  // Add selected documents to folder
  const addSelectedDocuments = () => {
    if (selectedDocuments.length === 0) return;
    
    addDocumentsToFolder(folder.id, selectedDocuments)
      .then(() => {
        // Aggiorniamo lo stato locale con i nuovi documentIds
        setLocalDocumentIds(prevIds => {
          const newIds = [...prevIds];
          selectedDocuments.forEach(docId => {
            if (!newIds.includes(docId)) {
              newIds.push(docId);
            }
          });
          return newIds;
        });
        
        // Aggiorniamo il componente forzando un refresh
        setLocalRefreshCounter(prev => prev + 1);
        
        // Reset dello stato locale
        setSelectedDocuments([]);
        setIsAddingMode(false);
      })
      .catch(error => {
        console.error('Errore nell\'aggiunta dei documenti:', error);
      });
  };
  
  // Cancel removing mode
  const cancelRemovingMode = () => {
    setIsRemovingMode(false);
    setSelectedDocuments([]);
    setIsCreatingFolder(false);
  };
  
  // Cancel adding mode
  const cancelAddingMode = () => {
    setIsAddingMode(false);
    setSelectedDocuments([]);
  };
  
  // Cancel folder creation
  const cancelFolderCreation = () => {
    setIsCreatingFolder(false);
    setIsNamingFolder(false);
    setSelectedDocuments([]);
    setFolderName('');
  };
  
  // Go back to document selection
  const handleBackToSelection = () => {
    setIsNamingFolder(false);
  };
  
  // Open a document
  const handleDocumentOpen = useCallback((documentId) => {
    // Passa il documentId alla funzione onChange per aprire il documento
    if (onChange && typeof onChange === 'function') {
      onChange(documentId);
    }
    console.log('Opening document:', documentId);
  }, [onChange]);
  
  // Handle folder name change
  const handleFolderNameChange = (e) => {
    setFolderName(e.target.value);
  };
  
  // Create a folder
  const handleCreateFolder = () => {
    if (!folderName.trim()) {
      toast.error('Inserisci un nome per la cartella');
      return;
    }
    
    // Verifica che non ci siano già cartelle con lo stesso nome
    const nameExists = childFolders.some(childFolder => 
      childFolder.name.toLowerCase() === folderName.trim().toLowerCase()
    );

    if (nameExists) {
      toast.error('Esiste già una cartella con questo nome');
      return;
    }
    
    // Verifica se siamo già in una cartella di secondo livello
    if (folder.parentId) {
      toast.error('Impossibile creare cartelle a questo livello. Le cartelle possono essere create solo a livello principale o come dirette figlie di una cartella principale.');
      return;
    }
    
    // Capture values before resetting state
    const newFolderName = folderName.trim();
    const docsToMove = [...selectedDocuments];
    const folderColor = selectedFolderColor;
    
    // Reset degli stati PRIMA di iniziare l'operazione per rendere l'UI più reattiva
    setIsCreatingFolder(false);
    setIsNamingFolder(false);
    setSelectedDocuments([]);
    setFolderName('');
    
    // Mostra un toast di operazione in corso
    const loadingToast = toast.loading(`Creazione cartella "${newFolderName}" in corso...`);
    
    const newFolder = {
      name: newFolderName,
      color: folderColor,
      parentId: folder.id,
      documentIds: docsToMove
    };
    
    // Dopo aver resettato lo stato dell'UI, crea la cartella
    // Passiamo il riferimento al toast alla funzione per evitare toast duplicati
    createNewFolder(newFolder, { skipLoadingToast: true, existingToast: loadingToast })
      .then((result) => {
        // Non è più necessario chiudere il toast qui perché viene gestito nella funzione createNewFolder
        // La funzione createNewFolder si occuperà di chiudere il toast
        
        // Aggiorniamo manualmente lo stato locale dei documentIds
        // per mantenere l'UI sincronizzata
        if (docsToMove.length > 0) {
          setLocalDocumentIds(prevIds => 
            prevIds.filter(id => !docsToMove.includes(id))
          );
        }
        
        // Forza un aggiornamento completo che include le sottocartelle
        forceRefreshFolders();
        
        // Aggiorna anche lo stato locale delle sottocartelle
        // Per garantire che la UI mostri subito la nuova cartella
        setTimeout(() => {
          // Forziamo un secondo aggiornamento per essere sicuri
          forceRefreshFolders();
          // Incrementiamo anche il counter locale per forzare un re-render del componente
          setLocalRefreshCounter(prev => prev + 1);
        }, 300);
      })
      .catch(error => {
        // Non è più necessario chiudere il toast qui perché viene gestito nella funzione createNewFolder
        // La funzione createNewFolder si occuperà di chiudere il toast in caso di errore
        
        // Visualizza messaggi di errore specifici
        if (error === 'Le cartelle possono essere create solo a livello principale o come dirette figlie di una cartella principale') {
          toast.error('Impossibile creare cartelle a questo livello. Le cartelle possono essere create solo a livello principale o come dirette figlie di una cartella principale.');
        } else {
          console.error('Errore durante la creazione della cartella:', error);
          toast.error('Errore durante la creazione della cartella');
        }
      });
  };
  
  // Select a folder color
  const handleSelectFolderColor = (color) => {
    setSelectedFolderColor(color);
  };
  
  // Open a subfolder
  const handleOpenChildFolder = (childFolderId) => {
    console.log('Opening folder:', childFolderId, 'from parent folder:', folder.id);
    
    // Prima di navigare, forziamo un aggiornamento dei dati per assicurarci che l'interfaccia sia sincronizzata
    forceRefreshFolders();
    
    // Aspetta un breve momento per permettere al Redux store di aggiornarsi
    setTimeout(() => {
      // Pass the folder ID and the current folder as parent to the onBack function
      // This allows navigation to open the folder properly
      onBack(childFolderId, folder);
    }, 100);
  };
  
  // Go back to parent
  const handleBack = () => {
    // Se abbiamo un parentFolder, torniamo ad esso invece di tornare alla vista principale
    if (isSubfolder && parentFolder) {
      console.log('Tornando alla cartella genitore:', parentFolder.id);
      onBack(null, parentFolder);
    } else {
      // Se non abbiamo un parentFolder, torniamo alla vista principale
      onBack();
    }
  };
  
  // Handle subfolder deletion - aggiunge gestione per refresh UI dopo eliminazione
  const handleChildFolderDelete = (childFolderId) => {
    console.log('Eliminazione cartella in corso:', childFolderId);
    
    // Cerchiamo la cartella figlio per ottenere il nome
    const childFolder = childFolders.find(cf => cf.id === childFolderId);
    const childFolderName = childFolder ? childFolder.name : 'cartella';
    
    // Crea un unico toast di caricamento per tutte le operazioni
    const loadingToast = toast.loading(`Eliminazione della cartella "${childFolderName}" in corso...`);
    
    // Passiamo l'existingToast per evitare la creazione di toast duplicati
    deleteFolderById(childFolderId, { existingToast: loadingToast })
      .then(() => {
        // Chiudi il toast di caricamento qui, una sola volta
        toast.dismiss(loadingToast);
        
        // Mostra un unico toast di successo qui
        toast.success('Cartella eliminata con successo');
        
        // Aggiornamento locale immediato: rimuoviamo la cartella dallo stato locale
        setLocalRefreshCounter(prev => prev + 1);
        
        // Forziamo un aggiornamento esplicito del componente
        setForceUpdate(prev => !prev);
        
        // Forzare un refresh esplicito delle cartelle nel Redux store
        forceRefreshFolders();
        
        // Dopo un breve tempo, forzare un secondo refresh per garantire sincronizzazione completa
        setTimeout(() => {
          forceRefreshFolders();
          setLocalRefreshCounter(prev => prev + 1);
        }, 100);
      })
      .catch(error => {
        // Chiudi il toast di caricamento in caso di errore
        toast.dismiss(loadingToast);
        
        console.error('Errore durante l\'eliminazione della cartella:', error);
        toast.error('Errore durante l\'eliminazione della cartella');
      });
  };
  
  // Delete the current folder
  const handleDeleteFolder = () => {
    if (window.confirm('Sei sicuro di voler eliminare questa cartella? I documenti contenuti non verranno eliminati.')) {
      // Crea un unico toast di caricamento per tutte le operazioni
      const loadingToast = toast.loading(`Eliminazione della cartella "${folder.name}" in corso...`);
      
      // Passiamo l'existingToast per evitare la creazione di toast duplicati
      deleteFolderById(folder.id, { existingToast: loadingToast })
        .then(() => {
          // Chiudi il toast di caricamento qui, una sola volta
          toast.dismiss(loadingToast);
          toast.success('Cartella eliminata con successo');
          
          // Forza un aggiornamento dello store Redux prima di navigare indietro
          forceRefreshFolders();
          
          // Attendi un breve periodo prima di navigare indietro
          // per dare tempo allo store di aggiornarsi
          setTimeout(() => {
            onBack();
          }, 100);
        })
        .catch(error => {
          // Chiudi il toast di caricamento in caso di errore
          toast.dismiss(loadingToast);
          console.error('Errore durante l\'eliminazione della cartella:', error);
          toast.error('Errore durante l\'eliminazione della cartella');
        });
    }
  };
  
  // Toggle search mode
  const toggleSearchMode = () => {
    setSearchMode(!searchMode);
    if (searchMode) {
      setSearchQuery('');
    }
  };
  
  // Handle search query change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  
  // Render search input
  const renderSearchInput = () => {
    if (!searchMode) return null;
    
    return (
      <div className="FolderContent__searchContainer">
        <div className="FolderContent__searchInputWrapper">
          <input
            type="text"
            className="FolderContent__searchInput"
            placeholder="Cerca documenti..."
            value={searchQuery}
            onChange={handleSearchChange}
            autoFocus
          />
        </div>
      </div>
    );
  };
  
  // Effetto per forzare un aggiornamento periodico quando siamo in una cartella non-root
  // Versione sicura che evita loop di aggiornamenti
  useEffect(() => {
    // Se siamo in una cartella con un padre, imposta un intervallo di aggiornamento
    if (folder.parentId) {
      console.log("Configurando l'aggiornamento periodico per la cartella figlia");
      
      const refreshInterval = setInterval(() => {
        // Verifichiamo che non sia già in corso un aggiornamento
        if (!isRefreshingRef.current) {
          isRefreshingRef.current = true;
          
          // Aggiorniamo le cartelle attraverso Redux
          forceRefreshFolders();
          
          // Resettiamo la flag dopo un ritardo per evitare aggiornamenti troppo frequenti
          setTimeout(() => {
            isRefreshingRef.current = false;
          }, 500);
        }
      }, 3000); // Aggiornamento ogni 3 secondi (più lungo per ridurre il carico)
      
      return () => clearInterval(refreshInterval);
    }
  }, [folder.parentId, forceRefreshFolders]);
  
  // Effetto per aggiornare le sottocartelle quando cambia il counter
  useEffect(() => {
    if (folder && folder.id) {
      console.log(`Aggiornamento sottocartelle per ${folder.name} (ID: ${folder.id})`);
      
      // Forza un refresh per assicurarsi che abbiamo i dati più aggiornati
      if (forceRefreshFolders) {
        forceRefreshFolders();
      }
      
      // Se siamo in una cartella, aggiorna le sottocartelle
      if (childFolders && childFolders.length > 0) {
        console.log(`Cartelle figlie aggiornate (${childFolders.length}):`, childFolders.map(f => f.name).join(', '));
      } else {
        console.log('Nessuna sottocartella trovata');
      }
    }
  }, [folder, localRefreshCounter, forceRefreshFolders]);
  
  return (
    <div className="FolderContent" data-is-subfolder={isSubfolder ? "true" : "false"}>
      <div className="FolderContent__header">
        <div className="FolderContent__headerLeft">
          <Button 
            onClick={handleBack}
            variant="outline"
            className="FolderContent__backButton"
          >
            <ChevronLeft size={24} />
          </Button>
          
          <h2 className="FolderContent__title">
            <FolderIcon 
              size={36} 
              strokeWidth={1.5}
              style={{ color: folderColor.icon }}
              className="FolderContent__titleIcon"
            />
            {folder.name}
          </h2>
        </div>
        
        <div className="FolderContent__headerRight">
          {!isRemovingMode && !isAddingMode && !isCreatingFolder && (
            <>
              <Button 
                onClick={toggleSearchMode}
                className={`FolderContent__action-button ${searchMode ? 'FolderContent__action-button--active' : ''}`}
                variant="outline"
              >
                {searchMode ? <X size={20} /> : <Search size={20} />}
                <span>{searchMode ? 'Chiudi ricerca' : 'Cerca'}</span>
              </Button>
              
              {!folder.parentId && (
                <Button 
                  onClick={toggleFolderMode}
                  className="FolderContent__action-button"
                  variant="outline"
                >
                  <FolderPlus size={20} />
                  <span>Crea cartella</span>
                </Button>
              )}
              
              <Button 
                onClick={toggleAddingMode}
                className="FolderContent__action-button"
                variant="outline"
              >
                <Plus size={20} className="mobile-only" />
                <Plus size={20} className="desktop-only" />
                <span className="desktop-only">Aggiungi documenti</span>
              </Button>
              
              <Button 
                onClick={toggleRemovingMode}
                className="FolderContent__action-button"
                variant="outline"
              >
                <Trash2 size={20} className="mobile-only" />
                <Trash2 size={20} className="desktop-only" />
                <span className="desktop-only">Rimuovi documenti</span>
              </Button>
            </>
          )}
        </div>
      </div>
      
      {renderSearchInput()}
      
      {/* Banner di selezione documenti per sottocartella spostato qui in alto */}
      {isCreatingFolder && !isNamingFolder && (
        <div className="MaterialsDocuments__selection-header" style={{
          backgroundColor: `rgba(${isRemovingMode ? folderColor.rgb : currentColor.rgb}, 0.05)`,
          borderColor: isRemovingMode ? folderColor.icon : currentColor.color,
          borderWidth: '2px',
          borderStyle: 'solid'
        }}>
          <div className="MaterialsDocuments__selection-container">
            <div className="MaterialsDocuments__document-selection">
              <h3 className="MaterialsDocuments__selection-text">
                {selectedDocuments.length === 0 
                  ? isRemovingMode ? 'Seleziona i documenti da rimuovere' : 'Seleziona i documenti' 
                  : 'Documenti selezionati'}
              </h3>
              {selectedDocuments.length > 0 && (
                <span className="MaterialsDocuments__selection-count">
                  {selectedDocuments.length === 1
                    ? '1 documento selezionato'
                    : `${selectedDocuments.length} documenti selezionati`
                  }
                </span>
              )}
            </div>
            
            {!isRemovingMode && (
              <div className="MaterialsDocuments__color-section">
                <div className="MaterialsDocuments__color-selector">
                  <span className="MaterialsDocuments__color-label">Scegli il colore:</span>
                  <div className="MaterialsDocuments__color-options">
                    {Object.keys(folderColors).map(colorKey => {
                      const color = folderColors[colorKey];
                      return (
                        <button
                          key={colorKey}
                          className={`MaterialsDocuments__color-option ${selectedFolderColor === colorKey ? 'MaterialsDocuments__color-option--selected' : ''}`}
                          style={{ backgroundColor: color.color }}
                          onClick={() => handleSelectFolderColor(colorKey)}
                          title={color.label}
                          aria-label={`Seleziona colore ${color.label}`}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>
          
          <div className="MaterialsDocuments__buttons">
            {isRemovingMode ? (
              <Button 
                onClick={removeSelectedDocuments} 
                variant="custom" 
                className="MaterialsDocuments__save-button"
                disabled={selectedDocuments.length === 0}
                style={{
                  backgroundColor: folderColor.buttonBg,
                  '--button-hover-bg': folderColor.buttonHoverBg,
                  '--button-active-bg': folderColor.buttonActiveBg,
                  '--button-shine-color': `rgba(255, 255, 255, 0.2)`
                }}
              >
                <span className="desktop-only">Rimuovi documenti</span>
                <Trash2 size={18} className="mobile-only" />
              </Button>
            ) : (
              <Button 
                onClick={handleProceedToNaming} 
                variant="custom" 
                className="MaterialsDocuments__save-button"
                disabled={selectedDocuments.length === 0}
                style={{
                  backgroundColor: currentColor.buttonBg,
                  '--button-hover-bg': currentColor.buttonHoverBg,
                  '--button-active-bg': currentColor.buttonActiveBg,
                  '--button-shine-color': `rgba(255, 255, 255, 0.2)`
                }}
              >
                <span className="desktop-only">Avanti</span>
                <Check size={18} className="mobile-only" />
              </Button>
            )}
            <Button 
              onClick={isRemovingMode ? cancelRemovingMode : cancelFolderCreation} 
              variant="outline" 
              className="MaterialsDocuments__cancel-button"
              style={{
                '--button-hover-bg': `rgba(${isRemovingMode ? folderColor.rgb : currentColor.rgb}, 0.1)`,
                '--button-hover-color': isRemovingMode ? folderColor.icon : currentColor.color,
                '--button-hover-border': isRemovingMode ? folderColor.icon : currentColor.color,
                color: isRemovingMode ? folderColor.icon : undefined,
                borderColor: isRemovingMode ? folderColor.icon : undefined
              }}
            >
              <X size={18} className="mobile-only" />
              <span className="desktop-only">Annulla</span>
            </Button>
          </div>
        </div>
      )}

      {/* Banner di selezione documenti per aggiungere documenti */}
      {isAddingMode && (
        <div className="MaterialsDocuments__selection-header" style={{
          backgroundColor: `rgba(${folderColor.rgb}, 0.05)`,
          borderColor: folderColor.icon,
          borderWidth: '2px',
          borderStyle: 'solid'
        }}>
          <div className="MaterialsDocuments__selection-container">
            <div className="MaterialsDocuments__document-selection">
              <h3 className="MaterialsDocuments__selection-text">
                {selectedDocuments.length === 0 
                  ? 'Seleziona i documenti da aggiungere' 
                  : 'Documenti selezionati'}
              </h3>
              {selectedDocuments.length > 0 && (
                <span className="MaterialsDocuments__selection-count">
                  {selectedDocuments.length === 1
                    ? '1 documento selezionato'
                    : `${selectedDocuments.length} documenti selezionati`
                  }
                </span>
              )}
            </div>
          </div>
          
          <div className="MaterialsDocuments__buttons">
            <Button 
              onClick={addSelectedDocuments} 
              variant="custom" 
              className="MaterialsDocuments__save-button"
              disabled={selectedDocuments.length === 0}
              style={{
                backgroundColor: folderColor.buttonBg,
                '--button-hover-bg': folderColor.buttonHoverBg,
                '--button-active-bg': folderColor.buttonActiveBg,
                '--button-shine-color': `rgba(255, 255, 255, 0.2)`
              }}
            >
              <span className="desktop-only">Aggiungi documenti</span>
              <Check size={18} className="mobile-only" />
            </Button>
            <Button 
              onClick={cancelAddingMode} 
              variant="outline" 
              className="MaterialsDocuments__cancel-button"
              style={{
                '--button-hover-bg': `rgba(${folderColor.rgb}, 0.1)`,
                '--button-hover-color': folderColor.icon,
                '--button-hover-border': folderColor.icon
              }}
            >
              <X size={18} className="mobile-only" />
              <span className="desktop-only">Annulla</span>
            </Button>
          </div>
        </div>
      )}

      {/* Box per il naming della sottocartella */}
      {isCreatingFolder && isNamingFolder && (
        <div className="MaterialsDocuments__selection-header" style={{
          backgroundColor: `rgba(${currentColor.rgb}, 0.05)`,
          borderColor: currentColor.color,
          borderWidth: '2px',
          borderStyle: 'solid'
        }}>
          <div className="MaterialsDocuments__naming-container">
            <div className="MaterialsDocuments__naming-input-group">
              <div className="MaterialsDocuments__folder-icon" style={{ color: currentColor.color }}>
                <FolderPlus size={24} />
              </div>
              <Input
                className="MaterialsDocuments__folder-name-input"
                type="text"
                placeholder="Nome della cartella"
                value={folderName}
                onChange={handleFolderNameChange}
                maxLength={50}
                autoFocus
                style={{ 
                  color: 'var(--grayscale-color-900)',
                  borderColor: currentColor.color,
                  boxShadow: `0 0 0 2px rgba(${currentColor.rgb}, 0.15)`,
                  '--current-folder-color-rgb': currentColor.rgb
                }}
              />
            </div>
            <div className="MaterialsDocuments__buttons">
              <Button 
                variant="custom" 
                className="MaterialsDocuments__save-button"
                onClick={handleCreateFolder}
                disabled={!folderName.trim()}
                style={{
                  backgroundColor: currentColor.buttonBg,
                  '--button-hover-bg': currentColor.buttonHoverBg,
                  '--button-active-bg': currentColor.buttonActiveBg,
                  '--button-shine-color': `rgba(255, 255, 255, 0.2)`
                }}
              >
                <span className="desktop-only">Crea Cartella</span>
                <Check size={18} className="mobile-only" />
              </Button>
              <Button 
                variant="outline" 
                className="MaterialsDocuments__back-button"
                onClick={handleBackToSelection}
                style={{
                  '--button-hover-bg': `rgba(${currentColor.rgb}, 0.1)`,
                  '--button-hover-color': currentColor.color,
                  '--button-hover-border': currentColor.color
                }}
              >
                <ArrowLeft size={18} />
                <span>Indietro</span>
              </Button>
            </div>
          </div>
        </div>
      )}
      
      {/* Visualizza sempre tutto in un'unica riga orizzontale, indipendentemente dal tipo di cartella */}
      <div 
        className="FolderContent__content-row" 
        data-is-subfolder={isSubfolder ? "true" : "false"}
      >
        {/* Se siamo in modalità aggiunta documenti, mostra i documenti disponibili da aggiungere */}
        {isAddingMode ? (
          <>
            {filteredAvailableDocuments.length > 0 ? (
              filteredAvailableDocuments.map(document => (
                <Document
                  key={document.id}
                  id={document.id}
                  title={document.title || ''}
                  type={document.type || 'pdf'}
                  tags={document.tags || []}
                  date={formatTimeStamp(document.uploadDate)}
                  isActive={selectedDocuments.includes(document.id)}
                  onChange={() => toggleDocumentSelection(document.id)}
                  selectionMode={true}
                  folderColor={folderColor}
                  className="FolderContent__document-item"
                />
              ))
            ) : (
              <div className="FolderContent__emptyState">
                {searchMode && searchQuery ? 
                  'Nessun documento trovato con la ricerca corrente.' : 
                  'Non ci sono documenti disponibili da aggiungere.'}
              </div>
            )}
          </>
        ) : (
          <>
            {/* Tutte le sottocartelle e i documenti in un'unica visualizzazione */}
            {!isCreatingFolder && childFolders && childFolders.length > 0 && (
              childFolders.map(childFolder => (
                <Folder
                  key={childFolder.id}
                  id={childFolder.id}
                  name={childFolder.name}
                  documentCount={childFolder.documentIds?.length || 0}
                  color={childFolder.color}
                  onClick={() => {}}
                  onOpen={handleOpenChildFolder}
                  onDelete={() => handleChildFolderDelete(childFolder.id)}
                  className="FolderContent__subfolder-item"
                />
              ))
            )}
            
            {filteredDocuments.length > 0 && (
              filteredDocuments.map(document => (
                <Document
                  key={document.id}
                  id={document.id}
                  title={document.title || ''}
                  type={document.type || 'pdf'}
                  tags={document.tags || []}
                  date={formatTimeStamp(document.uploadDate)}
                  isActive={selectedDocuments.includes(document.id)}
                  onChange={isRemovingMode || isCreatingFolder 
                    ? () => toggleDocumentSelection(document.id) 
                    : () => handleDocumentOpen(document.id)}
                  selectionMode={isRemovingMode || isCreatingFolder}
                  folderColor={isRemovingMode ? folderColor : (isCreatingFolder ? currentColor : folderColor)}
                  className="FolderContent__document-item"
                />
              ))
            )}
            
            {childFolders.length === 0 && filteredDocuments.length === 0 && (
              <div className="FolderContent__emptyState">
                {searchMode && searchQuery ? 
                  'Nessun contenuto trovato con la ricerca corrente.' : 
                  'Questa cartella non contiene sottocartelle o documenti.'}
              </div>
            )}
          </>
        )}
      </div>
      
      {/* Rimuoviamo questo footer per l'aggiunta documenti poiché ora abbiamo il banner in alto */}
      {/* Rimuovo questo footer poiché ora usiamo l'interfaccia superiore per la rimozione documenti */}
      {false && isRemovingMode && selectedDocuments.length > 0 && (
        <div className="FolderContent__footer">
          <Button onClick={cancelRemovingMode} variant="outline">
            Annulla
          </Button>
          <Button onClick={removeSelectedDocuments} variant="danger">
            Rimuovi {selectedDocuments.length} {selectedDocuments.length === 1 ? 'documento' : 'documenti'}
          </Button>
        </div>
      )}
    </div>
  );
};

FolderContent.propTypes = {
  folder: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    documentIds: PropTypes.array,
    color: PropTypes.string,
    parentId: PropTypes.string
  }).isRequired,
  onBack: PropTypes.func.isRequired,
  onChange: PropTypes.func
};

export default FolderContent;