import { useEffect, useState } from "react";

const useShowModal = (isFreeAccount, documentID = null) => {
  const [showModal, setShowModal] = useState(false);
  const [examCount, setExamCount] = useState(() => {
    const storedCount = localStorage.getItem(`examCount${documentID ? `_${documentID}` : ''}`);
    return storedCount ? parseInt(storedCount, 10) : 0;
  });

  useEffect(() => {
    if (isFreeAccount && examCount >= 1) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [examCount, isFreeAccount]);

  const closeModalHandler = () => setShowModal(false);

  const incrementExamCount = () => {
    if (isFreeAccount) {
      const newCount = examCount + 1;
      setExamCount(newCount);
      localStorage.setItem(`examCount${documentID ? `_${documentID}` : ''}`, newCount);
    }
  };

  const getTotalExamsAttempted = () => {
    let totalExams = 0;
    // Itera su tutti gli elementi del localStorage
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      // Controlla se la chiave inizia con "examCount"
      if (key && key.startsWith('examCount')) {
        const value = localStorage.getItem(key);
        if (value) {
          totalExams += parseInt(value, 10);
        }
      }
    }
    return totalExams;
  };

  return { showModal, closeModalHandler, incrementExamCount, examCount, getTotalExamsAttempted };
};

export default useShowModal;
