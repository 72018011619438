const sendBrevoEmail = async (email, fullName, templateId) => {
  let API_URL;

  switch(process.env.REACT_APP_ENVIRONMENT) {
    case "development":
      API_URL = "http://localhost:8080";
      break;
    case "preproduction":
      API_URL = process.env.REACT_APP_API_URL_PREPRODUCTION;
      break;
    default:
      API_URL = process.env.REACT_APP_API_URL_PRODUCTION;
  }

  const response = await fetch(`${API_URL}/send-brevo-email`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      /* 'Accept': 'application/json' */
    },
    body: JSON.stringify({ email, fullName, templateId })
  });

  if (!response.ok) {
    throw new Error('Failed to send email', response.error);
  } else {
    console.log('Brevo email sent successfully');
  }
}

export default sendBrevoEmail;