import { SET_INTERROGATION_QA, SET_CURRENT_EXAM_ID, RESET_INTERROGATION_QA } from './types';

export const setInterrogationQA = (interrogationQA) => ({
  type: SET_INTERROGATION_QA,
  payload: interrogationQA,
});

export const setCurrentExamID = (examID) => ({
  type: SET_CURRENT_EXAM_ID,
  payload: examID,
});

export const resetInterrogationQA = () => ({
  type: RESET_INTERROGATION_QA,
});

export const updateTimeRemaining = (time) => ({
  type: 'UPDATE_TIME_REMAINING',
  payload: time
});

export const updateInterrogationQA = (newQA) => (dispatch, getState) => {
  const currentQA = getState().exam.interrogationQA;
  dispatch({
    type: SET_INTERROGATION_QA,
    payload: [...currentQA, newQA]
  });
};
