import { firestore } from "@redux/firebase";
import { doc, setDoc, getDoc, updateDoc, deleteDoc, collection, query, orderBy, where, getDocs, increment, addDoc, serverTimestamp, getFirestore, limit } from "firebase/firestore";
import { toast } from "react-toastify";

// get document's questions
export const getQuestions = async documentId => {
  try {
    // Primo tentativo: nuovo formato con documento unico e mappa QA
    const newFormatDoc = await getDoc(doc(firestore, "questions", documentId));
    if (newFormatDoc.exists() && newFormatDoc.data().qaMap) {
      const qaMap = newFormatDoc.data().qaMap;
      return {
        docs: Object.entries(qaMap).map(([id, data]) => ({
          id,
          data: () => ({
            ...data,
            documentID: documentId,
            // Manteniamo i metadata per compatibilità con codice legacy
            metadata: { 
              isExamQuestion: data.type === 'exam',
              isQuizQuestion: data.type === 'quiz',
              isFlashcard: data.type === 'flashcard'
            }
          })
        }))
      };
    }

    // Fallback al formato legacy (documenti separati)
    const questionsQuery = query(
      collection(firestore, "questions"),
      where("documentID", "==", documentId),
      orderBy("uploadDate", "desc")
    );

    return getDocs(questionsQuery);
  } catch (error) {
    throw error;
  }
};

// get question
export const getQuestion = async (documentId, questionId) => {
  try {
    // Nuovo formato con 2 parametri
    if (questionId) {
      const parentDocRef = doc(firestore, "questions", documentId);
      const parentDocSnap = await getDoc(parentDocRef);
      
      if (parentDocSnap.exists()) {
        const qaMap = parentDocSnap.data().qaMap || {};
        return { id: questionId, ...qaMap[questionId] };
      }
    }

    // Formato legacy con 1 parametro
    const legacyDocRef = doc(firestore, "questions", documentId);
    const legacyDocSnap = await getDoc(legacyDocRef);

    if (legacyDocSnap.exists()) {
      return { id: documentId, ...legacyDocSnap.data() };
    }

    return {};
  } catch (error) {
    console.error("Errore nel recupero della domanda:", error);
    throw error;
  }
};

// get user's documents
export const getUserDocuments = async () => {
  try {
    const userId = JSON.parse(localStorage.getItem("userId"));
    console.log("Caricamento documenti per l'utente:", userId);
    
    // Semplifichiamo la query per evitare problemi con gli indici
    const documentsQuery = query(
      collection(firestore, "documents"),
      where("uploadedBy", "==", userId),
      // Non usiamo l'ordinamento qui per evitare problemi con gli indici
      // Faremo l'ordinamento in memoria
    );

    console.log("Esecuzione query per documenti...");
    const querySnapshot = await getDocs(documentsQuery);
    console.log(`Query completata, documenti trovati: ${querySnapshot.size}`);
    
    // Filtriamo e ordiniamo i documenti in memoria
    const documents = querySnapshot.docs
      .map(doc => ({
        id: doc.id,
        ...doc.data()
      }))
      // Filtriamo i documenti rimossi
      .filter(doc => !doc.isRemoved)
      // Ordiniamo per data di upload (dal più recente)
      .sort((a, b) => {
        const dateA = a.uploadDate?.seconds || 0;
        const dateB = b.uploadDate?.seconds || 0;
        return dateB - dateA;
      });

    // Loghiamo i documenti più recenti per debug
    if (documents.length > 0) {
      console.log("Documenti più recenti:", documents.slice(0, 3).map(doc => ({
        id: doc.id,
        title: doc.title,
        date: new Date(doc.uploadDate?.seconds * 1000).toISOString()
      })));
    }

    // Restituiamo i documenti nel formato che il componente si aspetta
    return {
      docs: documents,
      empty: documents.length === 0,
      size: documents.length
    };
  } catch (error) {
    console.error("Errore nel recupero dei documenti:", error);
    throw new Error("Errore nel recupero dei documenti dell'utente");
  }
};

// get user's documents not reviewed
export const getUserDocumentsNotReviewed = async () => {
  try {
    const userId = JSON.parse(localStorage.getItem("userId"));
    const examQuery = query(collection(firestore, "exam"));
    const documentsQuery = query(collection(firestore, "documents"), where("uploadedBy", "==", userId));
    const examDocsPromise = getDocs(examQuery);
    const documentsDocsPromise = getDocs(documentsQuery);

    // Wait for both queries to complete
    const [examDocs, documentsDocs] = await Promise.all([examDocsPromise, documentsDocsPromise]);

    // Extract data from document snapshots
    const examData = examDocs.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    const documentsData = documentsDocs.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    return documentsData.filter(document => document.id !== examData.map(exam => exam.documentID));
  } catch (error) {
    throw error;
  }
};

// get user's profile
export const getUserProfile = async userId => {
  try {
    const userRef = doc(firestore, "students", userId);
    const userSnap = await getDoc(userRef);
    return userSnap.data();
  } catch (error) {
    toast.error("Errore nel recupero del profilo dell'utente");
    throw error;
  }
};

// update resource
export const updateResource = async (collectionName, resourceId, updateData) => {
  try {
    const resourceRef = doc(firestore, collectionName, resourceId);
    await updateDoc(resourceRef, { ...updateData });
  } catch (error) {
    toast.error("Errore nell'aggiornamento della risorsa");
    throw error;
  }
};

// save exam
export const saveQAExam = async (examID, messages, reviewMode) => {
  try {
    const examSessionRef = doc(firestore, "examSessions", examID);
    let chatMessages;

    if (reviewMode === false) {
      // Formato per modalità non di revisione
      chatMessages = messages.map((message, index) => {
        const uniqueKey = message.id || `${examID}_${Date.now()}_${index}`;
        return {
          [uniqueKey]: {
            question: message.question ?? "",
            userAnswer: message.answer ?? "",
            correctAnswer: message.correctAnswer ?? ""
          }
        };
      });
    } else {
      // Formato standard per esami
      chatMessages = messages.reduce((acc, message) => {
        const [key, qaPairs] = message;
        const [question, userAnswer, feedback] = qaPairs;
        
        return [
          ...acc,
          {
            [key]: {
              question: question?.text ?? "",
              answer: userAnswer?.text ?? "",
              feedback: feedback?.text ?? "",
              correctAnswer: feedback?.correctAnswer ?? ""
            }
          }
        ];
      }, []);
    }

    await setDoc(examSessionRef, { chatMessages, reviewMode });
  } catch (error) {
    console.error("Errore nel salvataggio dell'esame:", error);
    throw error;
  }
};

// get user data
export const getUser = async userId => {
  try {
    const userRef = doc(firestore, "students", userId);
    const userSnap = await getDoc(userRef);
    return userSnap.data();
  } catch (error) {
    throw error;
  }
};

// Get document by id
export const getDocumentById = async documentId => {
  try {
    const docRef = doc(firestore, "documents", documentId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const documentData = docSnap.data();
      
      // Estrai i campi principali
      return {
        id: documentId,
        ...documentData,
        uploadDate: documentData.uploadDate?.toDate?.() || new Date(),
        lastModified: documentData.lastModified?.toDate?.() || new Date()
      };
    } else {
      throw new Error('Documento non trovato');
    }
  } catch (error) {
    console.error('Errore nel recupero del documento:', error);
    throw error;
  }
};

// get user's exams in progress based on user ID and document ID
export const getExamsInProgress = async () => {
  try {
    const userId = JSON.parse(localStorage.getItem("userId"));
    if (!userId) {
      throw new Error("User ID non trovato");
    }

    // Prima chiamata: ottieni i documenti dell'utente
    const documentsQuery = query(
      collection(firestore, "documents"),
      where("uploadedBy", "==", userId)
    );

    const documentsSnapshot = await getDocs(documentsQuery);
    const documentsData = documentsSnapshot.docs.reduce((acc, doc) => {
      acc[doc.id] = doc.data(); // Mappa i documenti per un accesso rapido
      return acc;
    }, {});

    // Seconda chiamata: ottieni tutti gli esami in corso
    const examsQuery = query(
      collection(firestore, "exam"),
      where("status", "==", "in-progress")
    );

    const examsSnapshot = await getDocs(examsQuery);
    const exams = examsSnapshot.docs.map(doc => ({
      examID: doc.id,
      ...doc.data(),
    }));

    // Unisci i dati degli esami con i dati dei documenti
    const examsWithDocuments = exams.map(exam => {
      const documentData = documentsData[exam.documentID];
      if (documentData) {
        return {
          ...exam,
          ...documentData,
          timeRemaining: exam.timeRemaining,
        };
      }
      return null;
    }).filter(exam => exam !== null); // Filtra gli esami nulli

    return examsWithDocuments;

  } catch (error) {
    console.error("Errore nel recupero degli esami:", error);
    throw error;
  }
};

// exam logic
export const getQAExamSessions = async examID => {
  try {
    const examSessionRef = doc(firestore, "examSessions", examID);
    const examSessionSnap = await getDoc(examSessionRef);
    const { chatMessages, reviewMode } = examSessionSnap.data();

    // Gestione formato interrogazione
    if (reviewMode === false) {
      return {
        messages: chatMessages.map(message => {
          const key = Object.keys(message)[0];
          const qaData = message[key];
          return {
            id: key, // Manteniamo l'ID univoco
            index: Number(key.split('_').pop()), // Estraiamo l'indice originale
            question: qaData.question,
            answer: qaData.answer,
            correct_answer: qaData.feedback,
          };
        }),
        reviewMode
      };
    }  

    // Formato standard per esami
    const messagesChat = chatMessages.map(message => {
      const key = Object.keys(message)[0];
      const { question, answer, feedback } = message[key];
      let messages = [
        question && { id: key, text: question, sender: "bot" },
        answer && { id: key, text: answer, sender: "user" },
        feedback && { id: key, text: feedback, sender: "bot", isFeedback: true },
        feedback.score &&  { score: feedback.score },
      ].filter(Boolean);

      return [key, messages];
    });

    return { messages: messagesChat };
  } catch (error) {
    throw error;
  }
};

// check if exam is in progress
export const isExamInProgress = async examID => {
  try {
    const examsQuery = doc(firestore, "exam", examID);
    const examSessionSnap = await getDoc(examsQuery);

    // check if exam is in progress exists
    if (!examSessionSnap.exists()) return null;

    return examSessionSnap.id;
  } catch (error) {
    console.log(error.message);
    throw error;
  }
};

// delete exam from Firestore
export const deleteExamFromFirestore = async (examID) => {
  try {
    const examRef = doc(firestore, "exam", examID);
    const examSnap = await getDoc(examRef);
    if (!examSnap.exists()) {
      throw new Error("L'esame non esiste");
    }
    const examData = examSnap.data();

    // Elimina l'esame
    await deleteDoc(examRef);

    // Elimina la sessione d'esame associata
    const examSessionRef = doc(firestore, "examSessions", examID);
    await deleteDoc(examSessionRef);

    // Aggiorna il contatore degli esami tentati per l'utente
    const userID = JSON.parse(localStorage.getItem("userId"));
    if (userID) {
      const userRef = doc(firestore, "students", userID);
      await updateDoc(userRef, {
        examsAttempted: increment(-1)
      });
    }

    // Se l'esame era associato a un documento, aggiorna il documento
    if (examData.documentID) {
      const documentRef = doc(firestore, "documents", examData.documentID);
      await updateDoc(documentRef, {
        hasExamInProgress: false
      });
    }

    return true;
  } catch (error) {
    console.error("Errore durante l'eliminazione dell'esame:", error);
    throw error;
  }
};

// Funzioni per la gestione delle cartelle

/**
 * Salva una cartella su Firebase
 * @param {Object} folder - Dati della cartella
 * @returns {Object} La cartella salvata con ID aggiornato
 */
export const saveFolder = async (folder) => {
  try {
    const userId = JSON.parse(localStorage.getItem("userId"));
    
    // Se è una sottocartella (ha un parentId), aggiungiamola alla cartella genitore invece di creare un nuovo documento
    if (folder.parentId) {
      // Ottieni l'ID effettivo della cartella genitore (rimuovi il prefisso 'firebase_' se presente)
      const parentId = folder.parentId.startsWith('firebase_') 
        ? folder.parentId.replace('firebase_', '') 
        : folder.parentId;
      
      // Elenco di tutti i possibili ID che potrebbero rappresentare la stessa cartella genitore
      const possibleParentIds = [
        parentId,
        `firebase_${parentId}`,
        parentId.replace('subfolder_', '')
      ];
      
      if (parentId.includes('_parent_')) {
        const parts = parentId.split('_parent_');
        possibleParentIds.push(parts[0]); // ID della sottocartella (prima parte)
        if (parts.length > 1) {
          possibleParentIds.push(parts[1]); // ID del parent (seconda parte)
          possibleParentIds.push(`firebase_${parts[1]}`);
        }
      }
      
      // Rimuovi duplicati
      const uniqueParentIds = [...new Set(possibleParentIds)];
      console.log("Possibili ID della cartella genitore:", uniqueParentIds);
      
      // Mantieni comunque un ID principale per la ricerca iniziale
      let cleanParentId = parentId;
      if (parentId.includes('subfolder_') && parentId.includes('_parent_')) {
        const parts = parentId.split('_parent_');
        if (parts.length > 1) {
          cleanParentId = parts[1]; // Prendiamo l'ID del parent
        }
      } else if (parentId.includes('_parent_')) {
        const parts = parentId.split('_parent_');
        if (parts.length > 1) {
          cleanParentId = parts[1]; // Prendiamo l'ID del parent
        }
      }
      
      console.log("Cartella genitore - ID originale:", parentId);
      console.log("Cartella genitore - ID principale per la ricerca:", cleanParentId);
      
      // Verifica se il genitore è una sottocartella
      const isParentSubfolder = parentId.includes('subfolder_');
      let parentFolderRef;
      let parentFolderSnap;
      
      if (isParentSubfolder) {
        // Se il genitore è una sottocartella, dobbiamo ottenerlo in modo diverso
        // Prova a cercare la cartella genitore usando tutti i possibili ID
        let found = false;
        
        // Prova prima i documenti standalone
        for (const potentialId of uniqueParentIds) {
          if (found) continue;
          
          parentFolderRef = doc(firestore, "folders", potentialId);
          console.log(`Cercando cartella genitore con ID potenziale: ${potentialId}`);
          parentFolderSnap = await getDoc(parentFolderRef);
          
          if (parentFolderSnap.exists()) {
            console.log(`Trovata cartella genitore con ID: ${potentialId}`);
            found = true;
            break;
          }
        }
        
        // Se non abbiamo trovato nessuna cartella standalone, cerca nelle sottocartelle
        if (!found) {
          console.log("Cartella genitore non trovata direttamente, cercando nelle sottocartelle...");
          
          // Ottieni tutte le cartelle dell'utente
          const foldersRef = collection(firestore, "folders");
          const q = query(foldersRef, where("userId", "==", userId));
          const snapshot = await getDocs(q);
          console.log(`Trovate ${snapshot.docs.length} cartelle principali per l'utente`);
          
          // Cerca in tutte le cartelle se una di esse contiene la sottocartella che stiamo cercando
          let foundParentFolder = null;
          let parentOfParentId = null;
          
          snapshot.forEach(doc => {
            if (found) return;
            
            const folderData = doc.data();
            console.log(`Esaminando cartella ${doc.id}, con ${folderData.subfolders?.length || 0} sottocartelle`);
            
            if (folderData.subfolders && folderData.subfolders.length > 0) {
              // Cerca la sottocartella per ID tra tutti i possibili ID
              for (const potentialId of uniqueParentIds) {
                const subfolder = folderData.subfolders.find(sf => {
                  const matches = (
                    sf.id === potentialId || 
                    (sf.id && sf.id.includes(potentialId))
                  );
                  if (matches) {
                    console.log(`Match trovato per sottocartella con ID potenziale: ${potentialId}, ID effettivo: ${sf.id}`);
                  }
                  return matches;
                });
                
                if (subfolder) {
                  foundParentFolder = subfolder;
                  parentOfParentId = doc.id; // ID della cartella che contiene la sottocartella genitore
                  console.log(`Trovata sottocartella genitore in cartella: ${parentOfParentId}`);
                  found = true;
                  break;
                }
              }
            }
          });
          
          if (foundParentFolder) {
            // Abbiamo trovato la sottocartella genitore all'interno di un'altra cartella
            // Ora dobbiamo creare una nuova sottocartella all'interno di questa sottocartella
            
            // Genera un ID per la nuova sottocartella
            const subfolderId = `subfolder_${new Date().getTime()}_${Math.random().toString(36).substr(2, 9)}_parent_${cleanParentId}`;
            
            // Crea la sottocartella
            const subfolder = {
              ...folder,
              id: subfolderId,
              userId,
              parentId: cleanParentId,
              createdAt: new Date().toISOString(),
              lastModified: new Date().toISOString(),
              subfolders: folder.subfolders || []
            };
            
            console.log("Creata sottocartella con ID:", subfolderId, "collegata al genitore (sottocartella):", cleanParentId);
            
            // Aggiorna la sottocartella genitore con la nuova sottocartella
            foundParentFolder.subfolders = foundParentFolder.subfolders || [];
            foundParentFolder.subfolders.push(subfolder);
            
            // Aggiorna la cartella principale che contiene la sottocartella genitore
            parentFolderRef = doc(firestore, "folders", parentOfParentId);
            await updateDoc(parentFolderRef, {
              subfolders: snapshot.docs.find(d => d.id === parentOfParentId).data().subfolders.map(sf => {
                if (sf.id === parentId || (sf.id && sf.id.includes(cleanParentId))) {
                  return foundParentFolder;
                }
                return sf;
              }),
              lastModified: serverTimestamp()
            });
            
            // Restituisci la sottocartella con l'ID aggiornato
            return { ...subfolder, id: subfolderId };
          }
          
          // Se arriviamo qui, non abbiamo trovato il genitore da nessuna parte
          throw new Error("Cartella genitore (sottocartella) non trovata");
        }
      } else {
        // Per cartelle normali, procediamo come prima
        parentFolderRef = doc(firestore, "folders", parentId);
        parentFolderSnap = await getDoc(parentFolderRef);
        
        if (!parentFolderSnap.exists()) {
          throw new Error("Cartella genitore non trovata");
        }
      }
      
      const parentFolder = parentFolderSnap.data();
      
      // Genera un ID per la sottocartella
      const subfolderId = `subfolder_${new Date().getTime()}_${Math.random().toString(36).substr(2, 9)}_parent_${parentId}`;
      
      // Crea la sottocartella con timestamp JavaScript normali (non serverTimestamp)
      const subfolder = {
        ...folder,
        id: subfolderId,
        userId,
        parentId: parentId,
        createdAt: new Date().toISOString(),
        lastModified: new Date().toISOString(),
        subfolders: folder.subfolders || []
      };
      
      console.log("Creata sottocartella con ID:", subfolderId, "collegata al genitore:", parentId);
      
      // Aggiungi la sottocartella all'array subfolders della cartella genitore
      const subfolders = parentFolder.subfolders || [];
      subfolders.push(subfolder);
      
      // Rimuovi i documenti della sottocartella dalla cartella genitore se presenti
      console.log("Documenti da rimuovere dal genitore:", folder.documentIds);
      let parentDocumentIds = parentFolder.documentIds || [];
      if (folder.documentIds && folder.documentIds.length > 0) {
        const docsBeforeFilter = [...parentDocumentIds];
        parentDocumentIds = parentDocumentIds.filter(id => !folder.documentIds.includes(id));
        const docsRemoved = docsBeforeFilter.length - parentDocumentIds.length;
        console.log(`Rimossi ${docsRemoved} documenti dal genitore. Nuovi documentIds del genitore:`, parentDocumentIds);
      }
      
      // Aggiorna la cartella genitore con la nuova sottocartella
      await updateDoc(parentFolderRef, {
        subfolders,
        documentIds: parentDocumentIds,
        lastModified: serverTimestamp()
      });
      
      // Restituisci la sottocartella con l'ID aggiornato
      return { ...subfolder, id: subfolderId };
    } 
    // Se è una cartella di primo livello (non ha parentId)
    else {
      // Crea la struttura della cartella da salvare con serverTimestamp
      const folderData = {
        ...folder,
        userId,
        createdAt: serverTimestamp(),
        lastModified: serverTimestamp(),
        subfolders: folder.subfolders || [] // Aggiungiamo il campo subfolders se non esiste
      };
      
      // Se è una nuova cartella, crea un nuovo documento
      if (!folder.id.startsWith('firebase_')) {
        const folderRef = collection(firestore, "folders");
        const newFolderDoc = await addDoc(folderRef, folderData);
        return { ...folderData, id: newFolderDoc.id };
      } 
      // Se ha già un ID Firebase, aggiorna il documento esistente
      else {
        const actualId = folder.id.replace('firebase_', '');
        const folderRef = doc(firestore, "folders", actualId);
        await setDoc(folderRef, folderData);
        return { ...folderData, id: actualId };
      }
    }
  } catch (error) {
    console.error("Errore nel salvataggio della cartella:", error);
    throw new Error("Errore nel salvataggio della cartella");
  }
};

/**
 * Aggiorna una cartella esistente su Firebase
 * @param {string} folderId - ID della cartella da aggiornare
 * @param {Object} updates - Campi da aggiornare
 */
export const updateFolder = async (folderId, updates) => {
  try {
    // Se l'ID ha il prefisso 'firebase_', rimuovilo
    const actualId = folderId.startsWith('firebase_') 
      ? folderId.replace('firebase_', '') 
      : folderId;
    
    const folderRef = doc(firestore, "folders", actualId);
    await updateDoc(folderRef, {
      ...updates,
      lastModified: serverTimestamp()
    });
    
    return true;
  } catch (error) {
    console.error("Errore nell'aggiornamento della cartella:", error);
    throw new Error("Errore nell'aggiornamento della cartella");
  }
};

// Alias per garantire compatibilità con altre parti del codice
export const updateFirestoreFolder = updateFolder;

// Elimina una cartella da Firestore
export const deleteFolder = async (folderId, options = {}) => {
  try {
    // Se l'ID ha il prefisso 'firebase_', rimuovilo
    const actualId = folderId.startsWith('firebase_') 
      ? folderId.replace('firebase_', '') 
      : folderId;
    
    // Verifica se è una sottocartella (controlla se l'ID contiene "subfolder_")
    const isSubfolder = actualId.includes('subfolder_');
    
    if (isSubfolder) {
      // Estrai l'ID della cartella genitore
      const parentFolderId = actualId.split('_parent_')[1];
      
      if (!parentFolderId) {
        throw new Error('ID della cartella genitore non trovato');
      }
      
      // Ottieni la cartella genitore
      const parentFolderRef = doc(firestore, 'folders', parentFolderId);
      const parentFolderSnap = await getDoc(parentFolderRef);
      
      if (!parentFolderSnap.exists()) {
        throw new Error('Cartella genitore non trovata');
      }
      
      const parentFolder = parentFolderSnap.data();
      
      // Trova la sottocartella per ottenere i suoi documentIds
      const subfolderToDelete = (parentFolder.subfolders || []).find(
        subfolder => subfolder.id === actualId
      );
      
      // Ottieni i documenti contenuti nella sottocartella
      const documentsInSubfolder = subfolderToDelete ? (subfolderToDelete.documentIds || []) : [];
      
      // Rimuovi la sottocartella dall'array delle sottocartelle
      const updatedSubfolders = (parentFolder.subfolders || []).filter(
        subfolder => subfolder.id !== actualId
      );
      
      // Aggiorna la cartella genitore, aggiungendo i documenti dalla sottocartella eliminata
      await updateDoc(parentFolderRef, {
        subfolders: updatedSubfolders,
        documentIds: [...(parentFolder.documentIds || []), ...documentsInSubfolder]
      });
      
      return true;
    } else {
      // Elimina la cartella normalmente
      const folderRef = doc(firestore, 'folders', actualId);
      
      // Verifica che la cartella esista prima di eliminarla
      const folderSnap = await getDoc(folderRef);
      if (!folderSnap.exists()) {
        throw new Error(`Cartella non trovata: ${actualId}`);
      }
      
      // Recuperiamo i documentIds della cartella che sta per essere eliminata
      // Non serve fare nulla di specifico con loro, poiché il reducer Redux
      // si occuperà di renderli nuovamente visibili nella vista principale
      
      await deleteDoc(folderRef);
      return true;
    }
  } catch (error) {
    console.error('Errore durante l\'eliminazione della cartella:', error);
    throw error;
  }
};

/**
 * Aggiunge un documento a una cartella su Firebase
 * @param {string} folderId - ID della cartella
 * @param {string} documentId - ID del documento da aggiungere
 */
export const addDocumentToFolder = async (folderId, documentId) => {
  try {
    console.log(`Iniziando aggiunta documento ${documentId} alla cartella ${folderId}`);
    const userId = JSON.parse(localStorage.getItem("userId"));
    
    // Verifica se è una sottocartella (ID inizia con 'subfolder_')
    if (folderId.startsWith('subfolder_')) {
      // Ottieni l'ID della cartella genitore dal folderId
      const parts = folderId.split('_parent_');
      if (parts.length < 2) {
        console.error("Formato ID sottocartella non valido:", folderId);
        throw new Error("ID della cartella genitore non trovato");
      }
      
      // Estrai l'ultima parte dell'ID che contiene il vero genitore
      const parentId = parts[parts.length - 1];
      
      if (!parentId) {
        console.error("ID cartella genitore mancante:", folderId);
        throw new Error("ID della cartella genitore non trovato");
      }
      
      // Crea un elenco di possibili ID per il genitore, simile a saveFolder
      const possibleParentIds = [
        parentId,
        `firebase_${parentId}`,
        parentId.replace('subfolder_', '')
      ];
      
      if (parentId.includes('_parent_')) {
        const subParts = parentId.split('_parent_');
        possibleParentIds.push(subParts[0]);
        if (subParts.length > 1) {
          possibleParentIds.push(subParts[1]);
          possibleParentIds.push(`firebase_${subParts[1]}`);
        }
      }
      
      // Rimuovi duplicati
      const uniqueParentIds = [...new Set(possibleParentIds)];
      console.log("Possibili ID della cartella genitore:", uniqueParentIds);
      
      // Flag per tenere traccia se abbiamo trovato la cartella
      let found = false;
      let parentFolderRef;
      let parentFolderSnap;
      let actualParentId;
      
      // Prova a cercare la cartella genitore direttamente usando tutti i possibili ID
      for (const potentialId of uniqueParentIds) {
        if (found) continue;
        
        actualParentId = potentialId;
        console.log(`Cercando cartella genitore con ID potenziale: ${actualParentId}`);
        parentFolderRef = doc(firestore, "folders", actualParentId);
        parentFolderSnap = await getDoc(parentFolderRef);
        
        if (parentFolderSnap.exists()) {
          console.log(`Trovata cartella genitore con ID: ${actualParentId}`);
          found = true;
          break;
        }
      }
      
      // Se non abbiamo trovato una cartella direttamente, cerchiamo nelle sottocartelle
      if (!found) {
        console.log("Cartella genitore non trovata direttamente, cercando nelle sottocartelle...");
        
        // Ottieni tutte le cartelle dell'utente
        const foldersRef = collection(firestore, "folders");
        const q = query(foldersRef, where("userId", "==", userId));
        const snapshot = await getDocs(q);
        
        console.log(`Trovate ${snapshot.docs.length} cartelle principali da esaminare`);
        
        // Funzione ricorsiva per cercare una sottocartella all'interno delle cartelle
        const findSubfolderRecursively = (parentFolderData, parentFolderId) => {
          if (!parentFolderData.subfolders || parentFolderData.subfolders.length === 0) {
            return null;
          }
          
          // Cerca la sottocartella direttamente nelle sottocartelle di questa cartella
          for (const subfolder of parentFolderData.subfolders) {
            // Cerca corrispondenze con tutti i possibili ID
            for (const potentialId of uniqueParentIds) {
              if (subfolder.id === potentialId || 
                  (subfolder.id && subfolder.id.includes(potentialId))) {
                return {
                  parentDocId: parentFolderId, 
                  parentData: parentFolderData,
                  subfolder: subfolder,
                  subfolderIndex: parentFolderData.subfolders.indexOf(subfolder)
                };
              }
            }
            
            // Cerca in modo ricorsivo nelle sottocartelle
            if (subfolder.subfolders && subfolder.subfolders.length > 0) {
              const nestedResult = findSubfolderRecursively(subfolder, parentFolderId);
              if (nestedResult) return nestedResult;
            }
          }
          
          return null;
        };
        
        // Cerca in tutte le cartelle
        let foundSubfolderInfo = null;
        
        for (const doc of snapshot.docs) {
          const folderData = doc.data();
          console.log(`Esaminando cartella ${doc.id} con ${folderData.subfolders?.length || 0} sottocartelle`);
          
          const result = findSubfolderRecursively(folderData, doc.id);
          if (result) {
            foundSubfolderInfo = result;
            console.log("Trovata sottocartella genitore:", foundSubfolderInfo);
            found = true;
            break;
          }
        }
        
        if (foundSubfolderInfo) {
          // Aggiungi il documento alla sottocartella trovata
          const { parentDocId, parentData, subfolder, subfolderIndex } = foundSubfolderInfo;
          
          // Aggiorna i documentIds della sottocartella
          const documentIds = subfolder.documentIds || [];
          if (!documentIds.includes(documentId)) {
            documentIds.push(documentId);
            subfolder.documentIds = documentIds;
            subfolder.lastModified = new Date().toISOString();
            
            // Aggiorna la cartella principale che contiene la sottocartella
            const mainFolderRef = doc(firestore, "folders", parentDocId);
            await updateDoc(mainFolderRef, {
              subfolders: parentData.subfolders,
              lastModified: serverTimestamp()
            });
            
            console.log("Documento aggiunto con successo alla sottocartella:", documentId);
            return true;
          }
        }
        
        if (!found) {
          console.error("Sottocartella genitore non trovata in nessuna cartella:", parentId);
          throw new Error("Cartella genitore non trovata");
        }
      } else {
        // Abbiamo trovato la cartella genitore direttamente
        const parentFolder = parentFolderSnap.data();
        
        // Trova la sottocartella nell'array subfolders
        const subfolders = parentFolder.subfolders || [];
        
        console.log("Sottocartelle disponibili:", subfolders.map(sf => ({ id: sf.id, name: sf.name })));
        
        const subfolderIndex = subfolders.findIndex(sf => sf.id === folderId);
        
        if (subfolderIndex === -1) {
          console.error("Sottocartella non trovata nell'array:", folderId);
          throw new Error("Sottocartella non trovata");
        }
        
        // Aggiungi il documento alla sottocartella
        const documentIds = subfolders[subfolderIndex].documentIds || [];
        if (!documentIds.includes(documentId)) {
          documentIds.push(documentId);
          subfolders[subfolderIndex].documentIds = documentIds;
          subfolders[subfolderIndex].lastModified = new Date().toISOString(); // Aggiorna il timestamp della sottocartella
          
          // Rimuovi il documento dalla cartella padre se è presente
          const parentDocumentIds = parentFolder.documentIds || [];
          if (parentDocumentIds.includes(documentId)) {
            console.log("Rimuovendo documento dalla cartella padre:", documentId);
            const updatedParentDocumentIds = parentDocumentIds.filter(id => id !== documentId);
            
            // Aggiorna la cartella genitore con la sottocartella aggiornata e rimuovi il documento dai documentIds del genitore
            await updateDoc(parentFolderRef, {
              documentIds: updatedParentDocumentIds,
              subfolders,
              lastModified: serverTimestamp()
            });
          } else {
            // Aggiorna solo la sottocartella senza modificare i documentIds del genitore
            await updateDoc(parentFolderRef, {
              subfolders,
              lastModified: serverTimestamp()
            });
          }
          
          console.log("Documento aggiunto con successo alla sottocartella:", documentId);
        }
      }
    } 
    // Se è una cartella di primo livello
    else {
      // Se l'ID ha il prefisso 'firebase_', rimuovilo
      const actualId = folderId.startsWith('firebase_') 
        ? folderId.replace('firebase_', '') 
        : folderId;
      
      const folderRef = doc(firestore, "folders", actualId);
      
      // Ottieni il documento più recente da Firestore
      console.log("Ottenendo dati più recenti della cartella:", actualId);
      const folderDoc = await getDoc(folderRef);
      
      if (!folderDoc.exists()) {
        console.error("Cartella non trovata:", actualId);
        throw new Error("Cartella non trovata");
      }
      
      const folderData = folderDoc.data();
      const documentIds = folderData.documentIds || [];
      
      console.log("Stato attuale documentIds su Firebase:", documentIds);
      
      // Aggiungi il documento solo se non è già presente
      if (!documentIds.includes(documentId)) {
        const updatedDocumentIds = [...documentIds, documentId];
        
        console.log("Aggiornamento documentIds su Firebase:", updatedDocumentIds);
        
        // Utilizza una transazione per garantire l'integrità dell'aggiornamento
        try {
          await updateDoc(folderRef, {
            documentIds: updatedDocumentIds,
            lastModified: serverTimestamp()
          });
          
          console.log("Documento aggiunto con successo alla cartella principale:", documentId, "Totale documenti:", updatedDocumentIds.length);
        } catch (updateError) {
          console.error("Errore durante l'aggiornamento della cartella:", updateError);
          throw updateError;
        }
      } else {
        console.log("Il documento è già presente nella cartella:", documentId);
      }
    }
    
    return true;
  } catch (error) {
    console.error("Errore nell'aggiunta del documento alla cartella:", error);
    throw new Error("Errore nell'aggiunta del documento alla cartella");
  }
};

/**
 * Rimuove un documento da una cartella su Firebase
 * @param {string} folderId - ID della cartella
 * @param {string} documentId - ID del documento da rimuovere
 */
export const removeDocumentFromFolder = async (folderId, documentId) => {
  try {
    console.log(`Iniziando rimozione documento ${documentId} dalla cartella ${folderId}`);
    const userId = JSON.parse(localStorage.getItem("userId"));
    
    // Verifica se è una sottocartella (ID inizia con 'subfolder_')
    if (folderId.startsWith('subfolder_')) {
      // Ottieni l'ID della cartella genitore dal folderId
      const parts = folderId.split('_parent_');
      if (parts.length < 2) {
        console.error("Formato ID sottocartella non valido:", folderId);
        throw new Error("ID della cartella genitore non trovato");
      }
      
      // Estrai l'ultima parte dell'ID che contiene il vero genitore
      const parentId = parts[parts.length - 1];
      
      if (!parentId) {
        console.error("ID cartella genitore mancante:", folderId);
        throw new Error("ID della cartella genitore non trovato");
      }
      
      // Crea un elenco di possibili ID per il genitore
      const possibleParentIds = [
        parentId,
        `firebase_${parentId}`,
        parentId.replace('subfolder_', '')
      ];
      
      if (parentId.includes('_parent_')) {
        const subParts = parentId.split('_parent_');
        possibleParentIds.push(subParts[0]);
        if (subParts.length > 1) {
          possibleParentIds.push(subParts[1]);
          possibleParentIds.push(`firebase_${subParts[1]}`);
        }
      }
      
      // Rimuovi duplicati
      const uniqueParentIds = [...new Set(possibleParentIds)];
      console.log("Possibili ID della cartella genitore per rimozione:", uniqueParentIds);
      
      // Flag per tenere traccia se abbiamo trovato la cartella
      let found = false;
      let parentFolderRef;
      let parentFolderSnap;
      let actualParentId;
      
      // Prova a cercare la cartella genitore direttamente usando tutti i possibili ID
      for (const potentialId of uniqueParentIds) {
        if (found) continue;
        
        actualParentId = potentialId;
        console.log(`Cercando cartella genitore con ID potenziale: ${actualParentId}`);
        parentFolderRef = doc(firestore, "folders", actualParentId);
        parentFolderSnap = await getDoc(parentFolderRef);
        
        if (parentFolderSnap.exists()) {
          console.log(`Trovata cartella genitore con ID: ${actualParentId} per rimozione documento`);
          found = true;
          break;
        }
      }
      
      // Se non abbiamo trovato una cartella direttamente, cerchiamo nelle sottocartelle
      if (!found) {
        console.log("Cartella genitore non trovata direttamente, cercando nelle sottocartelle per rimozione...");
        
        // Ottieni tutte le cartelle dell'utente
        const foldersRef = collection(firestore, "folders");
        const q = query(foldersRef, where("userId", "==", userId));
        const snapshot = await getDocs(q);
        
        console.log(`Trovate ${snapshot.docs.length} cartelle principali da esaminare per rimozione`);
        
        // Funzione ricorsiva per cercare una sottocartella all'interno delle cartelle
        const findSubfolderRecursively = (parentFolderData, parentFolderId) => {
          if (!parentFolderData.subfolders || parentFolderData.subfolders.length === 0) {
            return null;
          }
          
          // Cerca la sottocartella direttamente nelle sottocartelle di questa cartella
          for (const subfolder of parentFolderData.subfolders) {
            // Cerca corrispondenze con tutti i possibili ID
            for (const potentialId of uniqueParentIds) {
              if (subfolder.id === potentialId || 
                  (subfolder.id && subfolder.id.includes(potentialId))) {
                return {
                  parentDocId: parentFolderId, 
                  parentData: parentFolderData,
                  subfolder: subfolder,
                  subfolderIndex: parentFolderData.subfolders.indexOf(subfolder)
                };
              }
            }
            
            // Cerca in modo ricorsivo nelle sottocartelle
            if (subfolder.subfolders && subfolder.subfolders.length > 0) {
              const nestedResult = findSubfolderRecursively(subfolder, parentFolderId);
              if (nestedResult) return nestedResult;
            }
          }
          
          return null;
        };
        
        // Cerca in tutte le cartelle
        let foundSubfolderInfo = null;
        
        for (const doc of snapshot.docs) {
          const folderData = doc.data();
          console.log(`Esaminando cartella ${doc.id} con ${folderData.subfolders?.length || 0} sottocartelle per rimozione`);
          
          const result = findSubfolderRecursively(folderData, doc.id);
          if (result) {
            foundSubfolderInfo = result;
            console.log("Trovata sottocartella genitore per rimozione:", foundSubfolderInfo);
            found = true;
            break;
          }
        }
        
        if (foundSubfolderInfo) {
          // Rimuovi il documento dalla sottocartella trovata
          const { parentDocId, parentData, subfolder, subfolderIndex } = foundSubfolderInfo;
          
          // Aggiorna il proprio subfolders array per trovare la sottocartella esatta
          const targetSubfolder = foundSubfolderInfo.parentData.subfolders.find(sf => sf.id === folderId);
          
          if (targetSubfolder) {
            console.log("Sottocartella target trovata per rimozione:", targetSubfolder.id);
            
            // Rimuovi il documento dalla sottocartella
            const documentIds = targetSubfolder.documentIds || [];
            targetSubfolder.documentIds = documentIds.filter(id => id !== documentId);
            targetSubfolder.lastModified = new Date().toISOString();
            
            // Aggiorna la cartella principale che contiene la sottocartella
            const mainFolderRef = doc(firestore, "folders", parentDocId);
            await updateDoc(mainFolderRef, {
              subfolders: parentData.subfolders,
              lastModified: serverTimestamp()
            });
            
            console.log("Documento rimosso con successo dalla sottocartella:", documentId);
            return true;
          } else {
            console.error("Sottocartella specifica non trovata nell'array:", folderId);
            throw new Error("Sottocartella specifica non trovata");
          }
        }
        
        if (!found) {
          console.error("Sottocartella genitore non trovata in nessuna cartella:", parentId);
          throw new Error("Cartella genitore non trovata");
        }
      } else {
        // Abbiamo trovato la cartella genitore direttamente
        const parentFolder = parentFolderSnap.data();
        
        // Trova la sottocartella nell'array subfolders
        const subfolders = parentFolder.subfolders || [];
        
        console.log("Sottocartelle disponibili per rimozione:", subfolders.map(sf => ({ id: sf.id, name: sf.name })));
        
        const subfolderIndex = subfolders.findIndex(sf => sf.id === folderId);
        
        if (subfolderIndex === -1) {
          console.error("Sottocartella non trovata nell'array per rimozione:", folderId);
          throw new Error("Sottocartella non trovata");
        }
        
        // Rimuovi il documento dalla sottocartella
        const documentIds = subfolders[subfolderIndex].documentIds || [];
        subfolders[subfolderIndex].documentIds = documentIds.filter(id => id !== documentId);
        subfolders[subfolderIndex].lastModified = new Date().toISOString(); // Aggiorna il timestamp della sottocartella
        
        // Aggiorna la cartella genitore
        await updateDoc(parentFolderRef, {
          subfolders,
          lastModified: serverTimestamp()
        });
        
        console.log("Documento rimosso con successo dalla sottocartella:", documentId);
      }
    } 
    // Se è una cartella di primo livello
    else {
      // Se l'ID ha il prefisso 'firebase_', rimuovilo
      const actualId = folderId.startsWith('firebase_') 
        ? folderId.replace('firebase_', '') 
        : folderId;
      
      const folderRef = doc(firestore, "folders", actualId);
      const folderDoc = await getDoc(folderRef);
      
      if (!folderDoc.exists()) {
        throw new Error("Cartella non trovata");
      }
      
      const folderData = folderDoc.data();
      const documentIds = folderData.documentIds || [];
      
      // Rimuovi il documento
      await updateDoc(folderRef, {
        documentIds: documentIds.filter(id => id !== documentId),
        lastModified: serverTimestamp()
      });
      
      console.log("Documento rimosso con successo dalla cartella principale:", documentId);
    }
    
    return true;
  } catch (error) {
    console.error("Errore nella rimozione del documento dalla cartella:", error);
    throw new Error("Errore nella rimozione del documento dalla cartella");
  }
};

/**
 * Recupera tutte le cartelle dell'utente da Firebase
 * @returns {Array} Array di cartelle
 */
export const getUserFolders = async () => {
  try {
    const userId = JSON.parse(localStorage.getItem("userId"));
    const foldersQuery = query(
      collection(firestore, "folders"),
      where("userId", "==", userId)
    );

    const querySnapshot = await getDocs(foldersQuery);
    
    // Array per le cartelle di primo livello
    const rootFolders = [];
    
    // Mappa i documenti in oggetti cartella
    querySnapshot.docs.forEach(doc => {
      const folderData = doc.data();
      
      // Aggiungi la cartella di primo livello con il prefisso 'firebase_'
      rootFolders.push({
        ...folderData,
        id: `firebase_${doc.id}` // Aggiungi un prefisso per identificare le cartelle Firebase
      });
      
      // Estrai le sottocartelle e aggiungile all'array delle cartelle
      if (folderData.subfolders && folderData.subfolders.length > 0) {
        folderData.subfolders.forEach(subfolder => {
          // Aggiungi la sottocartella con il suo ID originale
          rootFolders.push({
            ...subfolder,
            parentId: `firebase_${doc.id}` // Aggiorna il parentId con il prefisso
          });
        });
      }
    });

    return rootFolders;
  } catch (error) {
    console.error("Errore nel recupero delle cartelle:", error);
    throw new Error("Errore nel recupero delle cartelle dell'utente");
  }
};

// Alias per compatibilità con codice legacy
export const getFolders = getUserFolders;

// Ottieni una cartella specifica per ID
export const getFolderById = async (folderId) => {
  try {
    if (!folderId) {
      console.error('ID cartella non valido');
      return null;
    }
    
    const userId = JSON.parse(localStorage.getItem("userId"));
    if (!userId) {
      console.error('Utente non autenticato');
      return null;
    }
    
    // Normalizza l'ID per gestire sia gli ID con prefisso che senza
    const normalizedId = folderId.startsWith('firebase_') ? 
      folderId.replace('firebase_', '') : folderId;
    
    // Recupera il documento della cartella da Firestore
    const folderRef = doc(firestore, "folders", normalizedId);
    const folderSnap = await getDoc(folderRef);
    
    if (folderSnap.exists()) {
      const folderData = folderSnap.data();
      // Verifica che la cartella appartenga all'utente corrente
      if (folderData.userId === userId) {
        return {
          id: normalizedId,
          ...folderData
        };
      } else {
        console.error('La cartella non appartiene all\'utente corrente');
        return null;
      }
    } else {
      console.log(`Cartella con ID ${normalizedId} non trovata`);
      return null;
    }
  } catch (error) {
    console.error('Errore nel recupero della cartella:', error);
    return null;
  }
};

// Rimuove una sottocartella da una cartella principale
export const removeSubfolderFromFolder = async (parentFolderId, subfolderId) => {
  try {
    if (!parentFolderId || !subfolderId) {
      console.error('ID mancanti per la rimozione della sottocartella');
      return false;
    }
    
    // Normalizza l'ID della cartella principale per uniformità
    const normalizedParentId = parentFolderId.startsWith('firebase_') ? 
      parentFolderId.replace('firebase_', '') : parentFolderId;
    
    // Ottieni la cartella principale
    const parentFolder = await getFolderById(normalizedParentId);
    if (!parentFolder) {
      console.error(`Cartella principale ${normalizedParentId} non trovata`);
      return false;
    }
    
    // Verifica che la cartella abbia sottocartelle
    if (!parentFolder.subfolders || !Array.isArray(parentFolder.subfolders) || parentFolder.subfolders.length === 0) {
      console.log(`La cartella ${normalizedParentId} non ha sottocartelle da rimuovere`);
      return false; 
    }
    
    // Verifica che la sottocartella esista
    const subfolder = parentFolder.subfolders.find(sf => sf.id === subfolderId);
    if (!subfolder) {
      console.error(`Sottocartella ${subfolderId} non trovata nella cartella ${normalizedParentId}`);
      return false;
    }
    
    // Filtra la sottocartella da rimuovere
    const updatedSubfolders = parentFolder.subfolders.filter(sf => sf.id !== subfolderId);
    
    // Aggiorna la cartella principale senza la sottocartella
    return updateFirestoreFolder(normalizedParentId, { 
      subfolders: updatedSubfolders 
    });
  } catch (error) {
    console.error('Errore nella rimozione della sottocartella:', error);
    return false;
  }
};