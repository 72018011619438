import React from 'react';
import PropTypes from 'prop-types';
import { HiLockClosed, HiArrowRight } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import './PremiumBanner.scss';

const PremiumBanner = ({ 
  abstractLength, 
  totalLength, 
  isAbstractTruncated 
}) => {
  const navigate = useNavigate();
  
  // Utilizziamo la costante per il limite di caratteri gratuiti
  const freeCharLimit = 1000;

  console.log('PremiumBanner props:', { abstractLength, totalLength, isAbstractTruncated });

  return (
    <div className="PremiumBanner">
      <div className="PremiumBanner__content">
        <div className="PremiumBanner__header">
          <HiLockClosed className="PremiumBanner__icon" />
          <h6>Versione gratuita</h6>
        </div>
        <p>Hai accesso a {freeCharLimit.toLocaleString()} caratteri su {totalLength.toLocaleString()}. Passa a Premium per sbloccare tutto il contenuto e scaricarlo.</p>
        <button 
          className="PremiumBanner__button animate-button"
          onClick={() => {
            navigate('/home/piani');
            setTimeout(() => {
              window.scrollTo(0, 0);
            }, 100);
          }}
        >
          <span>Aggiorna piano</span>
          <HiArrowRight className="PremiumBanner__button-icon" />
        </button>
      </div>
    </div>
  );
};

PremiumBanner.propTypes = {
  abstractLength: PropTypes.number.isRequired,
  totalLength: PropTypes.number.isRequired,
  isAbstractTruncated: PropTypes.bool.isRequired
};

export default PremiumBanner; 