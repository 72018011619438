import './feedbackCard.scss';
import { ChevronRight, CheckCircle, CircleX, Brain, RotateCcw, PhoneOff } from 'lucide-react';
import { useEffect, useState } from 'react';

// Onboarding
import { Steps } from "intro.js-react";
import { useSelector } from 'react-redux';
import "intro.js/introjs.css";
import useGetOnboardingState from '../../../uploadNotes/uploadNotesCard/uploadNotesForm/hooks/useGetOnboardingState';

const FeedbackCard = ({
  isExamStarted,
  feedback,
  nextQuestion,
  retryCurrQuestion,
  handleFinishExam,
  examState,
  aiIconState,
  isRecording,
  currentQuestion,
  qa,
  reviewMode,
  saveExam,
}) => {
  const currentStep = useSelector(state => state.onboarding.currentStep);
  const [stepsEnabled, setStepsEnabled] = useState(currentStep === 12 && feedback ? true : false);
  const { updateCurrentStep, getCurrentStep } = useGetOnboardingState();

  useEffect(() => {
    getCurrentStep();
  }, [getCurrentStep]);
  
  const steps = [
    {
      element: '.noFeedback-content',
      intro: "Il tutor AI ti darà consigli su come migliorare la tua risposta​",
    },
    {
      element: '.next-btn',
      intro: "Vai alla prossima domanda",
    },
    {
      element: '.retry-btn',
      intro: "Riprova la domanda",
    },
    {
      element: '.finish-exam-btn',
      intro: "Termina l'esame",
    },
  ];

  const onExit = async () => {
    setStepsEnabled(false);
    if(currentStep === 12) {
      updateCurrentStep(13);
    };
  };

  // Log del feedback per debug
  useEffect(() => {
    console.log("Feedback ricevuto nel componente FeedbackCard:", feedback);
    
    if(feedback && currentQuestion < qa.length) {
      setStepsEnabled(currentStep === 12 && feedback ? true : false);
      
      saveExam('in-progress', currentQuestion, 'auto-save');
    };
  }, [feedback]);

  const handleRetryQuestion = () => {
    if(currentStep === 12) {
      updateCurrentStep(13);
      setStepsEnabled(false);
    };
    retryCurrQuestion();
  };

  const handleNextQuestion = () => {
    if(currentStep === 12) {
      updateCurrentStep(13);
      setStepsEnabled(false);
    };
    nextQuestion();
  };

  const handleOnboardingFinishExam = () => {
    if(currentStep === 12) {
      updateCurrentStep(13);
      setStepsEnabled(false);
    };
    handleFinishExam();
  };

  const hasSuggestions = feedback?.suggestions;
  const isLastQuestion = currentQuestion === qa.length;

  if(!reviewMode)  return null;
  
  return (
    <div className="feedback-container">
      <Steps
        enabled={stepsEnabled}
        steps={steps}
        initialStep={0}
        onExit={onExit}
      />
      <div className="feedback-content">
        {!feedback && 
          <div className='noFeedback-container'>
            <div>
              <h2 className={`feedback-title`}>Esito della Risposta</h2>
              <p className="noFeedback">Il feedback apparirà qui dopo aver completato la risposta.</p>
            </div>

            {/* Pulsante per terminare l'esame */}
            <button onClick={handleFinishExam} className={`finish-exam-btn ${isExamStarted ? "active" : ""} ${examState === 'processing' || isRecording ? 'finish-btn-disabled' : ''}`}>
              <PhoneOff size={20}/>
              <span className='btn-text'>Termina Esame</span>
            </button>
          </div>
        }

        {feedback && (
          <>
            <div className='noFeedback-content'>
              <h2 className={`feedback-title ${isExamStarted ? "feedback-active" : ""}`}>Esito della Risposta</h2>

              {/* Aspetti Positivi */}
              <div className="positives">
                <div className='positives-row'>
                  <CheckCircle className='checkCircle' />
                  <p className="positive-feedback-subtitle">Aspetti Positivi</p>
                </div>
                <ul>
                  {feedback.positives.point1 
                    ? <li>{feedback.positives.point1}</li>
                    : <li>Nessun aspetto positivo rilevato.</li>
                  }

                  {feedback.positives.point2 
                    ? <li>{feedback.positives.point2}</li>
                    : null
                  }

                  {feedback.positives.point3 
                    ? <li>{feedback.positives.point3}</li>
                    : null
                  }

                  {feedback.positives.point4 
                    ? <li>{feedback.positives.point4}</li>
                    : null
                  }
                </ul>
              </div>

              {/* Aspetti da Migliorare */}
              <div className="negatives">
                <div className='negatives-row'>
                  <CircleX className='circleX' size={25}/>
                  <p className="negative-feedback-subtitle">Aspetti da Migliorare</p>
                </div>
                <ul>
                {feedback.negatives.point1 
                    ? <li>{feedback.negatives.point1}</li>
                    : <li>Nessun aspetto negativo rilevato.</li>
                  }

                  {feedback.negatives.point2 
                    ? <li>{feedback.negatives.point2}</li>
                    : null
                  }

                  {feedback.negatives.point3 
                    ? <li>{feedback.negatives.point3}</li>
                    : null
                  }

                  {feedback.negatives.point4 
                    ? <li>{feedback.negatives.point4}</li>
                    : null
                  }
                </ul>
              </div>

              {/* Suggerimenti AI */}
              <div className="suggestions">
                <div className='suggestions-row'>
                  <Brain size={25}/>
                  <p className="suggestions-title">Suggerimenti StudierAI</p>
                </div>
                <p>{hasSuggestions ? feedback.suggestions : "Nessun suggerimento disponibile."}</p>
              </div>
            </div>

            <div className='btns-container'>
              { !isLastQuestion &&
                <>
                  {/* Pulsanti per la prossima domanda*/}
                  <button onClick={handleNextQuestion} className={`next-btn ${isExamStarted ? "active" : ""} ${aiIconState !== 'speaking' && aiIconState !== 'stopped' ? "next-btn-disabled" : ""}`}>
                    <span className='btn-text'>Prossima Domanda</span>
                    <ChevronRight size={20}/>
                  </button>
                </>
              }
              
              {/* Pulsanti per riprovare la domanda*/}
              <button onClick={handleRetryQuestion} className={`retry-btn ${isExamStarted ? "active" : ""} ${aiIconState !== 'speaking' && aiIconState !== 'stopped' ? "retry-btn-disabled" : ""}`}>
                <RotateCcw size={20}/>
                <span className='btn-text'>Riprova Domanda</span>
              </button>

              {/* Pulsante per terminare l'esame */}
              <button 
                onClick={handleOnboardingFinishExam} 
                className={`finish-exam-btn ${isExamStarted ? "active" : ""} ${isLastQuestion && 'exam-finished-btn'} ${aiIconState !== 'speaking' && aiIconState !== 'stopped' ? "finish-btn-disabled" : ""}`}
              >
                {!isLastQuestion && <PhoneOff size={20}/>}
                <span className='btn-text'>{isLastQuestion ? 'Risultato Esame' : 'Termina Esame'}</span>
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FeedbackCard;