import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import Button from "@ui/button/button";
import PriceOption from "./priceOption/priceOption";
import Countdown from "../countDown/countDown";

import "./priceCard.scss";

const PriceCard = ({ 
  title, 
  originalCost, 
  cost, 
  method = "", 
  advised = false, 
  options, 
  buttonText, 
  onClick, 
  isDisabled = false, 
  freeNote,
  finalText,
  accessInfo,
  isAnnual,
  showCountdown = true
}) => {
  console.log('PriceCard props:', { title, showCountdown, advised, isPremium: buttonText === "Gestisci abbonamento" });

  // Determina se il pulsante deve avere l'animazione (acquista/inizia piani)
  const shouldAnimate = buttonText === "Acquista Ora" || 
                        buttonText === "Inizia Anno Accademico" || 
                        buttonText === "Inizia Piano Annuale";

  return (
    <div className={clsx("PriceCard", advised && `${title === 'Piano Accademico' ? "PriceCard--suggest-scolastico " :  title === 'Piano Universitario' ? "PriceCard--suggest-universitario" : 'PriceCard--suggest'}`)}>
      <div className="PriceCard__header">
        <h3 className="PriceCard__header-title">{title}</h3>
        <span className="PriceCard__header-cost">
          {originalCost && <span className="PriceCard__original-cost">{originalCost}€</span>}
          <span className="PriceCard__current-cost">{cost}€</span>
          {method && <span className="PriceCard__method">/ {method}</span>}
        </span>
        {isAnnual &&
          <div className={`${title === 'Piano Accademico' ? 'PriceCard__access-info-scolastico' : 'PriceCard__access-info'}`}>
            <span className="PriceCard__access-info-text">
              {accessInfo}
            </span>
          </div>
        }
        {showCountdown && Number(cost) > 0 && title !== "Prova Gratuita" && (
          <div className={`PriceCard__countdown ${title === 'Piano Accademico' ? 'PriceCard__countdown--scolastico' : ''}`}>
            <Countdown />
          </div>
        )}
      </div>
      <ul className="PriceCard__options">
        {options.map((option, index) => (
          <PriceOption key={index} option={option} title={title} />
        ))}
      </ul>
      <div className='PriceCard__button'>
        <Button 
          className={clsx(
            title === 'Piano Accademico' ? 'PriceCard__button-scolastico' : null,
            shouldAnimate ? 'animate-button' : null
          )} 
          disabled={isDisabled} 
          size="md" 
          variant="primary" 
          onClick={onClick}
        >
          {buttonText}
        </Button>
      </div>
      {freeNote && <p className="PriceCard__text PriceCard__text--black">{freeNote}</p>}
      {Number(cost) > 0 && <span className="PriceCard__final-text PriceCard__text--black">{finalText || "Puoi disdire quando vuoi"}</span>}
    </div>
  );
};

PriceCard.propTypes = {
  title: PropTypes.string.isRequired,
  originalCost: PropTypes.string,
  cost: PropTypes.string.isRequired,
  method: PropTypes.string,
  advised: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClick: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  freeNote: PropTypes.string,
  showCountdown: PropTypes.bool
};

export default PriceCard;