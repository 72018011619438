// components/CookieModal.jsx
"use client";
import { useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useLocation } from 'react-router-dom';

const CookieSolution = () => {
  const location = useLocation();
  
  useEffect(() => {
    const auth = getAuth();

    // Lista delle route pubbliche dove mostrare Iubenda
    const publicRoutes = ['/', '/landing', '/signIn', '/signUp', '/verifica-email'];

    const isPublicRoute = () => {
      return publicRoutes.includes(location.pathname);
    };

    const setupIubenda = () => {
      // Iubenda scripts configuration
      window._iub = window._iub || [];
      window._iub.csConfiguration = {
        askConsentAtCookiePolicyUpdate: true,
        enableFadp: true,
        enableLgpd: true,
        enableUspr: true,
        fadpApplies: true,
        floatingPreferencesButtonDisplay: "bottom-left",
        lang: "it",
        perPurposeConsent: true,
        preferenceCookie: { expireAfter: 180 },
        siteId: 3607864,
        usprApplies: true,
        whitelabel: false,
        cookiePolicyId: 73904869,
        banner: {
          acceptButtonDisplay: true,
          closeButtonDisplay: false,
          customizeButtonDisplay: true,
          explicitWithdrawal: true,
          listPurposes: true,
          ownerName: "studierai.app",
          position: "float-bottom-center",
          rejectButtonDisplay: true,
          showTitle: false,
          showTotalNumberOfProviders: true
        }
      };

      // Load Iubenda scripts
      const csScript = document.createElement("script");
      csScript.src = "https://cs.iubenda.com/autoblocking/3607864.js";
      csScript.type = "text/javascript";
      document.body.appendChild(csScript);

      const stubScript = document.createElement("script");
      stubScript.src = "//cdn.iubenda.com/cs/gpp/stub.js";
      stubScript.type = "text/javascript";
      document.body.appendChild(stubScript);

      const mainScript = document.createElement("script");
      mainScript.src = "//cdn.iubenda.com/cs/iubenda_cs.js";
      mainScript.type = "text/javascript";
      mainScript.charset = "UTF-8";
      mainScript.async = true;
      document.body.appendChild(mainScript);

      return { csScript, stubScript, mainScript };
    };

    const removeIubenda = () => {
      // Rimuovi tutti gli elementi di Iubenda
      const iubendaElements = document.querySelectorAll('[class*="iub"]');
      iubendaElements.forEach(element => element.remove());

      // Rimuovi gli script di Iubenda
      const scripts = document.querySelectorAll('script[src*="iubenda"]');
      scripts.forEach(script => script.remove());
    };

    // Gestisci la visibilità di Iubenda
    const handleIubendaVisibility = (user) => {
      if (user && user.emailVerified && !isPublicRoute()) {
        removeIubenda();
      } else if (isPublicRoute()) {
        setupIubenda();
      }
    };

    // Ascolta i cambiamenti di autenticazione
    const unsubscribe = onAuthStateChanged(auth, handleIubendaVisibility);

    // Gestisci la visibilità iniziale
    handleIubendaVisibility(auth.currentUser);

    // Cleanup
    return () => {
      unsubscribe();
      removeIubenda();
    };
  }, [location.pathname]); // Riesegui l'effetto quando cambia il pathname

  return null;
};

export default CookieSolution;
