import React, { useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { FaPlay } from 'react-icons/fa';
import './VideoContainer.scss';

const VideoContainer = ({ videoRef }) => {
  const localVideoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [player, setPlayer] = useState(null);
  const [isPlayerReady, setIsPlayerReady] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const initializeYouTubePlayer = useCallback(async () => {
    if (videoRef) {
      videoRef.current = localVideoRef.current;
    }

    const loadYouTubeAPI = () => {
      return new Promise((resolve, reject) => {
        if (window.YT) {
          resolve();
          return;
        }

        const tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        
        window.onYouTubeIframeAPIReady = () => {
          resolve();
        };

        tag.onerror = (error) => {
          reject(new Error('Failed to load YouTube IFrame API'));
        };

        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      });
    };

    try {
      await loadYouTubeAPI();
      
      if (!window.YT || !localVideoRef.current) {
        throw new Error('YouTube API or video container not available');
      }

      const newPlayer = new window.YT.Player(localVideoRef.current, {
        videoId: '0EbfTKC1HPI',
        playerVars: {
          autoplay: 0,
          controls: 1,
          rel: 0,
          modestbranding: 1,
          showinfo: 0,
          fs: 1,
          playsinline: 1,
          enablejsapi: 1,
          origin: window.location.protocol + '//' + window.location.host,
          host: window.location.protocol + '//www.youtube.com'
        },
        events: {
          'onReady': (event) => {
            try {
              setIsPlayerReady(true);
              const qualities = event.target.getAvailableQualityLevels();
              const hdQuality = qualities.includes('hd1080') ? 'hd1080' : 
                             qualities.includes('hd720') ? 'hd720' : 
                             qualities[0];
              event.target.setPlaybackQuality(hdQuality);
            } catch (error) {
              console.error('Error in onReady handler:', error);
              setIsPlayerReady(false);
            }
          },
          'onStateChange': (event) => {
            try {
              if (event.data === 1) {
                setIsPlaying(true);
              } else if (event.data === 0 || event.data === 2) {
                setIsPlaying(false);
              }
            } catch (error) {
              console.error('Error in onStateChange handler:', error);
              setIsPlaying(false);
            }
          },
          'onError': (e) => {
            console.error('YouTube Player Error:', e);
            setIsPlaying(false);
            setIsPlayerReady(false);
          }
        }
      });
      
      setPlayer(newPlayer);
    } catch (error) {
      console.error('Error initializing YouTube player:', error);
      setIsPlaying(false);
      setIsPlayerReady(false);
    }
  }, [videoRef]);

  useEffect(() => {
    if (!player) {
      initializeYouTubePlayer();
    }

    return () => {
      if (player?.destroy) {
        player.destroy();
      }
    };
  }, [player, initializeYouTubePlayer]);

  const handlePlayClick = useCallback(async (e) => {
    // Previene il comportamento di default solo se necessario
    if (e && e.cancelable) {
      e.preventDefault();
    }

    if (!player || !isPlayerReady) return;

    try {
      setIsPlaying(true);
      await player.playVideo();
    } catch (error) {
      console.error('Error playing video:', error);
      setIsPlaying(false);
    }
  }, [player, isPlayerReady]);

  return (
    <div className="VideoContainer">
      <div className="video-wrapper">
        <div ref={localVideoRef}></div>
        {!isPlaying && (
          <motion.div 
            className="play-button-overlay"
            onClick={handlePlayClick}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            style={{ touchAction: 'none' }}
          >
            <div className="play-button">
              <FaPlay className="play-icon" />
            </div>
            <span className="play-text">
              {isMobile ? "Tocca per guardare" : "Guarda il tutorial"}
            </span>
          </motion.div>
        )}
      </div>
    </div>
  );
};

VideoContainer.propTypes = {
  videoRef: PropTypes.object
};

export default VideoContainer;