import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDocuments } from '@lib/firestoreQueries';
import { toast } from 'react-toastify';

const useGetDocuments = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const documents = useSelector(state => state.document.documents);

  const fetchDocuments = useCallback(async (forceRefresh = false) => {
    try {
      if (forceRefresh) {
        setIsLoading(true);
      }
      
      console.log("Caricamento documenti...");
      const documentsResponse = await getUserDocuments();
      
      if (documentsResponse.empty) {
        console.log("Nessun documento trovato");
        dispatch({ type: 'SET_DOCUMENT', payload: [] });
      } else {
        console.log(`${documentsResponse.docs.length} documenti trovati`);
        
        const sortedDocs = [...documentsResponse.docs].sort((a, b) => {
          const dateA = a.uploadDate?.seconds || 0;
          const dateB = b.uploadDate?.seconds || 0;
          return dateB - dateA;
        });
        
        if (sortedDocs.length > 0) {
          console.log("Documenti più recenti caricati:", sortedDocs.slice(0, 3).map(doc => ({
            id: doc.id,
            title: doc.title,
            date: new Date(doc.uploadDate?.seconds * 1000).toISOString()
          })));
        }
        
        dispatch({ type: 'SET_DOCUMENT', payload: sortedDocs });
      }
    } catch (error) {
      console.error('Error fetching documents:', error);
      toast.error('Errore nel recupero dei documenti');
      dispatch({ type: 'SET_DOCUMENT', payload: [] });
    } finally {
      setIsLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchDocuments();
    
    const intervalId = setInterval(() => {
      fetchDocuments(false);
    }, 60000);
    
    return () => clearInterval(intervalId);
  }, [fetchDocuments]);

  return { 
    isLoading, 
    documents,
    refreshDocuments: () => fetchDocuments(true)
  };
};

export default useGetDocuments;
