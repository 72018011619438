import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";
import { ChevronDown, ChevronUp } from "lucide-react";

import Logo from "@ui/logo/logo";
import { IoMdClose } from "react-icons/io";

/* styles */
import "./landingMenu.scss";

const LandingMenu = ({ onClose }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isHomeOpen, setIsHomeOpen] = useState(false);
  const [isPerChiOpen, setIsPerChiOpen] = useState(false);
  const [isAltroOpen, setIsAltroOpen] = useState(false);

  const handleNavigate = (path, sectionId) => {
    // Chiudi il menu immediatamente per una migliore esperienza utente
    onClose();
    
    // Caso di navigazione ad una sezione interna della home
    if (path.startsWith('/#') && sectionId) {
      // Se siamo già nella home, scorriamo direttamente alla sezione
      if (location.pathname === '/') {
        scrollToSection(sectionId);
      } else {
        // Altrimenti navighiamo alla home e poi scorriamo
        navigate('/');
        // Utilizziamo MutationObserver invece di setTimeout per una migliore affidabilità
        const observer = new MutationObserver(() => {
          const element = document.getElementById(sectionId);
          if (element) {
            scrollToSection(sectionId);
            observer.disconnect();
          }
        });
        
        // Osserviamo i cambiamenti nel DOM dopo la navigazione
        observer.observe(document.body, {
          childList: true,
          subtree: true
        });
        
        // Timeout di sicurezza per disconnettere l'observer dopo 2 secondi
        setTimeout(() => observer.disconnect(), 2000);
      }
    } else {
      // Per le altre pagine, navighiamo normalmente
      navigate(path);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  // Funzione di supporto per scorrere a una sezione specifica
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      const headerOffset = 80;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  };

  useEffect(() => {
    const mainHeader = document.querySelector('.LandingHeader__content');
    if (mainHeader) mainHeader.style.display = 'none';
    const copilot = document.querySelector('co-pilot');
    if (copilot) copilot.style.display = 'none';

    return () => {
      if (mainHeader) mainHeader.style.display = '';
      if (copilot) copilot.style.display = 'block';
    };
  }, []);

  return (
    <div className="LandingMenu">
      <header className="LandingMenu__header">
        <Logo
          className="LandingMenu__header-logo"
        />
        <div className="LandingMenu__header-box" onClick={onClose}>
          <IoMdClose />
        </div>
      </header>
      
      <div className="LandingMenu__content">
        <div className="LandingMenu__nav-items">
          {/* Home Dropdown */}
          <div 
            className="LandingMenu__nav-item LandingMenu__nav-item--dropdown"
            onClick={() => setIsHomeOpen(!isHomeOpen)}
          >
            Home
            {isHomeOpen ? (
              <ChevronUp className="LandingMenu__chevron" />
            ) : (
              <ChevronDown className="LandingMenu__chevron" />
            )}
          </div>

          {isHomeOpen && (
            <div className="LandingMenu__dropdown-content">
              <div 
                className="LandingMenu__nav-item"
                onClick={() => handleNavigate('/#functionality', 'functionality')}
              >
                Funzionalità
              </div>
              <div 
                className="LandingMenu__nav-item"
                onClick={() => handleNavigate('/#video-section', 'video-section')}
              >
                Tutorial
              </div>
              <div 
                className="LandingMenu__nav-item"
                onClick={() => handleNavigate('/#beta-testimonial-slider', 'beta-testimonial-slider')}
              >
                Recensioni
              </div>
              <div 
                className="LandingMenu__nav-item"
                onClick={() => handleNavigate('/#studierai-stats', 'studierai-stats')}
              >
                Statistiche
              </div>
              <div 
                className="LandingMenu__nav-item"
                onClick={() => handleNavigate('/#faq', 'faq')}
              >
                FAQ
              </div>
            </div>
          )}

          {/* Per Chi Dropdown */}
          <div 
            className="LandingMenu__nav-item LandingMenu__nav-item--dropdown"
            onClick={() => setIsPerChiOpen(!isPerChiOpen)}
          >
            Studenti
            {isPerChiOpen ? (
              <ChevronUp className="LandingMenu__chevron" />
            ) : (
              <ChevronDown className="LandingMenu__chevron" />
            )}
          </div>

          {isPerChiOpen && (
            <div className="LandingMenu__dropdown-content">
              <div 
                className="LandingMenu__nav-item"
                onClick={() => handleNavigate('/faculty')}
              >
                Università
              </div>
              <div 
                className="LandingMenu__nav-item"
                onClick={() => handleNavigate('/faculty/scuole')}
              >
                Scuole
              </div>
              <div 
                className="LandingMenu__nav-item"
                onClick={() => handleNavigate('/faculty/partner')}
              >
                Diventa Partner
              </div>
            </div>
          )}

          {/* Piani e Prezzi */}
          <div 
            className="LandingMenu__nav-item"
            onClick={() => {
              navigate('/piani-e-prezzi');
              setTimeout(() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth"
                });
              }, 100);
              onClose();
            }}
          >
            Piani e Prezzi
          </div>

          {/* Chi Siamo */}
          <div 
            className="LandingMenu__nav-item"
            onClick={() => handleNavigate('/chi-siamo')}
          >
            Chi Siamo
          </div>

          {/* Altro Dropdown */}
          <div 
            className="LandingMenu__nav-item LandingMenu__nav-item--dropdown"
            onClick={() => setIsAltroOpen(!isAltroOpen)}
          >
            Altro
            {isAltroOpen ? (
              <ChevronUp className="LandingMenu__chevron" />
            ) : (
              <ChevronDown className="LandingMenu__chevron" />
            )}
          </div>

          {isAltroOpen && (
            <div className="LandingMenu__dropdown-content">
              <div 
                className="LandingMenu__nav-item"
                onClick={() => handleNavigate('/assistenza')}
              >
                Contattaci
              </div>
            </div>
          )}
        </div>

        <div className="LandingMenu__auth">
          <button
            className="LandingMenu__auth-button LandingMenu__auth-button--login"
            onClick={() => handleNavigate('/signIn')}
          >
            Accedi
          </button>
          <button
            className="LandingMenu__auth-button LandingMenu__auth-button--register"
            onClick={() => handleNavigate('/signUp')}
          >
            Registrati Gratis
          </button>
        </div>
      </div>
    </div>
  );
};

LandingMenu.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default LandingMenu;
