import { useState, useEffect } from "react";
import FeedbackAnswers from "../feebackAnswers/feebackAnswers";
import "./Question.scss";

// Onboarding
import { Steps } from "intro.js-react";
import { useSelector } from "react-redux";
import "intro.js/introjs.css";
import useGetOnboardingState from "../../uploadNotes/uploadNotesCard/uploadNotesForm/hooks/useGetOnboardingState";

import { 
  ChevronDown, 
  ChevronUp, 
  CheckCircle, 
  XCircle, 
  MinusCircle,
  MessageCircle,
  CircleArrowRight,
} from "lucide-react";

const Question = ({ index, status, colors, userAnswer, question, feedback, openQuestion, setOpenQuestion }) => {  
  const currentStep = useSelector(state => state.onboarding.currentStep);
  const [stepsEnabled, setStepsEnabled] = useState(currentStep <= 13 ? true : false);
  const { updateCurrentStep, getCurrentStep } = useGetOnboardingState();
  
  useEffect(() => {
    getCurrentStep();
  }, [getCurrentStep, currentStep]);

  const onExit = async () => {
    setStepsEnabled(false);
    if(currentStep <= 13) {
      updateCurrentStep(14);
    };
  };

  const steps = [
    currentStep <= 13 && {
      element: '.Question__container',
      intro: "Quando hai finito l'interrogazione, puoi rivedere le tue risposte",
    }
  ].filter(Boolean);

  useEffect(() => {
    const helperLayer = document.querySelector('.introjs-helperLayer');
    if (helperLayer) {
      helperLayer.style.marginTop = '0px';
    }
  
    const tooltip = document.querySelector('.introjs-tooltip');
    if (tooltip) {
      tooltip.style.marginTop = "0px";  
    }
  }, [stepsEnabled]);

  const getQuestionIcon = (status) => {
    switch (status) {
      case 'correct':
        return <CheckCircle style={{ color: '#16A34A' }} className="Question__header-icon" size={16} />;
      case 'incorrect':
        return <XCircle style={{ color: '#F44336' }} className="Question__header-icon" size={16} />;
      case 'partial':
        return <MinusCircle style={{ color: '#f59e0b' }} className="Question__header-icon" size={16} />;
      case 'skipped':
        return <CircleArrowRight className="Question__header-icon" size={16} />;
      default:
        return null;
    }
  };

  const getQuestionClass = (status) => {
    switch (status) {
      case 'correct':
        return 'Question__container Question__container--correct';
      case 'incorrect':
        return 'Question__container Question__container--incorrect';
      case 'partial':
        return 'Question__container Question__container--partial';
      case 'skipped':
        return 'Question__container Question__container--skipped';
      default:
        return 'Question__container';
    }
  };

  const toggleQuestion = (id) => {
    setOpenQuestion(openQuestion === id ? null : id);
    if(currentStep <= 13) {
      updateCurrentStep(14);
      setStepsEnabled(false);
    };
  };

  return (
    <div key={index} className={getQuestionClass(status)}  onClick={() => toggleQuestion(index)} >
      <Steps
        enabled={stepsEnabled}
        steps={steps}
        initialStep={0}
        onExit={onExit}
      />
      <div className="Question__header">
        <h4 className="Question__title" style={{ color: colors.text }}>
          <div className="Question__title-wrapper">
            <span>
              {getQuestionIcon(status)}
            </span>
            <span className={`Question__title-badge Question__title-badge--${status}`}>
              Domanda {index + 1}
            </span>
          </div>
        </h4>
        {openQuestion === index ? (
          <ChevronUp className="Question__icon" />
        ) : (
          <ChevronDown className="Question__icon" />
        )}
      </div>
      
      <h4 className="Question__title-text">
        {question}
      </h4>

      {openQuestion === index && (
        <div className="Question__content">
          <div className="Question__content__answer">
            <div className="Question__content__answer-title">
              <MessageCircle size={15}/>
              <h5 className="Question__content__answer-title-text" style={{ color: colors.text }}>Risposta:</h5>
            </div>
            <p className="Question__content__answer-text">{userAnswer}</p>
          </div>
          {feedback && (
            <div className={`Question__feedback Question__feedback--${status}`}>
              <div className="Question__feedback-title">
                <MessageCircle size={15} className="Question__feedback-icon" />
                <h5 className="Question__feedback-title-text" style={{ color: colors.text }}>Feedback:</h5>
              </div>
              <FeedbackAnswers feedback={feedback} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Question;
