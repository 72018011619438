import { firestore } from "../redux/firebase";
import { doc, updateDoc } from "firebase/firestore";

export const handleRefferalCodeTracking = (user, localReferralCode) => {
  if (typeof window.gtag === 'function') {
    window.gtag('event', 'referralCodeSaved', {
      'event_category': 'ReferralCode',
      'event_label': 'referralCode Saved',
      'email': user?.email || 'utente_anonimo',
      'referralCode': user?.referralCode || localReferralCode,
      'userId': localStorage.getItem("userId") || 'non_disponibile'
    });
  }
};

export const verifyReferralValidity = async (referralCode, user, setters) => {
  const { setIsValidCode, setIsVerifying } = setters;
  if (!referralCode) {
    setIsValidCode(false);
    return;
  }

  setIsVerifying(true);
  const localCode = localStorage.getItem("tempReferralCode");

  try {
    let API_URL;
    switch(process.env.REACT_APP_ENVIRONMENT) {
      case "development":
        API_URL = "http://localhost:8080";
        break;
      case "preproduction":
        API_URL = process.env.REACT_APP_API_URL_PREPRODUCTION;
        break;
      default:
        API_URL = process.env.REACT_APP_API_URL_PRODUCTION;
    }

    const response = await fetch(`${API_URL}/check-referral-eligibility?referralCode=${referralCode}`);
    const data = await response.json();

    if (!response.ok) {
      if (user?.referralCode === referralCode) {
        setIsValidCode(true);
      } else {
        setIsValidCode(false);
      }
      return;
    }

    if(data.couponDetails?.amount_off !== 1000) {
      setIsValidCode(false);
      return;
    }

    if (data.success) {
      if (data.couponDetails?.amount_off === 1000) {
        setIsValidCode(true);
        
        const studentId = cleanStudentId(localStorage.getItem("userId"));
        if (studentId && referralCode !== user?.referralCode) {
          await updateFirestoreProfile(studentId, { referralCode });
        }
      } else {
        setIsValidCode(false);
      }
    }
  } catch (error) {
    console.error("Verification error:", error);
    setIsValidCode(user?.referralCode === referralCode);
  } finally {
    setIsVerifying(false);
  }
};

export const generateReferralCode = async (getGenerateCode, setters, user) => {
  const { setIsLoading, setGenerationStatus, setLocalReferralCode } = setters;
  
  setIsLoading(true);
  setGenerationStatus("");

  try {
    const studentId = cleanStudentId(localStorage.getItem("userId"));
    if (!studentId) throw new Error("Missing student ID");

    const newCode = await getGenerateCode();
    setLocalReferralCode(newCode);
    localStorage.setItem("tempReferralCode", newCode);
    setGenerationStatus("success");

    await updateFirestoreProfile(studentId, {
      referralCode: newCode,
    });

    trackCodeGeneration(user);
  } catch (error) {
    console.error("Generation error:", error);
    setGenerationStatus("error");
  } finally {
    setIsLoading(false);
  }
};

// Helper functions
const cleanStudentId = (studentId) => {
  if (!studentId) return null;
  return studentId.replace(/^["']|["']$/g, '');
};

const updateFirestoreProfile = async (studentId, updateData) => {
  try {
    const studentRef = doc(firestore, "students", studentId);
    await updateDoc(studentRef, updateData);
  } catch (error) {
    console.error("Firestore update error:", error);
  }
};

const trackCodeGeneration = (user) => {
  if (typeof window.gtag === 'function') {
    window.gtag('event', 'referralCodeGenerated', {
      'event_category': 'ReferralCode',
      'event_label': 'Referral Code Generated',
      'email': user?.email || 'utente_anonimo'
    });
  }
}; 