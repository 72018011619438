import React from 'react'
import PropTypes from 'prop-types'
import useGetFlashcards from './hook/useGetFlashcards'
import { Flashcard } from './flashcard/flashcard'
import MaterialsFlashcardsNotFound from './materialsFlashcardsNotFound/materialsFlashcardsNotFound'
import './materialFlashcards.scss';
import { HiDownload, HiLockClosed, HiArrowRight } from 'react-icons/hi';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const FREE_FLASHCARD_LIMIT = 3;

const MaterialFlashcards = ({ idSelectedDocument, onboardingDocId, isLegacyFormat = true }) => {
  const { isLoading, flashcards } = useGetFlashcards(onboardingDocId || idSelectedDocument, isLegacyFormat);
  const { user } = useSelector(state => state.auth);
  const navigate = useNavigate();
  
  // Check if user is premium based on plan
  const isPremium = user?.plan === 'Premium';

   // Mantieni l'ordine originale del documento
  const sortedFlashcards = [...flashcards].filter(card => card && (
    (card.question && card.answer) || 
    (card.card_question && card.card_answer)
  ));
  
  // Filter out discarded questions from user
  const updatedQA = sortedFlashcards.filter(qa => qa && qa.isHelpful !== false); 
  
  const handleDownload = () => {
    if (!isPremium) return;

    // Prepara i dati delle flashcard in formato CSV
    const csvContent = updatedQA.map(card => {
      const question = card.card_question || card.question;
      const answer = card.card_answer || card.answer;
      // Escape delle virgole e newline per il CSV
      const escapedQuestion = `"${question.replace(/"/g, '""')}"`;
      const escapedAnswer = `"${answer.replace(/"/g, '""')}"`;
      return `${escapedQuestion},${escapedAnswer}`;
    }).join('\n');

    const header = 'Domanda,Risposta\n';
    const blob = new Blob([header + csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'flashcards.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  // show spinner if is loading
  if (isLoading) {
    return (
      <div className="MaterialsFlashcards">
        {/* Skeleton per l'header */}
        <div className="MaterialsFlashcards__stats-skeleton skeleton-animation">
          <div className="stats-header-skeleton">
            <div className="stats-icon-skeleton"></div>
            <div className="stats-info-skeleton">
              <div className="stats-details-skeleton"></div>
            </div>
          </div>
        </div>

        {/* Skeleton per le flashcard */}
        <div className='MaterialsFlashcards__container-skeleton'>
          {[...Array(10)].map((_, index) => (
            <div key={index} className="flashcard-skeleton">
              <div className="question-skeleton skeleton-animation"></div>
              <div className="answer-skeleton skeleton-animation"></div>
            </div>
          ))}
        </div>
      </div>
    );
  }
  
  // return select document if id selected document is null
  if (!idSelectedDocument && !onboardingDocId)
    return <MaterialsFlashcardsNotFound message='Seleziona un documento' />

  // return empty flashcards if flashcards is empty
  if (!isLoading && updatedQA.length === 0 && idSelectedDocument !== 'yZI5jQzrvGbTsid0kvk6')
    return (
      <MaterialsFlashcardsNotFound message={
        isLegacyFormat 
          ? 'Nessuna domanda di ripetizione trovata nel documento selezionato'
          : 'Nessuna flashcard trovata nel documento selezionato'
      } />
    )

  const availableCards = isPremium ? updatedQA.length : Math.min(updatedQA.length, FREE_FLASHCARD_LIMIT);
  const lockedCards = updatedQA.length - availableCards;

  return (
    <div className='MaterialsFlashcards'>
      {/* Stats Section */}
      <div className="MaterialsFlashcards__stats">
        <div className="MaterialsFlashcards__stats-header">
          <div 
            className={`MaterialsFlashcards__stats-icon ${!isPremium ? 'MaterialsFlashcards__stats-icon--disabled' : ''}`}
            onClick={handleDownload}
            title={isPremium ? "Scarica flashcard" : "Funzione Premium"}
          >
            {isPremium ? <HiDownload size={24} /> : <HiLockClosed size={24} />}
          </div>
          <div className="MaterialsFlashcards__stats-info">
            <div className="MaterialsFlashcards__stats-details">
              <div className="MaterialsFlashcards__stats-item">
                {updatedQA.length} {isPremium ? 'flashcard disponibili' : 'flashcard'}
              </div>
              {!isPremium && (
                <div className="MaterialsFlashcards__stats-item MaterialsFlashcards__stats-item--premium">
                  <strong>{availableCards} / {updatedQA.length}</strong> flashcard
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {!isPremium && lockedCards > 0 && (
        <div className="MaterialsFlashcards__premium-banner">
          <div className="MaterialsFlashcards__premium-banner-content">
            <div className="MaterialsFlashcards__premium-banner-header">
              <HiLockClosed className="MaterialsFlashcards__premium-banner-icon" />
              <h6>Versione gratuita</h6>
            </div>
            <p>Hai accesso a {availableCards} flashcard su {updatedQA.length}. Passa a Premium per sbloccarle tutte e scaricarle.</p>
            <button
              className="MaterialsFlashcards__premium-banner-button animate-button"
              onClick={() => {
                navigate('/home/piani');
                setTimeout(() => {
                  window.scrollTo(0, 0);
                }, 100);
              }}
            >
              <span>Aggiorna piano</span>
              <HiArrowRight className="MaterialsFlashcards__premium-banner-button-icon" />
            </button>
          </div>
        </div>
      )}

      <div className='MaterialsFlashcards__container row gy-xs-1 g-md-1 g-lg-3'>
        {updatedQA.map((flashcard, index) => (
          <Flashcard 
            key={index} 
            question={flashcard.card_question || flashcard.question} 
            answer={flashcard.card_answer || flashcard.answer}
            tags={flashcard.tags}
            isLocked={!isPremium && index >= FREE_FLASHCARD_LIMIT}
          />
        ))}
      </div>
    </div>
  )
}

// MaterialFlashcards propTypes
MaterialFlashcards.propTypes = {
  idSelectedDocument: PropTypes.string,
  isLegacyFormat: PropTypes.bool
}

export default MaterialFlashcards