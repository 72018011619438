import './answersControls.scss';
import { useEffect, useState } from 'react';

// Onboarding
import { Steps } from "intro.js-react";
import { useSelector } from 'react-redux';
import useGetOnboardingState from '../../../uploadNotes/uploadNotesCard/uploadNotesForm/hooks/useGetOnboardingState';
import "intro.js/introjs.css";

// Aggiungi import per il nuovo componente
import HintButton from './HintButton';

const AnswersControls = ({ 
  showChat, 
  examState, 
  isExamStarted, 
  setShowChat, 
  MessageCircle, 
  MicOff, 
  Mic, 
  isRecording, 
  toggleRecording, 
  startExam,
  currentHints = ['Nessun suggerimento disponibile', 'Nessun suggerimento disponibile'],
  reviewMode
}) => {
  const currentStep = useSelector(state => state.onboarding.currentStep);
  const [stepsEnabled, setStepsEnabled] = useState(currentStep === 10 ? true : false);  
  const { updateCurrentStep, getCurrentStep } = useGetOnboardingState();

  useEffect(() => {
    getCurrentStep();
  }, [getCurrentStep, currentStep]);
  
  const steps = [
    currentStep === 10 && {
      element: '.controls',
      intro: "Clicca sul microfono o la chat per rispondere alle domande",
    },
  ].filter(Boolean);

  const onExit = async () => {
    setStepsEnabled(false);
  };

  const handleToggleRecording = () => {
    if(currentStep === 10) {
      updateCurrentStep(11);
    };
    setShowChat(false);
    setStepsEnabled(false);
    toggleRecording();
  };

  useEffect(() => {
    const helperLayer = document.querySelector('.introjs-helperLayer');
    if (helperLayer) {
      helperLayer.style.marginTop = '0px';
    }
    
    const tooltip = document.querySelector('.introjs-tooltip');
    if (tooltip) {
      tooltip.style.marginTop = "0px";  
    }
  }, [stepsEnabled]);

  const handleOpenChat = () => {
    if(currentStep === 10) {
      updateCurrentStep(12);
      setStepsEnabled(false);
    };

    if(examState === 'listening' || examState === 'waiting') {
      setShowChat(!showChat);
    };
    
    if(!isExamStarted) {
      startExam();
    };
  };

  return (
    <>
      <Steps
        enabled={stepsEnabled}
        steps={steps}
        initialStep={0}
        onExit={onExit}
      />
      {examState !== 'answered' && (
        <>
          {reviewMode && currentHints[0] !== 'Nessun suggerimento disponibile' && showChat === false && (
            <HintButton 
              currentHints={currentHints} 
              examState={examState} 
              isRecording={isRecording} 
              showChat={showChat} 
            />
          )}
          
          <div className={`controls ${showChat ? "controls chat-active" :  ""}`}>
            <span
              className={`control-button mic ${
                isRecording ? 'recording' : 'not-recording'
              } ${examState === 'processing' ? 'mic-inactive' : ''}`}
              onClick={handleToggleRecording}
              disabled={examState === 'answered' || examState === 'listening' || examState === 'waiting'}
              data-tooltip={isRecording ? "Ferma registrazione" : "Inizia registrazione"}
            >
              {isRecording ? <MicOff size={24} /> : <Mic size={24} />}
            </span>
            <span
              className={`control-button chat ${isRecording || examState === 'processing' ? 'inactive' : ''}`}
              onClick={handleOpenChat}
              disabled={isRecording}
              data-tooltip="Apri chat"
            >
              <MessageCircle size={24} />
            </span>
          </div>
        </>
      )}
    </>
  )
}

export default AnswersControls;