import "./index.scss";
import useGetQA from "./hooks/useGetQA";

import ChatLoader from "./chatLoader/chatLoader";
import ChatNotFound from "./chatNotFound/chatNotFound";
import ChatCard from "./chatCard/ExamCard";

const Chat = () => {
  const { isLoading, qa, currentQA, documentID } = useGetQA();

  // return spinner when qa is loading
  if (isLoading) return <ChatLoader />;

  return (
    <div className="Chat">
      <div className="container">
        <div className="justify-center ">
          {!qa.length && <ChatNotFound />}
          {qa.length > 0 && <ChatCard qa={qa} currentQA={currentQA} documentID={documentID} />}
        </div>
      </div>
    </div>
  );
};

export default Chat;
