import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const useSelectDocument = () => {
  const [selectedDocument, setSelectedDocument] = useState(null);
  const documents = useSelector(state => state.document.documents);

  useEffect(() => {
    // If the selected document is deleted, clear the selection
    if (selectedDocument && selectedDocument !== 'yZI5jQzrvGbTsid0kvk6' && !documents.find(doc => doc.id === selectedDocument.id)) {
      setSelectedDocument(null)
    }
    // If no document is selected and there are documents, select the first one
    if (!selectedDocument && documents.length > 0) {
      setSelectedDocument(documents[0]);
    }
  }, [documents, selectedDocument]);

  const changeDocumentId = (documentId) => {
    const document = documents.find(doc => doc.id === documentId);
    if(documentId === 'yZI5jQzrvGbTsid0kvk6') {
      setSelectedDocument('yZI5jQzrvGbTsid0kvk6');
    } else {
      setSelectedDocument(document || null);
    }
  };

  return { selectedDocument, changeDocumentId };
};

export default useSelectDocument;
