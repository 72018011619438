/**
 * Utility function to format dates consistently across folder components
 * Handles both Firestore timestamp objects and regular Date objects/strings
 */
export const formatDate = (date) => {
  try {
    // Gestione dei timestamp di Firestore
    if (date && typeof date === 'object' && date.seconds) {
      // È un timestamp di Firestore
      const d = new Date(date.seconds * 1000 + (date.nanoseconds || 0) / 1000000);
      const day = d.getDate().toString().padStart(2, '0');
      const month = (d.getMonth() + 1).toString().padStart(2, '0');
      const year = d.getFullYear();
      const hours = d.getHours().toString().padStart(2, '0');
      const minutes = d.getMinutes().toString().padStart(2, '0');
      return `${day}/${month}/${year} ${hours}:${minutes}`;
    } 
    // Gestione delle stringhe ISO o oggetti Date
    else {
      const d = new Date(date);
      if (isNaN(d.getTime())) {
        return 'Data non disponibile';
      }
      const day = d.getDate().toString().padStart(2, '0');
      const month = (d.getMonth() + 1).toString().padStart(2, '0');
      const year = d.getFullYear();
      const hours = d.getHours().toString().padStart(2, '0');
      const minutes = d.getMinutes().toString().padStart(2, '0');
      return `${day}/${month}/${year} ${hours}:${minutes}`;
    }
  } catch (error) {
    console.error('Errore nella formattazione della data:', error, date);
    return 'Data non disponibile';
  }
};

export default formatDate;
