import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import '../ExamChat/examChat.scss';

const ExamChat = ({
  showChat,
  chatMessages,
  Send,
  inputRef,
  isExamFinished,
  handleGetFeedback,
  examState,

  //form props
  register,
  isSubmitting,
  handleSubmit,
  disabledButton,
}) => {

  const chatRef = useRef(null);

  const scrollToBottom = () => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    if (showChat || isSubmitting) {
      scrollToBottom();
    }
  }, [chatMessages, showChat, isSubmitting, inputRef]);

  return (
    <div className={showChat ? "chat-container" :"chat-container-inactive"}>
      {showChat && (
        <div className={clsx("", (isSubmitting || isExamFinished) && "chat-container--disabled")}>
          <div ref={chatRef} className="chat-messages ">
            {chatMessages.map((message, index) => (
              <div
                key={index}
                className={`message ${message.sender === "user" ? "user" : "response"}`}
              >
                {message.text}
              </div>
            ))}
          </div>
          <div className="input-container">
            <input
              ref={inputRef}
              id="answer"
              className="chat-input"
              placeholder="Scrivi la tua risposta..."
              onKeyPress={(e) => {
                if (e.key === "Enter") handleSubmit(handleGetFeedback);
              }}
              {...register("answer")}
            />
            <button
              className={`send-button ${examState === 'processing' && 'inactive-btn'}`}
              type="submit"
              disabled={examState === 'processing'}
            >
              <Send size={20} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

ExamChat.propTypes = {
  showChat: PropTypes.bool.isRequired,
  chatMessages: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      sender: PropTypes.string.isRequired,
    })
  ).isRequired,
  inputRef: PropTypes.object.isRequired,
  Send: PropTypes.elementType.isRequired,
  isExamFinished: PropTypes.bool.isRequired,
  messageID: (props, propName, componentName) => {
    if (props.reviewMode === true && (props[propName] === undefined || typeof props[propName] !== 'string')) {
      return new Error(`Prop '${propName}' is required when reviewMode is true or undefined in ${componentName}`);
    }
  },
  onAddMessage: PropTypes.func.isRequired,
  onCreateFeedback: PropTypes.func.isRequired,
  onFeedbackError: PropTypes.func.isRequired,
  handleGetFeedback: PropTypes.func.isRequired,
};

export default ExamChat;


