import React from 'react';
import PropTypes from 'prop-types';
import { Folder as FolderIcon } from 'lucide-react';
import clsx from 'clsx';
import Heading from '@ui/heading/heading';
import './FolderCard.scss';
import { getFolderColor } from '../utils/folderColors';

/**
 * Base component for folder card UI that can be reused across different folder types
 */
const FolderCard = ({
  name,
  documentCount,
  createdAt,
  color = 'blue',
  isActive,
  children,
  onClick,
  className,
  editMode = false,
  editInput = null
}) => {
  const folderColor = getFolderColor(color);
  
  return (
    <div
      className={clsx('FolderCard', isActive && 'FolderCard--active', className)}
      onClick={onClick}
    >
      <div className='Document__card'>
        <div className='Document__row'>
          <FolderIcon 
            className='FolderCard__icon' 
            size={44} 
            strokeWidth={1.5}
            style={{ color: folderColor.icon }}
          />
          {children && (
            <div className="Document__actions">
              {children}
            </div>
          )}
        </div>

        <div className='Document__box'>
          {editMode ? (
            editInput
          ) : (
            <Heading 
              heading='h6' 
              title={name.trim().length > 20 ? `${name.slice(0,18)}...` : name} 
              className='FolderCard__name'
            />
          )}
          {createdAt && (
            <p className='Document__box-text'>
              <time dateTime={createdAt}>{createdAt}</time>
            </p>
          )}
        </div>
        
        {documentCount !== undefined && (
          <div className="FolderCard__tags">
            <div>
              <span 
                className="FolderCard__document-count"
                style={{ 
                  backgroundColor: folderColor.count,
                  color: folderColor.countText,
                  borderColor: folderColor.countBorder
                }}
              >
                {documentCount === 0
                  ? 'Vuota'
                  : documentCount === 1
                    ? '1 documento'
                    : `${documentCount} documenti`}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

FolderCard.propTypes = {
  name: PropTypes.string,
  documentCount: PropTypes.number,
  createdAt: PropTypes.string,
  color: PropTypes.string,
  isActive: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
  className: PropTypes.string,
  editMode: PropTypes.bool,
  editInput: PropTypes.node
};

export default FolderCard;