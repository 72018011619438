// Definizione delle action types
export const SET_PODCAST_LOADING = 'SET_PODCAST_LOADING';
export const SET_PODCAST_ERROR = 'SET_PODCAST_ERROR';
export const SET_PODCAST = 'SET_PODCAST';
export const CLEAR_CURRENT_PODCAST = 'CLEAR_CURRENT_PODCAST';

// Action creators
export const setPodcastLoading = (isLoading) => ({
  type: SET_PODCAST_LOADING,
  payload: isLoading
});

export const setPodcastError = (error) => ({
  type: SET_PODCAST_ERROR,
  payload: error
});

export const setPodcast = (payload) => ({
  type: SET_PODCAST,
  payload
});

export const clearCurrentPodcast = () => ({
  type: CLEAR_CURRENT_PODCAST
});

// Stato iniziale
const initialState = {
  podcasts: {},
  currentPodcast: null,
  isLoading: false,
  error: null
};

// Reducer
const podcastReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PODCAST_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };
    case SET_PODCAST_ERROR:
      return {
        ...state,
        error: action.payload
      };
    case SET_PODCAST: {
      const { documentId, podcastData } = action.payload;
      return {
        ...state,
        podcasts: documentId ? {
          ...state.podcasts,
          [documentId]: podcastData
        } : state.podcasts,
        currentPodcast: podcastData
      };
    }
    case CLEAR_CURRENT_PODCAST:
      return {
        ...state,
        currentPodcast: null
      };
    default:
      return state;
  }
};

export default podcastReducer; 