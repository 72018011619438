import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { Award } from 'lucide-react';
import { useLocation } from 'react-router-dom';
import ExamSummary from './examSummary/examSummary';
import QuestionsSummary from './questionsSummary/questionsSummary';
import ExamReportAnswers from './examReportAnswers/examReportAnswers';
import ExamFeedback from './examFeedback/ExamFeedback';
import { getFeedbackWithAssistant } from '../../services/openaiApi';
import { useSelector } from 'react-redux';
import Spinner from '@ui/spinner/spinner';
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "@redux/firebase";
import './index.scss';

// Defining StudierAI's color palette
const colors = {
  primary: '#92C37E',
  secondary: '#E5E7EB',
  text: '#333333',
  background: '#FFFFFF',
  error: '#EF4444',
  nextExam: '#dee9e7',
  answerBackground: '#F0F9FF',
  feedbackBackground: '#FDF6B2',
  correct: '#16A342',
  partial: '#FCD34D',
  incorrect: '#DC2626',
};

const ExamResult = () => {
  const { state } = useLocation();

  const [filter, setFilter] = useState('all');
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [partialAnswers, setPartialAnswers] = useState(0);
  const [incorrectAnswers, setIncorrectAnswers] = useState(0);
  const [skippedAnswers, setSkippedAnswers] = useState(0);
  const [interrogationScore, setInterrogationScore] = useState(0);
  const [feedback, setFeedback] = useState(null);
  const [examFeedback, setExamFeedback] = useState(null);

  const totalQuestions = useRef(state.messages.length);
  const interrogationQA = useSelector(state => state.exam.interrogationQA);
  const totalQuestionsInterrogation = useMemo(() => {
    if (state.qa.length > 10) {
      // Calcola una selezione casuale di massimo 10 domande
      return 10;
    } else {
      // Filtra normalmente le domande
      return state.qa.filter(qa => qa.isHelpful !== false && !qa.isFlashcard).length;
    }
  }, [interrogationQA, state]);
  

  const calculatedData = useMemo(() => {
    if(interrogationQA?.length > 0) return;
    
    let correctCount = 0;
    let partialCount = 0;
    let incorrectCount = 0;
    let skippedCount = 0;
    let totalScore = 0;
    let skippedQuestions = 0;

    const questions = state.messages.map((item, questionIndex) => {
      const [key, messages] = item;
      const questionScore = messages[3]?.score ? messages[3]?.score : messages[2]?.score || 'skipped';
      const status = questionScore === 'skipped' ? 'skipped' : questionScore >= 7 ? 'correct' : questionScore > 5 ? 'partial' : 'incorrect';

      if (status === 'skipped') {
        skippedQuestions += 1;
        skippedCount += 1;
      } else {
        totalScore += questionScore !== 'skipped' ? questionScore : 0;
      }

      if (status === 'correct') correctCount += 1;
      if (status === 'partial') partialCount += 1;
      if (status === 'incorrect') incorrectCount += 1;

      return { messages, status, questionIndex: questionIndex };
    });

    totalQuestions.current = state.messages.length - skippedQuestions;

    let average = totalQuestions.current === 0 ? 0 : totalScore;
    average = average / totalQuestions.current;

    // To avoid periodic numbers
    if (average % 1 !== 0) {
      average = Math.floor(average * 10) / 10;
    }

    return {
      questions,
      correctCount,
      partialCount,
      incorrectCount,
      skippedCount,
      average
    };
  }, [state.messages, interrogationQA.length]);

  const calculateInterrogationData = useCallback((questions) => {
    let interrogationCorrectCount = 0;
    let interrogationPartialCount = 0;
    let interrogationIncorrectCount = 0;
    console.log(questions)
    const interrogationQuestions = questions?.map((item, index) => {
      const question = interrogationQA[index].question;
      const answer = interrogationQA[index].answer;
      const feedback = item.feedback;
      const score = item.score;
      const status = score >= 7 ? 'correct' : score > 5 ? 'partial' : 'incorrect';
  
      if (status === 'correct') interrogationCorrectCount += 1;
      if (status === 'partial') interrogationPartialCount += 1;
      if (status === 'incorrect') interrogationIncorrectCount += 1;
  
      return { index, question, answer, feedback, score, status };
    });
  
    return {
      interrogationQuestions,
      interrogationCorrectCount,
      interrogationPartialCount,
      interrogationIncorrectCount,
    };
  }, [interrogationQA]);

  useEffect(() => {
    if(interrogationQA?.length === 0) {
      setCorrectAnswers(calculatedData.correctCount);
      setPartialAnswers(calculatedData.partialCount);
      setIncorrectAnswers(calculatedData.incorrectCount);
      setSkippedAnswers(calculatedData.skippedCount);
      setFilteredQuestions(calculatedData.questions);
    }
  }, [calculatedData, interrogationQA.length]);

  useEffect(() => {
    const evaluation = feedback?.evaluation || [];
    const qa = interrogationQA?.length > 0
      ? calculateInterrogationData(evaluation)?.interrogationQuestions || []
      : calculatedData?.questions;
    const filtered = qa.filter((item) => {
      if (filter === 'all') return true;
      return item.status === filter;
    });
    setFilteredQuestions(filtered);
  }, [filter, calculatedData?.questions, feedback, interrogationQA.length]);

  const getBorderColor = useCallback((score) => {
    if (score >= 7) return colors.correct;
    if (score > 5) return colors.partial;
    if (score <= 5) return colors.incorrect;
    return colors.error;
  }, []);
  
  
  useEffect(() => {
    if ((interrogationQA?.length === totalQuestionsInterrogation) && !state.reviewMode) {
      const fetchFeedback = async () => {
        const feedback = await getFeedbackWithAssistant(interrogationQA);
        setFeedback(feedback);
        console.log('Received feedback:', feedback);
        
        // Estrai i dati dalla nuova struttura
        const evaluation = feedback.evaluation || [];
        const finalFeedback = feedback.final_feedback || {};
        setExamFeedback(finalFeedback);

        const interrogationData = calculateInterrogationData(evaluation);

        setCorrectAnswers(interrogationData.interrogationCorrectCount);
        setPartialAnswers(interrogationData.interrogationPartialCount);
        setIncorrectAnswers(interrogationData.interrogationIncorrectCount);
        setFilteredQuestions(interrogationData.interrogationQuestions);

        let totalScore = finalFeedback.overall_score || 
            evaluation.reduce((acc, curr) => acc + curr.score, 0) / evaluation.length;
        if (totalScore % 1 !== 0) {
          totalScore = Math.floor(totalScore * 10) / 10;
        }
        setInterrogationScore(totalScore);
      };
      fetchFeedback();
    }
  }, [interrogationQA]);

  useEffect(() => {
    const checkFirstExam = async () => {
      try {
        const userId = JSON.parse(localStorage.getItem("userId"));
        if (!userId) return;

        const studentRef = doc(firestore, "students", userId);
        const studentSnap = await getDoc(studentRef);

        if (studentSnap.exists()) {
          const studentData = studentSnap.data();
          if (studentData.examsAttempted === 0) {
            if (typeof window.gtag === 'function') {
              window.gtag('event', 'firstExamResult', {
                'event_category': 'Exam',
                'event_label': 'First Exam Completed',
                'email': studentData.email
              });
            }
          }
        }
      } catch (error) {
        console.error("Error checking first exam:", error);
      }
    };

    checkFirstExam();
  }, []);

  if(interrogationQA?.length > 0 && !feedback) return (
    <div className="ExamResult-container__exam-result-spinner-container"> 
      <Spinner />
      <span className="ExamResult-container__exam-result-spinner-container__text">Caricamento Risultato Interrogazione...</span>
    </div>
  );

  return (
    <div className="ExamResult-container__exam-result">
      <div 
        className="ExamResult-container__exam-result__content" 
        style={{ borderTopColor: getBorderColor(interrogationQA?.length > 0 ? interrogationScore : calculatedData?.average) }}
      >
        <div className="ExamResult-container__exam-result__heading">
          <h2>
            <Award size={20} style={{ color: getBorderColor(interrogationQA?.length > 0 ? interrogationScore : calculatedData?.average) }} /> 
            {interrogationQA?.length > 0 ? "Risultato Interrogazione" : "Risultato Ripetizione"}
          </h2>
        </div>
    
        <ExamSummary 
          score={interrogationQA?.length > 0 ? interrogationScore : calculatedData?.average}
          totalQuestions={state.messages.length}
        />
    
        <QuestionsSummary 
          totalQuestions={interrogationQA?.length > 0 ? interrogationQA.length : state.messages.length}
          filter={filter}
          setFilter={setFilter}
          filteredQuestions={filteredQuestions}
          colors={colors}
          correctAnswers={interrogationQA?.length > 0 ? calculateInterrogationData(feedback?.evaluation).interrogationCorrectCount : correctAnswers}
          partialAnswers={interrogationQA?.length > 0 ? calculateInterrogationData(feedback?.evaluation).interrogationPartialCount : partialAnswers}
          incorrectAnswers={interrogationQA?.length > 0 ? calculateInterrogationData(feedback?.evaluation).interrogationIncorrectCount : incorrectAnswers}
          skippedAnswers={interrogationQA?.length > 0 ? 0 : skippedAnswers}
          interrogationQA={interrogationQA}
        />
        {interrogationQA?.length > 0 &&
          <ExamFeedback examFeedback={examFeedback} />
        }

        <ExamReportAnswers 
          filteredQuestions={filteredQuestions}
          interrogationQA={interrogationQA}
          colors={colors}
          state={state}
          averageScore={interrogationQA?.length > 0 ? interrogationScore : calculatedData?.average}
        />
      </div>
    </div>
  );
};

export default ExamResult;
