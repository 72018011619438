import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { HiLockClosed, HiPlay, HiArrowRight, HiPause, HiRefresh, HiDownload } from 'react-icons/hi';
import { FaFlask } from 'react-icons/fa';
import Button from '@ui/button/button';
import Spinner from '@ui/spinner/spinner';
import { useNavigate } from 'react-router-dom';
import './AudioSummary.scss';
import AudioPlayer from './AudioPlayer';

const AudioSummary = ({ 
  isPremium, 
  isGeneratingAudio, 
  handleGenerateAudio,
  podcastAudio = null,
  isLoadingPodcast = false
}) => {
  const navigate = useNavigate();
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioElement, setAudioElement] = useState(null);
  const [audioError, setAudioError] = useState(false);

  const handleUpgradePlan = () => {
    navigate('/home/piani');
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  };

  const handlePlayPause = () => {
    if (!audioElement) return;
    
    if (isPlaying) {
      audioElement.pause();
    } else {
      audioElement.play().catch(error => {
        console.error('Errore nella riproduzione audio:', error);
        setAudioError(true);
      });
    }
    
    setIsPlaying(!isPlaying);
  };

  const handleDownload = () => {
    if (!podcastAudio) return;
    
    let blob;
    
    // Se abbiamo direttamente il blob, usalo
    if (podcastAudio.audio_blob) {
      blob = podcastAudio.audio_blob;
    } 
    // Altrimenti, se abbiamo un URL, crea un link per il download
    else if (podcastAudio.audio_url) {
      // Per URL locali (blob:) non serve fare nulla di speciale
      const a = document.createElement('a');
      a.href = podcastAudio.audio_url;
      a.download = podcastAudio.filename || 'podcast_audio.mp3';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      return;
    } else {
      console.error('Nessun audio disponibile per il download');
      return;
    }
    
    // Crea un URL per il blob e avvia il download
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = podcastAudio.filename || 'podcast_audio.mp3';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  useEffect(() => {
    // Reset dello stato quando cambia podcastAudio
    setAudioError(false);
    
    if (podcastAudio?.audio_url) {
      try {
        console.log("Tentativo di caricamento audio da URL:", podcastAudio.audio_url);
        
        // Verifichiamo che l'URL sia valido
        if (!podcastAudio.audio_url.startsWith('http') && !podcastAudio.audio_url.startsWith('blob:')) {
          console.error('URL audio non valido:', podcastAudio.audio_url);
          setAudioError(true);
          return;
        }
        
        // Creiamo un elemento audio con l'URL originale, senza modifiche
        // Questo risolve il problema degli errori di console
        const audio = new Audio();
        
        // Evitiamo di loggare errori se siamo in fase di cleanup o cambio URL
        let isAudioElementActive = true;
        
        audio.addEventListener('ended', () => {
          if (isAudioElementActive) {
            console.log('Audio terminato');
            setIsPlaying(false);
          }
        });
        
        audio.addEventListener('error', (e) => {
          // Solo se l'elemento è ancora attivo, gestiamo l'errore
          if (isAudioElementActive) {
            // Creiamo un errore più silenzioso che non appaia nella console
            console.error('Errore durante il caricamento dell\'audio:', e);
            
            // Impostiamo un flag per evitare un loop di errori
            if (!audio._hasErrored) {
              audio._hasErrored = true;
              
              // Se l'elemento audio esiste ancora e non è stato distrutto
              // in un cleanup, proviamo a ricaricare l'URL in modo silenzioso
              console.log('Primo errore, tentativo di ricaricamento automatico...');
              setTimeout(() => {
                if (isAudioElementActive && audio) {
                  // Tentiamo di ricaricare l'audio
                  console.log('Ricaricamento URL audio:', podcastAudio.audio_url);
                  audio.src = podcastAudio.audio_url;
                  audio.load();
                }
              }, 500);
            } else {
              // Se abbiamo già tentato di ricaricare e ancora c'è un errore
              if (isAudioElementActive) {
                console.error('Errore persistente nel caricamento dell\'audio dopo il tentativo di ricaricamento');
                setAudioError(true);
              }
            }
          }
        });
        
        // Aggiungi un gestore di successo per debug
        audio.addEventListener('canplaythrough', () => {
          if (isAudioElementActive) {
            console.log('Audio caricato con successo e pronto per la riproduzione');
          }
        });
        
        // Imposta l'URL dell'audio
        audio.src = podcastAudio.audio_url;
        audio.load();
        
        setAudioElement(audio);
        
        return () => {
          // Disabilitiamo la gestione degli eventi durante il cleanup
          console.log('Cleanup elemento audio');
          isAudioElementActive = false;
          
          if (audio) {
            // Rimuoviamo tutti gli event listener prima di distruggere l'elemento
            audio.onended = null;
            audio.onerror = null;
            audio.oncanplaythrough = null;
            
            // Fermiamo e puliamo l'audio
            audio.pause();
            audio.src = '';
            
            // Preveniamo memory leak
            setTimeout(() => {
              try {
                if (audio) {
                  audio.remove();
                }
              } catch (err) {
                // Ignoriamo eventuali errori durante la cleanup
                console.debug('Errore durante la cleanup dell\'elemento audio:', err);
              }
            }, 100);
          }
        };
      } catch (error) {
        console.error('Errore nella creazione dell\'elemento audio:', error);
        setAudioError(true);
      }
    } else {
      console.log('Nessun URL audio disponibile nel podcast:', podcastAudio);
    }
  }, [podcastAudio]);
  
  // Semplifichiamo la funzione di retry per utilizzare l'approccio che funziona
  const handleRetryAudio = () => {
    setAudioError(false);
    
    if (!podcastAudio?.audio_url) {
      console.error('Impossibile ricaricare l\'audio: URL mancante');
      return;
    }
    
    console.log('Tentativo di ricaricamento manuale dell\'audio:', podcastAudio.audio_url);
    
    try {
      // Creiamo un nuovo elemento audio con l'URL originale
      const audio = new Audio();
      
      audio.addEventListener('ended', () => {
        console.log('Audio terminato dopo ricaricamento');
        setIsPlaying(false);
      });
      
      audio.addEventListener('error', (e) => {
        console.error('Errore nel ricaricamento audio:', e);
        // Aggiungiamo un tentativo aggiuntivo con un timeout
        setTimeout(() => {
          try {
            console.log('Secondo tentativo di ricaricamento dopo errore...');
            audio.src = podcastAudio.audio_url;
            audio.load();
          } catch (retryError) {
            console.error('Errore nel secondo tentativo di ricaricamento:', retryError);
            setAudioError(true);
          }
        }, 1000);
      });
      
      audio.addEventListener('canplaythrough', () => {
        console.log('Audio ricaricato con successo dopo retry manuale');
      });
      
      // Usa l'URL originale che sappiamo funzionare
      audio.src = podcastAudio.audio_url;
      audio.load();
      setAudioElement(audio);
      
    } catch (error) {
      console.error('Errore nel ricaricamento audio:', error);
      setAudioError(true);
    }
  };

  const renderContent = () => {
    if (!isPremium) {
      return (
        <div className="AudioSummary__premium-banner">
          <div className="AudioSummary__premium-banner-content">
            <div className="AudioSummary__premium-banner-header">
              <HiLockClosed className="AudioSummary__premium-banner-icon" />
              <h6>Versione gratuita</h6>
            </div>
            <p>Passa a Premium per sbloccare la funzionalità di podcast audio.</p>
            <button 
              className="AudioSummary__premium-banner-button animate-button"
              onClick={handleUpgradePlan}
            >
              <span>Aggiorna il tuo piano</span>
              <HiArrowRight className="AudioSummary__premium-banner-button-icon" />
            </button>
          </div>
        </div>
      );
    }

    if (!podcastAudio) {
      return (
        <Button
          className="AudioSummary__button"
          variant="primary"
          size="md"
          onClick={handleGenerateAudio}
          disabled={isGeneratingAudio}
        >
          {isGeneratingAudio ? (
            <>
              <Spinner size="sm" /> Generazione...
            </>
          ) : (
            <>
              <HiPlay /> Genera Podcast Audio
            </>
          )}
        </Button>
      );
    }

    if (audioError) {
      return (
        <div className="AudioSummary__error">
          <p>Si è verificato un errore durante il caricamento dell'audio. Questo potrebbe essere causato da:</p>
          <ul>
            <li>Problemi di connessione al server</li>
            <li>Formato audio non supportato dal browser</li>
            <li>Token di accesso scaduto</li>
            <li>Problemi di cache del browser</li>
          </ul>
          <div className="AudioSummary__error-actions">
            <Button
              className="AudioSummary__button"
              variant="secondary"
              size="md"
              onClick={handleRetryAudio}
            >
              <HiRefresh /> Riprova a caricare
            </Button>
            <Button
              className="AudioSummary__button"
              variant="primary"
              size="md"
              onClick={handleGenerateAudio}
              disabled={isGeneratingAudio}
            >
              <HiRefresh /> Rigenera Podcast
            </Button>
          </div>
        </div>
      );
    }

    if (isLoadingPodcast) {
      return (
        <div className="AudioSummary__loading">
          <Spinner size="medium" />
          <p>Caricamento podcast...</p>
        </div>
      );
    }

    return (
      <div className="AudioSummary__player">
        <AudioPlayer 
          audioElement={audioElement} 
          isPlaying={isPlaying} 
          togglePlayPause={handlePlayPause}
          audioUrl={podcastAudio?.audio_url}
        />
        
        <div className="AudioSummary__player-actions">
          {podcastAudio?.audio_url && !podcastAudio?.local_only && (
            <Button
              className="AudioSummary__button Btn--primary AudioSummary__button--animated"
              variant="primary"
              size="md"
              onClick={handleDownload}
            >
              <HiDownload /> Scarica
            </Button>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="AudioSummary">
      <div className={`AudioSummary__box ${!isPremium ? 'AudioSummary__box--free' : ''}`}>
        <h3 className="AudioSummary__title">
          Podcast Audio <span className="AudioSummary__beta-tag"><FaFlask className="AudioSummary__beta-tag-icon" />Beta</span>
        </h3>
        <p className="AudioSummary__description">
          Ascolta il riassunto dei documenti in formato podcast approfondito per studiare meglio in movimento.
          {podcastAudio?.local_only && <span className="AudioSummary__local-info"> Questo podcast è disponibile solo in questa sessione e non verrà salvato.</span>}
          {podcastAudio?.storage_only && !podcastAudio.error_message?.includes("Missing or insufficient permissions") && <span className="AudioSummary__partial-info"> Questo podcast è stato salvato parzialmente e potrebbe non essere disponibile in futuro.</span>}
          {podcastAudio?.storage_only && podcastAudio.error_message?.includes("Missing or insufficient permissions") && <span className="AudioSummary__storage-info"> Questo podcast è salvato su Firebase Storage ed è disponibile per l'ascolto.</span>}
        </p>
        
        {podcastAudio?.error_message && (
          <div className={`AudioSummary__${podcastAudio.error_message.includes("Missing or insufficient permissions") ? "info-message" : "error-message"}`}>
            <p><strong>{podcastAudio.error_message.includes("Missing or insufficient permissions") ? "Informazione:" : "Nota:"}</strong> {podcastAudio.error_message}</p>
            {podcastAudio.error_message.includes("Missing or insufficient permissions") && (
              <p className="AudioSummary__info-tip">
                L'audio è disponibile e puoi ascoltarlo normalmente. Il problema riguarda solo il salvataggio dei metadati, non l'audio stesso.
              </p>
            )}
          </div>
        )}
        
        <div className="AudioSummary__buttons">
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

AudioSummary.propTypes = {
  isPremium: PropTypes.bool.isRequired,
  isGeneratingAudio: PropTypes.bool.isRequired,
  handleGenerateAudio: PropTypes.func.isRequired,
  podcastAudio: PropTypes.object,
  isLoadingPodcast: PropTypes.bool
};

export default AudioSummary; 