import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useFetcher, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useYupValidationResolver, signInSchema } from "@utility/validationsSchema";
import useShowPassword from "@hooks/useShowPassword";
import { useDispatch } from "react-redux";
import { setUser } from "@redux/actions/authActions";

const useSignIn = () => {
  const resolver = useYupValidationResolver(signInSchema);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver });
  const fetcher = useFetcher();
  const { showPassword, handleShowPassword } = useShowPassword();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // display error message and handle redirect
  useEffect(() => {
    if (!fetcher.data) return;
    
    if (fetcher.data.isSuccessful) {
      if (fetcher.data.userData) {
        dispatch(setUser(fetcher.data.userData));
      }
      if (fetcher.data.redirectTo) {
        navigate(fetcher.data.redirectTo, { replace: true });
      }
      reset();
      return;
    }

    if (fetcher.data.message) {
      toast.error(fetcher.data.message);
    }
  }, [fetcher.data, reset, navigate, dispatch]);

  return {
    Form: fetcher.Form,
    register,
    errors,
    isSubmitting: fetcher.state === "submitting",
    handleSubmit,
    submit: fetcher.submit,
    showPassword,
    handleShowPassword,
  };
};

export default useSignIn;
