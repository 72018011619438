import { Book, Info, MessageCircle, X, XCircle } from "lucide-react";
import { HiLockClosed } from "react-icons/hi";
import { useSelector } from "react-redux";
import { FaFlask } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "./menuExamMode.scss";

export const MenuExamMode = ({ setShowModal, onStartPreparation, reviewMode, setReviewMode }) => {
  const { user } = useSelector(state => state.auth);
  const isPremium = user?.plan === 'Premium';
  const navigate = useNavigate();
  
  // Funzione per navigare e garantire lo scroll all'inizio della pagina
  const navigateToPlans = (e) => {
    e.stopPropagation();
    navigate('/home/piani');
    // Imposta un timeout breve per assicurarsi che la navigazione sia completata
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  };
  
  return (
    <div className="MenuExamMode">
      <header className="MenuExamMode__header">
        <div className="MenuExamMode__header__title-container">
          <h1 className="MenuExamMode__header__title">Modalità Preparazione</h1>
          <span className="MenuExamMode__header__subtitle">Scegli come vuoi esercitarti</span>
        </div>
        <X size={18} className="MenuExamMode__header__close-button" onClick={() => setShowModal(false)} />
      </header>

      <div onClick={() => setReviewMode(true)} className={`MenuExamMode__review-mode ${reviewMode ? "MenuExamMode__review-mode--active" : ""}`}>
        <div className="MenuExamMode__review-mode-title-container">
          <Book className="MenuExamMode__review-mode-icon" size={28} />
          <h3 className="MenuExamMode__review-mode-title">Ripetizione</h3>
        </div>

        <ul className="MenuExamMode__review-mode-list">
          <li className="MenuExamMode__review-mode-list-item">Ripassa gli argomenti in modo approfondito</li>
          <li className="MenuExamMode__review-mode-list-item">Tempo illimitato per le risposte</li>
          <li className="MenuExamMode__review-mode-list-item">Feedback dettagliato per ogni risposta</li>
          <li className="MenuExamMode__review-mode-list-item">Possibiità di saltare le domande</li>
        </ul>
      </div>
      
      <div 
        onClick={() => isPremium && setReviewMode(false)} 
        className={`MenuExamMode__interrogation-mode ${reviewMode ? "" : "MenuExamMode__interrogation-mode--active"} ${!isPremium ? "MenuExamMode__interrogation-mode--locked" : ""}`}
      >
        {!isPremium ? (
          <div className="MenuExamMode__premium-overlay">
            <div className="MenuExamMode__premium-content MenuExamMode__premium-content--upgrade">
              <button 
                className="MenuExamMode__premium-upgrade-button"
                onClick={navigateToPlans}
              >
                Aggiorna il tuo piano
              </button>
            </div>
          </div>
        ) : null}
        
        <div className="MenuExamMode__interrogation-mode-title-container">
          <MessageCircle className="MenuExamMode__interrogation-mode-icon" size={28}/>
          <div className="MenuExamMode__interrogation-mode-title-wrapper">
            <h3 className="MenuExamMode__interrogation-mode-title">Interrogazione <span className="MenuExamMode__interrogation-mode-beta"><FaFlask className="MenuExamMode__interrogation-mode-beta-icon" />Beta</span></h3>
          </div>
        </div>

        <ul className="MenuExamMode__interrogation-mode-list">
          <li className="MenuExamMode__interrogation-mode-list-item">Simula un'interrogazione reale</li>
          <li className="MenuExamMode__interrogation-mode-list-item">Tempo limitato per le risposte</li>
          <li className="MenuExamMode__interrogation-mode-list-item">Valutazione finale col voto</li>
          <li className="MenuExamMode__interrogation-mode-list-item">Difficoltà adattiva in base alle risposte</li>
        </ul>
      </div>

      <div className="MenuExamMode__warning-container">
        <Info size={25} className="MenuExamMode__warning-container-icon" />
        <span className="MenuExamMode__warning-container-text">In modalità interrogazione non avrai accesso ai feedback dettagliati, simulando una vera interrogazione orale con il professore. Le tue risposte saranno valutate solo alla fine.</span>
      </div>

      <div className="MenuExamMode__button-container">
        <button onClick={() => setShowModal(false)} className="MenuExamMode__button-container__button MenuExamMode__button-container--cancel">
          <span>Annulla</span>
        </button>
        <button 
          onClick={onStartPreparation} 
          className={`MenuExamMode__button-container__button MenuExamMode__button-container--start ${!reviewMode ? "MenuExamMode__button-container--start-blue" : ""}`}
          disabled={!reviewMode && !isPremium}
        >
          <span>Inizia</span>
        </button>
      </div>
    </div>
  );
};