import React from 'react';
import PropTypes from 'prop-types';
import { BsCircle, BsExclamationCircle, BsCheckCircle } from 'react-icons/bs';
import { QuestionStates, QuestionStateLabels } from './costants';

const QuestionStateIcon = ({ state, onClick }) => {
  const getStateConfig = () => {
    switch (state) {
      case QuestionStates.TO_REVIEW:
        return {
          icon: <BsExclamationCircle className='RepetitionQuestion__state-icon RepetitionQuestion__state-icon--review' />,
          tooltip: QuestionStateLabels[QuestionStates.TO_REVIEW]
        };
      case QuestionStates.KNOWN:
        return {
          icon: <BsCheckCircle className='RepetitionQuestion__state-icon RepetitionQuestion__state-icon--known' />,
          tooltip: QuestionStateLabels[QuestionStates.KNOWN]
        };
      default:
        return {
          icon: <BsCircle className='RepetitionQuestion__state-icon' />,
          tooltip: QuestionStateLabels[QuestionStates.NOT_EVALUATED]
        };
    }
  };

  const { icon, tooltip } = getStateConfig();

  return (
    <div
      className='RepetitionQuestion__state-control'
      onClick={onClick}
      title={tooltip}
    >
      {icon}
    </div>
  );
};

QuestionStateIcon.propTypes = {
  state: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default QuestionStateIcon;