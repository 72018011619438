import "./examFeedback.scss";

const ExamFeedback = (examFeedback) => {
  console.log(examFeedback)
  return (
    <div className="ExamFeedback">
      <ul className="ExamFeedback__list">
        <li className="ExamFeedback__list__strengths">{examFeedback.examFeedback.strengths}</li>
        <li className="ExamFeedback__list__weaknesses">{examFeedback.examFeedback.weaknesses}</li>
        <li className="ExamFeedback__list__advice">{examFeedback.examFeedback.advice}</li>
      </ul>
    </div>
  )
};

export default ExamFeedback;