import React from "react";
import "./uploadNoteFile.scss";
import PropTypes from "prop-types";
import { getFileIcon } from "@lib/file";
import { truncate } from "@utility/truncate";
import useDeleteNoteFile from "./hook/useDeleteNoteFile";

import Heading from "@ui/heading/heading";
import Button from "@ui/button/button";
import { IoClose } from "react-icons/io5";
import ErrorNoteFile from "./errorNoteFile/errorNoteFile";
import useGetDocuments from "../../../documentsQA/hooks/useGetDocuments";
import { useSelector } from "react-redux";
import { Image, Music, Video } from "lucide-react";

//Onboarding imports
import useGetOnboardingState from "../../uploadNotesForm/hooks/useGetOnboardingState";
import { setCurrentStep } from "../../../../../redux/actions/oboardingActions";

const UploadNoteFile = ({ errorMessage = "", file }) => {
  const { deleteNoteFile } = useDeleteNoteFile(file?.name);
  const { isLoading } = useGetDocuments();
  const { submitState } = useSelector(state => state.document);

  //Onboarding current step
  const currentStep = useSelector(state => state.onboarding.currentStep);
  const { updateCurrentStep } = useGetOnboardingState({ setCurrentStep, currentStep });
  
  const getUpdatedFileIcon = (type) => {
    if (type?.startsWith('image/')) return <Image className="UploadNoteFile__boxImage-icon" />;
    if (type?.startsWith('video/')) return <Video className="UploadNoteFile__boxImage-icon" />;
    if (type?.startsWith('audio/')) return <Music className="UploadNoteFile__boxImage-icon" />;
  };

  const handleDeleteNoteFile = () => {
    deleteNoteFile();
    if(currentStep < 9) {
      updateCurrentStep(22);
    };
  };

  return (
    <li className="UploadNoteFile">
      <div className="UploadNoteFile__inner">
        <div className="UploadNoteFile__left">
          <div className="UploadNoteFile__boxImage">
            {getUpdatedFileIcon(file?.type) 
              ? getUpdatedFileIcon(file?.type)
              :  <img className="UploadNoteFile__boxImage-image" src={getFileIcon(file?.type)} alt={file?.name} />
            }
          </div>
          <div>
            <Heading className="UploadNoteFile__title" title={file?.name?.length > 20 ? file?.name.slice(0, 20) + '...' : file?.name} heading="h6" />
            <p className="UploadNoteFile__text">
              {file?.extractedText ? truncate(file?.extractedText, 120) : "Nessun testo estratto"}
            </p>
          </div>
        </div>
        <div className="UploadNoteFile__actions">
          <Button disabled={((isLoading || submitState))} className="UploadNoteFile__actions-delete" size="sm" onClick={handleDeleteNoteFile}>
            <IoClose size={25} className="UploadNoteFile__delete-icon" />
          </Button>
          {errorMessage && <ErrorNoteFile errorMessage={errorMessage} />}
        </div>
      </div>
    </li>
  );
};

// UploadNoteFile prop types
UploadNoteFile.propTypes = {
  /* name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired, */
  /* file: PropTypes.object, */ 
  extractedText: PropTypes.string,
  errorMessage: PropTypes.string,
};

export default UploadNoteFile;
