import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { setDocument } from '@redux/actions/documentActions';
import { setUser } from '@redux/actions/authActions';
import { firestore, auth } from '@redux/firebase';
import { doc, updateDoc, increment, getDoc } from 'firebase/firestore';
import useGetOnboardingState from '../../uploadNotesCard/uploadNotesForm/hooks/useGetOnboardingState';

const useSaveDocuments = (onReset, isExam = false, saveExam = null) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth); // Ottieni l'oggetto user
  const currentStep = useSelector(state => state.onboarding.currentStep);
  const { getCurrentStep } = useGetOnboardingState();
  const feedbacks = useSelector(state => state.document.feedbacks);

  useEffect(() => {
    getCurrentStep(); 
  }, [getCurrentStep]);

  const updateDocumentsCount = async (userID) => {
    if(currentStep < 7) {
      return;
    };

    try {
      const studentRef = doc(firestore, "students", userID);
      const docSnap = await getDoc(studentRef);

      if (!docSnap.exists()) {
        throw new Error("Utente non trovato");
      }

      const { documentsUploaded = 0 } = docSnap.data();

      window.dataLayer = window.dataLayer || [];
      // First document upload event
      if (documentsUploaded === 0) {
        if (typeof window.gtag === 'function') {
          window.gtag('event', 'firstDocumentUploaded', {
            'event_category': 'Documents',
            'event_label': 'First Document Uploaded',
            'email': user.email
          });
        } else {
          console.error("gtag is not defined");
        }
      }

      //Normal document upload event
      if (typeof window.gtag === 'function') {
        window.gtag('event', 'normalDocumentUploaded', {
          'event_category': 'Documents',
          'event_label': 'Normal Document Uploaded',
          'email': user.email
        });
      } else {
        console.error("gtag is not defined");
      }

      await updateDoc(studentRef, {
        documentsUploaded: increment(1),
      });

      const updatedDoc = await getDoc(studentRef);
      if (updatedDoc.exists()) {
        dispatch(setUser(updatedDoc.data()));
      }
    } catch (error) {
      console.error('Errore nell\'aggiornamento del conteggio documenti:', error);
      throw error;
    }
  };

  const prepareDocuments = (docs) => {
    return docs.map(doc => {
      console.log('🔍 Preparing document:', doc);

      const metadata = doc.metadata || {};
      const examData = doc.examExtractedQA?.[0] || {};
      
      const summaryData = doc.summary || examData.summary || {};
      const sections = summaryData.sections || [];
      const abstract = summaryData.abstract || '';
      
      // Gestione dei tags
      const tags = metadata.tags || doc.tags || examData.tags || [];
      
      // Gestione delle flashcard - manteniamo il formato card_question/card_answer
      let flashcard_pairs = [];
      
      // Prova prima da qa_response (nuovo formato)
      if (doc.qa_response?.flashcards) {
        flashcard_pairs = doc.qa_response.flashcards;
      }
      // Se non ci sono flashcard in qa_response, prova da rawFlashcards
      else if (doc.rawFlashcards?.flashcards) {
        flashcard_pairs = doc.rawFlashcards.flashcards;
      }

      console.log('💳 Raw flashcards:', flashcard_pairs);

      // Prepara le flashcard per il frontend
      const preparedFlashcards = flashcard_pairs.map(card => ({
        question: card.card_question,
        answer: card.card_answer,
        type: 'flashcard',
        metadata: {
          isFlashcard: true,
          tags: tags
        }
      }));

      console.log('💳 Prepared flashcards:', preparedFlashcards);

      const normalizedSections = sections.map((section, index) => ({
        title: section.title || `Sezione ${index + 1}`,
        content: section.content || '',
        visuals: section.visuals || ''
      })).filter(s => s.content?.trim() !== '');

      // Prepara il documento con entrambi i formati
      const preparedDoc = {
        ...doc,
        extractedQA: [{
          qa_pairs: preparedFlashcards, // Formato per il frontend
          tags: tags,
          summary: summaryData
        }],
        examExtractedQA: [{
          qa_pairs: examData.qa_pairs || [],
          tags: tags,
          summary: summaryData
        }],
        rawExamData: {
          qa_pairs: examData.qa_pairs || [],
          tags,
          summary: {
            abstract: summaryData.abstract || '',
            sections: normalizedSections
          }
        },
        rawFlashcards: {
          qa_pairs: preparedFlashcards,
          flashcards: flashcard_pairs, // Manteniamo il formato originale
          summary: {
            abstract: summaryData.abstract || '',
            sections: normalizedSections
          }
        },
        sections: normalizedSections,
        sectionsCount: normalizedSections.length,
        metadata: {
          ...metadata,
          quizCount: doc.quiz_questions?.length || 0,
          hasAbstract: Boolean(abstract),
          sectionsCount: normalizedSections.length,
          flashcardsCount: flashcard_pairs.length,
          questionsCount: (examData.qa_pairs || []).length,
          feedbackCount: 0,
          isFlashcard: true
        },
        summary: {
          abstract: summaryData.abstract || '',
          sections: normalizedSections
        }
      };

      console.log('📝 Final document structure:', {
        flashcardsCount: preparedDoc.metadata.flashcardsCount,
        questionsCount: preparedDoc.metadata.questionsCount,
        extractedQA: preparedDoc.extractedQA,
        examExtractedQA: preparedDoc.examExtractedQA
      });

      return preparedDoc;
    });
  };

  const saveAllDocuments = async ({ documentsQA, feedbackStates = [] }) => {
    try {
      setIsLoading(true);
      const currentUser = auth.currentUser;

      if (!currentUser) {
        throw new Error('Utente non autenticato');
      }

      // Ensure feedbackStates is always an array
      const feedbackArray = Array.isArray(feedbackStates) ? feedbackStates : [];
      
      // Prepare documents for saving
      const preparedDocs = prepareDocuments(documentsQA);

      if (preparedDocs.length === 0) {
        throw new Error('Nessun documento da salvare');
      }
      
      const userID = currentUser.uid;

      // Save documents
      if (isExam && saveExam) {
        await saveExam(preparedDocs[0], feedbackArray);
      } else {
        await dispatch(setDocument(preparedDocs, feedbacks, feedbackArray));
        await updateDocumentsCount(userID);
      }
      
      const studentRef = doc(firestore, "students", userID);
      const docSnap = await getDoc(studentRef);
      const { documentsUploaded } = docSnap.data();

      if (documentsUploaded === 0) { 
        if (typeof window.gtag === 'function') {
          window.gtag('event', 'firstDocumentUploaded', {
            'event_category': 'Documents',
            'event_label': 'First Document Uploaded',
            'email': user.email
          });
        } else {
          console.error("gtag is not defined");
        }
      }

      toast.success('Documento salvato');

      setIsLoading(false);
      if (onReset) onReset();
      
      return true;
    } catch (error) {
      console.error('Errore nel salvataggio:', error);
      setIsLoading(false);
      toast.error(error.message || 'Errore nel salvataggio dei documenti');
      return false;
    }
  };

  return { isLoading, saveAllDocuments };
};

export default useSaveDocuments;
