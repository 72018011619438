import React from 'react';
import PropTypes from 'prop-types';
import Button from '@ui/button/button';
import useSaveDocuments from '../hooks/useSaveDocuments';
import { useDispatch } from 'react-redux';
import { RESET_PROCESSED_NOTES } from '../../../../redux/actions/types';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useGetOnboardingState from '../../uploadNotesCard/uploadNotesForm/hooks/useGetOnboardingState';
import { setCurrentStep } from '../../../../redux/actions/oboardingActions';
import { resetFeedback } from '../../../../redux/actions/documentActions';

const DocumentsQASaveButton = ({ documentsQA, onReset, feedbackStates = [] }) => {
  const { isLoading, saveAllDocuments } = useSaveDocuments(onReset);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentStep = useSelector(state => state.onboarding.currentStep);
  const { updateCurrentStep } = useGetOnboardingState({ setCurrentStep, currentStep });

  const handleSave = () => {
    if(currentStep < 9) {
      updateCurrentStep(9);
      navigate("/home/ripeti");
      dispatch({ type: RESET_PROCESSED_NOTES });
      return;
    };
    saveAllDocuments({ documentsQA, feedbackStates });
    dispatch({ type: RESET_PROCESSED_NOTES });
    dispatch(resetFeedback());
  };

  return (
    <Button
      size="sm-2"
      variant="primary"
      onClick={handleSave}
      disabled={isLoading}
      title={`Salva ${documentsQA.length} domande`}
    >
      Salva esame
    </Button>
  );
};

DocumentsQASaveButton.propTypes = {
  documentsQA: PropTypes.array.isRequired,
  onReset: PropTypes.func.isRequired,
  feedbackStates: PropTypes.arrayOf(
    PropTypes.shape({
      isHelpful: PropTypes.bool,
      timestamp: PropTypes.number,
      userId: PropTypes.string,
    })
  ),
};

export default React.memo(DocumentsQASaveButton);
