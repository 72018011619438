import React from 'react';
import PropTypes from 'prop-types';
import { HiLockClosed } from 'react-icons/hi';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import './Summary.scss';

const CHAR_LIMIT = 1000; // Free users character limit

const formatText = (text) => {
  if (!text) return '';
  return text
    .replace(/\s+/g, ' ')
    .replace(/\s*([.,;:!?])\s*/g, '$1 ')
    .trim();
};

const countChars = (text) => {
  if (!text) return 0;
  return formatText(text).length;
};

const SummarySections = ({ sections, totalChars, availableChars, onboardingDocumentData }) => {
  const { user } = useSelector(state => state.auth);
  const isPremium = user?.plan === 'Premium';

  if (isPremium) return null;
  
  return (
    <div className="Summary__stats">
      <div className="Summary__stats-header">
        <div className="Summary__stats-icon">
          <HiLockClosed />
        </div>
        <div className="Summary__stats-info">
          <div className="Summary__stats-details">
            <div className="Summary__stats-item Summary__stats-item--premium">
              Versione gratuita
            </div>
            <div className="Summary__stats-item">
              <span className="premium">{onboardingDocumentData ? '1000' : availableChars.toLocaleString()}</span> caratteri gratuiti su {onboardingDocumentData ? '5.278' : totalChars.toLocaleString()} totali
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Summary = ({ onboardingDocumentData, summary, sections }) => {
  const { user } = useSelector(state => state.auth);
  const isPremium = user?.plan === 'Premium';

  // Calcola il totale dei caratteri
  const abstractChars = countChars(summary);
  const sectionsChars = sections.reduce((acc, section) => 
    acc + countChars(section.content) + countChars(section.title), 0);
  const totalChars = abstractChars + sectionsChars;

  // Tronca il testo mantenendo frasi complete
  const truncateText = (text) => {
    if (!text || isPremium) return text;
    
    const formattedText = formatText(text);
    if (formattedText.length <= CHAR_LIMIT) return formattedText;

    const sentences = formattedText.split(/(?<=[.!?])\s+/);
    let currentLength = 0;
    let truncatedSentences = [];

    for (let sentence of sentences) {
      if (currentLength + sentence.length <= CHAR_LIMIT) {
        truncatedSentences.push(sentence);
        currentLength += sentence.length;
      } else break;
    }

    return truncatedSentences.join(' ');
  };

  const truncatedSummary = truncateText(summary);
  const availableChars = Math.min(totalChars, CHAR_LIMIT);

  const effectiveSections = onboardingDocumentData?.summary?.sections || sections;

  return (
    <div className="Summary">
      <SummarySections 
        sections={sections} 
        totalChars={totalChars}
        availableChars={availableChars}
        onboardingDocumentData={onboardingDocumentData}
      />

      <div className="Summary__summary-container">
        <div className="Summary__summary-container__text">
          <div className="Summary__summary-container__header">
            <h3>Riassunto</h3>
          </div>
          {(onboardingDocumentData || summary) && (
            <div className={clsx("Summary__summary-abstract", {
              "Summary__summary-abstract--preview": !isPremium && (onboardingDocumentData || summary).length > CHAR_LIMIT
            })}>
              <p>{onboardingDocumentData?.summary?.abstract || truncatedSummary}</p>
              {!isPremium && (onboardingDocumentData || summary).length > CHAR_LIMIT && (
                <div className="Summary__content-truncated">
                  <div className="Summary__premium-banner">
                    <div className="Summary__premium-banner-content">
                      <div className="Summary__premium-banner-header">
                        <HiLockClosed className="Summary__premium-banner-icon" />
                        <h4>Contenuto Premium</h4>
                      </div>
                      <p>Sblocca il riassunto completo passando a Premium</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {effectiveSections && (
            <>
              {effectiveSections.slice(0, isPremium ? effectiveSections.length : 1).map((section, index) => (
                <div key={index} className="Summary__summary-section">
                  <h4>{section.title}</h4>
                  <p>{section.content}</p>
                </div>
              ))}
              {!isPremium && effectiveSections.length > 1 && (
                <div className="Summary__content-truncated">
                  <div className="Summary__premium-banner">
                    <div className="Summary__premium-banner-content">
                      <div className="Summary__premium-banner-header">
                        <HiLockClosed className="Summary__premium-banner-icon" />
                        <h4>Contenuto Premium</h4>
                      </div>
                      <p>Altre {effectiveSections.length - 1} sezioni disponibili con Premium</p>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

Summary.propTypes = {
  summary: PropTypes.string,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Summary; 