import React, { useState, useEffect } from "react";
import "./audioRecorder.scss";
import PropTypes from "prop-types";
import useAudioRecorder from "./hook/useAudioRecorder";
import { Mic, MicOff, MessageCircle } from "lucide-react";
import HintButton from "../../AnswersControls/HintButton";

// Onboarding
import { Steps } from "intro.js-react";
import { useSelector } from "react-redux";
import useGetOnboardingState from "../../../../uploadNotes/uploadNotesCard/uploadNotesForm/hooks/useGetOnboardingState";
import "intro.js/introjs.css";

const AudioRecorder = ({ 
  onHideAudioRecorder, 
  onFeedback, 
  onAddMessage, 
  showChat, 
  isRecording, 
  examState, 
  setExamState,
  isExamStarted, 
  setShowChat, 
  setIsRecording, 
  setUserAnswer, 
  nextQuestion,
  currentQuestion,
  qa,
  reviewMode,
  firstUserAnswer,
  saveExamInInterrogationMode,
  saveInterrogationQA,
  currentHints,
 }) => {
  const { stopRecording } = useAudioRecorder(
    onFeedback,
    onHideAudioRecorder,
    onAddMessage,
    setUserAnswer,
    currentQuestion,
    qa,
    reviewMode,
    saveExamInInterrogationMode,
    saveInterrogationQA,
  );

  const [isStopDisabled, setIsStopDisabled] = useState(false);

  const currentStep = useSelector(state => state.onboarding.currentStep);
  const [stepsEnabled, setStepsEnabled] = useState(currentStep === 11 ? true : false);
  const { updateCurrentStep, getCurrentStep } = useGetOnboardingState();

 useEffect(() => {
    getCurrentStep();
    if(currentStep === 11) {
      setStepsEnabled(true);
    }
  }, [getCurrentStep, currentStep]);

  useEffect(() => {
    const helperLayer = document.querySelector('.introjs-helperLayer');
    if (helperLayer) {
      helperLayer.style.marginTop = '0px';
    }
    
    const tooltip = document.querySelector('.introjs-tooltip');
    if (tooltip) {
      tooltip.style.marginTop = "0px";  
    }
  }, [isRecording]);

  const onExit = async () => {
    setStepsEnabled(false);
    if(currentStep === 11) {
      updateCurrentStep(12);
    };
  };

  const steps = [
    currentStep === 11 && {
      element: '.mic',
      intro: "Clicca per stoppare il microfono quando hai risposto",
      position: 'bottom',
    },
  ].filter(Boolean);
  
  useEffect(() => {
    if (isRecording) {
      const timer = setTimeout(() => {
        setIsStopDisabled(false);
      }, 1000); // Disable for 1 second
      return () => clearTimeout(timer);
    }
  }, [isRecording]);

  const handleOpenChat = () => {
    setShowChat(!showChat);
  };

  const handleStopRecording = () => {
    if(currentStep === 11) {
      updateCurrentStep(12);
    };

    if (!isStopDisabled) {
      setIsRecording(false);
      // GTA4 event
      firstUserAnswer();

      if(!reviewMode) {
        setExamState('listening');
      } else {
        setExamState('processing');
      };

      if(isRecording) {
        stopRecording();
        if(!reviewMode) {
          nextQuestion();
        }
      };
    }
  };

  return (  
    <div>
      <Steps
        enabled={stepsEnabled}
        steps={steps}
        initialStep={0}
        onExit={onExit}
      />
      {reviewMode && currentHints[0] !== 'Nessun suggerimento disponibile' && isRecording === false && (
        <HintButton 
          examState={examState} 
          currentHints={currentHints}
          audioRecorder={true}
        />
      )}
      
      <div className={showChat ? "controls chat-active" :  "controls"}>
        <span
          className={`control-button mic ${
            isRecording ? 'recording' : 'not-recording'
          }`}
          onClick={handleStopRecording}
          disabled={examState === 'answered' || isStopDisabled}
        >
          {isRecording ? <MicOff size={24} /> : <Mic size={24} />}
        </span>
        <button
          className={`control-button chat ${isExamStarted ? 'active' : 'inactive'} ${isRecording && 'inactive'}`}
          onClick={handleOpenChat}
          disabled={isRecording}
        >
          <MessageCircle size={24} />
        </button>
      </div>
    </div>
  );
};

AudioRecorder.propTypes = {
  onHideAudioRecorder: PropTypes.func.isRequired,
  onFeedback: PropTypes.func.isRequired,
  onAddMessage: PropTypes.func.isRequired,
  showChat: PropTypes.bool.isRequired,
  isRecording: PropTypes.bool.isRequired,
  examState: PropTypes.string.isRequired,
  setExamState: PropTypes.func.isRequired,
  isExamStarted: PropTypes.bool.isRequired,
  setShowChat: PropTypes.func.isRequired,
  setIsRecording: PropTypes.func.isRequired,
  setUserAnswer: PropTypes.func.isRequired,
};

export default AudioRecorder;