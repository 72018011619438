export const NAVIGATION_ITEMS = {
  home: {
    label: 'Home',
    items: [
      { label: 'Funzionalità', scrollTo: 'functionality' },
      { label: 'Tutorial', scrollTo: 'video-section' },
      { label: 'Recensioni', scrollTo: 'beta-testimonial-slider' },
      { label: 'Statistiche', scrollTo: 'studierai-stats' },
      { label: 'FAQ', scrollTo: 'faq' },
    ]
  },
  perChi: {
    label: 'Studenti',
    items: [
      { label: 'Università', to: '/faculty' },
      { label: 'Scuole', to: '/faculty/scuole' },
      { label: 'Diventa Partner', to: '/faculty/partner' }
    ]
  },
  altro: {
    label: 'Altro',
    items: [
      { label: 'Contattaci', to: '/assistenza' }
    ]
  }
};