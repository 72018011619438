import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useGetMultipleChoice from './hook/useGetMultipleChoice';
import MultipleChoice from './multipleChoice/multipleChoice';
import MaterialsFlashcardsNotFound from '../materialsFlashcards/materialsFlashcardsNotFound/materialsFlashcardsNotFound';
import './materialsMultipleChoice.scss';
import { HiDownload, HiX, HiLockClosed, HiArrowRight } from 'react-icons/hi';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const FREE_QUESTIONS_LIMIT = 3;

const MaterialsMultipleChoice = ({ idSelectedDocument, onboardingDocId, isLegacyFormat = true }) => {
  const { questions: updatedQuestions, isLoading } = useGetMultipleChoice((onboardingDocId || idSelectedDocument), isLegacyFormat);
  const [selectedDifficulty, setSelectedDifficulty] = useState(null);
  const [hasShownTooltip, setHasShownTooltip] = useState(false);
  const { user } = useSelector(state => state.auth);
  const navigate = useNavigate();
  
  // Check if user is premium based on plan
  const isPremium = user?.plan === 'Premium';

  // Salva lo stato del tooltip nel localStorage
  useEffect(() => {
    const tooltipShown = localStorage.getItem('multipleChoiceTooltipShown');
    if (tooltipShown) {
      setHasShownTooltip(true);
    }
  }, []);

  const handleTooltipSeen = () => {
    if (!hasShownTooltip) {
      setHasShownTooltip(true);
      localStorage.setItem('multipleChoiceTooltipShown', 'true');
    }
  };

  const handleDownload = () => {
    if (!isPremium) return;

    // Prepara i dati delle domande in formato CSV
    const csvContent = updatedQuestions.map(qa => {
      const escapedQuestion = `"${qa.query_text.replace(/"/g, '""')}"`;
      const escapedAnswer = `"${qa.correct_choice.replace(/"/g, '""')}"`;
      const escapedJustification = `"${qa.justification.replace(/"/g, '""')}"`;
      // Normalizza answer_choices per gestire sia array che oggetti
      const choices = Array.isArray(qa.answer_choices) ? 
        qa.answer_choices : 
        Object.values(qa.answer_choices);
      const escapedChoices = choices.map(choice => `"${String(choice).replace(/"/g, '""')}"`).join(',');
      return `${escapedQuestion},${escapedChoices},${escapedAnswer},${escapedJustification},${qa.difficulty_level}`;
    }).join('\n');

    const header = 'Domanda,Opzione 1,Opzione 2,Opzione 3,Opzione 4,Risposta Corretta,Spiegazione,Difficoltà\n';
    const blob = new Blob([header + csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'domande_multiple_choice.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Calcola le statistiche delle domande
  const calculateStats = () => {
    if (!updatedQuestions.length) return { total: 0, byDifficulty: {} };

    const stats = updatedQuestions.reduce((acc, question) => {
      const difficulty = question.difficulty_level || 'medium';
      acc.byDifficulty[difficulty] = (acc.byDifficulty[difficulty] || 0) + 1;
      return acc;
    }, { total: updatedQuestions.length, byDifficulty: {} });

    return stats;
  };

  // Filtra le domande in base alla difficoltà selezionata
  const filteredQuestions = selectedDifficulty
    ? updatedQuestions.map((q, originalIndex) => ({ ...q, originalIndex }))
        .filter(q => q.difficulty_level === selectedDifficulty)
    : updatedQuestions.map((q, originalIndex) => ({ ...q, originalIndex }));

  // Gestisce il click su un badge di difficoltà
  const handleDifficultyClick = (difficulty) => {
    if (selectedDifficulty === difficulty) {
      setSelectedDifficulty(null);
    } else {
      setSelectedDifficulty(difficulty);
    }
    handleTooltipSeen();
  };

  // show spinner if is loading
  if (isLoading) {
    return (
      <div className="MaterialsMultipleChoice">
        {/* Skeleton per l'header */}
        <div className="MaterialsMultipleChoice__stats-skeleton skeleton-animation">
          <div className="stats-header-skeleton">
            <div className="stats-icon-skeleton"></div>
            <div className="stats-info-skeleton">
              <div className="stats-details-skeleton"></div>
            </div>
          </div>
        </div>

        {/* Skeleton per le flashcard */}
        <div className='MaterialsMultipleChoice__container-skeleton'>
          {[...Array(10)].map((_, index) => (
            <div key={index} className="quiz-skeleton">
              <div className="question-skeleton skeleton-animation"></div>
              <div className="answer-skeleton skeleton-animation"></div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  // return select document if id selected document is null
  if (!idSelectedDocument && !onboardingDocId) {
    return <MaterialsFlashcardsNotFound message='Seleziona un documento' />;
  }

  // return empty message if no questions found
  if (!isLoading && updatedQuestions.length === 0) {
    return (
      <MaterialsFlashcardsNotFound 
        message='Nessuna domanda a risposta multipla trovata nel documento selezionato'
      />
    );
  }

  const stats = calculateStats();
  const availableQuestions = isPremium ? filteredQuestions.length : Math.min(filteredQuestions.length, FREE_QUESTIONS_LIMIT);
  const lockedQuestions = filteredQuestions.length - availableQuestions;

  return (
    <div className='MaterialsMultipleChoice'>
      {/* Stats Section */}
      <div className='MaterialsMultipleChoice__stats'>
        <div className='MaterialsMultipleChoice__stats-header'>
          <div 
            className={`MaterialsMultipleChoice__stats-icon ${!isPremium ? 'MaterialsMultipleChoice__stats-icon--disabled' : ''}`}
            onClick={handleDownload}
            title={isPremium ? "Scarica tutte le domande in formato CSV" : "Passa a Premium per scaricare tutte le domande"}
          >
            {isPremium ? <HiDownload size={24} /> : <HiLockClosed size={24} />}
          </div>
          <div className='MaterialsMultipleChoice__stats-info'>
            <div className='MaterialsMultipleChoice__stats-details'>
              <div className='MaterialsMultipleChoice__stats-item'>
                {filteredQuestions.length} {isPremium ? 'domande disponibili' : 'domande'}
              </div>
              <div className='MaterialsMultipleChoice__stats-difficulties'>
                {Object.entries(stats.byDifficulty).map(([difficulty, count]) => (
                  <button 
                    key={difficulty}
                    onClick={() => handleDifficultyClick(difficulty)}
                    onMouseEnter={handleTooltipSeen}
                    className={`MaterialsMultipleChoice__stats-difficulty MaterialsMultipleChoice__stats-difficulty--${difficulty} ${selectedDifficulty === difficulty ? 'MaterialsMultipleChoice__stats-difficulty--selected' : ''}`}
                    title={!hasShownTooltip ? "Clicca per filtrare le domande" : undefined}
                  >
                    {count} {difficulty === 'easy' ? 'Facili' : difficulty === 'medium' ? 'Medie' : 'Difficili'}
                    {selectedDifficulty === difficulty && (
                      <HiX 
                        className="MaterialsMultipleChoice__stats-difficulty-clear"
                        title="Rimuovi filtro"
                      />
                    )}
                  </button>
                ))}
              </div>
              {!isPremium && (
                <div className='MaterialsMultipleChoice__stats-item MaterialsMultipleChoice__stats-item--premium'>
                  <strong>{availableQuestions} / {filteredQuestions.length}</strong> domande
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {!isPremium && lockedQuestions > 0 && (
        <div className="MaterialsMultipleChoice__premium-banner">
          <div className="MaterialsMultipleChoice__premium-banner-content">
            <div className="MaterialsMultipleChoice__premium-banner-header">
              <HiLockClosed className="MaterialsMultipleChoice__premium-banner-icon" />
              <h6>Versione gratuita</h6>
            </div>
            <p>Hai accesso a {availableQuestions} domande su {filteredQuestions.length}. Passa a Premium per sbloccarle tutte e scaricarle.</p>
            <button 
              className="MaterialsMultipleChoice__premium-banner-button animate-button"
              onClick={() => {
                navigate('/home/piani');
                setTimeout(() => {
                  window.scrollTo(0, 0);
                }, 100);
              }}
            >
              <span>Aggiorna piano</span>
              <HiArrowRight className="MaterialsMultipleChoice__premium-banner-button-icon" />
            </button>
          </div>
        </div>
      )}

      {/* Questions Grid */}
      <div className='MaterialsMultipleChoice__container row gy-xs-1 g-md-1 g-lg-3'>
        {filteredQuestions.map((question) => (
          <MultipleChoice 
            key={question.originalIndex}
            queryText={question.query_text}
            answerChoices={question.answer_choices}
            correctChoice={question.correct_choice}
            justification={question.justification}
            difficultyLevel={question.difficulty_level}
            isLocked={!isPremium && question.originalIndex >= FREE_QUESTIONS_LIMIT}
          />
        ))}
      </div>
    </div>
  );
};

MaterialsMultipleChoice.propTypes = {
  idSelectedDocument: PropTypes.string,
  isLegacyFormat: PropTypes.bool
};

export default MaterialsMultipleChoice; 