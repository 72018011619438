import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import './FolderList.scss';

import Folder from './Folder';
import FolderContent from './FolderContent';
import { useFolderSelectors } from '../hooks/useFolderSelectors';

const FolderList = ({ selectedFolder, onSelectFolder }) => {
  const { 
    rootFolders, 
    allFolders, 
    getFolderById,
    refreshCounter
  } = useFolderSelectors();
  
  const [openFolderId, setOpenFolderId] = useState(null);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [folderDocuments, setFolderDocuments] = useState([]);
  
  // Log per debug
  useEffect(() => {
    console.log(`FolderList renderizzato, rootFolders: ${rootFolders.length}, refreshCounter: ${refreshCounter}`);
  }, [rootFolders, refreshCounter]);
  
  // Quando cambia il folder aperto, aggiorniamo il folder corrente e i suoi documenti
  useEffect(() => {
    if (!openFolderId) {
      setCurrentFolder(null);
      setFolderDocuments([]);
      return;
    }

    const folder = getFolderById(openFolderId);
    if (!folder) {
      console.log(`Folder con ID ${openFolderId} non trovato`);
      setOpenFolderId(null);
      setCurrentFolder(null);
      setFolderDocuments([]);
      return;
    }

    console.log(`Folder aperto: ${folder.name} (${folder.id})`);
    setCurrentFolder(folder);
    // I documenti verranno richiesti nel componente FolderContent
  }, [openFolderId, getFolderById, refreshCounter, allFolders]);
  
  // Select a folder (single click)
  const handleSelectFolder = useCallback((folderId) => {
    console.log(`Selecting folder ${folderId}`);
    onSelectFolder(folderId);
  }, [onSelectFolder]);

  // Open a folder
  const handleOpenFolder = useCallback((folderId) => {
    console.log(`Opening folder ${folderId}`);
    setOpenFolderId(folderId);
  }, []);

  // Go back to folder list
  const handleBackToFolders = useCallback(() => {
    console.log('Back to folder list');
    setOpenFolderId(null);
  }, []);

  // If a folder is open, show its content
  if (openFolderId && currentFolder) {
    return (
      <FolderContent 
        folder={currentFolder} 
        documents={[]} // I documenti verranno recuperati all'interno del componente FolderContent
        onBack={handleBackToFolders} 
      />
    );
  }

  // If no folders, show empty state
  if (rootFolders.length === 0) {
    return (
      <div className="FolderList__empty">
        <p>Non ci sono cartelle</p>
        <p className="FolderList__empty-hint">
          Premi il pulsante "Crea Cartella" in alto per creare una nuova cartella
        </p>
      </div>
    );
  }

  return (
    <div className="FolderList">
      {/* Lista delle cartelle */}
      <ul className="FolderList__list">
        {rootFolders.map(folder => {
          // Calculate document count
          const documentCount = folder.documentIds?.length || 0;
          
          return (
            <Folder
              key={folder.id}
              id={folder.id}
              name={folder.name}
              documentCount={documentCount}
              color={folder.color}
              isActive={selectedFolder === folder.id}
              onClick={handleSelectFolder}
              onOpen={handleOpenFolder}
              createdAt={folder.createdAt}
            />
          );
        })}
      </ul>
    </div>
  );
};

FolderList.propTypes = {
  selectedFolder: PropTypes.string,
  onSelectFolder: PropTypes.func.isRequired,
};

export default FolderList;