// Utility file defining colors for folders

export const folderColors = {
  blue: { 
    name: 'blue', 
    label: 'Blu', 
    color: '#3498db', 
    rgb: '52, 152, 219',
    buttonBg: '#3498db',
    buttonHoverBg: '#2980b9',
    buttonActiveBg: '#2471a3',
    icon: '#3498db', 
    count: 'rgba(52, 152, 219, 0.1)', 
    countText: '#3498db', 
    countBorder: 'rgba(52, 152, 219, 0.2)'
  },
  green: { 
    name: 'green', 
    label: 'Verde', 
    color: '#2ecc71', 
    rgb: '46, 204, 113',
    buttonBg: '#27ae60',
    buttonHoverBg: '#219d54',
    buttonActiveBg: '#1e8449',
    icon: '#2ecc71', 
    count: 'rgba(46, 204, 113, 0.1)', 
    countText: '#2ecc71', 
    countBorder: 'rgba(46, 204, 113, 0.2)'
  },
  yellow: { 
    name: 'yellow', 
    label: 'Giallo', 
    color: '#f1c40f', 
    rgb: '241, 196, 15',
    buttonBg: '#f39c12',
    buttonHoverBg: '#e67e22',
    buttonActiveBg: '#d35400',
    icon: '#f1c40f', 
    count: 'rgba(241, 196, 15, 0.1)', 
    countText: '#f1c40f', 
    countBorder: 'rgba(241, 196, 15, 0.2)'
  },
  orange: { 
    name: 'orange', 
    label: 'Arancione', 
    color: '#e67e22', 
    rgb: '230, 126, 34',
    buttonBg: '#d35400',
    buttonHoverBg: '#c0392b',
    buttonActiveBg: '#a93226',
    icon: '#e67e22', 
    count: 'rgba(230, 126, 34, 0.1)', 
    countText: '#e67e22', 
    countBorder: 'rgba(230, 126, 34, 0.2)'
  },
  purple: { 
    name: 'purple', 
    label: 'Viola', 
    color: '#9b59b6', 
    rgb: '155, 89, 182',
    buttonBg: '#8e44ad',
    buttonHoverBg: '#7d3c98',
    buttonActiveBg: '#6c3483',
    icon: '#9b59b6', 
    count: 'rgba(155, 89, 182, 0.1)', 
    countText: '#9b59b6', 
    countBorder: 'rgba(155, 89, 182, 0.2)'
  }
};

export const getFolderColor = (color = 'blue') => {
  return folderColors[color] || folderColors.blue;
};

export const getDocumentFolderColor = (folderColor) => {
  if (!folderColor) return null;
  
  return { 
    color: folderColor.countText, 
    rgb: folderColor.countText.replace('#', '').match(/.{2}/g).map(x => parseInt(x, 16)).join(', ') 
  };
};

export default folderColors;