import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { 
  createFolder, 
  updateFolder, 
  deleteFolder, 
  addDocumentToFolder, 
  removeDocumentFromFolder,
  refreshFolders
} from '../../../../redux/actions/documentActions';
import { UPDATE_FOLDER, FREE_DOCUMENTS_FROM_FOLDER, REMOVE_DOCUMENT_FROM_FOLDER } from '../../../../redux/actions/types';
import { useCallback, useRef, useEffect, useState } from 'react';
import { 
  withTimeout, 
  withRetry, 
  debounce, 
  throttle, 
  withLock 
} from '../../../../lib/helpers/promiseHelpers';
import {
  removeDocumentFromFolder as removeDocumentFromFirestoreFolder
} from '../../../../lib/firestoreQueries';

/**
 * Hook personalizzato per operazioni sulle cartelle
 * Fornisce funzioni per creare, aggiornare ed eliminare cartelle
 */
export const useFolderActions = () => {
  // I React Hooks devono essere chiamati al livello superiore
  // non possono essere messi dentro condizioni o try/catch
  const dispatch = useDispatch();
  
  // Ref per limitare gli aggiornamenti troppo frequenti
  const lastRefreshTimeRef = useRef(0);
  const operationLocks = useRef({
    create: { locked: false },
    delete: { locked: false },
    update: { locked: false }
  });
  
  // Pulizia delle risorse quando il componente viene smontato
  useEffect(() => {
    // Inizializza il ref
    lastRefreshTimeRef.current = 0;
    
    // Cleanup function
    return () => {
      // Resetta il ref quando il componente viene smontato
      lastRefreshTimeRef.current = 0;
    };
  }, []); // Array di dipendenze vuoto: esegue solo al mount/unmount
  
  // Miglioro il selettore per gestire qualsiasi struttura dello stato
  // Questo è più robusto e gestisce tutti i casi in cui lo stato potrebbe essere malformato
  const folders = useSelector(state => {
    // Caso 1: L'intero state è null o undefined
    if (!state) return [];
    
    // Caso 2: State è null o non è un oggetto
    if (typeof state !== 'object') return [];
    
    // Caso 3: Accediamo direttamente a state.document.folders
    // Se state.document esiste e folders è un array, lo restituiamo
    if (state.document && Array.isArray(state.document.folders)) {
      return state.document.folders;
    }
    
    // Caso 4: Se siamo qui, potrebbe essere che document non esiste
    // o che folders non è un array o non esiste
    return [];
  });

  /**
   * Normalizza un ID cartella rimuovendo i prefissi comuni
   * Se preserveSubfolderFormat è true, mantiene il formato completo per gli ID di sottocartelle
   */
  const normalizeId = (id, preserveSubfolderFormat = false) => {
    if (!id) return id;
    
    // Se preserveSubfolderFormat è true e l'ID inizia con 'subfolder_', 
    // restituisci l'ID completo perché il formato è importante per le sottocartelle
    if (preserveSubfolderFormat && typeof id === 'string' && id.startsWith('subfolder_')) {
      return id;
    }
    
    // Altrimenti, rimuovi i prefissi noti
    return typeof id === 'string' ? id.replace(/^(firebase_|local_|folder_)/, '') : id;
  };

  /**
   * Versione sicura del dispatch che gestisce errori
   * @param {Object} action Azione Redux
   * @returns {Promise} Promise che si risolve o rifiuta con il risultato
   */
  const safeDispatch = (action) => {
    if (!dispatch) {
      console.error('Dispatch non disponibile');
      return Promise.reject('Dispatch non disponibile');
    }
    
    try {
      // Dispatch potrebbe restituire una Promise o un valore diretto
      const result = dispatch(action);
      
      // Se il risultato è una Promise, gestiamo anche gli errori asincroni
      if (result && typeof result.then === 'function') {
        // Ottieni il tipo di azione per i messaggi di errore
        const actionType = action?.type || (typeof action === 'function' ? 'thunk action' : 'unknown action');
        
        // Aumentiamo il timeout per le operazioni di sincronizzazione delle cartelle a 30 secondi
        // Altri tipi di azioni possono rimanere con un timeout più breve
        const timeoutMs = actionType === 'REFRESH_FOLDERS' || 
                         (typeof action === 'function' && actionType === 'thunk action') ? 
                         30000 : 15000;
        
        // Usa il timeout adatto
        return withTimeout(
          result, 
          timeoutMs, 
          `Timeout durante l'esecuzione dell'azione ${actionType}`
        ).catch(error => {
          // Se si tratta di un errore di timeout ma l'operazione è di sincronizzazione cartelle
          // restituisci comunque un "successo" per evitare che l'UI si blocchi
          if (error.message && error.message.includes('Timeout') && 
              (actionType === 'REFRESH_FOLDERS' || actionType === 'thunk action')) {
            console.warn('Timeout nell\'aggiornamento delle cartelle, ma l\'operazione continua in background');
            return { success: true, background: true };
          }
          return Promise.reject(error);
        });
      }
      
      return Promise.resolve(result);
    } catch (error) {
      console.error('Errore durante il dispatch:', error);
      return Promise.reject(error);
    }
  };

  /**
   * Crea una nuova cartella
   */
  const createNewFolder = (folderData, options = {}) => {
    return withLock(async () => {
      try {
        if (!folderData || typeof folderData !== 'object') {
          toast.error('Dati della cartella non validi');
          return Promise.reject('Dati della cartella non validi');
        }

        const { name, color = 'blue', parentId = null, documentIds = [] } = folderData;
        
        if (!name || name.trim() === '') {
          toast.error('Il nome della cartella è obbligatorio');
          return Promise.reject('Il nome della cartella è obbligatorio');
        }
        
        // Normalizza documentIds per garantire che sia un array
        const normalizedDocIds = Array.isArray(documentIds) ? documentIds : [];
        
        // Normalizza parentId se presente
        const normalizedParentId = parentId ? normalizeId(parentId) : null;
        
        // Verifica che il livello di nidificazione non superi il massimo consentito
        if (normalizedParentId) {
          // Trova la cartella genitore
          const parentFolder = folders.find(f => normalizeId(f.id) === normalizedParentId);
          if (!parentFolder) {
            toast.error('Cartella genitore non trovata');
            return Promise.reject('Cartella genitore non trovata');
          }
          
          // Se il genitore ha già un genitore, non consentire ulteriori nidificazioni
          if (parentFolder.parentId) {
            toast.error('Impossibile creare cartelle a questo livello. Le cartelle possono essere create solo a livello principale o come dirette figlie di una cartella principale.');
            return Promise.reject('Le cartelle possono essere create solo a livello principale o come dirette figlie di una cartella principale');
          }
        }
        
        // Verifica nomi duplicati nello stesso livello
        const nameExists = folders.some(folder => {
          // Confronta solo con cartelle allo stesso livello (con lo stesso parentId)
          const sameLevel = 
            (normalizeId(folder.parentId) === normalizedParentId) || 
            (!folder.parentId && !normalizedParentId);
          
          // Nome case-insensitive
          const sameName = folder.name && 
            folder.name.toLowerCase() === name.trim().toLowerCase();
          
          return sameLevel && sameName;
        });

        if (nameExists) {
          toast.error('Esiste già una cartella con questo nome');
          return Promise.reject('Esiste già una cartella con questo nome');
        }
        
        console.log("Creando cartella:", { 
          name: name.trim(), 
          color, 
          parentId: normalizedParentId, 
          documentIds: normalizedDocIds.length 
        });
        
        // Usa il toast esistente o crea un nuovo toast di caricamento se necessario
        let loadingToast = options.existingToast;
        if (!options.skipLoadingToast && !loadingToast) {
          loadingToast = toast.loading(`Creazione cartella "${name.trim()}" in corso...`);
        }
        
        try {
          // Usa la funzione createFolder con i parametri nel formato corretto
          const result = await withRetry(
            () => safeDispatch(createFolder(name.trim(), normalizedDocIds, color, normalizedParentId)),
            {
              maxRetries: 3,
              delay: 300,
              backoffFactor: 1.5
            }
          );
          
          // Chiudi il toast di caricamento
          toast.dismiss(loadingToast);
          toast.success(`Cartella "${name.trim()}" creata con successo`);
          
          // Aggiornamento immediato e forzato per sottocartelle
          if (normalizedParentId) {
            // Forza un aggiornamento immediato senza throttling per le sottocartelle
            console.log("Aggiornamento immediato per sottocartella appena creata");
            forceRefreshFolders({ immediate: true });
            
            // Applica un secondo aggiornamento dopo un breve ritardo per assicurarsi che tutti i componenti siano aggiornati
            setTimeout(() => {
              forceRefreshFolders({ immediate: true });
            }, 500);
          } else {
            // Per le cartelle principali, usa il normale refresh throttled
            forceRefreshFolders();
          }
          
          return result;
        } catch (error) {
          // Chiudi il toast di caricamento
          toast.dismiss(loadingToast);
          
          console.error('Errore nella creazione della cartella:', error);
          // Gestisci in modo sicuro il messaggio di errore
          const errorMessage = error ? (error.message || String(error)) : 'Errore sconosciuto';
          toast.error('Errore nella creazione della cartella: ' + errorMessage);
          return Promise.reject(error);
        }
      } catch (error) {
        console.error('Errore imprevisto nella creazione della cartella:', error);
        toast.error('Si è verificato un errore imprevisto');
        return Promise.reject(error);
      }
    }, operationLocks.current.create);
  };

  /**
   * Aggiorna i dettagli di una cartella
   */
  const updateFolderDetails = (folderId, updateData) => {
    return withLock(
      () => safeDispatch(updateFolder(folderId, updateData))
        .then(result => {
          forceRefreshFolders();
          return result;
        }),
      operationLocks.current.update
    );
  };

  /**
   * Raccoglie tutti i documenti associati a una cartella, incluse le sottocartelle
   * @param {string} folderId - ID della cartella principale
   * @param {Array} allFolders - Lista di tutte le cartelle disponibili
   * @returns {Array} Array di ID dei documenti
   */
  const getDocumentsRecursively = (folderId, allFolders) => {
    // Normalizza l'ID cartella (utilità interna)
    const normalizedFolderId = normalizeId(folderId);
    
    // Trova la cartella principale
    const folder = allFolders.find(f => normalizeId(f.id) === normalizedFolderId);
    if (!folder) {
      console.log(`Cartella ${folderId} non trovata per raccogliere documenti`);
      return [];
    }
    
    // Raccogli i documenti della cartella principale
    const docIds = Array.isArray(folder.documentIds) ? [...folder.documentIds] : [];
    
    // Raccogli documenti dalle sottocartelle (se presenti)
    if (Array.isArray(folder.subfolders)) {
      folder.subfolders.forEach(subfolder => {
        if (Array.isArray(subfolder.documentIds)) {
          docIds.push(...subfolder.documentIds);
        }
      });
    }
    
    // Rimuovi duplicati e restituisci array
    return [...new Set(docIds)];
  };

  /**
   * Elimina una cartella dato il suo ID
   */
  const deleteFolderById = (folderId, options = {}) => {
    return withLock(async () => {
      try {
        if (!folderId) {
          toast.error('ID cartella non valido');
          return Promise.reject('ID cartella non valido');
        }

        // Normalizza l'ID
        const normalizedId = normalizeId(folderId);
        
        // Trova la cartella
        const folder = folders.find(f => normalizeId(f.id) === normalizedId);
        
        if (!folder) {
          toast.error('Cartella non trovata');
          return Promise.reject('Cartella non trovata');
        }
        
        const folderName = folder.name || 'Cartella';

        // Utilizza il toast esistente se fornito, altrimenti ne crea uno nuovo
        const loadingToast = options.existingToast || toast.loading(`Eliminazione della cartella "${folderName}" in corso...`);

        try {
          // Usa deleteFolder che non mostra toast
          const result = await withRetry(
            () => safeDispatch(deleteFolder(normalizedId)),
            { maxRetries: 2 }
          );
          
          // Chiudi il toast solo se non è stato fornito dall'esterno
          if (!options.existingToast) {
            toast.dismiss(loadingToast);
          }
          
          // Aggiornamento immediato dell'UI
          forceRefreshFolders({ immediate: true });
          
          // Forza un secondo refresh per garantire che tutti i documenti vengano visualizzati 
          setTimeout(() => {
            console.log("Secondo refresh forzato dopo eliminazione cartella");
            forceRefreshFolders({ immediate: true });
            
            // Terzo refresh per sicurezza dopo un intervallo più lungo
            setTimeout(() => {
              forceRefreshFolders({ immediate: true });
            }, 500);
          }, 100);
          
          return result;
        } catch (error) {
          // Chiudi il toast di caricamento
          toast.dismiss(loadingToast);
          
          console.error('Errore nell\'eliminazione della cartella:', error);
          toast.error('Errore nell\'eliminazione della cartella');
          return Promise.reject(error);
        }
      } catch (error) {
        console.error('Errore imprevisto nell\'eliminazione della cartella:', error);
        toast.error('Si è verificato un errore imprevisto');
        return Promise.reject(error);
      }
    }, operationLocks.current.delete);
  };

  /**
   * Aggiunge documenti a una cartella
   */
  const addDocumentsToFolder = (folderId, documentIds) => {
    return withLock(async () => {
      try {
        if (!folderId) {
          toast.error('ID cartella non valido');
          return Promise.reject('ID cartella non valido');
        }
        
        if (!documentIds || !Array.isArray(documentIds) || documentIds.length === 0) {
          toast.error('Nessun documento selezionato');
          return Promise.reject('Nessun documento selezionato');
        }

        // Verifica se l'ID è di una sottocartella
        const isSubfolder = typeof folderId === 'string' && folderId.startsWith('subfolder_');
        
        // Normalizza l'ID cartella, preservando il formato completo per le sottocartelle
        const normalizedFolderId = normalizeId(folderId, isSubfolder);
        
        console.log(`Aggiunta documenti a cartella. ID originale: ${folderId}, ID normalizzato: ${normalizedFolderId}, isSubfolder: ${isSubfolder}`);
        
        // Trova la cartella nello stato attuale
        // Per le sottocartelle, devo cercare sia con l'ID completo che con l'ID normalizzato
        const folder = isSubfolder 
          ? folders.find(f => {
              // Controlla prima nelle sottocartelle di tutte le cartelle
              if (f.subfolders && Array.isArray(f.subfolders)) {
                return f.subfolders.some(sf => sf.id === folderId || sf.id === normalizedFolderId);
              }
              return false;
            })
          : folders.find(f => normalizeId(f.id) === normalizedFolderId);
        
        if (!folder) {
          console.error(`Cartella con ID ${folderId} non trovata nello stato Redux`);
          toast.error('Cartella non trovata');
          return Promise.reject('Cartella non trovata');
        }

        // Per le sottocartelle, trova i documentIds corretti
        let currentDocIds = [];
        if (isSubfolder && folder.subfolders) {
          const subfolder = folder.subfolders.find(sf => sf.id === folderId || sf.id === normalizedFolderId);
          if (subfolder) {
            currentDocIds = Array.isArray(subfolder.documentIds) ? subfolder.documentIds : [];
          }
        } else {
          currentDocIds = Array.isArray(folder.documentIds) ? folder.documentIds : [];
        }
        
        // Filtra eventuali documenti già presenti nella cartella
        const newDocIds = documentIds.filter(id => !currentDocIds.includes(id));
        
        if (newDocIds.length === 0) {
          toast.info('Tutti i documenti selezionati sono già presenti nella cartella');
          return Promise.resolve({ success: true, message: 'Nessun nuovo documento da aggiungere' });
        }

        // Mostra il toast di caricamento
        const loadingToast = toast.loading(`Aggiunta di ${newDocIds.length} ${newDocIds.length === 1 ? 'documento' : 'documenti'} in corso...`);

        try {
          // Prima aggiorna Redux localmente per un feedback immediato
          // Per le sottocartelle, l'aggiornamento locale è più complesso
          if (isSubfolder) {
            // Trova la cartella principale che contiene la sottocartella
            const parentFolder = folders.find(f => 
              f.subfolders && Array.isArray(f.subfolders) && 
              f.subfolders.some(sf => sf.id === folderId || sf.id === normalizedFolderId)
            );
            
            if (parentFolder) {
              // Creazione di un nuovo array di sottocartelle con il documentIds aggiornato
              const updatedSubfolders = parentFolder.subfolders.map(sf => {
                if (sf.id === folderId || sf.id === normalizedFolderId) {
                  return {
                    ...sf,
                    documentIds: [...(sf.documentIds || []), ...newDocIds]
                  };
                }
                return sf;
              });
              
              // Aggiornamento della cartella principale con le sottocartelle aggiornate
              safeDispatch({
                type: UPDATE_FOLDER,
                payload: {
                  folderId: parentFolder.id,
                  updates: {
                    subfolders: updatedSubfolders
                  }
                }
              });
            }
          } else {
            // Normale aggiornamento per cartelle principali
            safeDispatch({
              type: UPDATE_FOLDER,
              payload: {
                folderId: normalizedFolderId,
                updates: {
                  documentIds: [...currentDocIds, ...newDocIds]
                }
              }
            });
          }
          
          // Forza un aggiornamento UI immediato
          forceRefreshFolders();
          
          // Crea operazioni di aggiunta per ogni documento
          let successCount = 0;
          let errorCount = 0;
          
          // Esegui le operazioni in sequenza per evitare race condition
          for (const docId of newDocIds) {
            try {
              console.log(`Iniziando aggiunta documento ${docId} alla cartella ${folderId}`);
              // Usa l'ID originale non normalizzato per le sottocartelle
              await safeDispatch(addDocumentToFolder(folderId, docId));
              successCount++;
            } catch (docError) {
              console.error(`Errore nell'aggiunta del documento ${docId}:`, docError);
              errorCount++;
            }
          }
          
          // Chiudi il toast di caricamento
          toast.dismiss(loadingToast);
          
          // Mostra messaggio appropriato
          if (errorCount > 0) {
            if (errorCount === newDocIds.length) {
              toast.error('Errore nell\'aggiunta dei documenti alla cartella');
            } else {
              toast.warning(`Aggiunti ${successCount} documenti con ${errorCount} errori`);
            }
          } else {
            toast.success(`${newDocIds.length} documenti aggiunti alla cartella`);
          }
          
          // Aggiornamento finale
          forceRefreshFolders();
          
          return { 
            success: errorCount === 0, 
            count: successCount, 
            errors: errorCount, 
            total: newDocIds.length 
          };
        } catch (error) {
          // Chiudi il toast di caricamento
          toast.dismiss(loadingToast);
          
          console.error('Errore nell\'aggiunta dei documenti:', error);
          toast.error('Si è verificato un errore durante l\'aggiunta dei documenti');
          
          return Promise.reject(error);
        }
      } catch (error) {
        console.error('Errore imprevisto nell\'aggiunta di documenti:', error);
        toast.error('Si è verificato un errore imprevisto');
        return Promise.reject(error);
      }
    }, operationLocks.current.update);
  };

  /**
   * Rimuove documenti da una cartella
   */
  const removeDocumentsFromFolder = (folderId, documentIds, options = {}) => {
    return withLock(async () => {
      try {
        if (!folderId) {
          toast.error('ID cartella non valido');
          return Promise.reject('ID cartella non valido');
        }
        
        if (!documentIds || !Array.isArray(documentIds) || documentIds.length === 0) {
          toast.error('Nessun documento selezionato');
          return Promise.reject('Nessun documento selezionato');
        }
        
        // Verifica se l'ID è di una sottocartella
        const isSubfolder = typeof folderId === 'string' && folderId.startsWith('subfolder_');
        
        // Normalizza l'ID cartella, preservando il formato completo per le sottocartelle
        const normalizedFolderId = normalizeId(folderId, isSubfolder);
        
        console.log(`Rimozione documenti da cartella. ID originale: ${folderId}, ID normalizzato: ${normalizedFolderId}, isSubfolder: ${isSubfolder}`);
        
        // Trova la cartella nello stato attuale
        const folder = isSubfolder 
          ? folders.find(f => {
              // Controlla prima nelle sottocartelle di tutte le cartelle
              if (f.subfolders && Array.isArray(f.subfolders)) {
                return f.subfolders.some(sf => sf.id === folderId || sf.id === normalizedFolderId);
              }
              return false;
            })
          : folders.find(f => normalizeId(f.id) === normalizedFolderId);
        
        if (!folder) {
          console.error(`Cartella con ID ${folderId} non trovata nello stato Redux`);
          toast.error('Cartella non trovata');
          return Promise.reject('Cartella non trovata');
        }

        // Per le sottocartelle, trova i documentIds corretti
        let currentDocIds = [];
        if (isSubfolder && folder.subfolders) {
          const subfolder = folder.subfolders.find(sf => sf.id === folderId || sf.id === normalizedFolderId);
          if (subfolder) {
            currentDocIds = Array.isArray(subfolder.documentIds) ? subfolder.documentIds : [];
          }
        } else {
          currentDocIds = Array.isArray(folder.documentIds) ? folder.documentIds : [];
        }
        
        // Verifica quali documenti esistono effettivamente nella cartella
        const existingDocIds = documentIds.filter(id => currentDocIds.includes(id));
        
        if (existingDocIds.length === 0) {
          toast.info('Nessuno dei documenti selezionati è presente nella cartella');
          return Promise.resolve({ success: true, message: 'Nessun documento da rimuovere' });
        }

        // Usa il toast esistente o crea un nuovo toast di caricamento se necessario
        let loadingToast = options.existingToast;
        if (!options.skipLoadingToast && !loadingToast) {
          loadingToast = toast.loading(`Rimozione di ${existingDocIds.length} ${existingDocIds.length === 1 ? 'documento' : 'documenti'} in corso...`);
        }

        try {
          // Aggiornamento locale Redux per feedback immediato
          if (isSubfolder) {
            // Trova la cartella principale che contiene la sottocartella
            const parentFolder = folders.find(f => 
              f.subfolders && Array.isArray(f.subfolders) && 
              f.subfolders.some(sf => sf.id === folderId || sf.id === normalizedFolderId)
            );
            
            if (parentFolder) {
              // Aggiornamento della sottocartella
              const updatedSubfolders = parentFolder.subfolders.map(sf => {
                if (sf.id === folderId || sf.id === normalizedFolderId) {
                  return {
                    ...sf,
                    documentIds: (sf.documentIds || []).filter(id => !existingDocIds.includes(id))
                  };
                }
                return sf;
              });
              
              // Dispatch dell'aggiornamento
              safeDispatch({
                type: UPDATE_FOLDER,
                payload: {
                  folderId: parentFolder.id,
                  updates: {
                    subfolders: updatedSubfolders
                  }
                }
              });
            }
          } else {
            // Per cartelle normali, rimuovi i documenti dall'array documentIds
            safeDispatch({
              type: REMOVE_DOCUMENT_FROM_FOLDER,
              payload: {
                folderId: normalizedFolderId,
                documentIds: existingDocIds
              }
            });
          }
          
          // Forza un aggiornamento UI immediato
          forceRefreshFolders();
          
          // Esegui operazioni di rimozione in Firestore
          let successCount = 0;
          let errorCount = 0;
          
          // Usa l'ID cartella originale non normalizzato per le sottocartelle
          const actualFolderId = folderId;
          
          // Rimuovi i documenti in sequenza
          for (const docId of existingDocIds) {
            try {
              await withRetry(
                () => removeDocumentFromFirestoreFolder(actualFolderId, docId),
                { maxRetries: 2 }
              );
              successCount++;
            } catch (docError) {
              console.error(`Errore nella rimozione del documento ${docId}:`, docError);
              errorCount++;
            }
          }
          
          // Chiudi il toast di caricamento
          toast.dismiss(loadingToast);
          
          // Mostra messaggio appropriato
          if (errorCount > 0) {
            if (errorCount === existingDocIds.length) {
              toast.error('Errore nella rimozione dei documenti dalla cartella');
            } else {
              toast.warning(`Rimossi ${successCount} documenti con ${errorCount} errori`);
            }
          } else {
            toast.success(`${existingDocIds.length} documenti rimossi dalla cartella`);
          }
          
          // Aggiornamento finale
          forceRefreshFolders();
          
          return { 
            success: errorCount === 0, 
            count: successCount, 
            errors: errorCount, 
            total: existingDocIds.length 
          };
        } catch (error) {
          // Chiudi il toast di caricamento
          toast.dismiss(loadingToast);
          
          console.error('Errore nella rimozione dei documenti:', error);
          toast.error('Si è verificato un errore durante la rimozione dei documenti');
          
          return Promise.reject(error);
        }
      } catch (error) {
        console.error('Errore imprevisto nella rimozione di documenti:', error);
        toast.error('Si è verificato un errore imprevisto');
        return Promise.reject(error);
      }
    }, operationLocks.current.update);
  };

  /**
   * Libera i documenti da una cartella eliminata
   */
  const freeDocumentsFromFolder = (folderId, documentIds) => {
    try {
      if (!folderId) {
        console.error('ID cartella non valido');
        return Promise.reject('ID cartella non valido');
      }
      
      if (!documentIds || !Array.isArray(documentIds) || documentIds.length === 0) {
        console.log('Nessun documento da liberare');
        return Promise.resolve({ success: true, message: 'Nessun documento da liberare' });
      }
      
      // Trova la cartella per verificare se ha sottocartelle
      const folder = folders.find(f => f.id === folderId || 
                                      `firebase_${normalizeId(f.id)}` === folderId ||
                                      f.id === `firebase_${normalizeId(folderId)}`);
      
      let includeSubfolders = false;
      let hasSubfolders = false;
      
      if (folder && Array.isArray(folder.subfolders) && folder.subfolders.length > 0) {
        hasSubfolders = true;
        includeSubfolders = true;
        console.log(`La cartella ha ${folder.subfolders.length} sottocartelle, verranno liberati anche i loro documenti`);
      }
      
      console.log(`Liberazione di ${documentIds.length} documenti dalla cartella ${folderId}`);
      
      // Dispatch diretto dell'azione FREE_DOCUMENTS_FROM_FOLDER con flag per sottocartelle
      const freePromise = safeDispatch({
        type: FREE_DOCUMENTS_FROM_FOLDER,
        payload: {
          folderId,
          documentIds,
          includeSubfolders
        }
      });
      
      // Se ci sono sottocartelle, libera esplicitamente anche i loro documenti
      if (hasSubfolders) {
        return freePromise.then(() => {
          const subfoldersPromises = folder.subfolders.map(subfolder => {
            if (subfolder && subfolder.id && Array.isArray(subfolder.documentIds) && subfolder.documentIds.length > 0) {
              console.log(`Liberazione esplicita di ${subfolder.documentIds.length} documenti dalla sottocartella ${subfolder.id}`);
              
              // Dispatch specifico per ogni sottocartella
              return safeDispatch({
                type: FREE_DOCUMENTS_FROM_FOLDER,
                payload: {
                  folderId: subfolder.id,
                  documentIds: subfolder.documentIds
                }
              });
            }
            return Promise.resolve();
          });
          
          // Attendi che tutte le liberazioni di sottocartelle siano completate
          return Promise.all(subfoldersPromises)
            .then(() => {
              // Forza più aggiornamenti dell'interfaccia utente per garantire la visualizzazione corretta
              forceRefreshFolders({ immediate: true });
              
              setTimeout(() => {
                // Secondo refresh dopo un breve ritardo
                forceRefreshFolders({ immediate: true });
              }, 300);
              
              return { 
                success: true, 
                folderId, 
                count: documentIds.length,
                subfoldersProcessed: folder.subfolders.length
              };
            });
        });
      }
      
      // Se non ci sono sottocartelle, gestione normale
      return freePromise.then(() => {
        forceRefreshFolders();
        return { success: true, folderId, count: documentIds.length };
      });
    } catch (error) {
      console.error('Errore nella liberazione dei documenti:', error);
      return Promise.reject(error);
    }
  };

  // OTTIMIZZATO: Forzare un refresh delle cartelle per aggiornare l'UI
  const forceRefreshFolders = useCallback(throttle((options = {}) => {
    const now = Date.now();
    const { immediate = false } = options;
    
    // Se è richiesto l'aggiornamento immediato, ignora il controllo del tempo
    if (!immediate) {
      // Non aggiornare se l'ultima operazione è avvenuta meno di 200ms fa
      if (lastRefreshTimeRef.current && (now - lastRefreshTimeRef.current < 200)) {
        console.log("Aggiornamento cartelle ignorato (troppe richieste ravvicinate)");
        return Promise.resolve();
      }
    }
    
    lastRefreshTimeRef.current = now;
    console.log(`Forzando refresh delle cartelle nello store Redux${immediate ? ' (immediato)' : ''}`);
    
    return safeDispatch(refreshFolders())
      .catch(error => {
        console.error("Errore durante l'aggiornamento delle cartelle:", error);
        return Promise.reject(error);
      });
  }, 200), [dispatch]);
  
  // Restituisci le funzioni di azione
  return {
    createNewFolder,
    updateFolderDetails,
    deleteFolderById,
    addDocumentsToFolder,
    removeDocumentsFromFolder,
    freeDocumentsFromFolder,
    forceRefreshFolders
  };
};

export default useFolderActions;