import React from 'react';
import PropTypes from 'prop-types';
import { FaLock } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import './Flashcards.scss';
import { HiLockClosed } from 'react-icons/hi';
import { useDispatch } from 'react-redux';
import { setFeedback } from '../../../../../redux/actions/documentActions';

const FREE_FLASHCARDS_LIMIT = 3;

const FlashcardsSummary = ({ flashcards }) => {
  const { user } = useSelector(state => state.auth);
  const isPremium = user?.plan === 'Premium';

  if (isPremium) return null;

  return (
    <div className="Flashcards__stats">
      <div className="Flashcards__stats-header">
        <div className="Flashcards__stats-icon">
          <HiLockClosed />
        </div>
        <div className="Flashcards__stats-info">
          <div className="Flashcards__stats-details">
            <div className="Flashcards__stats-item Flashcards__stats-item--premium">
              Versione gratuita
            </div>
            <div className="Flashcards__stats-item">
              <span className="premium">{FREE_FLASHCARDS_LIMIT}</span> flashcard gratuite su {flashcards.length} totali
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Flashcards = ({ flashcards, onFeedback, onboardingDocumentData }) => {
  const { user } = useSelector(state => state.auth);
  const isPremium = user?.plan === 'Premium';
  const [flippedCards, setFlippedCards] = React.useState({});
  const flashcardsFeedbackState = useSelector(state => state.document.feedbacks);
  const [cardFeedback, setCardFeedback] = React.useState(Object.fromEntries(flashcardsFeedbackState['flashcards']));
  const dispatch = useDispatch();

  const handleFlip = (index) => {
    setFlippedCards(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const handleFeedback = (type, index, isHelpful) => {
    dispatch(setFeedback(type, index, isHelpful ));
    setCardFeedback(prev => ({
      ...prev,
      [index]: isHelpful
    }));
    onFeedback(index, isHelpful);
  };

  return (
    <div className="Flashcards">
      <FlashcardsSummary flashcards={flashcards} />

      {flashcards.map((flashcard, index) => {
        const isLocked = !isPremium && index >= FREE_FLASHCARDS_LIMIT;
        const currentFeedback = cardFeedback[index];

        return (
          <div 
            key={index} 
            className={clsx("Flashcards__container", {
              "Flashcards__container--locked": isLocked,
              "Flashcards__container--flipped": flippedCards[index]
            })}
            onClick={() => !isLocked && handleFlip(index)}
          >
            {!isLocked ? (
              <>
                <div className="Flashcards__card">
                  <div className="Flashcards__card-front">
                    <div className="Flashcards__number">{index + 1}</div>
                    <div className="Flashcards__content">{flashcard.question}</div>
                  </div>
                  <div className="Flashcards__card-back">
                    <div className="Flashcards__number">{index + 1}</div>
                    <div className="Flashcards__content">{flashcard.answer}</div>
                  </div>
                </div>
                {(onFeedback && !onboardingDocumentData) && (
                  <div className="Flashcards__feedback">
                    <button 
                      className={clsx("Flashcards__feedback-button Flashcards__feedback-button--helpful", {
                        "Flashcards__feedback-button--active": currentFeedback === true
                      })}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleFeedback('flashcards', index, true)
                      }}
                    >
                      Utile
                    </button>
                    <button 
                      className={clsx("Flashcards__feedback-button Flashcards__feedback-button--not-helpful", {
                        "Flashcards__feedback-button--active": currentFeedback === false
                      })}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleFeedback('flashcards', index, false);
                      }}
                    >
                      Non utile
                    </button>
                  </div>
                )}
              </>
            ) : (
              <div className="Flashcards__preview">
                <div className="Flashcards__preview-text">
                  {flashcard.question.substring(0, 40)}...
                </div>
                <div className="Flashcards__lock-message">
                  <FaLock className="Flashcards__lock-icon" />
                  <span>Contenuto Premium</span>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

Flashcards.propTypes = {
  flashcards: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.string.isRequired
    })
  ).isRequired,
  onFeedback: PropTypes.func
};

export default Flashcards; 