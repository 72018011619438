import React, { useState, useCallback, useEffect } from 'react';
import './question.scss';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { HiLockClosed, HiDocumentText } from 'react-icons/hi';
import { BsQuestionCircle, BsLightbulb } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import QuestionStateIcon from './QuestionStateIcon';
import { QuestionStates } from './costants';

const Question = ({ 
  question, 
  answer, 
  isLocked, 
  knowledgeState: initialKnowledgeState, 
  onKnowledgeStateChange,
  onShowDocument,
  questionData
}) => {
  const [isRevealed, setIsRevealed] = useState(false);
  const [knowledgeState, setKnowledgeState] = useState(initialKnowledgeState || QuestionStates.NOT_EVALUATED);
  const { user } = useSelector(state => state.auth);
  const hasDocumentReference = questionData?.documentReference?.id;

  useEffect(() => {
    if (initialKnowledgeState !== undefined) {
      setKnowledgeState(initialKnowledgeState);
    }
  }, [initialKnowledgeState]);

  // Tracciamento analytics quando la risposta viene rivelata
  useEffect(() => {
    if (isRevealed && typeof window.gtag === 'function') {
      window.gtag('event', 'answerRevealed', {
        event_category: 'Documents',
        event_label: 'Answer Revealed',
        email: user.email,
      });
    }
  }, [isRevealed, user.email]);

  const handleReveal = useCallback(() => {
    if (isLocked) return;
    setIsRevealed(prev => !prev);
  }, [isLocked]);

  const handleKnowledgeStateChange = useCallback((e) => {
    e.stopPropagation();
    if (isLocked) return;

    const nextState = (knowledgeState + 1) % QuestionStates.TOTAL_STATES;
    setKnowledgeState(nextState);
    onKnowledgeStateChange?.(nextState);
  }, [knowledgeState, isLocked, onKnowledgeStateChange]);

  const handleShowDocument = useCallback((e) => {
    e.stopPropagation();
    if (isLocked || !hasDocumentReference) return;
    
    onShowDocument?.(questionData);
  }, [isLocked, hasDocumentReference, onShowDocument, questionData]);

  return (
    <div className={clsx(
      'RepetitionQuestion',
      isRevealed && 'RepetitionQuestion--revealed',
      knowledgeState === QuestionStates.TO_REVIEW && 'RepetitionQuestion--state-1',
      knowledgeState === QuestionStates.KNOWN && 'RepetitionQuestion--state-2',
      isLocked && 'RepetitionQuestion--locked'
    )}>
      <div className='RepetitionQuestion__inner'>
        {isLocked ? (
          <div className='RepetitionQuestion__content'>
            <div className='RepetitionQuestion__header'>
              <HiLockClosed className='RepetitionQuestion__icon' />
            </div>
            <div className='RepetitionQuestion__text-wrapper'>
              <p className='RepetitionQuestion__text RepetitionQuestion__text--question'>
                {question}
              </p>
            </div>
          </div>
        ) : (
          <div className='RepetitionQuestion__content' onClick={handleReveal}>
            <div className='RepetitionQuestion__header'>
              <div className='RepetitionQuestion__controls-container'>
                <div
                  className='RepetitionQuestion__reveal-control'
                  onClick={handleReveal}
                >
                  {isRevealed ? (
                    <BsLightbulb className='RepetitionQuestion__icon RepetitionQuestion__icon--revealed' />
                  ) : (
                    <BsQuestionCircle className='RepetitionQuestion__icon' />
                  )}
                </div>
                
                <QuestionStateIcon
                  state={knowledgeState}
                  onClick={handleKnowledgeStateChange}
                />
                
                {hasDocumentReference && !isRevealed && (
                  <div 
                    className='RepetitionQuestion__document-control'
                    onClick={handleShowDocument}
                    title="Visualizza documento originale"
                  >
                    <HiDocumentText className='RepetitionQuestion__document-icon' />
                  </div>
                )}
              </div>
            </div>

            <div className='RepetitionQuestion__text-wrapper'>
              <p className={clsx(
                'RepetitionQuestion__text',
                !isRevealed && 'RepetitionQuestion__text--question',
                isRevealed && 'RepetitionQuestion__text--answer'
              )}>
                {isRevealed ? answer : question}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

Question.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  isLocked: PropTypes.bool,
  knowledgeState: PropTypes.number,
  onKnowledgeStateChange: PropTypes.func,
  onShowDocument: PropTypes.func,
  questionData: PropTypes.object
};

export default Question;