import React, { useState, useEffect, useRef, useCallback } from 'react';
import './questionWithDocument.scss';
import { getDocumentById } from '@lib/firestoreQueries';
import { HiChevronLeft, HiDocumentText, HiOutlineXMark, HiMagnifyingGlass, HiArrowUp, HiArrowDown } from 'react-icons/hi2';
import { toast } from 'react-toastify';
import Spinner from '@ui/spinner/spinner';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

// Configurazione per PDF.js
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

// Funzione per estrarre le frasi delimitatrici da un testo
const extractDelimitingSentences = (text) => {
  if (!text) return { startSentence: null, endSentence: null };
  
  // Dividi il testo in frasi
  const sentences = text.split(/[.!?]+/).filter(s => s.trim().length > 0);
  
  if (sentences.length === 0) return { startSentence: null, endSentence: null };
  if (sentences.length === 1) return { startSentence: sentences[0].trim() + '.', endSentence: sentences[0].trim() + '.' };
  
  // Prendi la prima e l'ultima frase significativa
  return {
    startSentence: sentences[0].trim() + '.',
    endSentence: sentences[sentences.length - 1].trim() + '.'
  };
};

const QuestionWithDocument = ({ 
  question, 
  onClose, 
  isPremium = true, 
  documentData = null,
  updateQuestionContextMarkers
}) => {
  const [document, setDocument] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [matchingIndexes, setMatchingIndexes] = useState([]);
  const [currentMatchIndex, setCurrentMatchIndex] = useState(0);
  const [relevantPhrases, setRelevantPhrases] = useState([]);

  const textContainerRef = useRef(null);
  
  useEffect(() => {
    const fetchDocument = async () => {
      try {
        if (documentData) {
          setDocument(documentData);
          setIsLoading(false);
          return;
        }
        
        if (!question?.documentReference?.id) {
          throw new Error('ID documento non trovato');
        }
        
        setIsLoading(true);
        const fetchedDocument = await getDocumentById(question.documentReference.id);
        if (!fetchedDocument) {
          throw new Error('Documento non trovato');
        }
        setDocument(fetchedDocument);
      } catch (error) {
        console.error('Errore nel recupero del documento:', error);
        toast.error('Errore nel caricamento del documento');
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchDocument();
  }, [question, documentData]);
  
  // Evidenzia le occorrenze trovate
  const highlightTextMatches = useCallback((matches, termLength) => {
    if (!textContainerRef.current || !document || !document.text || !matches.length) return;
    
    const preElement = textContainerRef.current.querySelector('pre');
    if (!preElement) return;
    
    let highlightedText = document.text;
    
    // Costruisci il testo con le evidenziazioni (partendo dalla fine per non invalidare gli indici)
    for (let i = matches.length - 1; i >= 0; i--) {
      const startIndex = matches[i];
      const endIndex = startIndex + termLength;
      
      const beforeMatch = highlightedText.substring(0, startIndex);
      const matchText = highlightedText.substring(startIndex, endIndex);
      const afterMatch = highlightedText.substring(endIndex);
      
      const highlightClass = i === currentMatchIndex ? 'highlighted-text current' : 'highlighted-text';
      highlightedText = `${beforeMatch}<span class="${highlightClass}">${matchText}</span>${afterMatch}`;
    }
    
    preElement.innerHTML = highlightedText;
    
    // Scroll alla corrispondenza corrente
    const currentHighlight = textContainerRef.current.querySelector('.highlighted-text.current');
    if (currentHighlight) {
      currentHighlight.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [document, currentMatchIndex, textContainerRef]);
  
  // Funzione per evidenziare un intervallo di testo
  const highlightTextRange = useCallback((startIndex, endIndex) => {
    if (!textContainerRef.current || !document || !document.text) return;
    
    const preElement = textContainerRef.current.querySelector('pre');
    if (!preElement) return;
    
    const beforeHighlight = document.text.substring(0, startIndex);
    const highlightedText = document.text.substring(startIndex, endIndex);
    const afterHighlight = document.text.substring(endIndex);
    
    preElement.innerHTML = `${beforeHighlight}<span class="highlighted-text current">${highlightedText}</span>${afterHighlight}`;
    
    // Scroll alla sezione evidenziata
    const highlight = textContainerRef.current.querySelector('.highlighted-text.current');
    if (highlight) {
      highlight.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [document, textContainerRef]);
  
  // Funzione di ricerca testo
  const handleSearch = useCallback((text = searchText) => {
    if (!document || !document.text || !text) return;
    
    const searchTerm = text.toLowerCase();
    const docText = document.text.toLowerCase();
    const matches = [];
    
    let index = docText.indexOf(searchTerm);
    while (index !== -1) {
      matches.push(index);
      index = docText.indexOf(searchTerm, index + 1);
    }
    
    setMatchingIndexes(matches);
    setCurrentMatchIndex(0);
    
    // Scroll alla prima occorrenza nel container di testo
    if (matches.length > 0 && textContainerRef.current) {
      highlightTextMatches(matches, searchTerm.length);
    }
  }, [document, searchText, textContainerRef, highlightTextMatches]);
  
  // Cerca il contesto rilevante usando le frasi delimitatrici
  const findContextUsingDelimiters = useCallback(() => {
    if (!document?.text || !question) return false;
    
    // Se la domanda ha già contextMarkers, usali
    if (question.contextMarkers?.startSentence && question.contextMarkers?.endSentence) {
      const { startSentence, endSentence } = question.contextMarkers;
      
      // Trova le posizioni nel documento
      const startIndex = document.text.indexOf(startSentence);
      if (startIndex === -1) return false;
      
      const searchAfterStart = document.text.substring(startIndex + startSentence.length);
      const endIndex = searchAfterStart.indexOf(endSentence);
      if (endIndex === -1) return false;
      
      // Calcola l'indice assoluto della fine
      const absoluteEndIndex = startIndex + startSentence.length + endIndex + endSentence.length;
      
      // Evidenzia il testo rilevante
      highlightTextRange(startIndex, absoluteEndIndex);
      
      return true;
    }
    
    // Se non ci sono contextMarkers, estrai le frasi delimitatrici dalla risposta
    if (question.answer) {
      const { startSentence, endSentence } = extractDelimitingSentences(question.answer);
      if (!startSentence || !endSentence) return false;
      
      // Trova le posizioni nel documento
      const startIndex = document.text.indexOf(startSentence);
      if (startIndex === -1) return false;
      
      // Se start e end sono uguali, cerca solo la frase iniziale
      if (startSentence === endSentence) {
        highlightTextRange(startIndex, startIndex + startSentence.length);
        
        // Salva i contextMarkers per uso futuro
        if (question.id && typeof updateQuestionContextMarkers === 'function') {
          updateQuestionContextMarkers(question.id, { startSentence, endSentence });
        }
        
        return true;
      }
      
      const searchAfterStart = document.text.substring(startIndex + startSentence.length);
      const endIndex = searchAfterStart.indexOf(endSentence);
      if (endIndex === -1) return false;
      
      // Calcola l'indice assoluto della fine
      const absoluteEndIndex = startIndex + startSentence.length + endIndex + endSentence.length;
      
      // Evidenzia il testo rilevante
      highlightTextRange(startIndex, absoluteEndIndex);
      
      // Salva i contextMarkers per uso futuro
      if (question.id && typeof updateQuestionContextMarkers === 'function') {
        updateQuestionContextMarkers(question.id, { startSentence, endSentence });
      }
      
      return true;
    }
    
    return false;
  }, [document, question, highlightTextRange, updateQuestionContextMarkers]);
  
  // Estrazione frasi rilevanti dal documento in base alla domanda e risposta
  useEffect(() => {
    if (!document || !document.text || !question) return;
    
    // Prima prova a trovare il contesto usando le frasi delimitatrici
    const foundUsingDelimiters = findContextUsingDelimiters();
    
    // Se non è stato trovato usando i delimitatori, usa il metodo originale
    if (!foundUsingDelimiters) {
      // Estrai termini chiave dalla domanda e risposta
      const extractKeyTerms = (text) => {
        if (!text) return [];
        
        // Rimuovi parole comuni e articoli/congiunzioni
        const stopWords = ['il', 'lo', 'la', 'i', 'gli', 'le', 'un', 'uno', 'una', 'e', 'o', 'a', 'per', 'con', 'su', 'in', 'da', 'di', 'del', 'della', 'dei', 'degli', 'delle', 'che', 'chi', 'è', 'sono', 'ed', 'come', 'cosa'];
        
        // Tokenizza il testo in parole
        const words = text.toLowerCase()
          .replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, '')
          .split(/\s+/)
          .filter(word => word.length > 3 && !stopWords.includes(word));
        
        // Rimuovi duplicati e prendi al massimo 8 parole chiave
        return [...new Set(words)].slice(0, 8);
      };
      
      const questionTerms = extractKeyTerms(question.question);
      const answerTerms = extractKeyTerms(question.answer);
      
      // Combina i termini e rimuovi duplicati
      const keyTerms = [...new Set([...questionTerms, ...answerTerms])];
      
      // Trova frasi nel documento che contengono i termini chiave
      const findRelevantPhrases = (text, terms) => {
        if (!text || !terms.length) return [];
        
        // Divide il testo in frasi
        const sentences = text.split(/[.!?]+/).filter(s => s.trim().length > 0);
        
        // Valuta ogni frase in base ai termini contenuti
        const scoredSentences = sentences.map(sentence => {
          const normalizedSentence = sentence.toLowerCase();
          let score = 0;
          
          terms.forEach(term => {
            if (normalizedSentence.includes(term)) {
              score += 1;
            }
          });
          
          return { sentence: sentence.trim(), score, terms: terms.filter(term => normalizedSentence.includes(term)) };
        });
        
        // Filtra frasi con almeno un termine e ordina per punteggio
        return scoredSentences
          .filter(item => item.score > 0)
          .sort((a, b) => b.score - a.score)
          .slice(0, 5); // Prendi le 5 frasi più rilevanti
      };
      
      const phrases = findRelevantPhrases(document.text, keyTerms);
      setRelevantPhrases(phrases);
      
      // Pre-imposta il testo di ricerca alla frase più rilevante se esiste
      if (phrases.length > 0) {
        // Seleziona una porzione della frase più rilevante
        const topPhrase = phrases[0].sentence;
        if (topPhrase.length > 20) {
          // Prendi una sottostringa significativa
          const searchPortion = topPhrase.substring(0, Math.min(30, topPhrase.length));
          setSearchText(searchPortion);
          setTimeout(() => handleSearch(searchPortion), 500);
        }
      }
    }
  }, [document, question, handleSearch, findContextUsingDelimiters]);
  
  // Navigazione tra le occorrenze
  const navigateToNextMatch = useCallback(() => {
    if (matchingIndexes.length === 0) return;
    
    const nextIndex = (currentMatchIndex + 1) % matchingIndexes.length;
    setCurrentMatchIndex(nextIndex);
    highlightTextMatches(matchingIndexes, searchText.length);
  }, [matchingIndexes, currentMatchIndex, searchText, highlightTextMatches]);
  
  const navigateToPrevMatch = useCallback(() => {
    if (matchingIndexes.length === 0) return;
    
    const prevIndex = (currentMatchIndex - 1 + matchingIndexes.length) % matchingIndexes.length;
    setCurrentMatchIndex(prevIndex);
    highlightTextMatches(matchingIndexes, searchText.length);
  }, [matchingIndexes, currentMatchIndex, searchText, highlightTextMatches]);
  
  // Gestione PDF
  const handleDocumentLoadSuccess = useCallback(({ numPages }) => {
    setNumPages(numPages);
  }, []);
  
  const changePageNext = useCallback(() => {
    if (pageNumber < numPages) {
      setPageNumber(prevPage => prevPage + 1);
    }
  }, [pageNumber, numPages]);
  
  const changePagePrev = useCallback(() => {
    if (pageNumber > 1) {
      setPageNumber(prevPage => prevPage - 1);
    }
  }, [pageNumber]);
  
  // Determina se siamo su un dispositivo mobile
  const isMobile = useCallback(() => {
    return window.innerWidth < 576;
  }, []);
  
  const renderDocumentContent = () => {
    if (!document || !document.text) {
      return (
        <div className="QuestionWithDocument__content__empty">
          <p>Contenuto del documento non disponibile</p>
        </div>
      );
    }
    
    // Visualizzazione PDF se disponibile
    if (document.type?.toLowerCase() === 'pdf' && document.base64) {
      return (
        <div className="QuestionWithDocument__content__pdf">
          <div className="QuestionWithDocument__content__pdf-controls">
            <button 
              onClick={changePagePrev} 
              disabled={pageNumber <= 1}
            >
              <HiArrowUp />
              <span>Pagina precedente</span>
            </button>
            <span>
              Pagina {pageNumber} di {numPages || '--'}
            </span>
            <button 
              onClick={changePageNext} 
              disabled={pageNumber >= numPages}
            >
              <span>Pagina successiva</span>
              <HiArrowDown />
            </button>
          </div>
          <Document
            file={`data:application/pdf;base64,${document.base64}`}
            onLoadSuccess={handleDocumentLoadSuccess}
            loading={<Spinner size="md" />}
            error={<p>Errore nel caricamento del PDF</p>}
          >
            <Page 
              pageNumber={pageNumber} 
              renderTextLayer={true}
              renderAnnotationLayer={true}
              scale={1.2}
              width={window.innerWidth < 768 ? window.innerWidth - 60 : undefined}
            />
          </Document>
        </div>
      );
    }
    
    // Mostra il testo estratto con ricerca
    return (
      <>
        <div className="QuestionWithDocument__content__search">
          <div className="search-controls">
            <input 
              type="text" 
              placeholder="Cerca nel testo..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
            />
            <div className="search-buttons-group">
              <button onClick={() => handleSearch()}>
                <HiMagnifyingGlass />
              </button>
              
              {matchingIndexes.length > 0 && (
                <>
                  <button onClick={navigateToPrevMatch}>
                    <HiArrowUp />
                  </button>
                  <button onClick={navigateToNextMatch}>
                    <HiArrowDown />
                  </button>
                </>
              )}
            </div>
            
            {matchingIndexes.length > 0 && (
              <span>
                {currentMatchIndex + 1} di {matchingIndexes.length}
              </span>
            )}
          </div>
        </div>
        
        <div className="QuestionWithDocument__content__text" ref={textContainerRef}>
          <pre>{document.text}</pre>
        </div>
      </>
    );
  };
  
  // Aggiungi un listener per il ridimensionamento della finestra
  useEffect(() => {
    const handleResize = () => {
      // Forza un re-render quando la finestra viene ridimensionata
      setPageNumber(pageNumber);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [pageNumber]);
  
  return (
    <div className="QuestionWithDocument">
      <div className="QuestionWithDocument__header">
        <button 
          className="QuestionWithDocument__header__back-button"
          onClick={onClose}
        >
          <HiChevronLeft />
          <span>Torna alle domande</span>
        </button>
        <h3 className="QuestionWithDocument__header__title">
          <HiDocumentText />
          {document?.title || 'Materiale di studio'}
        </h3>
        <button 
          className="QuestionWithDocument__header__close-button"
          onClick={onClose}
        >
          <HiOutlineXMark />
        </button>
      </div>
      
      <div className="QuestionWithDocument__container">
        <div className="QuestionWithDocument__question">
          <h4>Domanda:</h4>
          <p>{question?.question}</p>
          <div className="QuestionWithDocument__answer">
            <h4>Risposta:</h4>
            <p>{question?.answer}</p>
          </div>
        </div>
        
        <div className="QuestionWithDocument__content">
          <h4>Materiale di studio:</h4>
          {isLoading ? (
            <div className="QuestionWithDocument__loading">
              <Spinner size="md" />
              <p>Caricamento documento...</p>
            </div>
          ) : renderDocumentContent()}
        </div>
      </div>
    </div>
  );
};

export default QuestionWithDocument; 