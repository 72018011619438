import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetInterrogationQA } from "../../../redux/actions/examActions";

const useGoBack = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()

  // go back when user click ripeti esame
  const goToReview = () => {
    dispatch(resetInterrogationQA());
    navigate(-1);
  };

  return { goToReview };
};

export default useGoBack;
