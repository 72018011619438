import React, { useState, useEffect } from 'react';
import { Users, ChevronRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import './ReferralBanner.scss';

const ReferralBanner = () => {
  const [isVisible, setIsVisible] = useState(true);
  const navigate = useNavigate();

  // Close banner and store state in localStorage
  const closeBanner = () => {
    setIsVisible(false);

    // Track user closed banner
    const userClosedReferralBanner = (user) => {
      if (typeof window.gtag === 'function') {
        window.gtag('event', 'userClosedReferralBanner', {
          'event_category': 'ReferralCode',
          'event_label': 'User Closed Banner',
          'email': user?.email || 'utente_anonimo'
        });
      }
    }; 
  };

  if (!isVisible) return null;

  return (
    <div className="ReferralBanner">
      <div className="container">
        <div className="ReferralBanner__content">
          <div className="ReferralBanner__icon">
            <Users size={18} />
          </div>
          <div className="ReferralBanner__text-container">
            {/* Versione desktop del testo */}
            <p className="ReferralBanner__text-desktop">
              Guadagna €5 in giftcard Amazon per ogni amico che fai iscrivere a StudierAI Premium Annuale!
            </p>
            
            {/* Versione mobile con scorrimento */}
            <div className="ReferralBanner__text-mobile">
              <div className="ReferralBanner__text-marquee">
                <span className="ReferralBanner__text">
                  Guadagna €5 in giftcard Amazon per ogni amico che fai iscrivere a StudierAI Premium Annuale!
                </span>
                <span className="ReferralBanner__text" style={{ marginLeft: '40px' }}>
                  Guadagna €5 in giftcard Amazon per ogni amico che fai iscrivere a StudierAI Premium Annuale!
                </span>
              </div>
            </div>
          </div>
          <button className="ReferralBanner__button" onClick={(e) => {
            e.stopPropagation();
            navigate('/home/referralCode');
          }}>Invita</button>
        </div>
          <button 
            className="ReferralBanner__close" 
            onClick={(e) => {
              e.stopPropagation();
              closeBanner();
            }}
            aria-label="Chiudi banner"
          >
            ✕
          </button>
      </div>
    </div>
  );
};

export default ReferralBanner; 
