/**
 * Funzioni di utilità per gestire Promise in modo più sicuro
 */

/**
 * Aggiunge un timeout a una Promise
 * @param {Promise} promise - La Promise da wrappare
 * @param {number} timeoutMs - Timeout in millisecondi
 * @param {string} errorMessage - Messaggio di errore da mostrare in caso di timeout
 * @returns {Promise} - Una nuova Promise che si risolve o viene rifiutata entro il timeout
 */
export const withTimeout = (promise, timeoutMs = 10000, errorMessage = 'Operazione scaduta') => {
  // Crea una Promise che si risolve dopo timeoutMs
  const timeoutPromise = new Promise((_, reject) => {
    const id = setTimeout(() => {
      clearTimeout(id);
      reject(new Error(`Timeout (${timeoutMs}ms): ${errorMessage}`));
    }, timeoutMs);
  });
  
  // Restituisce la Promise che si completa per prima tra le due
  return Promise.race([promise, timeoutPromise]);
};

/**
 * Esegue una Promise con retry in caso di errore
 * @param {Function} promiseFn - Funzione che restituisce una Promise
 * @param {Object|number} options - Opzioni di configurazione o numero massimo di tentativi
 * @param {number} [options.maxRetries=3] - Numero massimo di tentativi
 * @param {number} [options.delay=1000] - Ritardo tra i tentativi in millisecondi
 * @param {number} [options.backoffFactor=1] - Fattore di incremento del ritardo
 * @param {number} [delayMs] - (Deprecato) Ritardo tra i tentativi in millisecondi
 * @returns {Promise} - La Promise risultante
 */
export const withRetry = (promiseFn, options = {}, delayMs) => {
  // Gestione della compatibilità con vecchie chiamate
  let maxRetries = 3;
  let delay = 1000;
  let backoffFactor = 1;
  
  if (typeof options === 'object') {
    maxRetries = options.maxRetries || 3;
    delay = options.delay || 1000;
    backoffFactor = options.backoffFactor || 1;
  } else if (typeof options === 'number') {
    // Vecchio formato: withRetry(fn, maxRetries, delayMs)
    maxRetries = options;
    delay = delayMs || 1000;
  }
  
  return new Promise(async (resolve, reject) => {
    let lastError;
    
    for (let attempt = 1; attempt <= maxRetries; attempt++) {
      try {
        const result = await promiseFn();
        return resolve(result);
      } catch (error) {
        console.log(`Tentativo ${attempt} fallito:`, error);
        lastError = error;
        
        if (attempt < maxRetries) {
          // Applica backoff esponenziale se richiesto
          const currentDelay = delay * Math.pow(backoffFactor, attempt - 1);
          await new Promise(resolve => setTimeout(resolve, currentDelay));
        }
      }
    }
    
    reject(lastError || new Error('Operazione fallita dopo diversi tentativi'));
  });
};

/**
 * Cancella una Promise se il componente viene smontato
 * @param {Promise} promise - La Promise da cancellare
 * @param {boolean} shouldContinue - Flag che indica se il componente è ancora montato
 * @returns {Promise} - Una Promise che viene cancellata se shouldContinue diventa false
 */
export const cancelablePromise = (promise, shouldContinue) => {
  return new Promise((resolve, reject) => {
    promise.then(
      result => shouldContinue ? resolve(result) : reject({ isCanceled: true }),
      error => shouldContinue ? reject(error) : reject({ isCanceled: true })
    );
  });
};

/**
 * Esegue una Promise con un meccanismo di lock per evitare esecuzioni concorrenti
 * @param {Function} fn - Funzione che restituisce una Promise
 * @param {Object} lockObj - Oggetto che contiene lo stato del lock
 * @returns {Promise} - La Promise risultante
 */
export const withLock = (fn, lockObj = { locked: false }) => {
  return new Promise((resolve, reject) => {
    // Se è già in uso, rifiuta la Promise
    if (lockObj.locked) {
      console.log("Operazione bloccata: un'altra operazione è in corso");
      return reject(new Error("Operazione in corso. Riprova tra qualche istante."));
    }
    
    // Imposta il lock
    lockObj.locked = true;
    
    // Esegui la funzione e rilascia il lock
    return Promise.resolve()
      .then(() => fn())
      .then(result => {
        lockObj.locked = false;
        resolve(result);
        return result;
      })
      .catch(error => {
        lockObj.locked = false;
        // Gestiamo in modo sicuro l'errore prima di propagarlo
        const safeError = error || new Error('Errore sconosciuto nell\'operazione');
        reject(safeError);
        return Promise.reject(safeError);
      });
  });
};

/**
 * Crea una versione throttled di una funzione
 * @param {Function} fn - Funzione da throttlare
 * @param {number} wait - Tempo di attesa in ms
 * @returns {Function} - Funzione throttled
 */
export const throttle = (fn, wait = 300) => {
  let lastCall = 0;
  let timeout = null;
  let lastArgs = null;
  
  return function throttled(...args) {
    const now = Date.now();
    lastArgs = args;
    
    // Se è passato abbastanza tempo dall'ultima chiamata
    if (now - lastCall >= wait) {
      lastCall = now;
      return fn.apply(this, args);
    }
    
    // Altrimenti programma una chiamata futura
    return new Promise((resolve) => {
      if (timeout) {
        clearTimeout(timeout);
      }
      
      timeout = setTimeout(() => {
        lastCall = Date.now();
        timeout = null;
        resolve(fn.apply(this, lastArgs));
      }, wait - (now - lastCall));
    });
  };
}; 