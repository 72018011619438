import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react'
import './materialsDocuments.scss'
import { formatTimeStamp } from './formatTimeStamp'
import { useSelector, useDispatch } from 'react-redux'
import { FolderPlus, Check, X, ArrowLeft, ArrowRight } from 'lucide-react';
import { createFolder } from '../../../redux/actions/documentActions';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

// Importo i componenti dalla nuova struttura
import { Document } from './document/document'
import { Folder, FolderContent } from '../folders';
// Importiamo l'utility per gestire i colori delle cartelle in modo coerente in tutta l'app
import { getFolderColor } from '../folders/utils/folderColors';
// Importiamo anche l'oggetto folderColors direttamente per accedere a tutti i colori disponibili
import folderColors from '../folders/utils/folderColors';
import useGetOnboardingState from '../../uploadNotes/uploadNotesCard/uploadNotesForm/hooks/useGetOnboardingState'
import Button from '@ui/button/button';
import Input from '@ui/input/input';

const MaterialsDocuments = ({ selectedDocument, onChange, onCreateFolder, onFolderNavigation, showSearchResults = false, searchQuery = '' }) => {
  const allDocuments = useSelector(state => state.document.documents);
  const allFolders = useSelector(state => state.document.folders);
  const currentStep = useSelector(state => state.onboarding.currentStep);
  const { getCurrentStep } = useGetOnboardingState();
  const dispatch = useDispatch();
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [isCreatingFolder, setIsCreatingFolder] = useState(false);
  const [openFolderId, setOpenFolderId] = useState(null);
  const [selectedFolderColor, setSelectedFolderColor] = useState('blue'); // Colore predefinito: blu
  const [folderName, setFolderName] = useState('');
  const [isNamingFolder, setIsNamingFolder] = useState(false);
  const [inputFocused, setInputFocused] = useState(false);
  const folderNameInputRef = useRef(null);
  const [isFolderOpen, setIsFolderOpen] = useState(false);
  const [openFolderData, setOpenFolderData] = useState(null);
  const [forceUpdate, setForceUpdate] = useState(false);

  // Filtra le cartelle per mostrare solo quelle di primo livello (senza parentId)
  const folders = useMemo(() => {
    return allFolders.filter(folder => !folder.parentId);
  }, [allFolders]);

  // Filtra i documenti per mostrare solo quelli che non sono già assegnati a cartelle
  const documents = useMemo(() => {
    // Raccogli tutti gli ID dei documenti già presenti in cartelle
    const documentsInFolders = new Set();
    
    // Controlla nelle cartelle principali
    allFolders.forEach(folder => {
      (folder.documentIds || []).forEach(docId => {
        documentsInFolders.add(docId);
      });
      
      // Controlla anche nei subfolders
      if (folder.subfolders && folder.subfolders.length > 0) {
        folder.subfolders.forEach(subfolder => {
          (subfolder.documentIds || []).forEach(docId => {
            documentsInFolders.add(docId);
          });
        });
      }
    });
    
    console.log(`Documenti in cartelle: ${documentsInFolders.size} su ${allDocuments.length}`);
    
    // Filtra i documenti che non sono in nessuna cartella
    return allDocuments.filter(doc => !documentsInFolders.has(doc.id));
  }, [allDocuments, allFolders]);

  // Filtra i documenti che non sono già in altre cartelle (per la creazione cartella)
  const availableDocuments = useMemo(() => {
    if (!isCreatingFolder) return documents;
    return documents;
  }, [documents, isCreatingFolder]);

  // Memorizza il colore della cartella corrente usando getFolderColor
  const currentColor = useMemo(() => 
    getFolderColor(selectedFolderColor)
  , [selectedFolderColor]);

  // re-render when onboarding document is removed
  useEffect(() => {
    getCurrentStep();
  }, [currentStep, getCurrentStep]);

  // Focus sull'input del nome quando si passa alla fase di naming
  useEffect(() => {
    if (isNamingFolder && folderNameInputRef.current) {
      folderNameInputRef.current.focus();
    }
  }, [isNamingFolder]);

  // Memorizza le funzioni di callback per evitare ri-creazioni ad ogni render
  const handleSelectFolder = useCallback((folderId) => {
    if (!isCreatingFolder) {
      setSelectedFolder(folderId);
    }
  }, [isCreatingFolder]);

  const handleOpenFolder = (folderId) => {
    console.log("Apertura cartella:", folderId);
    
    // Cerca la cartella corrispondente all'ID fornito
    const targetFolder = folders.find(f => f.id === folderId);
    
    if (!targetFolder) {
      console.error("Cartella non trovata:", folderId);
      return;
    }
    
    console.log("Cartella trovata:", targetFolder);
    
    // Controlla se è una sottocartella (ha un parentId)
    if (targetFolder.parentId) {
      const parentFolder = folders.find(f => f.id === targetFolder.parentId || 
        (f.id.startsWith('firebase_') && f.id.replace('firebase_', '') === targetFolder.parentId));
      
      if (parentFolder) {
        console.log("Parent folder trovata:", parentFolder);
      } else {
        console.log("Parent folder non trovata per ID:", targetFolder.parentId);
      }
    }
    
    setSelectedFolder(folderId);
    setIsFolderOpen(true);
    setOpenFolderData(targetFolder);
    
    // Notifica al componente padre che l'utente è entrato in una cartella
    if (onFolderNavigation && typeof onFolderNavigation === 'function') {
      onFolderNavigation(true);
    }
  };

  const handleBackFromFolder = useCallback((subfolderId, parentFolder) => {
    console.log("Ritorno da cartella:", subfolderId, parentFolder);
    
    // Funzione helper per cercare una sottocartella in modo ricorsivo
    const findSubfolderRecursively = (folders, targetId) => {
      // Cerca la sottocartella direttamente nella lista delle cartelle
      const directMatch = folders.find(f => 
        f.id === targetId || 
        f.id === targetId.replace('firebase_', '') ||
        targetId === 'firebase_' + f.id
      );
      
      if (directMatch) {
        return directMatch;
      }
      
      // Cerca nelle sottocartelle di ogni cartella
      for (const folder of folders) {
        if (folder.subfolders && folder.subfolders.length > 0) {
          const nestedMatch = folder.subfolders.find(sf => 
            sf.id === targetId || 
            sf.id === targetId.replace('firebase_', '') ||
            targetId === 'firebase_' + sf.id
          );
          
          if (nestedMatch) {
            return nestedMatch;
          }
          
          // Cerca in modo ricorsivo nelle sotto-sottocartelle
          if (folder.subfolders && folder.subfolders.some(sf => sf.subfolders && sf.subfolders.length > 0)) {
            const deepMatch = findSubfolderRecursively(folder.subfolders, targetId);
            if (deepMatch) return deepMatch;
          }
        }
      }
      
      return null;
    };
    
    // Se è stato passato un parentFolder ma non un subfolderId, significa che stiamo 
    // tornando indietro da una sottocartella alla cartella genitore
    if (!subfolderId && parentFolder) {
      console.log("Apertura cartella genitore:", parentFolder.name, parentFolder.id);
      
      // Cerca la cartella genitore tra le cartelle principali
      const parentFolderData = allFolders.find(f => 
        f.id === parentFolder.id || 
        f.id === parentFolder.id.replace('firebase_', '') ||
        parentFolder.id === 'firebase_' + f.id
      );
      
      if (parentFolderData) {
        console.log("Cartella genitore trovata:", parentFolderData);
        // Imposta la cartella genitore come cartella corrente
        setOpenFolderId(parentFolder.id);
        setSelectedFolder(parentFolder.id);
        setIsFolderOpen(true);
        setOpenFolderData(parentFolderData);
        return;
      } else {
        // Se per qualche motivo non troviamo la cartella genitore, usiamo i dati che ci sono stati passati
        console.log("Usando i dati del parentFolder passati direttamente:", parentFolder);
        setOpenFolderId(parentFolder.id);
        setSelectedFolder(parentFolder.id);
        setIsFolderOpen(true);
        setOpenFolderData(parentFolder);
        return;
      }
    }
    
    // Se è stato specificato un subfolderId e parentFolder, apri la sottocartella
    if (subfolderId && parentFolder) {
      console.log("Navigazione a sottocartella:", subfolderId);
      
      // Prima verifica nei subfolders della cartella genitore
      if (parentFolder.subfolders && parentFolder.subfolders.length > 0) {
        const subfolderInParent = parentFolder.subfolders.find(sf => 
          sf.id === subfolderId || 
          sf.id === subfolderId.replace('firebase_', '') ||
          subfolderId === 'firebase_' + sf.id
        );
        
        if (subfolderInParent) {
          console.log("Dati sottocartella trovati nei subfolders:", subfolderInParent);
          setSelectedFolder(subfolderId);
          setIsFolderOpen(true);
          setOpenFolderData(subfolderInParent);
          return;
        }
      }
      
      // Se non trovato nei subfolders, cerca nelle cartelle principali
      const subfolderData = findSubfolderRecursively(allFolders, subfolderId);
      
      if (subfolderData) {
        console.log("Dati sottocartella trovati con ricerca ricorsiva:", subfolderData);
        setSelectedFolder(subfolderId);
        setIsFolderOpen(true);
        setOpenFolderData(subfolderData);
        return;
      }
      
      console.error("Sottocartella non trovata:", subfolderId);
      toast.error("Impossibile aprire la sottocartella: non trovata");
      setOpenFolderId(null);
      setSelectedFolder(null);
      setIsFolderOpen(false);
      setOpenFolderData(null);
      return;
    }
    
    // Se non è stato specificato né subfolderId né parentFolder, torna alla vista principale
    console.log("Ritorno alla vista principale");
    setOpenFolderId(null);
    setSelectedFolder(null);
    setIsFolderOpen(false);
    setOpenFolderData(null);
    
    // Notifica al componente padre che l'utente è uscito da tutte le cartelle
    if (onFolderNavigation && typeof onFolderNavigation === 'function') {
      onFolderNavigation(false);
    }
  }, [allFolders, onFolderNavigation]);

  const toggleDocumentSelection = useCallback((documentId) => {
    if (isCreatingFolder) {
      setSelectedDocuments(prevSelected => 
        prevSelected.includes(documentId) 
          ? prevSelected.filter(id => id !== documentId)
          : [...prevSelected, documentId]
      );
    } else {
      onChange(documentId);
    }
  }, [isCreatingFolder, onChange]);

  // Avvia il processo di creazione cartella - Modificato per essere richiamato dall'esterno
  const handleStartCreateFolder = useCallback(() => {
    setIsCreatingFolder(true);
    setSelectedDocuments([]);
    setIsNamingFolder(false);
    setFolderName('');
  }, []);

  // Esponi la funzione per creare cartelle
  useEffect(() => {
    if (onCreateFolder && typeof onCreateFolder === 'function') {
      onCreateFolder(handleStartCreateFolder);
    }
  }, [onCreateFolder, handleStartCreateFolder]);

  // Gestisce il cambio del nome della cartella
  const handleFolderNameChange = (e) => {
    setFolderName(e.target.value);
  };

  // Crea la cartella con i documenti selezionati
  const handleCreateFolder = () => {
    if (!folderName.trim()) {
      toast.error('Inserisci un nome per la cartella');
      return;
    }

    // Verifica che non ci siano già cartelle con lo stesso nome
    const nameExists = allFolders.some(folder => 
      folder.name.toLowerCase() === folderName.trim().toLowerCase()
    );

    if (nameExists) {
      toast.error('Esiste già una cartella con questo nome');
      return;
    }

    // Mostra un toast di operazione in corso
    const loadingToast = toast.loading(`Creazione cartella "${folderName.trim()}" in corso...`);

    // Crea una nuova cartella con il colore selezionato
    dispatch(createFolder(folderName.trim(), selectedDocuments, selectedFolderColor))
      .then(() => {
        // Chiudi il toast di caricamento
        toast.dismiss(loadingToast);
        toast.success('Cartella creata con successo');
        // Reset dello stato
        handleCancelCreateFolder();
      })
      .catch(error => {
        // Chiudi il toast di caricamento
        toast.dismiss(loadingToast);
        console.error('Errore nella creazione della cartella:', error);
        toast.error('Errore nella creazione della cartella');
      });
  };

  // Annulla la creazione cartella
  const handleCancelCreateFolder = () => {
    setIsCreatingFolder(false);
    setSelectedDocuments([]);
    setSelectedFolderColor('blue'); // Reset del colore selezionato
    setIsNamingFolder(false);
    setFolderName('');
  };

  // Passa alla fase di naming della cartella
  const handleProceedToNaming = () => {
    if (selectedDocuments.length === 0) {
      toast.error('Seleziona almeno un documento da aggiungere alla cartella');
      return;
    }
    setIsNamingFolder(true);
  };

  // Torna alla selezione dei documenti dalla fase di naming
  const handleBackToSelection = () => {
    setIsNamingFolder(false);
  };

  // Gestisce la selezione del colore della cartella
  const handleSelectFolderColor = (color) => {
    setSelectedFolderColor(color);
  };

  // Gestisce il cambio di documento o cartella dal componente FolderContent
  const handleDocumentChange = useCallback((documentId, updatedFolder, updatedParentFolder) => {
    // Verifica se ci sono state modifiche alla cartella
    if (updatedFolder) {
      console.log("Aggiornamento cartella:", updatedFolder, "Parent:", updatedParentFolder);
      
      // Aggiorna lo stato locale
      if (openFolderData && openFolderData.id === updatedFolder.id) {
        // Aggiorna direttamente lo stato locale
        const newOpenFolderData = {
          ...openFolderData,
          ...updatedFolder
        };
        setOpenFolderData(newOpenFolderData);
        console.log("Stato cartella locale aggiornato:", newOpenFolderData);
      }
      
      // Se è stata aggiornata anche la cartella genitore, aggiorna il Redux store
      if (updatedParentFolder) {
        console.log("Aggiornamento cartella genitore:", updatedParentFolder);
        
        // Aggiorna il Redux store per la cartella genitore
        dispatch({
          type: 'UPDATE_FOLDER',
          payload: {
            folderId: updatedParentFolder.id,
            updates: {
              documentIds: updatedParentFolder.documentIds
            }
          }
        });
      }
      
      // Aggiorna il Redux store per la cartella attuale
      dispatch({
        type: 'UPDATE_FOLDER',
        payload: {
          folderId: updatedFolder.id,
          updates: {
            documentIds: updatedFolder.documentIds
          }
        }
      });
      
      // Forza un aggiornamento forzato dei dati della cartella corrente
      const folder = allFolders.find(f => f.id === updatedFolder.id);
      if (folder) {
        console.log("Trovata cartella nel Redux store, forzando aggiornamento:");
        // Forza un re-render dell'interfaccia
        setForceUpdate(prev => !prev);
      }
    } 
    // Altrimenti, gestisci la selezione del documento
    else if (documentId) {
      console.log("Selezione documento:", documentId);
      onChange(documentId);
    }
  }, [onChange, dispatch, allFolders, openFolderData]);

  // Memo per i dati della cartella, indipendentemente dal fatto che sia aperta
  const folderData = useMemo(() => {
    if (!openFolderId) return null;
    
    const folder = allFolders.find(f => f.id === openFolderId);
    if (!folder) return null;
    
    // Filtra i documenti che appartengono alla cartella
    const folderDocuments = allDocuments.filter(doc => 
      folder.documentIds?.includes(doc.id)
    );
    
    console.log(`Documenti filtrati per cartella ${folder.name}:`, folderDocuments.length);
    return { folder, folderDocuments };
  }, [allFolders, allDocuments, openFolderId]);

  // Filtro dei documenti se è impostata la ricerca
  const filteredDocuments = useMemo(() => {
    if (!showSearchResults || !searchQuery.trim()) {
      return isCreatingFolder ? availableDocuments : documents;
    }
    
    const query = searchQuery.toLowerCase().trim();
    return allDocuments.filter(doc => 
      doc.title.toLowerCase().includes(query) ||
      (doc.tags && Array.isArray(doc.tags) && doc.tags.some(tag => tag.toLowerCase().includes(query)))
    );
  }, [showSearchResults, searchQuery, isCreatingFolder, availableDocuments, documents, allDocuments]);

  // Otteniamo tutti i colori disponibili da folderColors
  const availableColors = useMemo(() => {
    // Utilizziamo direttamente l'oggetto folderColors importato 
    // che contiene tutti i colori disponibili
    return Object.values(folderColors);
  }, []);

  // Contenuto cartella aperta
  if (isFolderOpen && openFolderData) {
    return (
      <FolderContent
        folder={openFolderData}
        documents={allDocuments}
        onBack={handleBackFromFolder}
        onChange={handleDocumentChange}
      />
    );
  }
  
  return (
    <div className='MaterialsDocuments'>
      {/* Header con controlli per la creazione cartella */}
      {isCreatingFolder && (
        <div className="MaterialsDocuments__selection-header" style={{
          backgroundColor: `rgba(${currentColor.rgb}, 0.05)`,
          borderColor: currentColor.color,
          borderWidth: '2px',
          borderStyle: 'solid'
        }}>
          {isNamingFolder ? (
            <div className="MaterialsDocuments__naming-container">
              <div className="MaterialsDocuments__naming-input-group">
                <div className="MaterialsDocuments__folder-icon" style={{ color: currentColor.color }}>
                  <FolderPlus size={24} />
                </div>
                <Input
                  ref={folderNameInputRef}
                  className="MaterialsDocuments__folder-name-input"
                  type="text"
                  placeholder="Nome della cartella"
                  value={folderName}
                  onChange={handleFolderNameChange}
                  maxLength={50}
                  onFocus={() => setInputFocused(true)}
                  onBlur={() => setInputFocused(false)}
                  style={{ 
                    color: 'var(--grayscale-color-900)',
                    borderColor: inputFocused ? currentColor.color : 'var(--grayscale-color-300)',
                    boxShadow: inputFocused ? `0 0 0 2px rgba(${currentColor.rgb}, 0.15)` : 'none',
                    '--current-folder-color-rgb': currentColor.rgb
                  }}
                />
              </div>
              <div className="MaterialsDocuments__buttons">
                <Button 
                  variant="custom" 
                  className="MaterialsDocuments__save-button"
                  onClick={handleCreateFolder}
                  disabled={!folderName.trim()}
                  style={{
                    backgroundColor: currentColor.buttonBg,
                    '--button-hover-bg': currentColor.buttonHoverBg,
                    '--button-active-bg': currentColor.buttonActiveBg,
                    '--button-shine-color': `rgba(255, 255, 255, 0.2)`
                  }}
                >
                  <span className="desktop-only">Crea Cartella</span>
                  <Check size={18} className="mobile-only" />
                </Button>
                <Button 
                  variant="outline" 
                  className="MaterialsDocuments__back-button"
                  onClick={handleBackToSelection}
                  style={{
                    '--button-hover-bg': `rgba(${currentColor.rgb}, 0.1)`,
                    '--button-hover-color': currentColor.color,
                    '--button-hover-border': currentColor.color
                  }}
                >
                  <ArrowLeft size={18} />
                  <span>Indietro</span>
                </Button>
                <Button 
                  variant="outline" 
                  className="MaterialsDocuments__cancel-button"
                  onClick={handleCancelCreateFolder}
                  style={{
                    '--button-hover-bg': `rgba(${currentColor.rgb}, 0.1)`,
                    '--button-hover-color': currentColor.color,
                    '--button-hover-border': currentColor.color
                  }}
                >
                  <X size={18} />
                  <span>Annulla</span>
                </Button>
              </div>
            </div>
          ) : (
            <>
              <div className="MaterialsDocuments__selection-container">
                {isCreatingFolder && availableDocuments.length === 0 ? (
                  <span className="MaterialsDocuments__selection-text MaterialsDocuments__selection-text--empty">
                    Non ci sono documenti disponibili. Tutti i documenti sono già in cartelle.
                  </span>
                ) : (
                  <>
                    <div className="MaterialsDocuments__document-selection">
                      <h3 className="MaterialsDocuments__selection-text">
                        {selectedDocuments.length === 0 
                          ? 'Seleziona i documenti' 
                          : 'Documenti selezionati'}
                      </h3>
                      {selectedDocuments.length > 0 && (
                        <span className="MaterialsDocuments__selection-count">
                          {selectedDocuments.length === 1
                            ? '1 documento selezionato'
                            : `${selectedDocuments.length} documenti selezionati`
                          }
                          {availableDocuments.length > 0 && ` (su ${availableDocuments.length} disponibili)`}
                        </span>
                      )}
                    </div>
                  </>
                )}
                
                <div className="MaterialsDocuments__color-section">
                  <div className="MaterialsDocuments__color-selector">
                    <span className="MaterialsDocuments__color-label">Scegli il colore:</span>
                    <div className="MaterialsDocuments__color-options">
                      {availableColors.map(color => (
                        <button
                          key={color.name}
                          className={`MaterialsDocuments__color-option ${selectedFolderColor === color.name ? 'MaterialsDocuments__color-option--selected' : ''}`}
                          style={{ backgroundColor: color.color }}
                          onClick={() => handleSelectFolderColor(color.name)}
                          title={color.label}
                          aria-label={`Seleziona colore ${color.label}`}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="MaterialsDocuments__buttons">
                <Button 
                  variant="custom" 
                  className="MaterialsDocuments__save-button"
                  onClick={handleProceedToNaming}
                  disabled={selectedDocuments.length === 0 || availableDocuments.length === 0}
                  style={{
                    backgroundColor: currentColor.buttonBg,
                    '--button-hover-bg': currentColor.buttonHoverBg,
                    '--button-active-bg': currentColor.buttonActiveBg,
                    '--button-shine-color': `rgba(255, 255, 255, 0.2)`
                  }}
                >
                  <span className="desktop-only">Avanti</span>
                  <Check size={18} className="mobile-only" />
                </Button>
                <Button 
                  variant="outline" 
                  className="MaterialsDocuments__cancel-button"
                  onClick={handleCancelCreateFolder}
                  style={{
                    '--button-hover-bg': `rgba(${currentColor.rgb}, 0.1)`,
                    '--button-hover-color': currentColor.color,
                    '--button-hover-border': currentColor.color
                  }}
                >
                  <X size={18} />
                  <span>Annulla</span>
                </Button>
              </div>
            </>
          )}
        </div>
      )}

      {/* Lista unificata di cartelle e documenti */}
      <ul className='MaterialsDocuments__unified-list'>
        {/* Cartelle - mostrate solo quando non si sta creando una nuova cartella e non è attiva la ricerca */}
        {!isCreatingFolder && !showSearchResults && folders.map(folder => {
          const documentCount = folder.documentIds.length;
          
          return (
            <Folder
              key={folder.id}
              id={folder.id}
              name={folder.name}
              documentCount={documentCount}
              isActive={selectedFolder === folder.id}
              onClick={handleSelectFolder}
              onOpen={handleOpenFolder}
              createdAt={folder.createdAt}
              color={folder.color || 'blue'} // Utilizzo del colore della cartella
            />
          );
        })}
        
        {/* Documenti */}
        {filteredDocuments.map(document => {
          const isSelected = selectedDocuments.includes(document.id);
          
          return (
            <Document
              key={document.id}
              id={document.id}
              title={document.title}
              type={document.type}
              tags={document?.tags || null}
              date={formatTimeStamp(document.uploadDate)}
              isActive={isCreatingFolder ? isSelected : selectedDocument?.id === document.id}
              onChange={toggleDocumentSelection}
              selectionMode={isCreatingFolder}
              folderColor={isCreatingFolder ? currentColor : null} // Passa il colore della cartella
            />
          );
        })}

        {/* Documento di onboarding se necessario */}
        {currentStep < 22 && 
          currentStep > 8 &&
          (!isCreatingFolder || !availableDocuments.some(doc => doc.id === 'yZI5jQzrvGbTsid0kvk6')) && (
            <Document
              key={'yZI5jQzrvGbTsid0kvk6'}
              id={'yZI5jQzrvGbTsid0kvk6'}
              title={'Divina_Commedia'}
              type={'pdf'}
              tags={['introduzione', 'saggio', 'letteratura italiana']}
              isActive={selectedDocument === 'yZI5jQzrvGbTsid0kvk6'}
              onChange={toggleDocumentSelection}
              selectionMode={isCreatingFolder}
              folderColor={isCreatingFolder ? currentColor : null} // Passa il colore della cartella
            />
          )
        }
      </ul>
    </div>
  );
};

// Aggiungi i nuovi prop types
MaterialsDocuments.propTypes = {
  selectedDocument: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func.isRequired,
  onCreateFolder: PropTypes.func,
  onFolderNavigation: PropTypes.func,
  showSearchResults: PropTypes.bool,
  searchQuery: PropTypes.string
};

export default MaterialsDocuments;
