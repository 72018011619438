import Logo from "@ui/logo/logo";
import Navbar from "@ui/navbar/navbar";
import { FileSearch, Sparkles, Folders } from "lucide-react";
import UserAvatarDropdown from "./userAvatarDropdown/userAvatarDropdown";
import HamburgerMenu from "./hamburgerMenu/hamburgerMenu";
import UpgradePlan from "./upgradePlan/upgradePlan";
import Menu from "./menu/menu";
import NotificationBell from './notificationBell/notificationBell';

/* styles */
import "./header.scss";

// Onboarding
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Steps } from "intro.js-react";
import useGetOnboardingState from "../../../pages/uploadNotes/uploadNotesCard/uploadNotesForm/hooks/useGetOnboardingState";
import { RESET_PROCESSED_NOTES } from "../../../redux/actions/types";

const Header = () => {
  const currentStep = useSelector(state => state.onboarding.currentStep);
  const [stepsEnabled, setStepsEnabled] = useState(currentStep === 14);
  const { getCurrentStep, updateCurrentStep } = useGetOnboardingState();
  const dispatch = useDispatch();
  
  useEffect(() => {
    const handleResize = () => {
      setStepsEnabled(currentStep === 14);
    };

    getCurrentStep();
    handleResize(); // Check on mount

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [currentStep, getCurrentStep]);

  const steps = [
    currentStep === 14 && {
      element: window.innerWidth >= 992 ? '.materiale-navItem' : '.HamburgerMenu__button',
      intro: "Nella sezione Materiali puoi vedere i tuoi documenti caricati",
    }
  ].filter(Boolean);
  
  const onExit = async () => {
    if(currentStep === 14) {
      updateCurrentStep(15);
    };

    setStepsEnabled(false);
  };

  const handleOnboardingSteps = () => {
    // Prevent strange onboarding behaviours
    if(currentStep < 9) {
      dispatch({ type: RESET_PROCESSED_NOTES });      
      updateCurrentStep(22)
    };
  };
  
  return (
    <header className="Header">
      {stepsEnabled && (  
        <Steps
          enabled={stepsEnabled}
          steps={steps}
          initialStep={0}
          onExit={onExit}
        />  
      )}
      <div className="container">
        <div className="Header__row">
          <div className="Header__left">
            <Logo className="Header__logo" />
          </div>
          <Navbar className="Header__navbar">
            <Navbar.ItemIcon
              className="Header__navbar-navItem"
              title="Carica Appunti"
              icon={<FileSearch size={20} />}
              to="/home"
              onClick={handleOnboardingSteps}
              end              
            />
            <Navbar.ItemIcon
              className="Header__navbar-navItem"
              title="Ripeti"
              icon={<Sparkles size={20} />}
              to="/home/ripeti"
              onClick={handleOnboardingSteps}
              end
            />
            <Navbar.ItemIcon
              className="Header__navbar-navItem materiale-navItem"
              title="Materiale"
              icon={<Folders size={20} />}
              to="/home/materiale"
              onClick={handleOnboardingSteps}
              end
            />
          </Navbar>
          <div className="Header__rightBox">
            {/* <NotificationBell /> */}
            <UserAvatarDropdown handleOnboardingSteps={handleOnboardingSteps} />
            <UpgradePlan onClick={handleOnboardingSteps} className="Header__rightBox-upgradePlan" />
            <HamburgerMenu handleOnboardingSteps={handleOnboardingSteps}>
              <Menu />
            </HamburgerMenu>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;