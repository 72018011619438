import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { truncate } from "@utility/truncate";
import { getFileIcon } from "@lib/file";
import { getTagColor } from "../../uploadNotes/documentsQA/hooks/useGetTagColor";
import Heading from "@ui/heading/heading";
import Field from "@ui/field/field";
import "./reviewDocument.scss";
import { Image, Music, Video, FileText, Trash2 } from "lucide-react";
import { useDispatch } from "react-redux";
import { setCurrentExamID } from "../../../redux/actions/examActions";

// Onboarding
import { Steps } from "intro.js-react";
import { useSelector } from "react-redux";
import useGetOnboardingState from "../../uploadNotes/uploadNotesCard/uploadNotesForm/hooks/useGetOnboardingState";
import "intro.js/introjs.css";

const useDocumentSelection = (id, examID, title, status, onChange) => {
  const { register } = useForm();
  
  return register("document", {
    onChange: (event) => onChange({ id: event.target.value, examID, title, status })
  });
};

const ReviewDocument = ({ 
  id = '', 
  examID = '', 
  title = '', 
  type = '', 
  tags = [], 
  text = '', 
  status = '', 
  onChange = () => {}, 
  selectedId = '', 
  setSelectedId = () => {}, 
  selectedStatus = '', 
  setSelectedStatus = () => {},
  onDelete = () => {}
}) => {
  const documentRegister = useDocumentSelection(id, examID, title, status, onChange);
  const dispatch = useDispatch();
  const tagsRef = useRef(null);
  
  // Onboarding states
  const currentStep = useSelector(state => state.onboarding.currentStep);
  const [stepsEnabled, setStepsEnabled] = useState(currentStep === 9 ? true : false);
  const { updateCurrentStep, getCurrentStep } = useGetOnboardingState();

  useEffect(() => {
    getCurrentStep();
  }, [getCurrentStep]);

  const steps = [
    currentStep === 9 && {
      element: '.DivinaCommedia',
      intro: "Seleziona il documento",
    },
    currentStep === 9 && {
      element: '.Review__button',
      intro: "Clicca su avvia preparazione per iniziare l'esame",
    }
  ].filter(Boolean);
  
  const onExit = async () => {
    setStepsEnabled(false);
    if(currentStep === 9) {
      updateCurrentStep(10);
    };
  };

  useEffect(() => {
    const helperLayer = document.querySelector('.introjs-helperLayer');
    if (helperLayer) {
      helperLayer.style.marginTop = '0px';
    }
    
    const tooltip = document.querySelector('.introjs-tooltip');
    if (tooltip) {
      tooltip.style.marginTop = "0px";  
    }
  }, [stepsEnabled]);
  // Gestione dello scrolling per dispositivi touch
  useEffect(() => {
    if (!tagsRef.current) return;
    
    let scrollTimeout;
    const tagsElement = tagsRef.current;
    
    const handleScroll = () => {
      tagsElement.classList.add('scrolling');
      
      // Rimuovi la classe dopo che lo scroll è terminato
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => {
        tagsElement.classList.remove('scrolling');
      }, 1000);
    };
    
    tagsElement.addEventListener('scroll', handleScroll);
    
    return () => {
      tagsElement.removeEventListener('scroll', handleScroll);
      clearTimeout(scrollTimeout);
    };
  }, []);

  const getUpdatedFileIcon = (fileType) => {
    switch(fileType.toLowerCase()) {
      // Casi per immagini
      case 'png':
      case 'image/png':
      case 'jpeg':
      case 'jpg':
      case 'image/jpeg':
      case 'image/jpg':
      case 'gif':
      case 'image/gif':
      case 'webp':
      case 'image/webp':
      case 'svg':
      case 'image/svg+xml':
      case 'bmp':
      case 'image/bmp':
      case 'tiff':
      case 'image/tiff':
        return <Image className="FilteredDocument__left-icon" size={44} />;
      // Casi per audio
      case 'mp3':
      case 'audio/mpeg':
      case 'wav':
      case 'audio/wav':
      case 'ogg':
      case 'audio/ogg':
        return <Music className="FilteredDocument__left-icon" size={44} />;
      // Casi per video
      case 'mp4':
      case 'video/mp4':
      case 'avi':
      case 'video/x-msvideo':
      case 'mov':
      case 'video/quicktime':
      case 'mkv':
      case 'video/x-matroska':
        return <Video className="FilteredDocument__left-icon" size={44} />;
      case 'multiple':
        return <FileText className="FilteredDocument__left-icon" size={44} />;
      default:
        return null;
    }
  };

  const handleClick = () => {
    if(status === 'in-progress') {  
      setSelectedId(examID);
    } else {
      setSelectedId(id);
    }
    setSelectedStatus(status);
    
    // Save the examID in the redux store
    dispatch(setCurrentExamID(examID));
  };

  const handleDelete = () => {
    if(window.confirm('Sei sicuro di voler eliminare questo documento?')) {
      onDelete(examID);
    } else {
      return;
    }
  };
  return (
    <li 
      className={`FilteredDocument__wrapper ${selectedStatus === status && (selectedId === examID || selectedId === id) ? 'FilteredDocument__wrapper--selected' : 'FilteredDocument__wrapper--unselected'} ${id === 'yZI5jQzrvGbTsid0kvk6' ? 'DivinaCommedia' : ''}`}
    >
      <Steps
        enabled={stepsEnabled}
        steps={steps}
        initialStep={0}
        onExit={onExit}
      />
      <div className="FilteredDocument">
        <div className="FilteredDocument__inner">
          <div className="FilteredDocument__left">
            {getUpdatedFileIcon(type) || <img className="FilteredDocument__left-image" src={getFileIcon(type)} alt={`file ${title}`} />}
            <div className="FilteredDocument__box">
              <Heading title={title.length > 20 ? title.slice(0, 20) + '...' : title || 'Esame in corso'} heading="h5" />
              {text && <p className="FilteredDocument__box-text">{truncate(text, 80)}</p>}
            </div>
          </div>
          <div className="FilteredDocument__actions">
            <Field className="FilteredDocument__field">
              <Field.Label className="FilteredDocument__label" htmlFor={`document-check-${id}`} />
              <input
                className="FilteredDocument__input"
                id={`document-check-${id}`}
                value={id}
                type="radio"
                onClick={handleClick}
                {...documentRegister}
                />

              {status === 'in-progress' && (
                <button 
                  className="FilteredDocument__delete-btn FilteredDocument__delete-btn--mobile"
                  onClick={handleDelete}
                  title="Elimina documento"
                  aria-label="Elimina documento"
                  >
                  <Trash2 className="FilteredDocument__delete-btn-icon" size={20} />
                </button>
              )}
            </Field>

            {status === 'in-progress' && (
              <button 
                className="FilteredDocument__delete-btn"
                onClick={handleDelete}
                title="Elimina documento"
                aria-label="Elimina documento"
              >
                <Trash2 className="FilteredDocument__delete-btn-icon" size={20} />
              </button>
            )}
          </div>
        </div>
        
        {/** Mobile exam in progress */}
        <div className="FilteredDocument__mobile-wrapper">
          <Field className="FilteredDocument__mobile-checkbox">
            <Field.Label className="FilteredDocument__label" htmlFor={`document-check-${id}`} />
            <input
              className="FilteredDocument__input"
              id={`document-check-${id}`}
              value={id}
              type="radio"
              onClick={handleClick}
              {...documentRegister}
              />
          </Field>
          { tags !== null && (Array.isArray(tags) || (typeof tags === 'object' && tags.tags)) ? 
            <div className="FilteredDocument__tags" ref={tagsRef}>
                {(Array.isArray(tags) ? tags : tags.tags).map((tag, idx) => {
                  return (
                    <div key={`${tag}-${idx}`}>
                    <span
                      className="FilteredDocument__tag"
                      style={{ 
                        backgroundColor: getTagColor(idx, tag).backgroundColor, 
                        color: getTagColor(idx, tag).color,
                        borderColor: getTagColor(idx, tag).borderColor,
                      }}
                      >
                      {tag}
                    </span>
                  </div> 
                )})}
            </div>
            
            : null
          }
        </div>
      </div>
    </li>
  );
};

ReviewDocument.propTypes = {
  id: PropTypes.string.isRequired,
  examID: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  tags: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.shape({
      tags: PropTypes.arrayOf(PropTypes.string)
    })
  ]),
  text: PropTypes.string,
  status: PropTypes.string,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  selectedId: PropTypes.string,
  setSelectedId: PropTypes.func,
  selectedStatus: PropTypes.string,
  setSelectedStatus: PropTypes.func
};

export default ReviewDocument;
