import React from 'react';
import PropTypes from 'prop-types';
import { HiLockClosed } from 'react-icons/hi';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import './Questions.scss';
import { setFeedback } from '../../../../../redux/actions/documentActions';
import { useDispatch } from 'react-redux';

const QuestionsSummary = ({ questions }) => {
  const { user } = useSelector(state => state.auth);
  const isPremium = user?.plan === 'Premium';

  if (isPremium) return null;

  return (
    <div className="Questions__stats">
      <div className="Questions__stats-header">
        <div className="Questions__stats-icon">
          <HiLockClosed />
        </div>
        <div className="Questions__stats-info">
          <div className="Questions__stats-details">
            <div className="Questions__stats-item Questions__stats-item--premium">
              Versione gratuita
            </div>
            <div className="Questions__stats-item Questions__stats-item--premium">
              Hai accesso a tutte le domande premium
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Questions = ({ questions, onFeedback, onboardingDocumentData }) => {
  const [expandedQuestions, setExpandedQuestions] = React.useState({});
  const questionsFeedbackState = useSelector(state => state.document.feedbacks);
  const [questionsFeedback, setQuestionsFeedback] = React.useState(Object.fromEntries(questionsFeedbackState['questions']));
  const dispatch = useDispatch();

  const handleToggleExpand = (index) => {
    setExpandedQuestions(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const handleFeedback = (type, index, isHelpful) => {
    dispatch(setFeedback(type, index, isHelpful ));
    setQuestionsFeedback(prev => ({
      ...prev,
      [index]: isHelpful
    }));
    onFeedback(index, isHelpful);
  };

  return (
    <div className="Questions">
      <QuestionsSummary questions={questions} />

      {questions.map((question, index) => {
        const currentFeedback = questionsFeedback[index];
        
        return (
          <div 
            key={index} 
            className={clsx("Questions__container", {
              "Questions__container--expanded": expandedQuestions[index]
            })}
          >
            <div 
              className="Questions__header"
              onClick={() => handleToggleExpand(index)}
            >
              <div className="Questions__question">
                <div className="Questions__number">{index + 1}</div>
                <h4 className="Questions__text">{question.question}</h4>
              </div>
              <div className="Questions__toggle">
                <svg 
                  className={clsx("Questions__toggle-icon", {
                    "Questions__toggle-icon--expanded": expandedQuestions[index]
                  })}
                  width="20" 
                  height="20" 
                  viewBox="0 0 20 20" 
                  fill="none" 
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M5 7.5L10 12.5L15 7.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </div>
            </div>

            {expandedQuestions[index] && (
              <>
                <div className="Questions__answer">
                  {question.answer}
                </div>
                {(onFeedback && !onboardingDocumentData) && (
                  <div className="Questions__feedback">
                    <button 
                      className={clsx("Questions__feedback-button Questions__feedback-button--helpful", {
                        "Questions__feedback-button--active": currentFeedback === true
                      })}
                      onClick={() => handleFeedback('questions', index, true)}
                    >
                      Utile
                    </button>
                    <button 
                      className={clsx("Questions__feedback-button Questions__feedback-button--not-helpful", {
                        "Questions__feedback-button--active": currentFeedback === false
                      })}
                      onClick={() => handleFeedback('questions', index, false)}
                    >
                      Non utile
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

Questions.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.string.isRequired
    })
  ).isRequired,
  onFeedback: PropTypes.func
};

export default Questions; 