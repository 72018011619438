import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TbEdit } from "react-icons/tb";
import { RiDeleteBinLine } from "react-icons/ri";
import { IoMdCheckmark } from "react-icons/io";
import { toast } from 'react-toastify';
import '../ui/Folder.scss';

import FolderCard from '../ui/FolderCard';
import Input from '@ui/input/input';
import { useFolderActions } from '../hooks/useFolderActions';
import { formatDate } from '../utils/formatDate';

const Folder = ({ 
  id, 
  name, 
  documentCount, 
  isActive, 
  onClick, 
  onOpen, 
  createdAt = new Date().toISOString(), 
  color = 'blue', 
  onDelete,
  className
}) => {
  // Stati locali
  const [isEditing, setIsEditing] = useState(false);
  const [folderName, setFolderName] = useState(name || '');
  
  // Hook chiamato direttamente al livello superiore senza try/catch
  // come richiesto dalle regole dei React Hooks
  const folderActions = useFolderActions();
  
  // Funzioni sicure che verificano l'esistenza delle azioni prima di chiamarle
  const updateFolderDetails = (folderId, updateData) => {
    if (!folderActions || !folderActions.updateFolderDetails) {
      console.error('updateFolderDetails non disponibile');
      toast.error('Funzionalità temporaneamente non disponibile');
      return Promise.reject('Funzione non disponibile');
    }
    return folderActions.updateFolderDetails(folderId, updateData);
  };
  
  const deleteFolderById = (folderId) => {
    if (!folderActions || !folderActions.deleteFolderById) {
      console.error('deleteFolderById non disponibile');
      toast.error('Funzionalità temporaneamente non disponibile');
      return Promise.reject('Funzione non disponibile');
    }
    return folderActions.deleteFolderById(folderId);
  };
  
  // Format date in modo sicuro
  let formattedDate;
  try {
    formattedDate = formatDate(createdAt);
  } catch (error) {
    console.error('Errore nel formato della data:', error);
    formattedDate = new Date().toLocaleDateString();
  }
  
  // Handle folder click
  const handleFolderClick = (e) => {
    try {
      e.stopPropagation();
      if (!isEditing) {
        // Open directly with a single click
        if (typeof onOpen === 'function') onOpen(id);
        
        // Keep onClick for compatibility with existing code
        if (typeof onClick === 'function') onClick(id);
      }
    } catch (error) {
      console.error('Errore nel click sulla cartella:', error);
    }
  };

  // Handle editing folder name
  const handleEditName = (e) => {
    try {
      e.stopPropagation();
      if (isEditing) {
        const newName = (folderName || '').trim().replace(/\s+/g, ' ');
        if (!newName) {
          toast.error('Il nome della cartella non può essere vuoto');
          setFolderName(name || '');
          setIsEditing(false);
          return;
        }
        if (newName !== name) {
          if (/^[\w\sàèéìòù'\-.,]{2,50}$/i.test(newName)) {
            updateFolderDetails(id, { name: newName });
          } else {
            toast.error('Il nome deve contenere tra i 2-50 caratteri');
            setFolderName(name || '');
          }
        }
        setIsEditing(false);
      } else {
        setIsEditing(true);
      }
    } catch (error) {
      console.error('Errore durante la modifica del nome:', error);
      setIsEditing(false);
    }
  };

  // Handle folder deletion
  const handleDelete = (e) => {
    try {
      e.stopPropagation();
      if (window.confirm('Sei sicuro di voler eliminare questa cartella? I documenti contenuti non verranno eliminati.')) {
        // Use the folder actions hook from component level
        deleteFolderById(id)
          .then(() => {
            // If a callback is provided, call it
            if (typeof onDelete === 'function') {
              onDelete();
            }
          })
          .catch(error => {
            console.error('Errore nella cancellazione della cartella:', error);
            toast.error('Errore durante l\'eliminazione della cartella');
          });
      }
    } catch (error) {
      console.error('Errore durante l\'eliminazione della cartella:', error);
      toast.error('Errore durante l\'eliminazione della cartella');
    }
  };

  // Handle name change during editing
  const handleNameChange = (e) => {
    try {
      const value = e.target.value || '';
      setFolderName(value.charAt(0).toUpperCase() + value.slice(1));
    } catch (error) {
      console.error('Errore durante la modifica del nome:', error);
    }
  };

  // Render folder actions (edit and delete buttons)
  const renderFolderActions = () => {
    try {
      if (isEditing) {
        return <IoMdCheckmark className='Document__saveIcon' onClick={handleEditName} />;
      }
      
      return (
        <>
          <TbEdit className='Document__editIcon' onClick={handleEditName} />
          <RiDeleteBinLine className='Document__deleteIcon' onClick={handleDelete} />
        </>
      );
    } catch (error) {
      console.error('Errore nel rendering delle azioni:', error);
      return null;
    }
  };

  // Render sicuro che gestisce eventuali errori
  try {
    return (
      <>
        <FolderCard
          name={isEditing ? null : name}
          documentCount={documentCount}
          createdAt={formattedDate}
          color={color}
          isActive={isActive}
          onClick={handleFolderClick}
          className={className}
          editMode={isEditing}
          editInput={
            isEditing ? (
              <Input 
                className='Document_titleInput'
                type='text'
                value={folderName}
                onChange={handleNameChange}
                maxLength={50}
                pattern="^[\w\sàèéìòù'\-.,]{2,50}$"
                required
                autoFocus
              />
            ) : null
          }
        >
          {renderFolderActions()}
        </FolderCard>
      </>
    );
  } catch (error) {
    console.error('Errore nel rendering del componente Folder:', error);
    // Fallback in caso di errore di rendering
    return (
      <div className="Folder Folder--error">
        <span>Errore di visualizzazione cartella</span>
      </div>
    );
  }
};

Folder.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  documentCount: PropTypes.number.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  createdAt: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object  // For Firestore timestamps
  ]),
  color: PropTypes.string,
  onDelete: PropTypes.func,
  className: PropTypes.string
};

export default Folder;