import axios from "axios";
import { storage, firestore } from '@redux/firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { savePodcastToRedux } from './podcastAudioService';

let API_URL;

if (process.env.REACT_APP_ENVIRONMENT === "development") {
  API_URL = process.env.REACT_APP_API_URL_DEVELOPMENT;

} else if (process.env.REACT_APP_ENVIRONMENT === "preproduction") {
  API_URL = process.env.REACT_APP_API_URL_PREPRODUCTION;
} else {
  API_URL = process.env.REACT_APP_API_URL_PRODUCTION;
}

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: API_URL ? API_URL.replace(/["']/g, '') : 'http://localhost:8080',
  headers: {
    "Content-Type": "application/json",
  },
});

// Function to process documents
const processDocument = async (documentText, requestType = '', isPremium = false, retries = 3) => {
  try {
    console.log(`Processing document - Premium: ${isPremium}, Type: ${requestType}, Length: ${documentText.length}`);
    
    const requestBody = { 
      documentText,
      requestType,
      isPremium
    };

    const response = await axiosInstance.post("/process-document", requestBody);

    if (response.status === 200 && response.data) {
      return response.data;
    } else {
      console.error(`API request unexpected response:`, response);
      throw new Error(`API request unexpected response with status ${response.status}`);
    }
  } catch (error) {
    console.error("Error processing document:", error);
    if (retries > 0) {
      console.log(`Retrying... (${3 - retries + 1})`);
      return processDocument(documentText, requestType, isPremium, retries - 1);
    } else {
      console.error("Max retries reached. Throwing error.", error);
      throw error;
    }
  }
};

// Function to get feedback
const getFeedback = async (userResponse, currentQuestion, openAIAnswer) => {
  try {
    if (!currentQuestion || !openAIAnswer) {
      throw new Error("La domanda corrente o la risposta di OpenAI non possono essere vuote.");
    }

    const requestBody = JSON.stringify({ userResponse, currentQuestion, openAIAnswer });

    const response = await axiosInstance.post("/get-feedback", requestBody, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`API request failed with status ${response.status}`);
    }
  } catch (error) {
    throw error;
  }
};

const getFeedbackWithAssistant = async (questionsArray) => {
  try {
    const requestBody = JSON.stringify({ questions: questionsArray });

    const response = await axiosInstance.post("/get-feedback-with-assistant", requestBody, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`API request failed with status ${response.status}`);
    }
  } catch (error) {
    throw error;
  }
};

// Function to transcribe audio
const transcribeAudio = async (audioBlob) => {
  try {
    const formData = new FormData();
    formData.append("file", audioBlob, "audio.mp3");
    formData.append("model", "whisper-1");

    console.log("Sending request to OpenAI API with formData:", formData);

    const response = await axios.post('https://api.openai.com/v1/audio/transcriptions', formData, {
      headers: {
        "Authorization": `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
        "Content-Type": "multipart/form-data",
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      console.error("API request failed with status:", response.status);
      throw new Error(`API request failed with status ${response.status}`);
    }
  } catch (error) {
    console.log("Error transcribing audio:", error);
    if (error.response) {
      console.error("Response data:", error.response.data);
      console.error("Response status:", error.response.status);
      console.error("Response headers:", error.response.headers);
    }
    throw error;
  }
};

const processImage = async (imageFile) => {
  try {
    // Convert image to base64
    const base64Image = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => resolve(e.target.result.split(',')[1]);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(imageFile);
    });

    const requestBody = {
      model: "gpt-4o",
      messages: [
        {
          role: "user",
          content: [
            {
              type: "text",
              text: "Please extract and return only the text content from this image, without any additional commentary or formatting."
            },
            {
              type: "image_url",
              image_url: {
                url: `data:${imageFile.type};base64,${base64Image}`,
              }
            }
          ],
        }
      ]
    };

    const response = await axios.post('https://api.openai.com/v1/chat/completions', requestBody, {
      headers: {
        "Authorization": `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200 && response.data.choices && response.data.choices.length > 0) {
      return response.data.choices[0].message.content;
    } else {
      console.error("API request unexpected response:", response);
      throw new Error(`API request unexpected response with status ${response.status}`);
    }
  } catch (error) {
    console.error("Error processing image:", error);
    if (error.response) {
      console.error("Response data:", error.response.data);
      console.error("Response status:", error.response.status);
      console.error("Response headers:", error.response.headers);
    }
    throw new Error(error.response?.data?.error?.message || error.message || "Unknown error occurred");
  }
};

const generateSpeech = async (text, options = {}) => {
  try {
    // Impostazioni predefinite
    const defaultOptions = {
      voice: "nova",
      speed: 1.0,  // Velocità normale
      accent: null,
      emotional_range: "medium",
      intonation: "natural",
      impressions: null,
      tone: "professional"
    };

    // Unisci le opzioni predefinite con quelle fornite
    const settings = {...defaultOptions, ...options};
    
    // Costruisci l'oggetto voice_settings
    const voice_settings = {
      speed: settings.speed
    };
    
    // Costruisci un prompt per controllare altri aspetti della voce
    let prompt = null;
    if (settings.accent || settings.emotional_range || settings.intonation || 
        settings.impressions || settings.tone) {
      prompt = `Speak with ${settings.tone || 'professional'} tone`;
      
      if (settings.accent) {
        prompt += `, using a ${settings.accent} accent`;
      }
      
      if (settings.emotional_range) {
        prompt += `, with ${settings.emotional_range} emotional range`;
      }
      
      if (settings.intonation) {
        prompt += `, and ${settings.intonation} intonation`;
      }
      
      if (settings.impressions) {
        prompt += `. ${settings.impressions}`;
      }
      
      prompt += ".";
    }
    
    // Costruisci la richiesta 
    const requestBody = {
      model: "gpt-4o-mini-tts",
      voice: settings.voice,
      input: text,
      voice_settings: voice_settings
    };
    
    // Aggiungi il prompt se presente
    if (prompt) {
      requestBody.prompt = prompt;
    }

    const response = await axios.post('https://api.openai.com/v1/audio/speech', requestBody, {
      headers: {
        "Authorization": `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
        "Content-Type": "application/json",
      },
      responseType: 'arraybuffer',
    });

    return response.data;
  } catch (error) {
    console.error("Error generating speech:", error);
    throw error;
  }
};

const generatePodcast = async (text, documentId = null, documentTitle = null, userId = null) => {
  try {
    console.log('Inizio generazione podcast con testo di lunghezza:', text.length);
    
    // Utilizzo l'URL relativo che verrà gestito dal proxy
    const response = await axiosInstance.post('/generate-podcast', {
      text: text
    });
    
    console.log('Risposta ricevuta dal server:', response.status);
    
    // Verifica che la risposta contenga i dati audio in base64
    if (response.data && response.data.audio_base64) {
      try {
        // Converti il base64 in un Blob
        const byteCharacters = atob(response.data.audio_base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const audioBlob = new Blob([byteArray], { type: 'audio/mp3' });
        
        // Verifica che l'utente sia autenticato prima di caricare su Firebase
        const auth = getAuth();
        const currentUser = auth.currentUser;
        
        // Utilizziamo l'ID utente passato come parametro se disponibile, altrimenti usiamo currentUser
        const effectiveUserId = userId || (currentUser ? currentUser.uid : null);
        
        if (!effectiveUserId) {
          console.error("Utente non autenticato. Impossibile salvare su Firebase.");
          // Restituisci comunque l'audio ma senza salvarlo su Firebase
          const podcastData = {
            success: true,
            audio_url: URL.createObjectURL(audioBlob),
            local_only: true,
            podcast_config: response.data.podcast_config
          };
          
          // Salva in Redux se abbiamo l'ID del documento
          if (documentId) {
            savePodcastToRedux(podcastData, documentId);
          }
          
          return podcastData;
        }
        
        // Carica il file su Firebase Storage
        const filename = response.data.audio_filename || `podcast_${Date.now()}.mp3`;
        const storageRef = ref(storage, `podcasts/${effectiveUserId}/${filename}`);
        
        console.log("Tentativo di caricamento su Firebase Storage:", storageRef);
        
        // Carica il blob su Firebase Storage
        const uploadResult = await uploadBytes(storageRef, audioBlob);
        console.log("Upload completato:", uploadResult);
        
        // Ottieni l'URL di download
        const downloadURL = await getDownloadURL(uploadResult.ref);
        console.log("URL di download ottenuto:", downloadURL);
        
        try {
          // Salva i metadati nella collection "podcastAudio" in Firestore
          const podcastData = {
            audio_url: downloadURL,
            filename: filename,
            created_at: serverTimestamp(),
            document_id: documentId || null,
            document_title: documentTitle || null,
            text_excerpt: response.data.document_text || text.substring(0, 500),
            user_id: effectiveUserId
          };
          
          console.log("Tentativo di salvataggio in Firestore:", podcastData);
          
          // Aggiungi il documento alla collection podcastAudio (rinominata per evitare spazi)
          const docRef = await addDoc(collection(firestore, "podcastAudio"), podcastData);
          console.log("Documento salvato in Firestore con ID:", docRef.id);
          
          // Crea l'oggetto podcast completo
          const completePodcastData = {
            success: true,
            audio_url: downloadURL,
            firestore_id: docRef.id,
            podcast_config: response.data.podcast_config,
            ...podcastData
          };
          
          // Salva in Redux se abbiamo l'ID del documento
          if (documentId) {
            savePodcastToRedux(completePodcastData, documentId);
          }
          
          // Restituisci i dati del podcast con l'URL di Firebase
          return completePodcastData;
        } catch (firestoreError) {
          console.error("Errore nel salvataggio su Firestore:", firestoreError);
          
          // Messaggio di errore specifico per problemi di permessi
          let errorMessage = "Audio salvato su Storage ma non su Firestore";
          
          if (firestoreError.code === "permission-denied" || 
              firestoreError.message.includes("Missing or insufficient permissions")) {
            errorMessage = "Audio salvato correttamente su Firebase Storage e disponibile per l'ascolto. Non è stato possibile salvare i metadati su Firestore per permessi insufficienti, ma questo non influisce sulla riproduzione del podcast.";
          }
          
          // Crea l'oggetto podcast con errore
          const errorPodcastData = {
            success: true,
            audio_url: downloadURL,
            storage_only: true,
            error_message: errorMessage,
            podcast_config: response.data.podcast_config,
            // Aggiungi il blob audio per consentire il download diretto
            audio_blob: audioBlob,
            filename: filename,
            document_id: documentId,
            document_title: documentTitle
          };
          
          // Salva in Redux se abbiamo l'ID del documento
          if (documentId) {
            savePodcastToRedux(errorPodcastData, documentId);
          }
          
          // Se fallisce il salvataggio su Firestore, restituisci comunque l'URL dell'audio
          return errorPodcastData;
        }
      } catch (storageError) {
        console.error("Errore nel caricamento su Firebase Storage:", storageError);
        
        // Crea un URL locale per l'audio
        const byteCharacters = atob(response.data.audio_base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const audioBlob = new Blob([byteArray], { type: 'audio/mp3' });
        const localUrl = URL.createObjectURL(audioBlob);
        
        // Crea l'oggetto podcast con errore di storage
        const localPodcastData = {
          success: true,
          audio_url: localUrl,
          local_only: true,
          error_message: "Impossibile salvare su Firebase: " + storageError.message,
          podcast_config: response.data.podcast_config,
          filename: `podcast_${Date.now()}.mp3`,
          document_id: documentId,
          document_title: documentTitle
        };
        
        // Salva in Redux se abbiamo l'ID del documento
        if (documentId) {
          savePodcastToRedux(localPodcastData, documentId);
        }
        
        // Restituisci un URL locale per l'audio
        return localPodcastData;
      }
    } else {
      // Controllo aggiuntivo per verificare se la risposta contiene un messaggio di errore
      if (response.data && response.data.error) {
        throw new Error(`Errore del server: ${response.data.error}`);
      } else {
        throw new Error("Risposta del server non valida: dati audio mancanti");
      }
    }
  } catch (error) {
    console.error("Error generating podcast:", error);
    
    // Logging dettagliato dell'errore per facilitare il debug
    if (error.response) {
      // La richiesta è stata effettuata e il server ha risposto con un codice di stato
      // che non rientra nell'intervallo 2xx
      console.error("Errore di risposta dal server:", {
        status: error.response.status,
        statusText: error.response.statusText,
        data: error.response.data
      });
      
      // Se il server ha inviato un messaggio di errore, lo utilizziamo
      if (error.response.data && error.response.data.error) {
        throw new Error(`Errore del server: ${error.response.data.error}`);
      }
    } else if (error.request) {
      // La richiesta è stata effettuata ma non è stata ricevuta alcuna risposta
      console.error("Nessuna risposta ricevuta dal server");
      throw new Error("Impossibile contattare il server per la generazione del podcast");
    }
    
    throw error;
  }
};

export { processDocument, getFeedback, transcribeAudio, processImage, generateSpeech, getFeedbackWithAssistant, generatePodcast };