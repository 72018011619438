import React, { useState } from 'react';
import { HiLockClosed } from 'react-icons/hi';
import { BsQuestionCircle, BsLightbulb } from 'react-icons/bs';
import './flashcard.scss';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { firestore } from "@redux/firebase";

export const Flashcard = ({ question, answer, isLocked }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const { user } = useSelector(state => state.auth);

  const handleClick = async () => {
    if (!isLocked) {
      setIsFlipped(!isFlipped);

       // Trigger GA4 custom event firstFlashcardFlipped
      try {
        const userId = JSON.parse(localStorage.getItem("userId"));
      
        const studentRef = doc(firestore, "students", userId);
        const studentSnap = await getDoc(studentRef);
      
        if (studentSnap.exists()) {
          const studentData = studentSnap.data();
          if (studentData.firstFlashcardFlipped === false) {
            if (typeof window.gtag === 'function') {
              window.gtag('event', 'firstFlashcardFlipped', {
                'event_category': 'Flashcard',
                'event_label': 'First Flashcard Flipped',
                'email': studentData.email
              });
            }
            
            await updateDoc(studentRef, {
              firstFlashcardFlipped: true
            });
          }
        }
      } catch (error) {
        console.error("Error checking first flashcard flip:", error);
      }
      // Trigger GA4 custom event flashCardFlipped
      if (typeof window.gtag === 'function') {
        window.gtag('event', 'flashCardFlipped', {
          'event_category': 'Documents',
          'event_label': 'FlashCard Flipped',
          'email': user.email
        });
      }
    }
  };

  return (
    <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
      <div className={`Flashcard ${isFlipped ? 'Flashcard--flip' : ''} ${isLocked ? 'Flashcard--locked' : ''}`}>
        <div className="Flashcard__inner" onClick={handleClick}>
          {isLocked ? (
            <div className="Flashcard__preview">
              <p className="Flashcard__preview-text">{question.slice(0, 50)}...</p>
              <div className="Flashcard__preview-fade">
                <div className="Flashcard__content-truncated">
                  <HiLockClosed className="Flashcard__lock-icon" />
                  <span>Contenuto Premium</span>
                </div>
              </div>
            </div>
          ) : (
            <div className="Flashcard__content">
              <div className={`Flashcard__header ${isFlipped ? 'Flashcard__header--revealed' : ''}`}>
                {isFlipped ? (
                  <BsLightbulb className="Flashcard__icon" />
                ) : (
                  <BsQuestionCircle className="Flashcard__icon" />
                )}
              </div>

              <div className="Flashcard__main">
                <div className="Flashcard__text-wrapper">
                  <p className="Flashcard__text">
                    {isFlipped ? answer : question}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

Flashcard.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  isLocked: PropTypes.bool
};