import { redirect } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  updateProfile,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  getAuth,
} from "firebase/auth";
import { setDoc, doc, updateDoc, getDoc } from "firebase/firestore";
import { firestore, auth } from "@redux/firebase";
import { toast } from "react-toastify";
import processFile from "./textExtractor";
import { getQuestion } from "@lib/firestoreQueries";
import { getFeedback } from "@services/openaiApi";
import { getUser } from "@lib/firestoreQueries";
import { useCallback } from "react";
import sendBrevoEmail from "../../utils/brevoEmailsUtils";

// login action
export const loginAction = async ({ request }) => {
  const formData = await request.formData();
  const email = formData.get("email").trim();
  const password = formData.get("password").trim();

  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    if (!user || !user.emailVerified) {
      throw new Error("Email not verified. Please verify your email.");
    }

    // Ottieni i dati dell'utente da Firestore
    const userDoc = await getDoc(doc(firestore, "students", user.uid));
    if (!userDoc.exists()) {
      throw new Error("User data not found");
    }

    const userData = userDoc.data();

    // Funzione per verificare l'inattività dell'utente
    const checkUserInactivity = (lastLoginAt) => {
      if (!lastLoginAt) return false;
      
      const lastLogin = lastLoginAt instanceof Date ? lastLoginAt : lastLoginAt.toDate();
      const currentDate = new Date();
      const twoWeeksInMs = 14 * 24 * 60 * 60 * 1000; // 14 giorni in millisecondi
      
      const timeDifference = currentDate.getTime() - lastLogin.getTime();
      
      return timeDifference > twoWeeksInMs;
    };

    const isInactive = checkUserInactivity(userData.lastLoginAt);

    if(isInactive) {
      await updateDoc(doc(firestore, "students", user.uid), {
        currentStep: 0,
      });
    }

    if (isInactive && typeof window.gtag === 'function') {
      window.gtag('event', 'userComeBack', {
        'event_category': 'User',
        'event_label': 'User Returned After Inactivity',
        'user_email': user.email || 'no_email',
      });
    }
    
    // Aggiorna lastLoginAt
    await setDoc(doc(firestore, "students", user.uid), {
      ...userData,
      lastLoginAt: new Date()
    }, { merge: true });

    // Trigger GA4 event for successful login
    if (typeof window.gtag === "function") {
      window.gtag("event", "userNormalLogin", {
        event_category: "User",
        event_label: "user normal login",
        email: user.email,
      });
    }

    // Salva l'ID utente nel localStorage
    localStorage.setItem("userId", JSON.stringify(user.uid));

    toast.success("Login avvenuto con successo!");

    // Ritorna i dati dell'utente e il flag di successo
    return { 
      isSuccessful: true,
      userData: { ...userData, uid: user.uid },
      redirectTo: '/home'
    };
  } catch (error) {
    console.error("Login error:", error);
    let errorMessage = "Si è verificato un errore durante il login.";
    
    if (error.code === 'auth/wrong-password') {
      errorMessage = "Password non corretta";
    } else if (error.code === 'auth/user-not-found') {
      errorMessage = "Utente non trovato";
    } else if (error.code === 'auth/too-many-requests') {
      errorMessage = "Troppi tentativi. Riprova più tardi";
    } else if (error.message) {
      errorMessage = error.message;
    }
    
    toast.error(errorMessage);
    return { 
      isSuccessful: false,
      message: errorMessage 
    };
  }
};

// sign up action
export const signUpAction = async ({ request }) => {
  let formData;
  try {
    formData = await request.formData();
    const user = {
      fullName: formData.get("fullName").trim(),
      email: formData.get("email").trim(),
      emailUseConsent: formData.get("emailUseConsent").trim(),
      study: formData.get("study"),
      documentsUploaded: 0,
      maxDocumentsUpload: 2,
      examsAttempted: 0,
      flashcardsFlipped: 0,
      multipleChoiceAnswered: 0,
      plan: "Base",
      policyAccepted: formData.get("policyAccepted"),
      currentStep: 0,
      amountEarned: 0,
      friendsInvited: 0,
    };
    const userCredential = await createUserWithEmailAndPassword(auth, user.email, formData.get("password").trim());
    await setDoc(doc(firestore, "students", userCredential.user.uid), user);
    await updateProfile(userCredential.user, { displayName: user.fullName });
    localStorage.setItem("newUser", "true");

    // Invio email di benvenuto
    sendBrevoEmail(user.email, user.fullName, 14);

    return { isSuccessful: true };
  } catch (error) {
    // Tracciamento errori di signup con Google Analytics
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'newUserError', {
        'event_category': 'Error',
        'event_label': 'SignUp Error',
        'error_type': 'signup',
        'error_code': error.code || 'unknown',
        'error_message': error.message || 'Unknown error',
        'user_email': formData?.get("email")?.trim() || 'no_email'
      });
    }
    
    if (error.code === "auth/email-already-in-use") return { message: "Email già in uso" };
    return { message: error.message };
  }
};

// reset password action
export const resetPasswordAction = async ({ request }) => {
  try {
    const formData = await request.formData();
    const email = formData.get("email");

    // check email format
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!regex.test(email)) throw new Error("Inserisci un corretto formato");

    await sendPasswordResetEmail(auth, email);
    toast.success("Email per la reimpostazione della password inviata!");
    return redirect("/");
  } catch (error) {
    // check if email is not valid
    if (error.message === "Inserisci un corretto formato") return { message: error.message };

    // send generic error message
    toast.error(error.message);
    return redirect("/reimposta-password");
  }
};

// check verify email action
export const checkVerifyEmailAction = async () => {
  try {
    await getAuth().currentUser.reload();
    const user = getAuth().currentUser;

    // check if user email is verified
    if (!user.emailVerified) return null;

    // retrieve user data
    const userInfo = await getUser(user.uid);
    localStorage.setItem("userId", JSON.stringify(user.uid));

    return { user: userInfo };
  } catch (error) {
    toast.error(error.message);
    return redirect("/controlla-verifica-email");
  }
};

// upload notes action
export const uploadNotesAction = async ({ request }) => {
  const formData = await request.formData();
  const files = formData.getAll("notes");
  
  let promiseArray = [];

  for (const file of files) {
    try {
      const processedFile = await processFile(file);

      if (!processedFile) {
        return;
      }

      promiseArray = [...promiseArray, processedFile];
    } catch (error) {
      console.error(`Error processing file ${file.name}:`, error);
      // Tracking dell'errore di elaborazione file
      try {
        const userAuth = auth.currentUser;
        if (userAuth) {
          // Ottieni informazioni utente per verificare piano
          const userDoc = await getDoc(doc(firestore, "students", userAuth.uid));
          const userData = userDoc.exists() ? userDoc.data() : null;
          const isFreePlan = (userData?.plan || "Base") === "Base";
          
          if (isFreePlan && typeof window.gtag === 'function') {
            window.gtag('event', 'newUserError', {
              'event_category': 'Error',
              'event_label': 'File Processing Error',
              'error_type': 'file_processing',
              'user_email': userAuth.email || 'no_email',
              'error_message': error.message || 'Unknown error',
              'file_name': file.name || 'unknown_file',
              'file_type': file.type || 'unknown_type',
              'file_size': file.size || 0
            });
          }
        }
      } catch (trackingError) {
        console.error("Errore nel tracciamento:", trackingError);
      }
      
      promiseArray = [...promiseArray, error.file || error];
    }
  }

  return promiseArray;
};

// user profile action
export const userProfileAction = async ({ request }) => {
  try {
    const formData = await request.formData();
    const user = Object.fromEntries(formData);

    // update profile
    await updateProfile(auth.currentUser, user);
    const resourceRef = doc(firestore, "students", auth.currentUser.uid);
    await updateDoc(resourceRef, {
      fullName: user.fullName,
      studyAt: user.studyAt,
      email: user.email,
    });
    toast.success("Il tuo profilo è stato aggiornato con successo!");
    return { user };
  } catch (error) {
    const message = error.message;
    toast.error(message);
    return null;
  }
};

// get feedback from chat gpt
export const getFeedbackQuestionAction = async ({ request }) => {
  try {
    const formData = await request.formData();
    const data = Object.fromEntries(formData);

     // search question on firebase
     let question = {};
     let questionEntry = {};
     
     // Primo tentativo con messageID diretto
     try {
       question = await getQuestion(data.messageID);
     } catch (e) {
       console.log('Primo tentativo fallito, procedo con formato alternativo');
     }
 
     // Secondo tentativo con parsing del messageID
     if (!question || Object.keys(question).length === 0) {
       const [type, documentId, index] = data.messageID.split('_');
       if (!documentId) throw new Error("Formato ID domanda non valido");
 
       questionEntry = await getQuestion(documentId, data.messageID);
       if (!questionEntry || Object.keys(questionEntry).length === 0) {
         throw new Error("Domanda non trovata con nessun formato ID");
       }
     } else {
       questionEntry = question;
     }
 
     let feedbackData = '';
 
     feedbackData = await getFeedback(
       data.answer,
       questionEntry.question,
       questionEntry.answer
     );
 
     return { 
       feedback: feedbackData.feedback,
       score: feedbackData.score,
       questionType: questionEntry.type
     };  } catch (error) {
    toast.error(error.message);
    return { error: error.message };
  }
};
