import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import useGetQuestions from './hook/useGetQuestions';
import Question from './question/question';
import QuestionWithDocument from './questionWithDocument/questionWithDocument';
import MaterialsFlashcardsNotFound from '../materialsFlashcards/materialsFlashcardsNotFound/materialsFlashcardsNotFound';
import './materialsQuestions.scss';
import { HiDownload, HiLockClosed, HiArrowRight, HiX } from 'react-icons/hi';
import { BsQuestionCircle, BsExclamationCircle, BsCheckCircle } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { QuestionStates, QuestionStateLabels } from './question/costants';
import { toast } from 'react-toastify';

const MaterialsQuestions = ({ idSelectedDocument, onboardingDocId, isLegacyFormat = true }) => {
  const { isLoading, questions, updateQuestionState, updateQuestionContextMarkers } = useGetQuestions(onboardingDocId || idSelectedDocument, isLegacyFormat);
  const { user } = useSelector(state => state.auth);
  const navigate = useNavigate();
  const [questionStates, setQuestionStates] = useState({});
  const [selectedState, setSelectedState] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  // Check if user is premium based on plan
  const isPremium = user?.plan === 'Premium';

  // Filter out discarded questions and apply filters
  const updatedQuestions = questions
    .filter(qa => qa && qa.isHelpful !== false)
    .filter(qa => {
      const currentState = questionStates[qa.id] || qa.knowledgeState || QuestionStates.NOT_EVALUATED;
      return selectedState === null || currentState === selectedState;
    })
    .sort((a, b) => a.question.localeCompare(b.question));

  // Calcola il numero di domande per ogni stato
  const questionStats = questions
    .filter(qa => qa && qa.isHelpful !== false)
    .reduce((acc, question) => {
      const state = questionStates[question.id] || question.knowledgeState || QuestionStates.NOT_EVALUATED;
      acc[state] = (acc[state] || 0) + 1;
      return acc;
    }, {});

  // Effetto per resettare il filtro quando non ci sono più domande nel filtro corrente
  useEffect(() => {
    if (selectedState !== null && (questionStats[selectedState] || 0) === 0) {
      setSelectedState(null);
    }
  }, [questionStats, selectedState]);

  const handleKnowledgeStateChange = useCallback(async (questionId, newState) => {
    try {
      const oldState = questionStates[questionId] || questions.find(q => q.id === questionId)?.knowledgeState || QuestionStates.NOT_EVALUATED;

      // Aggiorna lo stato locale immediatamente per una UI reattiva
      setQuestionStates(prev => ({
        ...prev,
        [questionId]: newState
      }));

      // Aggiorna lo stato nel backend
      await updateQuestionState(questionId, newState);

      // Se siamo in un filtro e l'ultima domanda è stata spostata, resetta il filtro
      if (selectedState === oldState && questionStats[oldState] === 1) {
        setSelectedState(null);
      }
    } catch (error) {
      // In caso di errore, ripristina lo stato precedente
      setQuestionStates(prev => {
        const { [questionId]: _, ...rest } = prev;
        return rest;
      });
      console.error('Error updating question state:', error);
      toast.error("Errore nell'aggiornamento dello stato della domanda");
    }
  }, [updateQuestionState, questions, selectedState, questionStats, questionStates]);

  const handleShowDocument = useCallback((question) => {
    setSelectedQuestion(question);
  }, []);

  const handleCloseDocument = useCallback(() => {
    setSelectedQuestion(null);
  }, []);

  const handleDownload = useCallback(() => {
    if (!isPremium) return;

    const csvContent = updatedQuestions.map(question => {
      const state = questionStates[question.id] || question.knowledgeState || QuestionStates.NOT_EVALUATED;
      return `"${question.question.replace(/"/g, '""')}","${question.answer.replace(/"/g, '""')}","${QuestionStateLabels[state]}"`;
    }).join('\n');

    const header = 'Domanda,Risposta,Stato\n';
    const blob = new Blob([header + csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'domande_ripetizione.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [isPremium, updatedQuestions, questionStates]);

  const handleStateFilter = (state) => {
    setSelectedState(state === selectedState ? null : state);
  };

  // show spinner if is loading
  if (isLoading) {
    return (
      <div className="MaterialsQuestions">
        <div className="MaterialsQuestions__stats-skeleton skeleton-animation">
          <div className="stats-header-skeleton">
            <div className="stats-icon-skeleton"></div>
            <div className="stats-info-skeleton">
              <div className="stats-details-skeleton"></div>
            </div>
          </div>
        </div>

        <div className='MaterialsQuestions__container-skeleton'>
          {[...Array(8)].map((_, index) => (
            <div key={index} className="questions-skeleton">
              <div className="question-skeleton skeleton-animation"></div>
              <div className="answer-skeleton skeleton-animation"></div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  // return select document if id selected document is null
  if (!idSelectedDocument && !onboardingDocId) {
    return <MaterialsFlashcardsNotFound message='Seleziona un documento' />;
  }

  // return empty message if no questions found
  if (!isLoading && updatedQuestions.length === 0) {
    return (
      <MaterialsFlashcardsNotFound
        message='Nessuna domanda trovata nel documento selezionato'
      />
    );
  }

  return (
    <div className='MaterialsQuestions'>
      {selectedQuestion && (
        <QuestionWithDocument
          question={selectedQuestion}
          onClose={handleCloseDocument}
          isPremium={isPremium}
          updateQuestionContextMarkers={updateQuestionContextMarkers}
        />
      )}
      
      <div className='MaterialsQuestions__stats'>
        <div className='MaterialsQuestions__stats-header'>
          <div
            className={`MaterialsQuestions__stats-icon ${!isPremium ? 'MaterialsQuestions__stats-icon--disabled' : ''}`}
            onClick={handleDownload}
            title={isPremium ? "Scarica tutte le domande in formato CSV" : "Passa a Premium per scaricare tutte le domande"}
          >
            {isPremium ? <HiDownload /> : <HiLockClosed />}
          </div>
          <div className="MaterialsQuestions__stats-info">
            <div className="MaterialsQuestions__stats-details">
              <div className="MaterialsQuestions__stats-item">
                {updatedQuestions.length} domande disponibili
              </div>
              <div
                className={`MaterialsQuestions__stats-item ${(questionStats[QuestionStates.NOT_EVALUATED] || 0) > 0 ? 'MaterialsQuestions__stats-item--clickable' : ''} ${selectedState === QuestionStates.NOT_EVALUATED ? 'MaterialsQuestions__stats-item--selected' : ''}`}
                onClick={() => (questionStats[QuestionStates.NOT_EVALUATED] || 0) > 0 && handleStateFilter(QuestionStates.NOT_EVALUATED)}
              >
                <div className="MaterialsQuestions__stats-item-icon MaterialsQuestions__stats-item-icon--not-evaluated">
                  <BsQuestionCircle />
                </div>
                {questionStats[QuestionStates.NOT_EVALUATED] || 0}
                {selectedState === QuestionStates.NOT_EVALUATED && (
                  <HiX className="MaterialsQuestions__stats-item-close" />
                )}
              </div>
              <div
                className={`MaterialsQuestions__stats-item ${(questionStats[QuestionStates.TO_REVIEW] || 0) > 0 ? 'MaterialsQuestions__stats-item--clickable' : ''} ${selectedState === QuestionStates.TO_REVIEW ? 'MaterialsQuestions__stats-item--selected' : ''}`}
                onClick={() => (questionStats[QuestionStates.TO_REVIEW] || 0) > 0 && handleStateFilter(QuestionStates.TO_REVIEW)}
              >
                <div className="MaterialsQuestions__stats-item-icon MaterialsQuestions__stats-item-icon--review">
                  <BsExclamationCircle />
                </div>
                {questionStats[QuestionStates.TO_REVIEW] || 0}
                {selectedState === QuestionStates.TO_REVIEW && (
                  <HiX className="MaterialsQuestions__stats-item-close" />
                )}
              </div>
              <div
                className={`MaterialsQuestions__stats-item ${(questionStats[QuestionStates.KNOWN] || 0) > 0 ? 'MaterialsQuestions__stats-item--clickable' : ''} ${selectedState === QuestionStates.KNOWN ? 'MaterialsQuestions__stats-item--selected' : ''}`}
                onClick={() => (questionStats[QuestionStates.KNOWN] || 0) > 0 && handleStateFilter(QuestionStates.KNOWN)}
              >
                <div className="MaterialsQuestions__stats-item-icon MaterialsQuestions__stats-item-icon--known">
                  <BsCheckCircle />
                </div>
                {questionStats[QuestionStates.KNOWN] || 0}
                {selectedState === QuestionStates.KNOWN && (
                  <HiX className="MaterialsQuestions__stats-item-close" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {!isPremium && (
        <div className="MaterialsQuestions__premium-banner">
          <div className="MaterialsQuestions__premium-banner-content">
            <div className="MaterialsQuestions__premium-banner-header">
              <HiLockClosed className="MaterialsQuestions__premium-banner-icon" />
              <h6>Versione gratuita</h6>
            </div>
            <p>Hai accesso a tutte le domande di ripetizione premium. Passa a Premium per poterle scaricare.</p>
            <button
              className="MaterialsQuestions__premium-banner-button animate-button"
              onClick={() => {
                navigate('/home/piani');
                setTimeout(() => {
                  window.scrollTo(0, 0);
                }, 100);
              }}
            >
              <span>Aggiorna piano</span>
              <HiArrowRight className="MaterialsQuestions__premium-banner-button-icon" />
            </button>
          </div>
        </div>
      )}

      <div className='MaterialsQuestions__container row gy-xs-1 g-md-1 g-lg-3'>
        {updatedQuestions.length === 0 ? (
          <div className="MaterialsQuestions__no-results">
            <p>Nessuna domanda trovata con i filtri selezionati</p>
            {selectedState !== null && (
              <button
                className="MaterialsQuestions__reset-filters"
                onClick={() => setSelectedState(null)}
              >
                Rimuovi tutti i filtri
              </button>
            )}
          </div>
        ) : (
          updatedQuestions.map((question, index) => (
            <div key={index} className='col-xs-12 col-sm-6 col-md-4 col-lg-3'>
              <Question
                question={question.question}
                answer={question.answer}
                isLocked={false}
                knowledgeState={questionStates[question.id] || question.knowledgeState || QuestionStates.NOT_EVALUATED}
                onKnowledgeStateChange={(newState) => handleKnowledgeStateChange(question.id, newState)}
                onShowDocument={handleShowDocument}
                questionData={question}
              />
            </div>
          ))
        )}
      </div>
    </div>
  );
};

MaterialsQuestions.propTypes = {
  idSelectedDocument: PropTypes.string,
  onboardingDocId: PropTypes.string,
  isLegacyFormat: PropTypes.bool
};

export default MaterialsQuestions;