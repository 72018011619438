import React, { useState, useEffect, useCallback, useRef } from 'react';
import { 
  HiPlay,
  HiPause
} from 'react-icons/hi';
import './AudioPlayer.scss';

const AudioPlayer = ({ 
  audioElement, 
  isPlaying, 
  togglePlayPause,
  audioUrl 
}) => {
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  
  const progressRef = useRef(null);
  
  // Formatta il tempo in mm:ss
  const formatTime = useCallback((time) => {
    if (isNaN(time)) return '0:00';
    
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }, []);
  
  // Aggiorna il tempo di riproduzione corrente
  const updateTime = useCallback(() => {
    if (audioElement) {
      setCurrentTime(audioElement.currentTime);
      setDuration(audioElement.duration);
    }
  }, [audioElement]);
  
  // Gestione del click sulla barra di avanzamento
  const handleProgressBarClick = useCallback((e) => {
    if (!audioElement || !progressRef.current) return;
    
    const progressRect = progressRef.current.getBoundingClientRect();
    const clickPosition = (e.clientX - progressRect.left) / progressRect.width;
    
    if (clickPosition >= 0 && clickPosition <= 1) {
      audioElement.currentTime = clickPosition * audioElement.duration;
      updateTime();
    }
  }, [audioElement, updateTime]);
  
  // Imposta i listener per l'elemento audio
  useEffect(() => {
    if (!audioElement) return;
    
    const handleTimeUpdate = () => updateTime();
    const handleDurationChange = () => setDuration(audioElement.duration);
    
    audioElement.addEventListener('timeupdate', handleTimeUpdate);
    audioElement.addEventListener('durationchange', handleDurationChange);
    
    // Inizializza i valori
    updateTime();
    
    return () => {
      audioElement.removeEventListener('timeupdate', handleTimeUpdate);
      audioElement.removeEventListener('durationchange', handleDurationChange);
    };
  }, [audioElement, updateTime]);
  
  return (
    <div className="AudioPlayer">
      <div className="AudioPlayer__container">
        <button 
          className="AudioPlayer__play-button"
          onClick={togglePlayPause}
          aria-label={isPlaying ? 'Pausa' : 'Riproduci'}
        >
          {isPlaying ? <HiPause /> : <HiPlay />}
        </button>
        
        <div 
          className="AudioPlayer__progress-container"
          ref={progressRef}
          onClick={handleProgressBarClick}
        >
          <div className="AudioPlayer__progress-bar">
            <div 
              className="AudioPlayer__progress-fill"
              style={{ width: `${(currentTime / duration) * 100 || 0}%` }}
            />
          </div>
        </div>
        
        <div className="AudioPlayer__time-display">
          {formatTime(currentTime)} / {formatTime(duration)}
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer; 