import React from 'react';
import PropTypes from 'prop-types';
import { FolderPlus } from 'lucide-react';
import './CreateSubfolderTile.scss';

const CreateSubfolderTile = ({ onClick }) => {
  return (
    <li className="CreateSubfolderTile" onClick={onClick}>
      <div className='CreateSubfolderTile__card'>
        <div className='CreateSubfolderTile__icon-container'>
          <FolderPlus size={28} className='CreateSubfolderTile__icon' strokeWidth={2} />
        </div>
        <p className='CreateSubfolderTile__text'>Crea sottocartella</p>
      </div>
    </li>
  );
};

CreateSubfolderTile.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CreateSubfolderTile; 