import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './materialsSummary.scss';
import useGetSummary from './hook/useGetSummary';
import Spinner from '@ui/spinner/spinner';
import { TbSection } from 'react-icons/tb';
import { HiDownload, HiLockClosed } from 'react-icons/hi';
import { useSelector, useDispatch } from 'react-redux';
import { jsPDF } from 'jspdf';
import useGetOnboardingState from '../../uploadNotes/uploadNotesCard/uploadNotesForm/hooks/useGetOnboardingState';
import { ContentIndex, AudioSummary, PremiumBanner } from './components';
import { generatePodcast } from '../../../services/openaiApi';
import { getPodcastAudioByDocumentId } from '../../../services/podcastAudioService';
import { toast } from 'react-toastify';
import { setPodcast } from '../../../redux/reducers/podcastReducer';

const CHAR_LIMIT = 1000; // Free users character limit

const MaterialsSummary = ({ document, onboardingDocument }) => {
  const { isLoading, summary, isGenerating } = useGetSummary(document);
  const [activeSection, setActiveSection] = useState(null);
  const sectionRefs = useRef({});
  const { user } = useSelector(state => state.auth);
  const [isGeneratingAudio, setIsGeneratingAudio] = useState(false);
  const [podcastAudio, setPodcastAudio] = useState(null);
  const [isLoadingPodcast, setIsLoadingPodcast] = useState(false);
  // Aggiungiamo uno stato locale per l'ID dell'utente come fallback
  const [localUserId, setLocalUserId] = useState(null);
  
  // Accediamo allo stato dei podcast in Redux
  const { podcasts, currentPodcast } = useSelector(state => state.podcast);
  const dispatch = useDispatch();
  
  // Check if user is premium based on plan
  const isPremium = user?.plan === 'Premium';

  const currentStep = useSelector(state => state.onboarding.currentStep);
  const { getCurrentStep } = useGetOnboardingState();
  
  // Effetto per recuperare l'ID dell'utente da localStorage se non è disponibile in Redux
  useEffect(() => {
    if (!user?.uid) {
      try {
        const storedUserId = localStorage.getItem('userId');
        if (storedUserId) {
          const parsedUserId = JSON.parse(storedUserId);
          console.log('Recuperato userId da localStorage:', parsedUserId);
          setLocalUserId(parsedUserId);
        } else {
          console.warn('Nessun userId trovato in localStorage');
        }
      } catch (error) {
        console.error('Errore nel recupero dell\'userId da localStorage:', error);
      }
    } else {
      // Se l'utente è disponibile in Redux, aggiorniamo anche lo stato locale
      console.log('Utente disponibile in Redux, userId:', user.uid);
      setLocalUserId(user.uid);
    }
  }, [user]);
  
  useEffect(() => {
    getCurrentStep();
  }, [getCurrentStep]);
  
  // Sincronizziamo lo stato locale con Redux
  useEffect(() => {
    if (document?.id && podcasts && podcasts[document.id]) {
      setPodcastAudio(podcasts[document.id]);
    } else if (currentPodcast && currentPodcast.document_id === document?.id) {
      setPodcastAudio(currentPodcast);
    } else {
      // Se non c'è un podcast per questo documento, resettiamo lo stato locale
      setPodcastAudio(null);
    }
  }, [document?.id, podcasts, currentPodcast]);
  
  // Effetto per caricare il podcast audio per il documento corrente
  useEffect(() => {
    const loadPodcastAudio = async () => {
      // Utilizziamo localUserId come fallback quando user.uid non è disponibile
      const effectiveUserId = user?.uid || localUserId;
      
      // Se non abbiamo un documento o un utente, o se è un documento di onboarding, non cercare podcast
      if (!document?.id || !effectiveUserId || onboardingDocument) {
        console.log('Condizioni non soddisfatte per caricare il podcast:', { 
          documentId: document?.id, 
          userId: effectiveUserId,
          reduxUserId: user?.uid,
          localUserId,
          isOnboarding: !!onboardingDocument 
        });
        return;
      }
      
      // Se stiamo già generando un podcast, non eseguire la query
      if (isGeneratingAudio) {
        console.log('Generazione audio in corso, non carichiamo da Firebase');
        return;
      }
      
      console.log('Tentativo di caricamento podcast per il documento:', document.id, 'con userId:', effectiveUserId);
      
      // Se abbiamo già il podcast in Redux per questo documento specifico, non fare richieste inutili
      if (podcasts && podcasts[document.id]) {
        console.log(`Podcast già disponibile in Redux per il documento ${document.id}:`, podcasts[document.id]);
        setPodcastAudio(podcasts[document.id]);
        return;
      }
      
      // Se abbiamo già il podcast corrente in Redux e corrisponde al documento corrente, usalo
      if (currentPodcast && currentPodcast.document_id === document.id) {
        console.log(`Usando il podcast corrente già in Redux per il documento ${document.id}:`, currentPodcast);
        setPodcastAudio(currentPodcast);
        return;
      }
      
      try {
        console.log(`Nessun podcast trovato in Redux, caricamento da Firebase per il documento ${document.id} con userId ${effectiveUserId}`);
        setIsLoadingPodcast(true);
        const podcast = await getPodcastAudioByDocumentId(document.id, effectiveUserId);
        
        if (podcast) {
          console.log('Podcast caricato con successo da Firestore:', podcast);
          // Verifica che il podcast sia effettivamente per il documento corrente
          if (podcast.document_id === document.id) {
            setPodcastAudio(podcast);
            // Salviamo anche in Redux per persistenza tra le navigazioni
            dispatch(setPodcast({ documentId: document.id, podcastData: podcast }));
          } else {
            console.warn('Il podcast caricato non corrisponde al documento corrente', {
              podcastDocumentId: podcast.document_id,
              currentDocumentId: document.id
            });
            // Non resettiamo lo stato se il podcast non corrisponde
          }
        } else {
          console.log(`Nessun podcast trovato in Firestore per il documento ${document.id}`);
          // Non resettiamo lo stato se non troviamo un podcast
        }
      } catch (error) {
        console.error('Errore nel caricamento del podcast:', error);
        // Non resettiamo lo stato in caso di errore
      } finally {
        setIsLoadingPodcast(false);
      }
    };
    
    // Eseguiamo la funzione di caricamento solo se abbiamo un ID utente
    if (user?.uid || localUserId) {
      loadPodcastAudio();
    } else {
      console.log('In attesa dell\'ID utente prima di caricare il podcast');
    }
  }, [document?.id, user?.uid, localUserId, onboardingDocument, isGeneratingAudio, podcasts, currentPodcast, dispatch]);

  // Usa onboardingSummary se esiste, altrimenti usa summary
  const effectiveSummary = summary || onboardingDocument;

  // Formatta il testo per una migliore leggibilità
  const formatText = (text) => {
    if (!text) return '';
    return text
      .replace(/\s+/g, ' ')
      .replace(/\s*([.,;:!?])\s*/g, '$1 ')
      .trim();
  };

  // Calcola le statistiche del riassunto
  const calculateStats = () => {
    if (!effectiveSummary) return { readingTime: 0, charCount: 0, sectionsCount: 0 };

    // Funzione helper per contare i caratteri in modo accurato
    const countChars = (text) => {
      if (!text) return 0;
      return formatText(text).length;
    };

    // Calcola i caratteri dell'abstract
    const abstractChars = countChars(effectiveSummary.abstract);

    // Calcola i caratteri delle sezioni
    const sectionsChars = effectiveSummary.sections?.reduce((acc, section) => 
      acc + countChars(section.content) + countChars(section.title), 0) || 0;
    
    // Calcolo del tempo di lettura basato su 1200 caratteri al minuto (velocità media di lettura in italiano)
    const readingTime = Math.max(1, Math.ceil((abstractChars + sectionsChars) / 1200));

    return {
      readingTime,
      charCount: abstractChars + sectionsChars,
      sectionsCount: effectiveSummary.sections?.length || 0
    };
  };

  // Calcola il contenuto disponibile per utenti free e premium
  const processedContent = React.useMemo(() => {
    if (!effectiveSummary) return { abstract: '', sections: [], abstractTruncated: false };

    // Funzione per troncare il testo al segno di punteggiatura più vicino
    const truncateAtPunctuation = (text, maxLength) => {
      if (text.length <= maxLength) return text;
      
      // Cerca l'ultimo segno di punteggiatura entro un range ragionevole dal punto di troncamento
      const searchRange = Math.min(50, maxLength); // Cerca fino a 50 caratteri indietro
      const textToSearch = text.substring(maxLength - searchRange, maxLength);
      
      // Cerca l'ultimo segno di punteggiatura nel range
      const punctuationMarks = ['.', '!', '?', ';', ':', ','];
      let lastPunctuationIndex = -1;
      
      for (const mark of punctuationMarks) {
        const index = textToSearch.lastIndexOf(mark);
        if (index > lastPunctuationIndex) {
          lastPunctuationIndex = index;
        }
      }
      
      // Se trovato un segno di punteggiatura, tronca lì, altrimenti usa il punto originale
      if (lastPunctuationIndex !== -1) {
        return text.substring(0, maxLength - searchRange + lastPunctuationIndex + 1) + '...';
      } else {
        return text.substring(0, maxLength) + '...';
      }
    };

    let availableChars = CHAR_LIMIT;
    let abstractTruncated = false;
    let contentTruncated = false;
    let truncationPoint = null;

    // Processa l'abstract - mostra sempre l'abstract completo
    const abstractText = formatText(effectiveSummary.abstract || '');
    const abstractChars = abstractText.length;

    let remainingChars = availableChars;
    let processedAbstract = abstractText; // Mostra sempre l'abstract completo
    
    // Aggiorna i caratteri rimanenti
    if (!isPremium) {
      remainingChars -= abstractChars;
      if (remainingChars < 0) {
        remainingChars = 0;
        contentTruncated = true;
      }
    }

    // Processa le sezioni in un unico flusso di testo
    let processedSections = [];
    let unifiedContent = '';
    
    if (effectiveSummary.sections && effectiveSummary.sections.length > 0) {
      let currentPosition = 0;
      
      for (let i = 0; i < effectiveSummary.sections.length; i++) {
        const section = effectiveSummary.sections[i];
        const sectionTitle = section.title || `Sezione ${i + 1}`;
        const sectionContent = formatText(section.content || '');
        const sectionChars = sectionContent.length;
        
        // Aggiungi la sezione all'array per l'indice
        let sectionStatus = 'visible';
        
        if (!isPremium) {
          if (remainingChars <= 0) {
            // Sezione completamente bloccata ma visibile con indicatore
            sectionStatus = 'locked';
            unifiedContent += `\n\n## ${sectionTitle}\n\n`;
            currentPosition += unifiedContent.length;
            
            processedSections.push({
              ...section,
              title: sectionTitle,
              content: sectionContent, // Manteniamo il contenuto per riferimento
              status: sectionStatus,
              position: currentPosition
            });
            continue;
          } else if (sectionChars > remainingChars) {
            // Sezione parzialmente visibile
            const partialContent = truncateAtPunctuation(sectionContent, remainingChars);
            unifiedContent += `\n\n## ${sectionTitle}\n\n${partialContent}`;
            currentPosition += unifiedContent.length;
            
            sectionStatus = 'partial';
            contentTruncated = true;
            truncationPoint = `section-${i}`;
            remainingChars = 0;
          } else {
            // Sezione completamente visibile
            unifiedContent += `\n\n## ${sectionTitle}\n\n${sectionContent}`;
            currentPosition += unifiedContent.length;
            remainingChars -= sectionChars;
          }
        } else {
          // Per utenti premium, mostra tutto
          unifiedContent += `\n\n## ${sectionTitle}\n\n${sectionContent}`;
          currentPosition += unifiedContent.length;
        }
        
        processedSections.push({
          ...section,
          title: sectionTitle,
          content: sectionContent,
          status: sectionStatus,
          position: currentPosition
        });
      }
    }

    return {
      abstract: processedAbstract,
      sections: processedSections,
      unifiedContent,
      abstractTruncated,
      contentTruncated,
      truncationPoint,
      preview: isPremium ? '' : 'Abbonati per accedere al riassunto completo'
    };
  }, [effectiveSummary, isPremium]);

  const stats = calculateStats();

  const handleSectionClick = (sectionId) => {
    setActiveSection(sectionId);
    
    // Scroll alla sezione selezionata
    if (sectionRefs.current[sectionId]) {
      sectionRefs.current[sectionId].scrollIntoView({ 
        behavior: 'smooth',
        block: 'start'
      });
    }
  };

  // Funzione per generare l'audio del podcast
  const handleGenerateAudio = () => {
    // Utilizziamo localUserId come fallback quando user.uid non è disponibile
    const effectiveUserId = user?.uid || localUserId;
    
    if (!effectiveUserId) {
      toast.error('Utente non autenticato. Impossibile generare il podcast.');
      return;
    }
    
    setIsGeneratingAudio(true);
    
    // Testo garantito che funzionerà sempre
    let guaranteedText = "Podcast educativo creato per aiutarti nello studio.\n\n";
    guaranteedText += "Questo podcast sintetizza i concetti chiave presenti nel documento e li organizza in modo logico.";
    guaranteedText += " Utilizza questo audio per ripassare i concetti e consolidare il tuo apprendimento.";
    
    if (document && document.title) {
      guaranteedText = `Podcast sul documento: ${document.title}.\n\n`;
      guaranteedText += "Questo podcast ti offre un'analisi approfondita dei concetti chiave presenti nel documento.";
      guaranteedText += " Ti aiuterà a comprendere meglio il materiale e ricordare i punti fondamentali.";
    }
    
    // Debugging
    console.log('effectiveSummary:', effectiveSummary);
    console.log('document:', document);
    
    let fullSummaryText = '';
    
    if (effectiveSummary) {
      // Prima verifica: se effectiveSummary è un array
      if (Array.isArray(effectiveSummary)) {
        fullSummaryText = effectiveSummary
          .map(section => {
            if (typeof section === 'object' && section.content) {
              return `${section.title || 'Sezione'}: ${section.content}`;
            }
            return '';
          })
          .filter(Boolean)
          .join('\n\n');
      } 
      // Seconda verifica: se effectiveSummary è un oggetto con proprietà che sono oggetti
      else if (typeof effectiveSummary === 'object') {
        // Se è una struttura ad oggetto con proprietà numeriche (come un oggetto che simula un array)
        if (Object.keys(effectiveSummary).some(key => !isNaN(parseInt(key)))) {
          fullSummaryText = Object.values(effectiveSummary)
            .filter(section => typeof section === 'object' && section.content)
            .map(section => `${section.title || 'Sezione'}: ${section.content}`)
            .join('\n\n');
        } 
        // Se è un oggetto con una proprietà 'text' o 'content' diretta
        else if (effectiveSummary.text || effectiveSummary.content) {
          fullSummaryText = effectiveSummary.text || effectiveSummary.content;
        }
        // Se è un oggetto con sezioni
        else if (effectiveSummary.sections) {
          fullSummaryText = Array.isArray(effectiveSummary.sections) 
            ? effectiveSummary.sections
                .map(section => {
                  if (typeof section === 'object' && section.content) {
                    return `${section.title || 'Sezione'}: ${section.content}`;
                  }
                  return '';
                })
                .filter(Boolean)
                .join('\n\n')
            : '';
        }
      }
      // Terza verifica: se effectiveSummary è una semplice stringa
      else if (typeof effectiveSummary === 'string') {
        fullSummaryText = effectiveSummary;
      }
    }
    
    // Fallback: se non ci sono dati nel summary, usa tutti i testi visibili delle sezioni
    if (!fullSummaryText && document && document.sections) {
      fullSummaryText = Object.values(document.sections)
        .filter(section => section && section.text)
        .map(section => section.text)
        .join('\n\n');
    }
    
    // Se ancora non abbiamo testo, usa il testo garantito
    if (!fullSummaryText) {
      console.log('Utilizzo il testo garantito come fallback');
      fullSummaryText = guaranteedText;
    }
    
    console.log('fullSummaryText finale:', fullSummaryText ? fullSummaryText.substring(0, 100) + '...' : 'vuoto');
      
    // A questo punto fullSummaryText non può essere vuoto
    // Passa l'ID e il titolo del documento per il salvataggio su Firebase
    const documentId = document?.id || null;
    const documentTitle = document?.title || 'Documento senza titolo';
    
    generatePodcast(fullSummaryText, documentId, documentTitle, effectiveUserId)
      .then(response => {
        setPodcastAudio(response);
        // Salviamo in Redux il risultato per mantenere persistenza tra le navigazioni
        if (documentId) {
          dispatch(setPodcast({ documentId, podcastData: response }));
        }
        
        if (response.firestore_id) {
          toast.success('Podcast audio generato e salvato con successo!');
        } else if (response.storage_only) {
          if (response.error_message && response.error_message.includes("Missing or insufficient permissions")) {
            toast.success('Podcast audio salvato su Firebase Storage con successo!', {
              icon: '✅'
            });
          } else {
            toast.success('Podcast audio generato e salvato correttamente su Firebase Storage.', {
              icon: '✅'
            });
          }
        } else if (response.local_only) {
          toast.warning('Podcast audio generato (disponibile solo in questa sessione).', {
            icon: '⚠️'
          });
        } else {
          toast.success('Podcast audio generato con successo!');
        }
      })
      .catch(error => {
        console.error('Errore nella generazione del podcast:', error);
        toast.error('Si è verificato un errore durante la generazione del podcast');
      })
      .finally(() => {
        setIsGeneratingAudio(false);
      });
  };

  const handleDownload = () => {
    if (!effectiveSummary || typeof window === 'undefined' || !isPremium) return;

    // Crea un nuovo documento PDF
    const doc = new jsPDF();
    
    // Configura il font e le dimensioni
    doc.setFont('helvetica');
    doc.setFontSize(16);
    
    // Funzione per aggiungere testo con wrapping automatico
    const addWrappedText = (text, y) => {
      const pageWidth = doc.internal.pageSize.getWidth();
      const margin = 20;
      const maxWidth = pageWidth - 2 * margin;
      
      // Split del testo in righe
      const lines = doc.splitTextToSize(text, maxWidth);
      
      // Aggiungi le righe alla pagina
      lines.forEach((line, index) => {
        if (y + (index * 7) > doc.internal.pageSize.getHeight() - margin) {
          doc.addPage();
          y = margin;
        }
        doc.text(line, margin, y + (index * 7));
      });
      
      return y + (lines.length * 7) + 10;
    };

    let yPosition = 20;

    // Aggiungi il titolo
    doc.setFontSize(20);
    doc.text('Riassunto del documento', 20, yPosition);
    yPosition += 20;

    // Aggiungi l'abstract se presente
    if (effectiveSummary.abstract) {
      doc.setFontSize(16);
      doc.text('Introduzione', 20, yPosition);
      yPosition += 10;
      
      doc.setFontSize(12);
      yPosition = addWrappedText(formatText(effectiveSummary.abstract), yPosition);
    }

    // Aggiungi le sezioni
    if (effectiveSummary.sections?.length > 0) {
      effectiveSummary.sections.forEach((section, index) => {
        // Controlla se c'è abbastanza spazio nella pagina corrente
        if (yPosition > doc.internal.pageSize.getHeight() - 40) {
          doc.addPage();
          yPosition = 20;
        }

        doc.setFontSize(14);
        doc.text(section.title, 20, yPosition);
        yPosition += 10;

        doc.setFontSize(12);
        yPosition = addWrappedText(formatText(section.content), yPosition);

        // Aggiungi spazio tra le sezioni
        yPosition += 10;
      });
    }

    // Salva il PDF
    try {
      doc.save('riassunto.pdf');
    } catch (error) {
      console.error('Errore durante il download del PDF:', error);
    }
  };

  // Funzione per renderizzare il contenuto unificato con i titoli delle sezioni
  const renderUnifiedContent = () => {
    if (!processedContent.abstract && !processedContent.unifiedContent) {
      return <p className="MaterialsSummary__no-content">Nessun contenuto disponibile</p>;
    }

    // Dividi il contenuto unificato in sezioni basate sui titoli
    const contentParts = processedContent.unifiedContent.split(/\n\n## /);
    
    // Il primo elemento è vuoto o contiene solo newlines, lo rimuoviamo
    if (contentParts[0].trim() === '') {
      contentParts.shift();
    }

    // Trova le sezioni bloccate
    const lockedSections = !isPremium ? 
      processedContent.sections.filter(section => section.status === 'locked').map(section => section.title) : 
      [];

    return (
      <>
        {/* Titolo principale */}
        <h1 className="MaterialsSummary__main-title">Riassunto del documento</h1>
        
        {/* Abstract */}
        {processedContent.abstract && (
          <>
            <h2 id="abstract" className="MaterialsSummary__section-heading" ref={el => sectionRefs.current['abstract'] = el}>
              Introduzione
            </h2>
            <p className="MaterialsSummary__paragraph">{processedContent.abstract}</p>
            
            {/* Visuals per l'abstract */}
            {isPremium && effectiveSummary.abstractVisuals && (
              <div className="MaterialsSummary__visuals">
                <p>{effectiveSummary.abstractVisuals}</p>
              </div>
            )}
          </>
        )}
        
        {/* Sezioni */}
        {contentParts.map((part, index) => {
          // Gestisci il caso in cui non ci sia contenuto (sezione bloccata)
          if (!part.includes('\n\n') && part.trim() === '') return null;
          
          const [title, ...contentArr] = part.split('\n\n');
          const content = contentArr.join('\n\n');
          const sectionId = `section-${index}`;
          const isLocked = lockedSections.includes(title);
          
          // Trova la sezione corrispondente nei dati originali per accedere alle visuals
          const currentSection = processedContent.sections[index];
          
          return (
            <React.Fragment key={index}>
              <h2 
                id={sectionId} 
                className="MaterialsSummary__section-heading"
                ref={el => sectionRefs.current[sectionId] = el}
              >
                {title}
              </h2>
              {isLocked ? (
                <div className="MaterialsSummary__locked-section">
                  <HiLockClosed className="MaterialsSummary__locked-icon" />
                  <p>Questa sezione è disponibile solo per utenti premium</p>
                </div>
              ) : (
                <>
                  <p className="MaterialsSummary__paragraph">{content}</p>
                  
                  {/* Visuals per le sezioni */}
                  {isPremium && currentSection && currentSection.visuals && (
                    <div className="MaterialsSummary__visuals">
                      <p>{currentSection.visuals}</p>
                    </div>
                  )}
                </>
              )}
            </React.Fragment>
          );
        })}
      </>
    );
  };

  // Se sta caricando, mostra lo spinner
  if (isLoading) {
    return (
      <div className="MaterialsSummary">
        <div className="MaterialsSummary__loading">
          <Spinner size="sm" />
          <p>Caricamento riassunto...</p>
        </div>
      </div>
    );
  }

  // Se il summary è in generazione
  if (isGenerating) {
    return (
      <div className="MaterialsSummary">
        <div className="MaterialsSummary__loading">
          <Spinner size="sm" />
          <p>Generazione riassunto in corso...</p>
        </div>
      </div>
    );
  }

  // Se non c'è il documento, mostra subito il messaggio
  if (!document && currentStep === 20) {
    return (
      <div className="MaterialsSummary">
        <p className="MaterialsSummary__no-content">
          Riassunto non disponibile
        </p>
      </div>
    );
  }

  // Se non c'è il summary o non è nel formato corretto
  if (!effectiveSummary) {
    console.debug('Summary not found:', { document, effectiveSummary });
    return (
      <div className="MaterialsSummary">
        <p className="MaterialsSummary__no-content">
          Riassunto non disponibile
        </p>
      </div>
    );
  }

  return (
    <div className="MaterialsSummary">
      {/* Stats bar */}
      <div className="MaterialsSummary__stats">
        <div className="MaterialsSummary__stats-header">
          <div 
            className={`MaterialsSummary__stats-icon ${!isPremium ? 'MaterialsSummary__stats-icon--disabled' : ''}`}
            onClick={handleDownload}
            title={isPremium ? "Scarica riassunto" : "Funzione Premium"}
          >
            {isPremium ? <HiDownload size={24} /> : <HiLockClosed size={24} />}
          </div>
          <div className="MaterialsSummary__stats-info">
            <div className="MaterialsSummary__stats-details">
              <div className="MaterialsSummary__stats-item">
                <strong>~{stats.readingTime} min</strong>
              </div>
              <div className="MaterialsSummary__stats-item">
                {stats.charCount.toLocaleString()} {isPremium ? 'caratteri disponibili' : 'caratteri'}
              </div>
              <div className="MaterialsSummary__stats-item">
                <TbSection size={20} style={{ color: 'var(--grayscale-color-950)' }} />
                {stats.sectionsCount} sezioni
              </div>
              {!isPremium && (
                <div className="MaterialsSummary__stats-item MaterialsSummary__stats-item--premium">
                  <strong style={{ color: 'rgb(37 99 235)' }}>
                    {Math.min(stats.charCount, CHAR_LIMIT).toLocaleString()}/{stats.charCount.toLocaleString()} caratteri
                  </strong>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Utilizzo il componente PremiumBanner */}
      {!isPremium && (
        <PremiumBanner 
          abstractLength={processedContent.abstract.length}
          totalLength={stats.charCount}
          isAbstractTruncated={processedContent.abstractTruncated}
        />
      )}

      <div className="MaterialsSummary__wrapper">
        {/* Colonna sinistra: Indice e Riassunto Audio */}
        <div className="MaterialsSummary__sidebar">
          {/* Utilizzo il componente ContentIndex */}
          <ContentIndex 
            effectiveSummary={effectiveSummary}
            processedContent={processedContent}
            isPremium={isPremium}
            activeSection={activeSection}
            handleSectionClick={handleSectionClick}
          />
            
          {/* Sezione per Podcast Audio */}
          {(!isPremium || effectiveSummary) && (
            <div className="MaterialsSummary__audio">
              <AudioSummary 
                isPremium={isPremium} 
                isGeneratingAudio={isGeneratingAudio}
                handleGenerateAudio={handleGenerateAudio}
                podcastAudio={podcastAudio}
                isLoadingPodcast={isLoadingPodcast}
              />
            </div>
          )}
        </div>

        {/* Contenuto principale */}
        <div className={`MaterialsSummary__container ${!isPremium ? 'MaterialsSummary__container--free' : ''}`}>
          <div className="MaterialsSummary__content">
            {/* Contenuto unificato */}
            <div className={`MaterialsSummary__unified-content ${!isPremium && processedContent.contentTruncated ? 'MaterialsSummary__unified-content--free' : ''}`}>
              {renderUnifiedContent()}
            </div>
            
            {/* Visuals se presenti e l'utente è premium */}
            {effectiveSummary.visuals && isPremium && (
              <div className="MaterialsSummary__visuals">
                <p>{effectiveSummary.visuals}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

MaterialsSummary.propTypes = {
  document: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      sectionsCount: PropTypes.number,
      summary: PropTypes.shape({
        abstract: PropTypes.string,
        hasAbstract: PropTypes.bool,
        sections: PropTypes.arrayOf(PropTypes.shape({
          title: PropTypes.string,
          content: PropTypes.string,
          visuals: PropTypes.string // Contiene descrizioni o URL di immagini/grafici relativi alla sezione
        })),
        sectionsCount: PropTypes.number
      }),
      rawExamData: PropTypes.shape({
        summary: PropTypes.shape({
          abstract: PropTypes.string,
          sections: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string,
            content: PropTypes.string,
            visuals: PropTypes.string // Contiene descrizioni o URL di immagini/grafici relativi alla sezione
          }))
        })
      }),
      rawFlashcards: PropTypes.shape({
        summary: PropTypes.shape({
          abstract: PropTypes.string,
          sections: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string,
            content: PropTypes.string,
            visuals: PropTypes.string // Contiene descrizioni o URL di immagini/grafici relativi alla sezione
          }))
        })
      }),
      examExtractedQA: PropTypes.arrayOf(PropTypes.shape({
        summary: PropTypes.shape({
          abstract: PropTypes.string,
          sections: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string,
            content: PropTypes.string,
            visuals: PropTypes.string // Contiene descrizioni o URL di immagini/grafici relativi alla sezione
          }))
        })
      }))
    })
  ]),
  onboardingDocument: PropTypes.shape({
    abstract: PropTypes.string,
    sections: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.string,
      visuals: PropTypes.string // Contiene descrizioni o URL di immagini/grafici relativi alla sezione
    }))
  })
};

export default MaterialsSummary;