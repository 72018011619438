import { GoogleAuthProvider, signInWithPopup, browserPopupRedirectResolver } from "firebase/auth";
import { auth } from "@redux/firebase";
import { toast } from "react-toastify";
import { doc, setDoc, getDoc, deleteDoc, updateDoc } from "firebase/firestore";
import { firestore } from "@redux/firebase";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser } from "@redux/actions/authActions";
import { useCallback } from "react";
import { collection, query, where, limit, getDocs } from "firebase/firestore";
import sendBrevoEmail from "../../../utils/brevoEmailsUtils";

const useSignInGoogle = mode => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Funzione per verificare l'inattività dell'utente
  const checkUserInactivity = useCallback((lastLoginAt) => {
    if (!lastLoginAt) return false;
    
    const lastLogin = lastLoginAt instanceof Date ? lastLoginAt : lastLoginAt.toDate();
    const currentDate = new Date();
    const twoWeeksInMs = 14 * 24 * 60 * 60 * 1000; // 14 giorni in millisecondi
    
    const timeDifference = currentDate.getTime() - lastLogin.getTime();
    
    return timeDifference > twoWeeksInMs;
  }, []);

  const processAuthResult = useCallback(async (user) => {
    if (!user) return;

    const emailQuery = query(
      collection(firestore, "students"), 
      where("email", "==", user.email),
      limit(1)
    );
    const emailSnapshot = await getDocs(emailQuery);

    if (!emailSnapshot.empty) {
      const existingUserId = emailSnapshot.docs[0].id;

      // Controllo se l'account è stato eliminato
      const existingUserDoc = await getDoc(doc(firestore, "students", existingUserId));
      const existingUserData = existingUserDoc.data();

      // Verifica inattività utente
      const isInactive = checkUserInactivity(existingUserData.lastLoginAt);
      if(isInactive) {
        try {
          await updateDoc(doc(firestore, "students", existingUserId), {
            currentStep: 0,
          });
          // Aggiorna anche l'oggetto dei dati locali per assicurarsi che sia coerente
          existingUserData.currentStep = 0;
        } catch (error) {
          console.error("Errore nell'aggiornamento del currentStep:", error);
        }
      }
      
      if (isInactive && typeof window.gtag === 'function') {
        window.gtag('event', 'userComeBack', {
          'event_category': 'User',
          'event_label': 'User Returned After Inactivity',
          'user_email': user.email || 'no_email',
        });
      }

      if (existingUserData.isDeleted) {
        // Se l'account è stato eliminato, riattivalo
        await setDoc(doc(firestore, "students", existingUserId), {
          ...existingUserData,
          isDeleted: false, // Riattiva l'account
          lastLoginAt: new Date(),
          photoURL: user.photoURL || existingUserData.photoURL
        }, { merge: true });

        toast.success("Account riattivato con successo.");
      } else {
        // Aggiorna ultimo login
        await setDoc(doc(firestore, "students", existingUserId), {
          ...existingUserData,
          lastLoginAt: new Date(),
          photoURL: user.photoURL || existingUserData.photoURL
        }, { merge: true });
      }

      localStorage.setItem("userId", JSON.stringify(existingUserId));
      dispatch(setUser({ ...existingUserData, uid: existingUserId }));
      navigate('/home', { replace: true });
      return;
    } else {
      try {
        // Creazione nuovo utente con dati Google
        const newUserData = {
          fullName: user.displayName || "Utente Google",
          email: user.email,
          emailVerified: true,
          study: "Non specificato",
          documentsUploaded: 0,
          maxDocumentsUpload: 2,
          examsAttempted: 0,
          currentStep: 0,
          amountEarned: 0,  
          friendsInvited: 0,
          firstFlashcardFlipped: false,
          firstMultipleChoiceAnswered: false,
          plan: "Base",
          policyAccepted: true,
          emailUseConsent: true,
          isDeleted: false,
          photoURL: user.photoURL || "",
          createdAt: new Date(),
          lastLoginAt: new Date(),
        };

        await setDoc(doc(firestore, "students", user.uid), newUserData);
        
        localStorage.setItem("userId", JSON.stringify(user.uid));
        dispatch(setUser({ ...newUserData, uid: user.uid }));
        navigate('/home', { replace: true });

        // Invio email di benvenuto
        sendBrevoEmail(user.email, user.displayName, 14);

        if (typeof window.gtag === 'function') {
          window.gtag('event', 'userSignUpGoogle', {
            'event_category': 'Account',
            'event_label': 'User Sign Up Google',
            'email': user.email
          });
        }
        
      } catch (error) {
        const isFreePlan = user.plan === "Base";
        try {    
          if (isFreePlan && typeof window.gtag === 'function') {
            window.gtag('event', 'newUserError', {
              'event_category': 'Error',
              'event_label': 'File Processing Error',
              'error_type': 'file_processing',
              'user_email': user.email || 'no_email',
              'error_message': error.message || 'Unknown error',
            });
          }
        } catch (trackingError) {
          console.error("Errore nel tracciamento:", trackingError);
        }
        
        // Rollback in caso di errore
        await deleteDoc(doc(firestore, "students", user.uid));
        toast.error("Errore durante la registrazione con Google");
        throw error;
      }
    }
  }, [dispatch, navigate]);

  const signInWithGoogle = async () => {
    console.log("Starting Google sign in");
    try {
      const provider = new GoogleAuthProvider();
      
      provider.setCustomParameters({
        prompt: 'select_account consent'
      });

      provider.addScope('profile');
      provider.addScope('email');

      if (mode === "signUp") {
        console.log("Setting signup mode");
        localStorage.setItem("newUser", "true");
      }

      console.log("Initiating popup");
      const result = await signInWithPopup(auth, provider, browserPopupRedirectResolver);
      console.log("Got result from popup");
      await processAuthResult(result.user);

      if (typeof window.gtag === 'function') {
        window.gtag('event', 'userGoogleLogin', {
          'event_category': 'User',
          'event_label': 'user google login',
        });
      }

    } catch (error) {
      if (error.code === 'auth/popup-blocked') {
        toast.error("Il popup è stato bloccato. Abilita i popup per questo sito.");
      } else if (error.code === 'auth/popup-closed-by-user') {
        toast.info("Login annullato");
      } else {
        console.error("Auth error:", error);
        toast.error("Errore durante l'accesso con Google");
      }
    }
  };

  return {
    signInWithGoogle,
  };
};

export default useSignInGoogle;