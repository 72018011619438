import React, { useState, useCallback, useEffect } from "react";
import { IoMdCheckmark, IoMdClose } from "react-icons/io";
import { TbEdit, TbPlus, TbTag, TbBrain, TbCards, TbBook, TbCheckbox } from "react-icons/tb";
import PropTypes from "prop-types";
import clsx from "clsx";
import Input from '@ui/input/input';
import { ContentOverview } from "./itemQA/itemQA";
import "./documentQA.scss";
import { addDocumentSummary, changeDocumentTags } from "../../../../redux/actions/documentActions";
import { useDispatch, useSelector } from "react-redux";
import { getTagColor } from "../hooks/useGetTagColor";
import { useRef } from "react";
import MultipleChoice from './multipleChoice/MultipleChoice';
import Flashcards from './flashcards/Flashcards';
import Questions from './questions/Questions';
import Summary from './summary/Summary';

// Onboarding 
import { Steps } from "intro.js-react";
import { setCurrentStep } from "../../../../redux/actions/oboardingActions";
import useGetOnboardingState from "../../uploadNotesCard/uploadNotesForm/hooks/useGetOnboardingState";
import { getDocumentById, getQuestions } from "../../../../lib/firestoreQueries";
import "intro.js/introjs.css";

const MENU_ITEMS = [
  { id: 'questions', icon: TbBrain, label: 'Domande ripetizione', color: 'rgb(234 88 12)' },
  { id: 'flashcards', icon: TbCards, label: 'Flashcard', color: 'rgb(5 150 105)' },
  { id: 'quiz', icon: TbCheckbox, label: 'Domande a risposta multipla', color: 'rgb(147 51 234)' },
  { id: 'summary', icon: TbBook, label: 'Riassunto', color: 'rgb(37 99 235)' }
];


const DocumentQA = ({
  index,
  isActive,
  id,
  title,
  extractedQA,
  documentsQA,
  onUpdateTitle,
  feedbackStates,
  onFeedback,
  documentTag
}) => {
  const [isChanging, setIsChanging] = useState(false);
  const [inputValue, setInputValue] = useState(title);
  const [isEditingTags, setIsEditingTags] = useState(false);
  const [currentTags, setCurrentTags] = useState([]);
  const [newTag, setNewTag] = useState('');
  const [showTagInput, setShowTagInput] = useState(false);
  const [currentLink, setCurrentLink] = useState('questions');
  const dispatch = useDispatch();
  const isPremium = useSelector(state => state.user?.isPremium);

  const currentStep = useSelector(state => state.onboarding.currentStep);
  const [stepsEnabled, setStepsEnabled] = useState(currentStep < 6);
  const { updateCurrentStep, getCurrentStep } = useGetOnboardingState({ setCurrentStep, currentStep });
  const [onboardingDocumentData, setOnboardingDocumentData] = useState(null);
  const [onboardingDocumentFlashcards, setOnboardingDocumentFlashcards] = useState(null);
  const [onboardingDocumentExam, setOnboardingDocumentExam] = useState(null);
  const [onboardingDocumentQuiz, setOnboardingDocumentQuiz] = useState(null);
  const menuRef = useRef(null);

  useEffect(() => {
    getCurrentStep();
  }, [getCurrentStep, currentStep, stepsEnabled]); 
   
 const steps = [
    currentStep === 3 && {
      element: ".DocumentQA__menu__link--questions",
      intro: "Qui potrai accedere alle domande di ripetizione estratte dal documento",
    },
   currentStep === 4 && {
     element: ".DocumentQA__menu__link--flashcards",
     intro: "Qui troverai le flashcards",
   },
   currentStep === 5 && {
     element: ".DocumentQA__menu__link--quiz",
     intro: "Qui invece il quiz con domande a risposta multipla",
   },
   currentStep === 6 && {
    element: ".DocumentQA__menu__link--summary",
    intro: "Ed infine, il riassunto del documento",
   },
   currentStep === 7 && {
     element: ".DocumentQA__footer-content",
     intro: "Quando sei pronta/o, clicca sul tasto salva esame",
   },
 ].filter(Boolean);
 
 const onExit = async () => {
   if(currentStep === 7) {
     setStepsEnabled(false);
     return;
   }
   
   if(currentStep > 2 && currentStep < 8) {
     updateCurrentStep(currentStep + 1);
   }
 };
 
 const fetchDocument = async () => {
   try {
     const data = await getDocumentById('yZI5jQzrvGbTsid0kvk6'); // onboardingDocID
     setOnboardingDocumentData(data);
     console.log(onboardingDocumentData)
     setCurrentTags(data.tags.tags);
   } catch (error) {
     console.error('Error fetching document:', error);
   } 
 };
 
 const fetchQuestions = async () => {
   try {
     const questionsSnapshot = await getQuestions('yZI5jQzrvGbTsid0kvk6');// onboardingDocID
     const questions = questionsSnapshot.docs.map(doc => ({
       id: doc.id,
       ...doc.data()
     }));
 
     console.log('onboardingdocumentData', onboardingDocumentData);
     const flashcards = questions.filter(item => item.metadata.isFlashcard === true);
     console.log('FLASHCARDS',flashcards);
     const examQuestions = questions.filter(item => item.metadata.isExamQuestion === true);
     console.log('EXAM QUESTIONS',examQuestions);
     const quizQuestions = questions.filter(item => item.metadata.isQuizQuestion === true);
     console.log('QUIZ QUESTIONS',quizQuestions);
     setOnboardingDocumentFlashcards(flashcards);
     setOnboardingDocumentExam(examQuestions);
     setOnboardingDocumentQuiz(quizQuestions);
   } catch (error) {
     console.error('Error fetching questions:', error);
   }
 };
 
   useEffect(() => { 
     if(currentStep <= 8) {
       fetchDocument();
       fetchQuestions();
     }
   }, [currentStep]);
 
   useEffect(() => { 
     if (currentStep === 3) {
       console.log('Current step is 3');
       
       setTimeout(() => {
         const tooltip = document.querySelector('.introjs-tooltip');
         if (tooltip) {
           tooltip.style.marginTop = '28px';
         }
         
         const helperLayer = document.querySelector('.introjs-helperLayer');
         if (helperLayer) {
           helperLayer.style.marginTop = '28px';
         }
       }, 600); 
     } else if (currentStep === 5) {
       if (menuRef.current) {
         menuRef.current.scrollTo({
           left: menuRef.current.scrollWidth-450,
           behavior: 'smooth' // Aggiungi un comportamento di scorrimento fluido
         });
       }
     }
     else if (currentStep === 6) {
       if (menuRef.current) {
         menuRef.current.scrollTo({
           left: menuRef.current.scrollWidth,
           behavior: 'smooth' // Aggiungi un comportamento di scorrimento fluido
         });
       }
     }
     
   }, [currentStep]);
  

  // Estraiamo i dati specifici
  const extractData = () => {
    console.log('Raw DocumentsQA:', documentsQA);
    
    if (!documentsQA || !Array.isArray(documentsQA) || documentsQA.length === 0) {
      console.log('No documentsQA data available');
      return { 
        questions: [], 
        flashcards: [], 
        summary: '', 
        sections: [], 
        tags: [], 
        quiz_questions: [] 
      };
    }

    try {
      const rawData = documentsQA[0];
      console.log('Raw data structure:', rawData);

      // Parse delle flashcard
      let flashcardsData = [];
      try {
        // Prima prova da qa_response.flashcards (nuovo formato)
        if (rawData.qa_response?.flashcards) {
          flashcardsData = rawData.qa_response.flashcards.map(card => ({
            question: card.card_question,
            answer: card.card_answer
          }));
        }
        // Se non ci sono, prova da extractedQA[0].qa_pairs (formato frontend)
        else if (rawData.extractedQA?.[0]?.qa_pairs) {
          flashcardsData = rawData.extractedQA[0].qa_pairs;
        }
        // Infine prova da rawFlashcards.flashcards (backup)
        else if (rawData.rawFlashcards?.flashcards) {
          flashcardsData = rawData.rawFlashcards.flashcards.map(card => ({
            question: card.card_question,
            answer: card.card_answer
          }));
        }
        
        console.log('📝 Flashcards:', {
          count: flashcardsData.length,
          tags: rawData.tags?.length || 0
        });
      } catch (e) {
        console.error('Error parsing flashcards:', e);
        flashcardsData = [];
      }

      // Parse delle domande di ripetizione
      let examData;
      try {
        if (rawData.interrogation_questions) {
          const parsedQuestions = typeof rawData.interrogation_questions === 'string'
            ? JSON.parse(rawData.interrogation_questions)
            : rawData.interrogation_questions;
          
          if (Array.isArray(parsedQuestions)) {
            examData = parsedQuestions[0];
          } else {
            examData = parsedQuestions;
          }
        } else if (rawData.examExtractedQA && rawData.examExtractedQA.length > 0) {
          examData = rawData.examExtractedQA[0];
        }
        
        if (examData && !examData.qa_pairs && examData.question) {
          examData = {
            qa_pairs: [{ question: examData.question, answer: examData.answer }],
            tags: examData.tags || []
          };
        }
        
        console.log('📚 Exam questions:', {
          count: examData?.qa_pairs?.length || 0
        });
      } catch (e) {
        console.error('Error parsing exam questions:', e);
        examData = { qa_pairs: [] };
      }

      // Parse del summary
      let parsedSummary;
      try {
        parsedSummary = typeof rawData.summary === 'string'
          ? JSON.parse(rawData.summary)
          : rawData.summary;
        console.log('📑 Summary:', {
          hasAbstract: !!parsedSummary?.abstract,
          sectionsCount: parsedSummary?.sections?.length || 0
        });
      } catch (e) {
        console.error('Error parsing summary:', e);
        parsedSummary = { abstract: '', sections: [] };
      }

      // Parse multiple choice questions
      let quizData = [];
      try {
        if (rawData.quiz_questions) {
          quizData = typeof rawData.quiz_questions === 'string'
            ? JSON.parse(rawData.quiz_questions)
            : rawData.quiz_questions;
        }

        // Assicurati che sia un array
        if (!Array.isArray(quizData)) {
          quizData = [];
        }

        console.log('🎯 Quiz questions raw:', quizData);

        // Valida e formatta ogni domanda
        quizData = quizData
          .filter(q => {
            const isValid = q && q.query_text /* && Array.isArray(q.answer_choices) && q.answer_choices.length > 0 && q.correct_choice */;
            if (!isValid) {
              console.log('⚠️ Invalid quiz question:', q);
            }
            return isValid;
          })
          .map(q => ({
            query_text: q.query_text,
            answer_choices: q.answer_choices,
            correct_choice: q.correct_choice,
            difficulty_level: q.difficulty_level || 'medium',
            justification: q.justification || ''
          }))
          .sort((a, b) => {
            // Ordine personalizzato: facile -> medio -> difficile
            const difficultyOrder = { easy: 1, medium: 2, hard: 3 };
            return difficultyOrder[a.difficulty_level] - difficultyOrder[b.difficulty_level];
          });

        console.log('🎯 Final quiz questions:', {
          count: quizData.length,
          byDifficulty: quizData.reduce((acc, q) => {
            acc[q.difficulty_level] = (acc[q.difficulty_level] || 0) + 1;
            return acc;
          }, {})
        });
      } catch (e) {
        console.error('❌ Error parsing quiz questions:', e);
        quizData = [];
      }

      const result = {
        questions: examData?.qa_pairs || [],
        flashcards: flashcardsData,
        summary: parsedSummary?.abstract || '',
        sections: parsedSummary?.sections || [],
        tags: rawData.tags || [],
        quiz_questions: quizData
      };

      console.log('Final processed result:', {
        questionsCount: result.questions.length,
        flashcardsCount: result.flashcards.length,
        hasSummary: !!result.summary,
        sectionsCount: result.sections.length,
        tags: result.tags,
        quizCount: result.quiz_questions.length,
        rawFlashcards: flashcardsData,
        rawExamData: examData,
        rawQuiz: quizData
      });

      return result;
    } catch (error) {
      console.error('Error in extractData:', error);
      return {
        questions: [],
        flashcards: [],
        summary: '',
        sections: [],
        tags: [],
        quiz_questions: []
      };
    }
  };

  const { questions, flashcards, summary, sections, tags, quiz_questions } = extractData();

  // Aggiorniamo currentTags quando arrivano nuovi tags dal server
  useEffect(() => {
    if (tags && tags.length > 0) {
      // Limitiamo a 3 tag solo quelli che arrivano dal server
      const limitedTags = tags.slice(0, 3);
      setCurrentTags(limitedTags);
      dispatch(changeDocumentTags(id, limitedTags));
    }
  }, [tags, dispatch, id]);

  useEffect(() => {
    setInputValue(title);
    if (summary) {
      dispatch(addDocumentSummary(id, summary));
    }
  }, [title, summary, dispatch, id]);

  const position = isActive ? 0 : index === 0 ? 1 : index;

  const handleEditTitle = useCallback(() => {
    if (isChanging) {
      const newTitle = inputValue.trim();
      if (newTitle && title !== newTitle) {
        console.log('📝 Aggiornamento titolo temporaneo:', {
          id,
          oldTitle: title,
          newTitle
        });
       
        onUpdateTitle(newTitle);
      } else {
        // Se il titolo è vuoto o non è cambiato, ripristina il valore precedente
        setInputValue(title);
      }
      setIsChanging(false);
    } else {
      setIsChanging(true);
    }
  }, [isChanging, title, inputValue, id, onUpdateTitle]);

  const changingInputValue = useCallback((e) => {
    const value = e.target.value;
    setInputValue(value.charAt(0).toUpperCase() + value.slice(1));
  }, []);

  const handleKeyPress = useCallback((e) => {
    if (e.key === 'Enter') {
      handleEditTitle();
    } else if (e.key === 'Escape') {
      setInputValue(title);
      setIsChanging(false);
    }
  }, [handleEditTitle, title]);

  const handleAddTag = () => {
    const tagToAdd = newTag.trim();
    if (tagToAdd && !currentTags.includes(tagToAdd)) {
      const updatedTags = [...currentTags, tagToAdd];
      setCurrentTags(updatedTags);
      dispatch(changeDocumentTags(id, updatedTags));
      setNewTag('');
      setShowTagInput(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAddTag();
    } else if (e.key === 'Escape') {
      setNewTag('');
      setShowTagInput(false);
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    const updatedTags = currentTags.filter(tag => tag !== tagToRemove);
    setCurrentTags(updatedTags);
    dispatch(changeDocumentTags(id, updatedTags));
  };

  const handleBlur = useCallback(() => {
    handleEditTitle();
  }, [handleEditTitle]); 
 

  // Gestione separata dei feedback per flashcards e domande
  const handleQuestionFeedback = (questionIndex, isHelpful) => {
    console.log(`📝 Feedback domanda ${questionIndex + 1}: ${isHelpful ? '✅' : '❌'}`);
    onFeedback(id, 'questions', questionIndex, isHelpful);
  };

  const handleFlashcardFeedback = (flashcardIndex, isHelpful) => {
    console.log(`📝 Feedback flashcard ${flashcardIndex + 1}: ${isHelpful ? '✅' : '❌'}`);
    onFeedback(id, 'flashcards', flashcardIndex, isHelpful);
  };

  // Funzione per calcolare le metriche del riassunto
  const calculateSummaryMetrics = (summary, sections) => {
    const countChars = (text) => {
      if (!text) return 0;
      return text.replace(/\s+/g, ' ').trim().length;
    };

    const abstractChars = countChars(summary);
    const sectionsChars = sections.reduce((acc, section) => 
      acc + countChars(section.content) + countChars(section.title), 0);
    
    const totalChars = abstractChars + sectionsChars;
    // Calcolo del tempo di lettura basato su 1200 caratteri al minuto (velocità media di lettura in italiano)
    const readingTime = Math.max(1, Math.ceil(totalChars / 1200));

    return {
      totalChars,
      abstractChars,
      sectionsChars,
      readingTime,
      sectionsCount: sections.length
    };
  };

  const calculateTilte = () => {
    if(onboardingDocumentData?.title) {
      return onboardingDocumentData.title?.length > 25 
        ? onboardingDocumentData.title.slice(0, 25)
        : onboardingDocumentData.title
    } else {
      return title?.length > 25 
        ? title.slice(0, 25) + '...' 
        : title
    }
  };

  return (
   <div
     style={{ "--elPos": position }}
     className={clsx("DocumentQA", /* isActive && */ "DocumentQA--active")}
   > 
   {stepsEnabled && (  
      <Steps
        enabled={stepsEnabled}
        steps={steps}
        initialStep={0}
        onExit={onExit}
      />  
    )}
     <header className="DocumentQA__header">
       <div className="DocumentQA__title-container">
         {isChanging ? (
           <Input
             className="DocumentQA__title-Input"
             type="text"
             value={inputValue}
             onChange={changingInputValue}
             onKeyDown={handleKeyPress}
             onBlur={handleBlur}
             autoFocus
             />
            ) : (
              <h2 className="DocumentQA__title">{calculateTilte()}</h2>
            )}
          {!onboardingDocumentData &&
            <div className="DocumentQA__actions">
              <TbTag
                className={clsx(
                  "DocumentQA__tagIcon",
                  isEditingTags && "DocumentQA__tagIcon--active"
                )}
                onClick={() => setIsEditingTags(!isEditingTags)}
                />
              {isChanging
                ? <IoMdCheckmark className="DocumentQA__saveIcon" onClick={handleEditTitle}/>
                : <TbEdit className="DocumentQA__editIcon" onClick={handleEditTitle}/>
              }
            </div>
          }
       </div>
        
        <div className="DocumentQA__tags-wrapper">
          <div className="DocumentQA__tags-section">
            {currentTags.map((tag, idx) => (
              <div key={`${tag}-${idx}`} className="DocumentQA__tags">
                <span
                  className={clsx(
                    "DocumentQA__tag",
                    isEditingTags && "DocumentQA__tag--editing" 
                  )}
                  style={{ 
                    backgroundColor: getTagColor(idx, tag).backgroundColor, 
                    color: getTagColor(idx, tag).color,
                    borderColor: getTagColor(idx, tag).borderColor,
                  }}
                >
                  {tag}
                  {isEditingTags && (
                    <IoMdClose
                      className="DocumentQA__tag-remove"
                      onClick={() => handleRemoveTag(tag)}
                    />
                  )}
                </span>
              </div> 
            ))}
                {isEditingTags && !showTagInput && (
                  <button
                  className="DocumentQA__add-tag"
                  onClick={() => setShowTagInput(true)}
                  >
                    <TbPlus className="DocumentQA__add-icon" />
                    Aggiungi
                  </button>
                )}
            {isEditingTags && showTagInput && (
              <div className="DocumentQA__tag-input-wrapper">
                <Input
                  className="DocumentQA__tag-input"
                  type="text"
                  value={newTag}
                  onChange={(e) => setNewTag(e.target.value)}
                  onKeyDown={handleKeyDown}
                  placeholder="Premi invio per aggiungere..."
                  autoFocus
                />
              </div>
            )}
          </div>

          <div className="DocumentQA__menu-container">
            <div 
              className="DocumentQA__menu" 
              role="tablist"
              aria-label="Sezioni documento"
              ref={menuRef}
            >
              {MENU_ITEMS.map((item) => {
                const Icon = item.icon;
                return (
                  <div
                    key={item.id}
                    className={clsx(
                      "DocumentQA__menu__link",
                      `DocumentQA__menu__link--${item.id}`,
                      currentLink === item.id && "DocumentQA__menu__link--active"
                    )}
                    onClick={() => setCurrentLink(item.id)}
                    role="tab"
                    aria-selected={currentLink === item.id}
                    aria-controls={`panel-${item.id}`}
                    tabIndex={currentLink === item.id ? 0 : -1}
                    style={{ "--tab-color": item.color }}
                  >
                    <Icon size={20} />
                    <span>{item.label}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </header>
      
      {currentLink === 'flashcards' ? (
        <div className="DocumentQA__body">
          {onboardingDocumentFlashcards || flashcards  ? (
            <>
              <ContentOverview 
                icon={TbCards}
                count={onboardingDocumentFlashcards?.length || flashcards?.length}
                type="flashcard"
                color={MENU_ITEMS.find(item => item.id === 'flashcards').color}
                isPremium={isPremium}
              />
              <Flashcards 
                flashcards={onboardingDocumentFlashcards || flashcards}
                onFeedback={handleFlashcardFeedback}
                onboardingDocumentData={onboardingDocumentData}
              />
            </>
          ) : (
            <p>Nessuna flashcard disponibile</p>
          )}
        </div>
      ) : currentLink === 'summary' ? (
        <div className="DocumentQA__body">
          {(() => {
            const metrics = calculateSummaryMetrics(summary, sections);
            return (
              <>
                <ContentOverview 
                  icon={TbBook}
                  count={metrics.readingTime}
                  type="summary"
                  color={MENU_ITEMS.find(item => item.id === 'summary').color}
                  additionalInfo={{
                    words: metrics.totalChars,
                    sections: metrics.sectionsCount
                  }}
                />
                <Summary onboardingDocumentData={onboardingDocumentData} summary={summary} sections={sections} />
              </>
            );
          })()}
        </div>
      ) : currentLink === 'quiz' ? (
        <div className="DocumentQA__body">
          {onboardingDocumentQuiz || quiz_questions  ? (
            <MultipleChoice questions={onboardingDocumentQuiz || quiz_questions} />
          ) : (
            <div className="DocumentQA__empty">
              <p>Nessuna domanda a risposta multipla disponibile.</p>
            </div>
          )}
        </div>
      ) : (
        <div className="DocumentQA__body">
          {onboardingDocumentExam || questions ? (
            <>
              <ContentOverview 
                icon={TbBrain}
                count={(onboardingDocumentExam || questions).length}
                type="question"
                color={MENU_ITEMS.find(item => item.id === 'questions').color}
              />
              <Questions 
                questions={onboardingDocumentExam || questions}
                onFeedback={handleQuestionFeedback}
                onboardingDocumentData={onboardingDocumentData}
              />
            </>
          ) : (
            <p>Nessuna domanda di ripetizione disponibile</p>
          )}
        </div>
      )}
   </div>
 );
};


DocumentQA.propTypes = {
 index: PropTypes.number.isRequired,
 isActive: PropTypes.bool.isRequired,
 id: PropTypes.string.isRequired,
 title: PropTypes.string.isRequired,
 extractedQA: PropTypes.array,
 documentsQA: PropTypes.array,
 onUpdateTitle: PropTypes.func.isRequired,
 feedbackStates: PropTypes.object,
 onFeedback: PropTypes.func.isRequired,
 documentTag: PropTypes.array
};


export default DocumentQA;