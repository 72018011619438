import React, { useState } from 'react';
import './multipleChoice.scss';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { BsQuestionCircle, BsCheckCircle, BsXCircle, BsLightbulb, BsArrowRepeat } from 'react-icons/bs';
import { HiLockClosed } from 'react-icons/hi';
import { useSelector } from 'react-redux';
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { firestore } from "@redux/firebase";

const MultipleChoice = ({ 
  queryText, 
  answerChoices, 
  correctChoice, 
  justification, 
  difficultyLevel, 
  isLocked = false 
}) => {
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [showFeedback, setShowFeedback] = useState(false);
  const { user } = useSelector(state => state.auth);

  // Normalize answer choices to ensure consistent format
  const normalizedAnswerChoices = typeof answerChoices === 'object' && !Array.isArray(answerChoices) 
    ? answerChoices 
    : Object.fromEntries((answerChoices || []).map((choice, index) => [String(index + 1), choice]));

  const handleAnswerSelect = async (key) => {
    if (showFeedback || isLocked) return;
    setSelectedAnswer(key);
    setShowFeedback(true);

    const userId = JSON.parse(localStorage.getItem("userId"));
    const studentRef = doc(firestore, "students", userId);
    const studentSnap = await getDoc(studentRef);
    
    // Verifica e trigger primo completamento
    if (studentSnap.exists()) {
      const studentData = studentSnap.data();
      if (studentData.firstMultipleChoiceAnswered === false) {
        try {
          const userId = JSON.parse(localStorage.getItem("userId"));
          const studentRef = doc(firestore, "students", userId);
          
          if (typeof window.gtag === 'function') {
            window.gtag('event', 'firstMultipleChoiceAnswered', {
              'event_category': 'Documents',
              'event_label': 'First Multiple Choice Answered',
              'email': user.email
            });
          }
          
          await updateDoc(studentRef, {
            firstMultipleChoiceAnswered: true
          });
        } catch (error) {
          console.error("Error updating firstMultipleChoiceAnswered:", error);
        }
      }
    };

    // Trigger GA4 custom event 
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'multipleChoiceAnswered', {
        'event_category': 'Documents',
        'event_label': 'Multiple Choice Answered',
        'email': user.email,
        'difficulty': difficultyLevel,
        'isCorrect': String(key) === String(correctChoice)
      });
    } else {
      console.error("gtag is not defined");
    }
  };

  const handleReset = (e) => {
    if (isLocked) return;
    e.stopPropagation();
    setSelectedAnswer(null);
    setShowFeedback(false);
  };

  const handleReveal = (e) => {
    if (isLocked) return;
    e.stopPropagation();
    setShowFeedback(true);
    setSelectedAnswer(correctChoice);
  };

  const isAnswerCorrect = String(selectedAnswer) === String(correctChoice);

  const getDifficultyLabel = (level) => {
    const labels = {
      easy: 'Facile',
      medium: 'Media',
      hard: 'Difficile'
    };
    return labels[level] || level;
  };

  return (
    <div className='col-xs-12 col-sm-6 col-md-4 col-lg-3'>
      <div className={clsx('MultipleChoice', isLocked && 'MultipleChoice--locked')}>
        <div className='MultipleChoice__inner'>
          {/* Header with difficulty and controls */}
          <div className='MultipleChoice__header'>
            <div className='MultipleChoice__header-left'>
              <BsQuestionCircle className='MultipleChoice__icon' />
              {!isLocked && !showFeedback && (
                <button 
                  className='MultipleChoice__reveal-btn'
                  onClick={handleReveal}
                  title="Mostra risposta"
                >
                  <BsLightbulb />
                </button>
              )}
              {!isLocked && showFeedback && (
                <button 
                  className='MultipleChoice__reset-btn'
                  onClick={handleReset}
                  title="Riprova"
                >
                  <BsArrowRepeat />
                </button>
              )}
            </div>
            <div className='MultipleChoice__header-right'>
              <span className={clsx(
                'MultipleChoice__difficulty',
                `MultipleChoice__difficulty--${difficultyLevel}`
              )}>
                {getDifficultyLabel(difficultyLevel)}
              </span>
            </div>
          </div>

          {isLocked ? (
            <>
              {/* Preview for locked content */}
              <div className="MultipleChoice__preview">
                <div className="MultipleChoice__question">
                  <p className="MultipleChoice__text">{queryText.slice(0, 50)}...</p>
                </div>
                <div className="MultipleChoice__preview-fade">
                  <div className="MultipleChoice__content-truncated">
                    <HiLockClosed />
                    <span>Contenuto Premium</span>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {/* Question text */}
              <div className='MultipleChoice__question'>
                <p className='MultipleChoice__text'>{queryText}</p>
              </div>

              {/* Answer choices */}
              <div className='MultipleChoice__choices'>
                {Object.entries(normalizedAnswerChoices).map(([key, choice]) => {
                  const cleanChoice = choice.replace(/^opzione\s+(?:uno|due|tre|quattro|[1-4])\s*[-:]\s*/i, '');
                  const isCorrect = String(key) === String(correctChoice);
                  const isSelected = String(key) === String(selectedAnswer);
                  
                  return (
                    <button
                      key={key}
                      className={clsx(
                        'MultipleChoice__choice',
                        isSelected && 'MultipleChoice__choice--selected',
                        showFeedback && isCorrect && 'MultipleChoice__choice--correct',
                        showFeedback && isSelected && !isCorrect && 'MultipleChoice__choice--incorrect'
                      )}
                      onClick={() => handleAnswerSelect(key)}
                      disabled={showFeedback}
                    >
                      <span className='MultipleChoice__choice-text'>{cleanChoice}</span>
                      {showFeedback && (isCorrect || (isSelected && !isCorrect)) && (
                        <span className='MultipleChoice__choice-icon'>
                          {isCorrect ? (
                            <BsCheckCircle className='MultipleChoice__choice-icon--correct' />
                          ) : (
                            <BsXCircle className='MultipleChoice__choice-icon--incorrect' />
                          )}
                        </span>
                      )}
                    </button>
                  );
                })}
              </div>

              {/* Feedback section */}
              {showFeedback && (
                <div className={clsx(
                  'MultipleChoice__feedback',
                  isAnswerCorrect 
                    ? 'MultipleChoice__feedback--correct'
                    : 'MultipleChoice__feedback--incorrect'
                )}>
                  <div className='MultipleChoice__feedback-header'>
                    {isAnswerCorrect ? (
                      <>
                        <BsCheckCircle className='MultipleChoice__feedback-icon--correct' />
                        <span>Corretto!</span>
                      </>
                    ) : (
                      <>
                        <BsXCircle className='MultipleChoice__feedback-icon--incorrect' />
                        <span>Non corretto</span>
                      </>
                    )}
                  </div>
                  <p className='MultipleChoice__feedback-text'>
                    {justification}
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

MultipleChoice.propTypes = {
  queryText: PropTypes.string.isRequired,
  answerChoices: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired,
  correctChoice: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  justification: PropTypes.string.isRequired,
  difficultyLevel: PropTypes.oneOf(['easy', 'medium', 'hard']).isRequired,
  isLocked: PropTypes.bool
};

export default MultipleChoice; 