const useGenerateReffarlCode = () => {
  const getGenerateReferralCode = async () => {
    let API_URL;

    if (process.env.REACT_APP_ENVIRONMENT === "development") {
      API_URL = "http://localhost:8080";
    } else if (process.env.REACT_APP_ENVIRONMENT === "preproduction") {
      API_URL =  process.env.REACT_APP_API_URL_PREPRODUCTION;
    } else {
      API_URL = process.env.REACT_APP_API_URL_PRODUCTION;
    }
    
    console.log(`🔗 URL API per la generazione del codice: ${API_URL}/create-stripe-code`);
    
    let referralCode = null;
    const userId = localStorage.getItem("userId");
    
    if (!userId) {
      console.error("❌ userId non trovato nel localStorage");
      return null;
    }
    
    try {
      console.log(`🔍 Invio richiesta con userId: ${userId}`);
      const response = await fetch(`${API_URL}/create-stripe-code`, {
        method: "POST",
        headers: { 
          "Content-Type": "application/json",
          "Accept": "application/json"
        },
        body: JSON.stringify({ userId: userId })
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        console.error(`❌ Errore HTTP: ${response.status}`, errorText);
        throw new Error(`HTTP error! status: ${response.status}, details: ${errorText}`);
      }
  
      const data = await response.json();
      console.log("✅ Referral Code creato con successo:", data);
      
      if (!data.referralCode) {
        console.error("❌ Il server ha risposto senza un referralCode valido:", data);
        throw new Error("Risposta del server non valida: manca il referralCode");
      }
      
      referralCode = data.referralCode;
      console.log(`🎁 Codice referral generato: ${referralCode}`);
      return referralCode;
  
    } catch (error) {
      console.error("❌ Errore nella generazione referral code:", error);
      // Lancia l'errore per permettere a chi chiama la funzione di gestirlo
      throw error;
    }
  }

  return { getGenerateReferralCode };  
}

export default useGenerateReffarlCode;
