import { SchoolPlan } from "../SchoolPlan/SchoolPlan";
import { UniversityPlan } from "../UniversityPlan/UniversityPlan";

export const AnnualPlans = ({ isAnnual, setIsAnnual, isLoading, isPremium, handlePremiumSubscription, onClick, isLanding }) => {

  return (
    <div className={`Plans__annual-modes-container ${isLanding ? "Plans__annual-modes-container-landing" : null}`}>
      <SchoolPlan
        isPremium={isPremium}
        isLoading={isLoading}
        isAnnual={isAnnual}
        setIsAnnual={setIsAnnual}
        handlePremiumSubscription={isLanding ? onClick : () => handlePremiumSubscription("price_1R14csHFdskyUb7jyio3ntKo", 'Annual School')}     
        isLanding={isLanding}
      />
      
      <UniversityPlan
        isPremium={isPremium}
        isLoading={isLoading}
        isAnnual={isAnnual}
        setIsAnnual={setIsAnnual}
        handlePremiumSubscription={isLanding ? onClick : () => handlePremiumSubscription("price_1R14DMHFdskyUb7jGQfWLm2R",  'Annual University')}
        isLanding={isLanding}
      />
    </div>
  )
};