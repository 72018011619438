import React, { useCallback, useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { GrMoney } from "react-icons/gr";
import { IoCopy } from "react-icons/io5";
import { firestore } from "../../redux/firebase";
import { collection, query, where, getDocs, doc, updateDoc, getDoc, serverTimestamp } from "firebase/firestore";
import "./referralCode.scss";
import useGenerateReffarlCode from "../../hooks/useGenerateReffarlCode";
import { 
  handleRefferalCodeTracking,
  verifyReferralValidity,
  generateReferralCode 
} from "../../utils/referralUtils";
import { toast } from "react-toastify";

const ReferralCode = () => {
  const { user } = useSelector(state => state.user);
  const [friendsInvited, setFriendsInvited] = useState(0); // Numero di persone invitate
  const [amountEarned, setAmountEarned] = useState(0); // Ammontare totale guadagnato in €
  const [isLoading, setIsLoading] = useState(false); // Stato di caricamento per la generazione del codice
  const [isValidCode, setIsValidCode] = useState(true); // Flag per sapere se il codice è valido (per i prodotti specifici)
  const [isVerifying, setIsVerifying] = useState(false); // Stato di caricamento per la verifica del codice
  const [generationStatus, setGenerationStatus] = useState(""); // Stato della generazione del codice: "success", "error" o ""
  const [localReferralCode, setLocalReferralCode] = useState(localStorage.getItem("tempReferralCode") || ""); // Codice referral generato localmente
  const justGeneratedRef = useRef(false); // Riferimento per tracciare se un codice è stato appena generato
  const { getGenerateReferralCode } = useGenerateReffarlCode();
  
  const setters = {
    setIsLoading,
    setGenerationStatus,
    setLocalReferralCode,
    setIsValidCode,
    setIsVerifying
  };

  const handleRefferalCodeActions = () => {  
    handleRefferalCodeTracking(user, localReferralCode);
  }

  // Verifica se il codice è valido per i prodotti specifici
  const checkCodeValidity = async (referralCode) => {
    await verifyReferralValidity(
      referralCode,
      user,
      { setIsValidCode, setIsVerifying }
    );
  };

  // Genera un nuovo codice referral e aggiorna Firestore
  const generateNewCode = async () => {
    await generateReferralCode(
      getGenerateReferralCode,
      setters,
      user
    );
  };

  // Effettua la validazione del codice referral all'avvio del componente
  useEffect(() => {
    // Validare il codice referral dell'utente o il codice temporaneo
    const codeToValidate = user?.referralCode || localReferralCode;
    if (codeToValidate) {
      checkCodeValidity(codeToValidate);
    }
  }, [user?.referralCode, localReferralCode]);

  // Aggiunto useEffect dedicato per gestire i nuovi codici generati
  useEffect(() => {
    if (localReferralCode && !user?.referralCode) {
      setIsValidCode(true); // Imposta subito come valido il nuovo codice generato
      checkCodeValidity(localReferralCode); // Verifica asincrona
    }
  }, [localReferralCode]); // Scatta quando cambia il codice locale

  useEffect(() => {
    if (!user?.referralCode) {
      setFriendsInvited(0);
      setAmountEarned(0);
      return;
    }

    const fetchFriendsInvited = async () => {
      if (!user?.referralCode) {
        setFriendsInvited(0);
        setAmountEarned(0);
        return;
      }

      try {
        const studentsRef = collection(firestore, 'students');
        const q = query(studentsRef, where('referralCode', '==', user.referralCode));
        const querySnapshot = await getDocs(q);
        const invitedCount = querySnapshot.docs[0].data().friendsInvited;
        console.log("🔍 Invited Count:", invitedCount);
        setFriendsInvited(invitedCount);
        setAmountEarned(invitedCount * 5); // 5€ per ogni amico invitato
      } catch (error) {
        console.error("Error fetching friends invited:", error);
        setFriendsInvited(0);
        setAmountEarned(0);
      }
    };

    fetchFriendsInvited();
  }, [user?.referralCode, user?.plan, localReferralCode]);

  const handleShare = useCallback(() => {
    toast.success("Codice referral copiato negli appunti");
    const shareCode = user?.referralCode || localReferralCode;
    navigator.clipboard.writeText(shareCode);
  }, [user?.referralCode, localReferralCode]);

  return (
    <div className="referral-page">
      <div className="referral-container animate-fade-in">
        <h1 className="referral-title animate-slide-down">Invita Amici e Guadagna Premi</h1>
        <p className="referral-subtitle animate-slide-down">Condividi il tuo codice referral e guadagna 5€ in giftcard Amazon per ogni amico che si iscrive a StudierAI Premium Annuale</p>
        
        <div className="referral-code-card animate-slide-up">
          <div className="referral-code-header">Il Tuo Codice Referral</div>
          <div className="referral-code-content">
            {isVerifying ? (
              // Mostra indicatore di caricamento durante la verifica
              <div className="code-verifying">
                <p>Verifica del codice in corso...</p>
              </div>
            ) : isLoading ? (
              // Mostra indicatore di caricamento durante la generazione
              <div className="code-verifying">
                <p>Generazione del codice in corso...</p>
                <span className="loading-spinner"></span>
              </div>
            ) : (localReferralCode) ? (
              // Mostra prima il codice locale, se esiste E è valido
              <div className="code-display animate-pulse" onClick={handleRefferalCodeActions}>
                <span className="code-text">{localReferralCode}</span>
                <button 
                  className="copy-button-inline" 
                  onClick={handleShare}
                >
                  <IoCopy className="copy-button-inline__copy-icon" />
                  Copia
                </button>
              </div>
            ) : (user?.referralCode && isValidCode) ? (
              // Altrimenti, mostra il codice dal profilo utente se è valido
              <div className="code-display animate-pulse" onClick={handleRefferalCodeActions}>
                <span className="code-text">{user?.referralCode}</span>
                <button 
                  className="copy-button-inline" 
                  onClick={handleShare}
                >
                  <IoCopy className="copy-button-inline__copy-icon" />
                  Copia
                </button>
              </div>
            ) : (
              // Mostra il pulsante per generare quando non c'è nessun codice valido
              <div className="code-generator">
                <p className="code-generator-text">
                  {user?.referralCode && !isValidCode 
                    ? "Il tuo codice referral non è più valido per i prodotti attuali." 
                    : "Non hai ancora un codice referral."}
                </p>
                {generationStatus === "error" && 
                  <p className="code-generation-error">Si è verificato un errore nella generazione. Riprova.</p>
                }
                <button 
                  className="generate-code-button" 
                  onClick={generateNewCode}
                  disabled={isLoading}
                >
                  Genera Codice
                </button>
              </div>
            )}
          </div>
        </div>
        
        <div className="stats-container">
          <div className="stat-card animate-fade-in">
            <div className="stat-icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                <circle cx="9" cy="7" r="4"></circle>
                <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
              </svg>
            </div>
            <div className="stat-value animate-count-up">{friendsInvited}</div>
            <div className="stat-label">Amici Invitati</div>
          </div>
          
          <div className="stat-card animate-fade-in">
            <div className="stat-icon">
              <GrMoney />
            </div>
            <div className="stat-value animate-count-up">€ {amountEarned}</div>
            <div className="stat-label">Valore Guadagnato</div>
          </div>
        </div>
        
        <h2 className="how-it-works-title animate-fade-in">Come Funziona</h2>
        
        <div className="steps-container">
          <div className="step animate-slide-up">
            <div className="step-number">1</div>
            <div className="step-content">
              <h3 className="step-title">Condividi il Codice</h3>
              <p className="step-description">Invia il tuo codice referral unico agli amici</p>
            </div>
          </div>
          
          <div className="step animate-slide-up">
            <div className="step-number">2</div>
            <div className="step-content">
              <h3 className="step-title">Gli Amici si Uniscono</h3>
              <p className="step-description">I tuoi amici si registrano usando il tuo codice</p>
            </div>
          </div>
          
          <div className="step animate-slide-up">
            <div className="step-number">3</div>
            <div className="step-content">
              <h3 className="step-title">Guadagna Premi</h3>
              <p className="step-description">Ottieni 5€ per ogni amico che si iscrive a StudierAI Premium Annuale</p>
            </div>
          </div>
        </div>
      <div className="ReferralCode__container__giftcards-container">
        <h3 className="ReferralCode__card__title">Giftcards</h3>
        <span className="ReferralCode__container__giftcards-container__subtitle">
          Le ricompense appariranno qui sotto ogni primo giorno del mese.
        </span>
        <div className="ReferralCode__container__giftcards-container__giftcards-table">
          <div className="ReferralCode__container__giftcards-container__giftcards-table__giftcard">
            Nessuna giftcard disponibile
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default ReferralCode;
